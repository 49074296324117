/*accordian css*/

.faq-body ul .inner {
  /*padding-left: 1em;
  overflow: hidden;*/
  display: none;
  padding-bottom:20px;
}

.faq-body, ul.accordion,
ul.accordion li{
	position:relative;
}
ul.accordion > li > a:after {   
    content: ' \276F';
    transform: rotate(90deg);
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
}
.faq-body:before{
	content: "";
    //background-image: url(/images/dot-bgv4.png);
    height: 80%;
    width: 100%;
    position: absolute;
    background-repeat: repeat;
    top: 30%;
    left: 0px;
    z-index: -1;
    background-position: center;
    background-size: unset; 
	@include breakpoint(medium){
    	top: 40%;
    
    }
}
.faq-body__title{
	font-size:25px!important;
	@include breakpoint(medium){
		font-size:32px!important;
		//text-align:center;
	}
	&.centered{
		@include breakpoint(medium){
			font-size:39px!important;
			text-align: center;
		}
	}
}
.faq-body__subtitle{
	font-size:16px;
	margin-bottom:35px;
	@include breakpoint(medium){
		//text-align: center;
		font-size:17px;
		margin-bottom:60px;
	}
	&.centered{
		@include breakpoint(medium){
			text-align: center;
		}
	}
}
.faq-body__row{
	display: flex;
	gap:70px;
	row-gap:35px;
	flex-wrap: wrap;
	margin-top:25px;
	@include breakpoint(large){
		flex-wrap: nowrap;
		margin-top:55px;
	}
}
.faq-body__box{
	//background: #FFFFFF 0% 0% no-repeat padding-box;
	//box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	//border: 1px solid $color-business-blue;
	//border-radius: 12px;
	//padding:25px 20px!important;
	//@include breakpoint(medium){
	//	width:80%;
	//	padding:35px 50px!important;
	//	margin:0 auto!important;
	//}
	//@include breakpoint(large){
	//	width:70%;
	//	padding:50px 80px!important;
	//	margin:0 auto!important;
	//}
}
.faq-body ul.accordion { 
	flex-basis:100%;
	@include breakpoint(large){
		flex-basis:70%;
	}
}
.faq-body ul.accordion > li {
    margin: 0em 0;
    //border-bottom: 1px solid $color-business-blue;
	background-color:#F8F8FA;
    list-style: none;
    //margin-bottom: 10px;
    font-size: 16px;
    position: relative;
	border-radius: 12px;
	border:none;
	margin-bottom: 10px;
	@include breakpoint(large){
		margin-bottom:20px;
	}
}
.faq-body ul > li:last-of-type {
    border-bottom: none;
	margin-bottom:0px;
}

.faq-body ul > li a.toggle {
    width: 100%;
    display: block;
    background: #F8F8FA;
	border-radius: 12px;
    transition: background 0.3s ease;
    padding:  25px 40px;
	padding-right:60px;
    color: #1E225C;
    font-weight: 600;
    font-size: 16px;
    text-decoration:none;
}
.faq-body ul div.inner{
    padding:0px 40px;
	padding-bottom:25px;
}
.faq-wrapper {
    margin: 80px 0px;
	@include breakpoint(large){
    margin: 120px 0px;
	}
}
.faq-wrapper--dark{
	background:#F8F8FA;
	margin:0px!important;
	padding-bottom:120px;
	.faq-body ul.accordion{
		background-color: #F8F8FA;
	}
	.faq-body ul.accordion > li{
		background-color: white;
	}
	.faq-body ul.accordion > li > a.toggle{
		background-color: white;
	}
}
.faq-readmore{
	margin:20px auto;
	text-align:center;
	&.left{
		text-align: left;
	}
}
.banner-wrapper {
    position: relative;
    min-height: 550px;
}

@include breakpoint(medium up){

	.faq-body ul > li a.toggle{
		//padding-right: 25px;
	}
}

	ul.accordion li a:after{
		right: 40px!important;;
		top:24px!important;
	}
	.faq-wrapper {
	    margin-bottom: 60px;
	}

	.faq-callout-container{
		flex-basis: 100%;
		@include breakpoint(large){
			flex-basis: 26%;
		}
	}
.faq-callout{
	//background: #00C4C2 0% 0% no-repeat padding-box;
	border: 1px solid #1E225C;
	border-radius: 12px;
	display:flex;
	width:100%;
	margin:0 auto;
	@include breakpoint(medium){
		width:85%;
	}
	@include breakpoint(large){
		width:65%;
	}
	&.singlecol{
		display:block;
		padding:0px;
		@include breakpoint(medium){
			width:100%;
			position: sticky;
			top: 100px;
		}
		@include breakpoint(large){
			width:100%;
		}
	}
	&__text{
		padding:20px;
		&__title{
			font-size:20px;
			font-weight:800;
			color:$color-business-blue;
			@include header-text;
			padding-bottom:12px;
		}
		&__subtext{
			font-size:16px;
			color:$color-business-blue;
		}
		&__buttons{
			margin:20px 0px;
			a .text{
				@include header-text;
				font-weight:800;
				font-size:21px;
				@include breakpoint(large){
					font-size:22px;
				}
			}
			a .icon img { margin-top:5px; width:24px; }
		}
	}
	&__image{
		text-align: center;
		img{max-width: 300px; margin:0 auto;}
	}
}