$color-orange: #ff9900;//#ffa120;//#f5891e;
$color-orange-light: #ffbf6e;
$color-orange-dark: #ff892e;
$color-orange-shadow: #f0cda0;

$color-purple: #403d54;
$color-purple-light: #7b7795;
$color-purple-dark: #232130;
$color-purple-shadow: #a09eb1;

$color-business-blue: #1e225d;
$color-business-blue-normal: #201B66;
$color-business-blue-lighter: #2471B9;
$color-business-blue-light: #206bb4;
$color-business-blue-dark: #1b2a68;
$color-business-blue-darker: #09355c;
$color-business-blue-shadow: #9ea8b0;

$color-blue: #1E225C; 
$color-blue-selected:#106BC6;
$color-blue-light: #5992d2;
$color-blue-ultralight: #f2f8ff;

$color-line: #e3e5e7;

$color-white: #fff;
$color-gray: #D6D6D6;
$color-ultralight-gray: #fbfbfb;
$color-lightest-gray: #bbbbbb;
$color-lighter-gray: #f6f6f6;
$color-light-gray: #e6e6e6;
$color-gray-background: #F8F8FA;
$color-medium-light-gray: #a7a7a7;
$color-medium-gray: #cacaca;
$color-darker-gray: #888C93;
$color-dark-gray-light:#464646;
$color-dark-gray: #8a8a8a;
$color-darker-gray: #505050;
$color-black: #0a0a0a;

$color-light-green: #02ABAC;


/* Business school styles voor landing */
$trainingen-menu-left-width: 220px;
$trainingen-menu-height: 625px;

$payoff-background: #989490;

/* Generic */
$color-header: $color-business-blue;
$color-dark-primary: $color-business-blue;
$color-darker-primary: $color-business-blue;
$color-light-primary: $color-orange;

$block-hover: #f7f7f7;
$block-hover-border: darken($color-line, 3%);
$block-hover-shadow: inset 0 0 20px rgba(80, 80, 80, 0.08);

/* Site switch */
$site-switch-background: #1e225d;

/* Facebook */
$facebook-background: #3b5998;
$facebook-color: white;

/* Buttons */
$cf-button-color: $color-purple;
$cf-button-color-shadow: $color-purple-shadow;

/* Top Menu */
$top-menu-color-dark: $color-business-blue-dark;
$top-menu-color-light: $color-business-blue;

/* Main Menu */
$main-menu-color-dark: $color-business-blue-dark;
$main-menu-color-light: $color-business-blue;

/* Cart */
$shopping-cart-button-color: $color-orange;
$shopping-cart-button-color-light: $color-orange-light;

/* Post */
$post-summary-header: $color-header;
$post-summary-text: $color-header;

/* Sidebar blocks */
$sidebar-block-background: $color-business-blue;
$sidebar-block-background-light: $color-business-blue-lighter;

$calendar-date-dark: $color-purple;
$calendar-date-light: $color-purple-light;

$color-mobile-gradient: lighten($color-light-primary, 40%);

