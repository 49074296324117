.cta-block{
	&__intake{
		position:relative;
		margin:75px 0px;
		&.greybackground{
			margin:0px;
			padding:75px 0px;
			background-color:rgb(248, 248, 250);
		}
		&__lettersbg{
			display:none;
			@include breakpoint(large) {
			display:block;
			position:absolute;
			width:55%;
			height:100%;
			left:45%;
			top: 20px;
			z-index:-1;
			font-size:400px;
			overflow:hidden;
			@include header-text;
			font-size: 750px;
			font-weight:800;
			letter-spacing: -90px;
			line-height: 545px;
			color:#00c4c2;
			}
		}
		&__text{
			padding-top:20px;
			padding-bottom:40px;
			@include breakpoint(medium) {
				padding-bottom:40px;
				padding-top:100px;
			}
			font-size:rem-calc(18px);
			& > div{
				@include breakpoint(xlarge){
					width:80%;
				}
			}
			h2 { 
				font-size:20px!important; 
				@include breakpoint(medium){
					font-size:36px!important; 
				}
				
			}
			p {
				@include breakpoint(medium){
				font-size:rem-calc(18px);
				}
				margin-bottom:40px;
			}
			.cf-button--orange-flat{
				.cf-button__text{
					font-size:rem-calc(18);
				}
				.cf-button__icon{
					top:-0.125em;
				}
			}
			&__callout{
				margin-top:10px;
				box-shadow: 0px 3px 10px rgba(42,62,82,0.129);
				border: 1px solid $color-business-blue;
				border-radius: 8px;
				@include breakpoint(medium){
					margin-top:40px;
				
				}
				&__title{
					background-color:$color-business-blue;
					color:white;
					@include header-text;
					padding:8px 25px;
					font-size:rem-calc(18);
					font-weight:800;
					text-align:center;
					@include breakpoint(large){
						padding:8px 25px;
						text-align:left;
					}
				}
				&__content{
					padding:14px 10px;
					@include breakpoint(large){
						padding:20px 25px;
					}
					&__item{
						display:flex;
						flex-wrap:wrap;
						text-decoration:none;
						margin-bottom:10px;
						.icon {
							margin-right:5px;
							img{
								width:15px;
								filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
							}
							@include breakpoint(medium){
								margin-right:20px;
								img {
									width:24px;
								}
							}
						}
						.text{
							font-weight:600;
							font-size:rem-calc(15);
							@include breakpoint(medium){
								font-size:rem-calc(16);
							
							}
						}
						&:hover{
							text-decoration:underline;
						}
						&:last-of-type{
							margin-bottom:0px;
						}
					}
				}
			}
		}
		&__title{
			padding-top:100px;
			padding-bottom:100px;
			p {
				margin:0px;
				font-size:rem-calc(18px);
			}
		}
		&__designation{
			padding:10px 15px;
			background-color:white;
			font-size:rem-calc(14);
			box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
			strong{
				font-weight:800;
				@include header-text;
			}
		}
		&__image{
			&__lettersbg{
				height:100%;
				width:100vw;
				z-index:-1;
				font-size:400px;
				overflow:hidden;
				@include header-text;
				font-size: 350px;
				font-weight: 800;
				letter-spacing: -30px;
				line-height: 250px;
				position:absolute;
				left: -20px;
				text-align:right;
				@include breakpoint(medium) {
					text-align: center;
				}
				@include breakpoint(large) {
					display:none;
				
				}
			}
			display: flex;
				position:relative;
				height:300px;
				//padding: 0rem 2.5rem;
    			//padding-left: 2.5rem;
				@include breakpoint(large) {
    				padding-left: 1.5rem;
					height:inherit;
				}
				& > img {
					position:absolute;
					bottom:0px;
					right:0px;
					max-height:350px;
					@include breakpoint(medium){
						left:50%;
						transform:translateX(-50%);
					}
					@include breakpoint(large) {
						//max-width:65%;
						left:unset;
						transform: none;
						right:0px;
						//width:100%;
						//height:500px;
						max-height:500px;
					}
				}
			&__textbox{
				//position:absolute;
				//bottom:0px;
				//width:90%;
				//text-align:left;
  				align-self: flex-end;
  				width:100%;
				@include breakpoint(large) {
					position:relative;
					width:90%;
					width: 96%;
					//margin-left:10%;
					bottom: -40px;
				}
				&.training{
					@include breakpoint(large) {
						bottom: -20px;
					}
				}
			}
			.cluster-header__background__designation{
				display:inline-block;
				position:relative;
				bottom:0px;
				left:8px;
				margin-bottom:8px;
			}
			.cluster-header__background__overlay__reviews{
				position:relative;
				bottom:0px;
				width:100%;
			}
		}
	}
	&__brochure{
		background-color:#58c2c1;
		margin:75px 0px;
		&__text{
			padding-top:40px;
			padding-bottom:100px;
			@include breakpoint(medium){
				padding-top:100px;
				padding-bottom:100px;
			}
			color: $color-blue
			h2 { font-size:2rem; margin-bottom:25px; }
			&__description { margin-bottom:20px; color: $color-blue;}
		}
		&__usps{
			list-style-type:none;
			margin-left:0px;
			margin-bottom:25px;
			&__usp {
				display:inline-block;
				width:85%;
			}
			& li {
				@include poppins;
				font-size:1rem;
				font-weight:bold;
				margin-top:15px;
				color: $color-business-blue-dark;
				.fa, .svg-inline--fa {
					font-size:1.2rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
					vertical-align:top;
				}
			}
		}
		&__image{
			/*position:relative;*/
			padding-top:100px;
			@include breakpoint(medium){
				padding:50px 0px;
			}
			img {
				width:50%;
				
				@include breakpoint(medium up) {
					width:70%;
					max-width:230px;
				}
				/*@include breakpoint(large) {
					position:relative;
					bottom:40px;
					max-width:65%;
					width:100%;
				}*/
			}
		}
	}
	&__event{ 
		background-color:#58c2c1;
		margin:75px 0px;
		&__text{
			padding-top:40px;
			padding-bottom:100px;
			@include breakpoint(medium){
				padding-top:100px;
				padding-bottom:100px;
			}
			h2 { font-size:2rem; margin-bottom:25px; }
			&__description { margin-bottom:20px; }
		}
		&__usps{
			list-style-type:none;
			margin-left:0px;
			margin-bottom:25px;
			&__usp {
				display:inline-block;
				width:80%;
			}
			& li {
				@include poppins;
				font-size:1rem;
				font-weight:bold;
				margin-top:15px;
				color: $color-business-blue-dark;
				.fa, .svg-inline--fa {
					font-size:1.2rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
					vertical-align:top;
				}
			}
		}
		&__image{
			position:relative;
			padding-top:100px;
			@include breakpoint(medium){
				padding-top:0px;
			}
			img {
				width:100%;
				border-radius:0px 0px 0px 70px;
				
				@include breakpoint(medium) {
					position:absolute;
					top:40px;
					width:80%;
				}
				@include breakpoint(large) {
					position:absolute;
					top:40px;
					max-width:100%;
					width:100%;
				}
			}
		}
	}
	&__callout{
		background: #00C4C2 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
		border: 1px solid #1C2663;
		border-radius: 12px;
		padding:0px;
		width:100%;
		& > div {
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}
		&__details{
			padding:1.5rem 2rem;
			@include breakpoint(large){
				padding-right: 1rem;
			}
			&__links-box{
				a{ margin-bottom:10px;}
				@include breakpoint(large){
					display:flex;
					
					a{ margin-bottom:0px;}
				}
				a.cf-button--blue{ color:white!important; }
				a.cf-button--blue-flat-alt.outline .cf-button__icon{
					margin-right:10px;
					margin-left:0px;
				}
			}
		}
		&__image{
			padding:0;
			text-align:center;
			img{
				max-height:200px;
				@include breakpoint(large){
					max-height:unset;
				}
			}
			
		}
		&.contact > div{
			@include breakpoint(medium){
				flex-wrap:nowrap;
			}
		} 
	}
	&--general{
		background-color:$color-business-blue;
		.cta-block{
			&__intake{
				background-color:$color-business-blue;
				&__text{
					color:white;
					h2 { color:white; }
					p { color:white; }
				}
				&__title{
					p {
						color:white;
					}
				}
			}
			&__brochure{
				background-color:$color-business-blue;
				
				&__text{
					h2 { color:white; }
				}
				&__usps{
					&__usp {
					}
					& li {
						color: white;
						.fa, .svg-inline--fa {
							color:white;
						}
					}
				}
			}
		}
	}
	&--cyan{
		background-color:$color-business-blue;
		.cta-block{
			&__intake{
				background-color:$color-business-blue;
				&__text{
					color:white;
					h2 { color:white; }
					p { color:white; }
				}
				&__title{
					p {
						color:white;
					}
				}
			}
			&__brochure{
				background-color:$color-business-blue;
				
				&__text{
					h2 { color:white; }
				}
				&__usps{
					&__usp {
					}
					& li {
						color: white;
						.fa, .svg-inline--fa {
							color:white;
						}
					}
				}
			}
		}
	}
}