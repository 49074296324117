.grid-view{
	row-gap:20px;
	.grid-item{
		@include breakpoint(large){
			padding-right: 0.625rem;
			padding-left: 0.625rem;
		}
	}
}
.clusterprijsbottom{
	display:none;
	font-size:16px !important;
}
.keuzehulp_cluster{
	display:none !important;
}
.training-grid-block {
  display: block;
  position:relative;
  background-color:white;
  border-radius:2px 16px 16px 16px;
  //height:100%;
  box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
  @include transition(background 400ms, box-shadow 400ms);
  margin-bottom:0px;
  padding:7px;	
  &, &:active, &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  	@include transition(all 400ms);

  }

  @include breakpoint(medium) {
    //height: 460px;
  }

  .detailinformatie{
	bottom: 5%;
	position: absolute;
	background: none;
	padding-left:25px;

	.cursustype{
		background-color:#FFF500;
		border-radius: 100px;
		color:#1E225C;
		padding-top: 2px;
		padding-bottom: 2px;
		padding-left: 10px;
		padding-right: 10px;
		font-family: 'Lexend', sans-serif;
	}
	.cursusdays{
		font-family: 'Lexend', sans-serif;
		color:white;
		margin-left: 5px;
	}
  }
  
  &__favorite {
  	height:30px;
  	background-color:white;
  	position:absolute;
  	right:0px;
  	top:-5px;
  	z-index:4;
	border-radius: 0px 0px 0px 6px;
	display:flex;
	justify-content: center;
	align-items: center;
	padding:0 9px;
	&::after{
		content:"Bewaar";
		color:$color-business-blue;
		font-size:14px;
		font-family: 'lexend', sans-serif;
		padding-left:2px;
	}
  	&.selected{
 		//border: 1px solid #FF4F57;
  		&::after{
  			content:"Bewaard";
  		}
  		&.collection{
  			padding:5px 5px 5px 5px;
			&::after{
				content:"Verwijder";
			}
  		}
  		img.selected{
  			filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
  			display:block;
  			margin:5px!important;
  			margin-left:0px !important;
			height:50%;
			width:unset!important;
			//padding-left:2px;
			&.trashcan{
				height:80%;
			}
  		}
  		img.notselected{
  			display:none;
  			margin:5px!important;
  			margin-left:0px !important;
			height:50%;
			width:unset!important;
			//padding-left:2px;
  		}
  		&.collection{
  			img.selected{
  				filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
  			}
  		}
  	}
  	&.notselected{
  		img.selected{
  			display:none;
  			margin:5px!important;
  			margin-left:0px !important;
			height:50%;
			width:unset!important;
			//padding-left:2px;
  		}
  		img.notselected{
  			filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
  			display:block;
  			margin:5px!important;
  			margin-left:0px !important;
			height:50%;
			width:unset!important;
			//padding-left:2px;
  		}
  	}
  	&:hover{
  		cursor:pointer;
  		&.notselected{
			img.notselected{
				filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
				transition:(all 400ms);
			}
		}
  	}
  }
  &__aiproof{
	position:absolute;
  	top:10px;
  	left:15px;
  	img{
  	//filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(78deg) brightness(109%) contrast(101%) drop-shadow(0px 0px 4px #000);
  	//width:18px;
	height:60px;
	}
  }
  &__status-label{
	position:absolute;
  	top:10px;
  	left:15px;;
	background-color: #f2f8ff;
	padding: 5px 13px;
	border-radius: 6px;
	text-transform: capitalize;
	color: #1e225d;
	font-size: 12px;
	font-weight: bold;
  }
  &__image {
    width: 100%;
    height: 210px;
    border-radius:6px 6px 6px 6px;
    position: relative;
    & > img {
      border-radius:6px 6px 6px 6px;
      width: 100%;
      height:210px;
      object-fit:cover;
    }
    &__overlay{
		width: 100%;
		position: absolute;
		height: 100%;
    	opacity:1;
		border-radius: 8px;
    	//background: linear-gradient(to top left, transparent 50%, rgba(0, 0, 0, 0.5));
		background: transparent linear-gradient(7deg, rgba(30,34,44,0.482) 0%, rgba(92,92,92,0) 100%) 0% 0% no-repeat padding-box;
		@include transition(all 400ms);

    }
	&.letters{
		overflow: hidden;
		padding: 30px;
		padding-bottom:0px;
		& > img{
			border-bottom:1px solid $color-business-blue;
			height:180px;
			position:relative;
			z-index:5;
			object-fit: contain;
		}
	}
  }
  &__pricelabel{
  	position:absolute;
  	display:flex;
  	align-items:center;
  	top:15px;
  	left:30px;
  	color:white;
  	opacity:0;
  	font-size:16px;
  	font-weight:bold;
  	text-shadow: 0px 0px 4px #000000;
  	&.show{
  		opacity:1;
  	}
  	img{
  	filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(78deg) brightness(109%) contrast(101%) drop-shadow(0px 0px 4px #000);
  	width:18px;
  	height:18px;
  	margin-right:7px;
  	}
  }
  &__infolabel{
	position:absolute;
  	display:flex;
  	align-items:center;
	gap:20px;
  	bottom:15px;
  	left:20px;
  	color:white;
  	font-size:12px;
	font-weight: 600;
  	font-weight:bold;
	&.jobs-demand{
		font-size:11px;
		@include breakpoint(medium){
			font-size:12px;
		}
	}
	&__type{
		background-color:$color-business-blue;
		padding:5px 13px;
		border-radius: 6px;
		text-transform: capitalize;
	}
	//&.heeftlabel{
	//	left:20px!important;
	//	bottom:50px!important;
	//}
  }
  &__author{
  	position:absolute;
  	display:flex;
  	align-items:center;
  	top:15px;
  	left:20px;
  	color:white;
	span { color: white; }
  	opacity:0;
  	font-size:14px;
  	font-weight:bold;
  	//text-shadow: 0px 0px 4px #000000;
	@include header-text;
  	&.show{
  		opacity:1;
  	}
  	img{
  	filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(78deg) brightness(109%) contrast(101%) drop-shadow(0px 0px 4px #000);
  	width:18px;
  	height:18px;
  	margin-right:7px;
  	}
	&__date{
		font-weight:500;
		padding-left:3px;
	}
  }
  &__label {
  	display:inline-block;
  	padding:6px 14px;
  	background-color:yellow;
  	border-radius: 6px;
  	position:absolute;
	left:20px;
	bottom: 55px;
	@include breakpoint(large){
		bottom:50px;
	}
  	//bottom:15px;
  	//left:20px;
  	font-size:rem-calc(12px)!important;
	max-width: 90%;
	font-weight:500;
	&.has-tip{
		border-bottom:0px;
	}
	img{
		width:15px;
		display:inline-block!important;
	}
  	&--light{
  		background-color:white;
  		border:1px solid $color-business-blue;
  		//border-radius:4px;
  		left:0px;
		bottom:10px;
  		z-index: 10;
  	}
	&--blue{
		background-color: $color-business-blue;
		color:white;
		//font-size:14px!important;
	}
  	
  }
  
  &__lettersbg {
  	@include header-text;
  	font-size:280px;
  	line-height:200px;
  	letter-spacing:-26px;
  	color:#00C4C2;
  	position:absolute;
  	bottom:0px;
  	left:50%;
  	transform: translateX(-55%);
  	font-weight:900;
  }
  &__content {
    display: flex;
    flex-direction: column;
    //padding: 20px 15px;
	height:53%;
    @include breakpoint(medium) {
      //height: 460px - 175px;
      //padding: 15px 11px 10px;
      min-height:200px;
      //padding-top:25px;
    }
    &__infotext{
    		padding:25px 20px 0px 20px;
    	@include breakpoint(medium) {
    		padding: 20px 20px 0px 20px;
    	}
    	@include breakpoint(large){
    	
    		padding: 20px 20px 0px 20px;
    	}
    	@include breakpoint(xlarge){
    	
    		padding:25px 20px 0px 20px;
    	}
    }
    &__text{
    	height: 150px;
		@include breakpoint(large){
			height: 180px;
		}
    	text-overflow: ellipsis;
    }
  }

  &__title {
    width: 100%;
    color: $color-business-blue;
    @include header-text;
    font-weight: 800;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 15px;
	@include transition(all 400ms);
	@include breakpoint(medium){
		font-size: 22px;
		
	}
	&.postgridblock{
		font-size: 20px;
		font-family: 'Poppins', sans-serif;
	}

  }
  
  &__intro {
    width: 100%;
    line-height: 1.35;
    flex-grow: 1;
    max-height: 8em;
    font-weight:1rem;
    margin: 0 0 1rem;
    overflow: hidden;
    @include breakpoint(medium) {
      margin: 0;
    }

	&.postgridblock{
		font-size: 16px;
		font-weight:500;
	}
  }
  &__date{
  	color:#bababa;
	font-size:0.8rem;
  	display:block;
  	margin-bottom:10px;
  }
  &__price{
  	color:$color-orange;
  	font-weight:600;
  	font-size: 20px!important;
  	display:block;
	margin-top:1rem!important;
	font: normal normal 600 20px Lexend;

	span{
		font: normal normal 600 20px Lexend;
		color:$color-orange;
	}
  }
  &__bottom{
  	//align-self:flex-end;
  	//border-top:1px solid #E3E3E3;
  	display:flex;
	  padding:11px 20px 25px 20px;

  	
    	@include breakpoint(medium) {
			padding:11px 20px 17px 20px;

    	}
    	@include breakpoint(large){
    	
			padding:11px 20px 17px 20px;

    	}
    	@include breakpoint(xlarge){
    	
			padding:11px 20px 25px 20px;

    	}
	justify-content:space-between;
	align-items:center;
	&__info {
		display:flex;
		align-items:center;		
		flex-wrap: wrap;
		flex-grow:2;
		column-gap: 15px;
		@include breakpoint(medium){
			column-gap: 25px;
		}
		@include breakpoint(large){
			column-gap:35px;
		}
		//margin-bottom:15px;
		flex-direction: row;
		width: 100%;
		& > div{
			flex-basis: auto;
			display:flex;		
			//flex-basis: 30%;
			align-items:center;
			font-weight: 500px;
			@include header-text;
			font-size:14px;
			font-weight:600;
			font-weight:400;
			column-gap:7px;
			color: $color-business-blue;
			& svg path{
			 fill:#bababa;
			}
			img { width:16px; height:16px; filter: invert(70%) sepia(10%) saturate(367%) hue-rotate(199deg) brightness(94%) contrast(90%);}

			&.label {
				background-color:#FFF500;
				border-radius:100px;
				color:$color-business-blue;
				flex-basis: unset;
			}
			&.nolabel{
				padding: 0.33333rem 0rem;
				line-height:1;
				
				/*&::after{
					content:"\2022";
					margin-left:5px;
				}*/
			}
		}
		&__price {
			&.offer{
				color:$color-orange;
			}
		}
	}
	&__action{
		display:flex;
		align-items:center;		
		align-self:flex-end;
	}
	&__view{
    	font-size: 16px;
    	color:$color-orange;
    	font-weight:500;
    	margin-right:10px;
    	opacity:0;
    	display:none;
    	@include breakpoint(medium) {
    		display:none;
    	}
    	@include breakpoint(large){
    		display:none;
    	}
    	@include breakpoint(xlarge){
			//display:block;
    		display:none;
    	}
		&__inspiratie{
			font-size: 16px;
			color:$color-orange;
			font-weight:500;
			margin-right:10px;
			opacity:0;
			display:none;
			@include breakpoint(medium) {
				display:none;
			}
			@include breakpoint(large){
				display:none;
			}
			@include breakpoint(xlarge){
				//display:block;
				display:none;
			}
		}
	}
	&__clusterview{
		color:$color-orange;
		margin-right: 10px;
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight:500;
	}
	  
  }
  &__action {
  	margin-bottom:0px;
  	border-radius:4px;
	@include transition(all 400ms);
	&.cf-button--orange-flat{
		height:23px;
		width:23px;
		padding:5px 0px;
	}
  
  	svg{
  		transform: scale(0.7);
  	}
  	.cf-button__icon{
  		top:0px;
  		line-height: 12px;
  	}
  	.cf-button__icon img{
  		height:12px;
  	}
  }
  

  &--home {
    height: auto;
    @include breakpoint(large) {
      height: 460px;
    }
    .training-grid-block {
      &__content {
        height: auto;
        @include breakpoint(large) {
          height: 460px - 175px;
        }
      }

      &__intro {
        margin: 0 0 1rem;
        @include breakpoint(large) {
          margin: 0;
        }
      }

    }
  }


  &:hover, &:focus, &:active {
    //background: $block-hover;
  	box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
    border-color: $block-hover-border;
	.clusterprijs{
		display:none;
	}
	
	.clusterprijsbottom{
		display:block;
	}

	.keuzehulp_cluster{
		top:0;
		background-color:transparent;
		border: 1px solid white;
		display:flex !important;
		&::after{
			color:white;
		}
		img{
			filter: invert(100%) sepia(4%) saturate(3%) hue-rotate(39deg) brightness(103%) contrast(101%);
		}
	}
    .training-grid-block {
		&__title {
			color: $color-orange;
			@include transition(all 400ms);
		}
		&__action {
			@include cf-button-orange-hover;
		}
		&__bottom{
			&__view{
				opacity:1;
				@include transition(all 400ms);

				&__inspiratie{
					display:block;
					opacity:1;
					@include transition(all 400ms);
				}
			}
		}
		&__pricelabel{
			@include transition(all 400ms);
			opacity:1;
		}
		&__author{
			@include transition(all 400ms);
			opacity:1;
		}
		&__image{
			&__overlay{
				width:100%;
				position:absolute;
				height:100%;
				opacity:1;
				background-color:rgba(32, 42, 54, 0.5);
    			border-radius:6px 6px 6px 6px;
				@include transition(all 400ms);
			}
		}
    }
  }
  	&--keuzehulp{
		height:100%;
		display:flex;
		align-items:center;
		.training-grid-block {
			&__content{
				padding:50px;
				display:flex;
				align-items:flex-start;
				align-content:center;
				justify-content:center;
				height:inherit;
				p {
					margin-bottom:25px;
				}
			}
  			&__title {
  				font-size:1.7rem;
  			}
			&__intro{
				flex-grow:unset;
			}
		}
	}
	&--stap{
		.training-grid-block__image{
			padding: 9px;
			& > img{
				height:201px;
			}
			&.letters{
				padding: 30px;
				padding-bottom:0px;
				& > img{
					height:180px;
					position:relative;
					z-index:5;
					object-fit: contain;
				}
			}
		}
		.training-grid-block__content__text{
			height:200px;
		}
		.training-grid-block__title{
			margin-top:10px;
		}
		.training-grid-block__intro{
			font-size:1rem;
		}
		.training-grid-block__label{
			
		}
		.training-grid-block__content__infotext{
			@include breakpoint(large){
				margin-bottom:20px;
			}
		}
		.training-grid-block__bottom__info{
			column-gap: 10px;
			margin-bottom:20px;
			& > div{
				font-size:1rem;
			}
			&__days{
				flex-basis:36%;
			}
			&__price{
				flex-basis:60%;
				justify-content: flex-end;
			}
		}
	}
.inspiratie-grid-bottom{
	justify-content: end;
}
.inspiratie-bottom-info{
	margin-bottom: 20px;
}
.inspiratie-video-image-overlag{
	display: grid;
	justify-content: center;
	align-content: center;
}

.training-grid-block__infolabel{
	position: absolute;
	bottom: 20px;
	//left: 10px;
	@include breakpoint(medium){
		bottom: 15px;
	}
	&__tekst{
		color: white;
		padding: 5px 15px;
		font-weight: 600;
		&.withbackground{
			background: #1E225C;
			border-radius: 100px;
			font-size: 13px;
		}
	}
}
}