.beoordeling-block {
	background-color:$color-gray-background;
	padding-top:75px;
	padding-bottom:75px;
	position:relative;


	&:after{
		content: "";
		position: absolute;
		left: 50%;
		bottom: -75px;
		margin-left: -50px;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;
		border-top: 30px solid $color-gray-background;;
		border-bottom: 50px solid transparent;
	}
	&.keuzehulp{
		@include breakpoint(medium){
			margin-left:auto; 
			margin-right:auto;
		}
		&:after{
			display:none;
		}
		.row{

		}
		.beoordeling-block__item__row {
			&.keuzehulp{
				@include breakpoint(medium){
					margin-left: auto;
				}
			}
		}
		.divtitlekeuzehulp{
			@include breakpoint(medium){
				margin-left: auto;
			}
		}

		.column { display: block;}
	}	
	&__title{
		@include header-text;
		font-size:rem-calc(25px);
		font-weight:800;
		color:$color-business-blue;
		text-align:center;
		@include breakpoint(large){
			width:40%;
		}
		&.keuzehulp{
			font-size:rem-calc(20px);
			font-weight:600;
			width:initial;
			text-align:start;
			margin-bottom:7px;
		}
	}
	&__subtitle{
		margin-top:14px;
		text-align:center;
		a {
			@include header-text;
		}
		&.keuzehulp{
			font-weight:300;
			margin-top: 0;
			text-align: left;
		}
		
	}
	.column{
		display:grid;
		justify-items: center;

		&.keuzehulp{
			justify-items: start;
		}
	}
	&__item{
		&__row{
			margin-top:35px;
			justify-content:center;

			&.keuzehulp{
				justify-content:start;
				gap:28px;
				@include breakpoint(large){
					gap:100px;
				}
			}
		}
		&.keuzehulp{
			justify-content:start;
		}
		&__logo{
			text-align:center;
			
			img{
				height: 7vh;
				@include breakpoint(medium){
					height: 12.6vh;
				}
				object-fit: contain;

			}

			&.keuzehulp{
				height: 45px;
				@include breakpoint(medium){
					height: 45px;
				}
				img{
					height:100%;
					@include breakpoint(medium){
						height:120%;
						&.springtestlogo{
							height:100%;
						}
					}
				}
				@include breakpoint(large){
					text-align: left;
				}
			}

			.springtestlogo{
				margin-left:2vw;
				@include breakpoint(medium){
					padding: 15px;
				}
				&.keuzehulp{
					@include breakpoint(medium){
						padding: 0px;
					}
					margin-left:0px;
				}
			}
		}
		&__stars{
			text-align:center;
			margin-top:20px;
			color: rgb(255, 206, 0);
			svg path { fill:rgb(255, 206, 0); }

			&.keuzehulp{
				margin-top:16px;
				svg{
					width:12px;
					height:11px;
					margin-right:3px;
				}
			}
		}
		&__score{
			text-align:center;
			@include header-text;
			font-size:rem-calc(25px);
			font-weight:600;
			color:#46475D;
			margin-top:15px;

			&.keuzehulp{
				font-size:rem-calc(16px);
				text-align:start;
			}
		}
	}
}