.evaluatie-form {
  &__legend {
    @include raleway;
    font-weight: 700;
    color: $color-dark-primary;
    font-size: rem-calc(17px);
  }
  .evaluatieDIV{

    @media only screen and (max-width: 767px) {
      display:flex;
      flex-direction: row-reverse;

      &::after{
        content: "";
        height: 560px;
        width: 50px;
        position: absolute;
        left: 25px;
        background-image: linear-gradient(to right, #fff, transparent );
        pointer-events: none;
      }
    }
    table tbody tr td input{
      width: 20px;
      height: 20px;
    }
  }
  &__table-heading {
    text-align: left;
  }

  th, td {
    text-align: center;
    color:white;
  }
}