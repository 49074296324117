.trainingen-grid {

  margin: 1rem 0 2rem;
  @include breakpoint(medium) {
    margin: 3rem 0 2rem;
  }

  &__title {
    float: left;
    color: $color-header;
    margin: 0;
    font-size: 1.5rem;

    @include breakpoint(medium) {
      line-height: 1.8rem;
      font-size: 1.75rem;
      margin: 0 1rem 3rem 0;
    }
  }

  &__link-all {
    float: left;
    clear: left;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 1rem;

    em {
      font-style: normal;
      text-decoration: underline;
    }

    .icon {
      font-size: 1.1rem;
      position: relative;
      top: 2px;
      left: 2px;
    }

    @include breakpoint(medium) {
      clear: none;
      line-height: 2rem;
      margin-bottom: 0;
    }
  }

  &__item {
    margin: 0 0 2rem;
  }

  &__bekijk-aanbiedingen {
    padding: 20px 10px 20px 0;
    font-size: rem-calc(19);
    font-weight: 600;
    text-align: center;
    background: $color-lighter-gray;
    border-left: 1px solid $color-line;
    border-right: 1px solid $color-line;

    a {
      text-decoration: none;
    }

    i {
      position: relative;
      top: 2px;
      left: 8px;
    }
  }
}