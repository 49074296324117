.why-cf {
  //background: $color-ultralight-gray;
  padding: 25px 0px;
  padding-bottom:0px;
  background: linear-gradient(180deg, #ffffff 40%, #F5F5F5 40%);
  &__usp{
	row-gap: 20px;
  	&__container{
  		background-color:white;
  		border-radius:8px;
  		box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
  		padding:20px;
  		height:100%;
  	}
  	padding-bottom:75px;
  	&__icon {
  		height:128px;
  		margin-bottom:20px;
  		text-align:center;
  	}
  	
  	&__subtitle{
  		@include raleway;
  		color: $color-business-blue;
    	font-size: 1.3rem;
    	font-weight:bold;
    	text-align:center;
  	
  	}
  	&__text {
  		@include raleway;
  		text-align:center;
  	}
	&--prijs{
		.why-cf__usp__container{
			border: 1px solid #3BC3BE;
		}
  
	}
  
	&--locatie{
		.why-cf__usp__container{
			border: 1px solid #F5A001;
		}
  
	}
  
	&--creatief{
		.why-cf__usp__container{
			border: 1px solid #EF5155;
		}
  
	}
  
	&--top20{
		.why-cf__usp__container{
			border: 1px solid #b579db;
		}
	}
  }
  
  &__locatie{
  	&__foto{
  		background:url('/images/cf-locatie-new.jpg');
  		background-size:cover;
  		background-repeat:no-repeat;
  		background-position:center center;
  	}
  	width:100%;
  	max-width:100%;
  }
  &__prijs{
  	&__foto{
  		background:url('/images/cf-prijs-new.jpg');
  		background-size:cover;
  		background-repeat:no-repeat;
  		background-position:center center;
  	}
  	width:100%;
  	max-width:100%;
  }
  &__events{
  	&__foto{
  		background:url('/images/cf-events-new.jpg');
  		background-size:cover;
  		background-repeat:no-repeat;
  		background-position:center center;
  	}
  	width:100%;
  	max-width:100%;
  }
  &__community{
  	&__foto{
  		background:url('/images/cf-community.jpg');
  		background-size:cover;
  		background-repeat:no-repeat;
  		background-position:center center;
  	}
  	width:100%;
  	max-width:100%;
  }
  &__locatie__foto, &__prijs__foto, &__events__foto &__community__foto {
  	//-webkit-box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
	//-moz-box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
	//box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
  }
  &__locatie__tekst, &__prijs__tekst, &__events__tekst, &__community__tekst {
  	background:white;
  	//-webkit-box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
	//-moz-box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
	//box-shadow: 6px 8px 30px -6px rgba(148,148,148,1);
	padding:30px;
	& p {
		line-height:1.9;
	}
  	@include raleway;
	@include breakpoint(medium) {
		padding:50px;
	}
	@include breakpoint(large) {
		padding-right:70px;
	}
  }
  &__prijs__tekst {
	@include breakpoint(large) {
		padding-left:70px;
	}
  }
  &__locatie .column, &__prijs .column, &__events .column, &__community .column {
  	min-height:300px; 
  }
  .medium-offset-1{
  	@media print {
  		margin-left:0px;
  		-ms-flex: 0 0 25%;
    	flex: 0 0 100%;
    	max-width: 100%;
  	}
  }
  @include breakpoint(medium) {
    padding: 5rem 0;
    padding-bottom:0px;
  }
  @media print {
  	padding-top:0px;
  	padding-bottom:0px;
  }	
  &--small {
    @include show-for(medium);
    padding: 30px 10px 20px 25px;
  }
  &__title{
  	margin-bottom:0px;
  	@include breakpoint(medium) {
  		margin-bottom:70px;
  	}
  	h2{
    	margin: 0 0 1rem;
    	font-size: rem-calc(25);
    	text-align:center;
    	padding-left:15px;
    	padding-right:15px;
    	@include breakpoint(medium){
    		font-size: rem-calc(39);
    	}
  	}
  	h3 {
    	margin: 0 0 1rem;
    	font-size: rem-calc(25);
    	text-align:center;
    	padding-left:15px;
    	padding-right:15px;
    	@include breakpoint(medium){
    		font-size: rem-calc(39);
    	}
  	}
  	&__text{
  		color: $color-dark-gray;
    	margin: 0 0 1rem;
    	font-size: 1.1rem;
    	text-align:center;
    	padding:0px 25px;
  		@include breakpoint(medium) {
    	padding:0px 75px;
    	}
  		@include breakpoint(large) {
    	padding:0px 150px;
    	}
  	
  	}
  }
  
  &__subtitle{
  	@include raleway;
  	color: $color-business-blue;
  	font-size: 1.6rem;
  	font-weight:800;
  	@include breakpoint(large) {
    	padding-top:30px;
    }
  }

  &--small &__title {
    font-size: 1.5rem;
  }
  
  &__calltoaction{
  	padding-bottom:75px;
  }
  &__action {
    display: inline-block;
    margin: 2rem 0 0;
    color: $white;
    font-size: rem-calc(17);
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #e0e0e0;
    }
    i {
      font-size: 140%;
      position: relative;
      top: 4px;
      left: 10px;
    }
    @media print {
  		display:none;
  	}
  }
  
}