.cf-proof {
	//background: $color-ultralight-gray;
	margin: 25px 0px;
	
	&--category{
		margin:80px 0px;
		margin-top:150px;
		& .row > .column {
			padding:0 2rem;
		}
	}
	@include breakpoint(medium) {
		margin:150px 0px;
		
		&--category{
			& .row > .column {
				padding:0 0.9375rem;
			}
		}
	}
	
	&__text{
		&__title{
			font-size:rem-calc(25);
			font-weight:800;
			margin-bottom:25px;
			@include breakpoint(medium){
				margin-bottom:35px;
				font-size:rem-calc(39);
			}
		}
		&__description{
			margin-bottom:35px;
		}
	}
	&__usps{
		@include breakpoint(large){
			padding-left:4rem!important;
		}
		&__container{
			border: 3px solid rgba(239, 81, 85, 0.5);
			border-radius: 10px;
			padding:15px;
			@include breakpoint(large){
				padding:30px;
			}
			display:flex;
			margin-bottom:17px;
			&__icon{
				//margin-right: 30px;
				height:60px!important;
			}
			&__counter{
				flex-width:40%;
			}
			&__text{
				p{
					margin-bottom:0px;
					font-size:18px;
					font-weight:500;
					span{
						font-size:18px!important;
						font-weight:500!important;
					
					}
				}
			}
			&:first-of-type{
				border: 3px solid rgba(59, 195, 190, 0.5);
			}
			&:nth-of-type(2){
				border: 3px solid rgba(245, 160, 1, 0.5);
			}
		}
	}
	&__usp{
		padding-left: 1.1rem;
    	padding-right: 1.1rem;
		margin-bottom:25px;
		@include breakpoint(large) {
			margin-bottom:0px;
		}
		&__icon {
			height:128px;
			margin-bottom:20px;
		}
		&--trainingen{
			.cf-proof__usp__container{
				border: 3px solid rgba(239, 81, 85, 0.5);
				border-radius: 10px;
				padding:25px 0px;
				padding-bottom:35px;
				height: 100%;
			}
			.cf-proof__usp__icon{
				background:url('/images/icons/128/33_TRAINER.png');
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
			}
		}
		&--incompany{
			.cf-proof__usp__container{
				border: 3px solid rgba(245, 160, 1, 0.5);
				border-radius: 10px;
				padding:25px 0px;
				padding-bottom:35px;
				height: 100%;
			}
			.cf-proof__usp__icon{
				background:url('/images/icons/128/22_WORKFLOW.png');
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
			}

		}
		&--cursisten{
			.cf-proof__usp__container{
				border: 3px solid rgba(59, 195, 190, 0.5);
				border-radius: 10px;
				padding:25px 0px;
				padding-bottom:35px;
				height: 100%;
			}
			.cf-proof__usp__icon{
				background:url('/images/icons/128/17_PERSUASION.png');
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
			}

		}
		&--creatief{
			.cf-proof__usp__icon{
				background:url('/images/icons/128/36_IDEA.png');
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
			}

		}
		&--top20{
			.cf-proof__usp__icon{
				background:url('/images/icons/128/6_COMMUNITY.png');
				background-size:contain;
				background-repeat:no-repeat;
				background-position:center center;
			}

		}
		&__subtitle{
			@include header-text;
			font-size:2.5rem;
			font-weight:800;
			text-align:center;
			color: rgba(29, 33, 94, 1);
			margin-bottom:0px;
			@include breakpoint(large){
				font-size: 4rem;
			}

		}
		&__text {
			@include raleway;
			text-align:center;
			font-size: 1.3rem;
			margin-bottom:0px;
		}
  	}
  
	.medium-offset-1{
		@media print {
			margin-left:0px;
			-ms-flex: 0 0 25%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	@media print {
		padding-top:0px;
		padding-bottom:0px;
	}	
	&--small {
		@include show-for(medium);
		padding: 30px 10px 20px 25px;
	}
	&__title{
		margin-bottom:40px;
		@include breakpoint(medium) {
			margin-bottom:70px;
		}
		h2{
			@include header-text;
			font-size:25px;
			margin-bottom:35px;
			font-weight:800;
			text-align:start;

			@include breakpoint(medium) {
				font-size:45px;
				text-align:center;
			}
		}
		&__text{
			font-size:1.125rem;
			text-align:start;
			margin-right: 20px;
			@include breakpoint(medium) {
				text-align:center;
				margin-right: 0px;
			}
		}
	}

	&__subtitle{
		@include raleway;
		color: $color-black;
		font-size: 1.6rem;
		font-weight:800;
		@include breakpoint(large) {
			padding-top:30px;
		}
	}

	&--small &__title {
		font-size: 1.5rem;
	}

	&__action {
		display: inline-block;
		margin: 2rem 0 0;
		color: $white;
		font-size: rem-calc(17);
		text-decoration: none;
		font-weight: bold;
		&:hover {
		  color: #e0e0e0;
		}
		i {
		  font-size: 140%;
		  position: relative;
		  top: 4px;
		  left: 10px;
		}
		@media print {
			display:none;
		}
	}
}