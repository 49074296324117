.mobile_layout_checkout{
    &__sidebar{
        width: 100%;
        height:60px;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: fixed;
        background: white;
        top:0;
        z-index: 1;
        &__logo{
            display: grid;
            align-content: center;
            margin-left:20px;
            img{
                width: 100px;
            }
        }
        &__slideout{

            &__text{
                display:grid;
                align-items: center;
                height: 100%;
                float: right;
                margin-right: 20px;
                width:100%;
                p{
                    font-size:14px;
                    margin:0;
                    font-family: 'Lexend', sans-serif;
                    letter-spacing: -0.14px;
                    font-weight: 500;
                    text-align: right;
                    img{
                        width:30px;
                    }
                }
            }
        }
    }
    &__content{
        margin-top:60px;
        .desktop_layout_checkout__main__top > .row > .column{
            padding:0px 20px;
        }
    }
}
.layout__done{
    @include breakpoint(large){
        height:100vh;
        max-height:100vh;
        overflow: hidden;
        &.overflowscroll{
            overflow-y: scroll;
        }
    }
    &__content{
        display:flex;
        flex-wrap: wrap;
        @include breakpoint(large){
            flex-wrap: nowrap;
            height:90%;
        }
    }
    &__leftside{
        flex-basis: 100%;
        padding:20px;
        padding-bottom: 0px;
        @include breakpoint(large){
            flex-basis:50%;
            padding:50px;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        @include breakpoint(xxlarge){
            padding:100px;
            padding-bottom:0px;
        }
        h1 {
            letter-spacing: -1.35px;
            color: #1E225D;
            font: normal normal 800 25px/50px Lexend;
            @include breakpoint(large){
                font: normal normal 900 45px/70px Lexend;
            }
        }
        p {
            font-size:16px;
            color:$color-business-blue;
            @include breakpoint(large){
                font-size:16px;
            }
            
        }
        strong { font-size:16px; 
            color:$color-business-blue; }
        ul li{font-size:16px; 
            color:$color-business-blue;}
        .bottompart{
            @include breakpoint(large){
                max-height:70%;
            }
        }
        .icon{
            filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);
        }
        img.yougotthis{
            max-width: 300px;
            display: block;
            margin:0 auto;
            margin-top:30px;
            @include breakpoint(large){
                max-width: 70%;
                max-height: 100%;
            }
        }
        .maakaccountaan{
            border: 1px solid #1e225d;
            padding: 20px;
            border-radius: 12px;
            ul{
                list-style-type: none;
                margin-left: 0;

                li{
                    svg{
                        path{
                            fill: $color-business-blue;
                        }
                    }
                }
            }
        }
    }
    &__rightside{
        flex-basis: 100%;
        padding:0px 20px;
        @include breakpoint(large){
            flex-basis:50%;
            padding-top:175px;
        }
        @include breakpoint(xxlarge){
            padding:0px 100px;
            padding-top:150px;

        }

        .checkout__receipt__container{
            position: relative;
            .checkout__receipt{
                box-shadow: 0px 15px 40px rgba(42,62,82,0.152);
                padding:35px;
                @include breakpoint(large){
                    padding:35px;
                    max-height: 50vh;
                }
            }
            &::after{
                content:"CF";
                @include header-text;
                color:#00C4C2;
                font-size: 300px;
                font-weight: 900;
                display: block;
                margin-top: 50px;
                line-height: 1;
                letter-spacing: -40px;
                text-align: center;
                margin-left: -30px;
            }
            @include breakpoint(large){
                &::before{
                    content:"C";
                    @include header-text;
                    color:#00C4C2;
                    font-size: 600px;
                    position: absolute;
                    font-weight: 900;
                    top: -500px;
                    left: -120px;
                    z-index: -1;

                }
                &::after{
                    content:"F";
                    @include header-text;
                    color:#00C4C2;
                    font-size: 600px;
                    position: absolute;
                    bottom: -280px;
                    right: -80px;
                    font-weight: 900;
                    z-index: -1;
                }
            }
        }

    }
}
.desktop_layout_checkout{
    display:grid;
    grid-template-columns: 5fr 6fr;
    height:100vh;
    max-height:100vh;

    &__sidebar{
        background-color:#F8F8FA;
        overflow:scroll;
        &__div{
            //position:fixed;
            padding:0px 40px;
            height:100%;

            .logo-section{
                width: 100%;
                display: flex;
                margin-bottom:50px;
                padding-top: 30px;
                justify-content: space-between;
                gap:35px;
                img.icon{
                    width:25px;
                    margin-bottom:25px;
                    filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);
                }
                img.logo{
                    min-width:180px;
                    width: 180px;
    
                }
                .leftpart{
                    margin-left: -25px;
                    a{
                        display:flex;
                        align-items: center;
                        gap:20px;
                    }
                    
                }
                .rightpart{
                    font-size:rem-calc(15px);
                    color:$color-business-blue;
                    max-width:250px;
                    img{
                        width:15px;
                        filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);
                    }
               }
            }
            .content{
                height: 80%;
                display: grid;
                @media only screen and (max-height: 750px) {
                    height: 50%;
                }
                @include breakpoint(xxlarge){
                    padding:0px 20px;
                }
            }
        }
    }
    &__main{
        height:100%;
        overflow: scroll;
        &__top{
            overflow: scroll;
            height:85%;
            padding-bottom:30px;
            @include breakpoint(large){
                padding-bottom:90px;

            }
            .callout.alert{
                border-radius: 0;
                box-shadow: none;
                padding: 7px 15px;
                border:none;
            }
        }
        &__bottom{
            height:15%;
            position: relative;
            padding-bottom: 85px;
            @include breakpoint(large){
                padding-bottom:0px;
                &::before{
                    content:"";
                    position: absolute;
                    width:100%;
                    height:90px;
                    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
                    margin-top:-90px;
                    pointer-events:none;
                }
            }
            
        }
    }
}
.offcanvasboeking{
    background-color:white;
    padding:20px 25px;
    width:320px;
    z-index:9999!important;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2)!important;

    &__topnav{
        height:20px;
        width:100%;
        padding:0;
        margin-top:3vh;

        &__closebutton{
            float:right;
            cursor: pointer;
        }
    }
}
.cancellation-select{
    pointer-events:none;
}
.checkout{
    &__paymentmethods{
        align-self: end;
        padding-bottom: 15px;
        padding-top: 15px;
        &__title{
            font: normal normal medium 17px/30px Lexend;
            @include header-text;
            font-weight:medium;
            color:$color-business-blue;
            margin-bottom:10px;
        }
        &__items{
            display:flex;
            gap:10px;
            & > div{
                border: 1px solid #DDDDDD;
                border-radius: 8px;
                background:transparent;
                padding:6px;
                min-width:60px;
                min-height:60px;
                @include breakpoint(large){
                    padding:13px;
                    min-width:80px;
                    min-height:80px;

                }
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                img{
                    display:block;
                    margin-left:auto;
                    margin-right:auto;
                    max-width:54px;
                }
                &.withlegenda{
                    padding:6px;
                    @include breakpoint(large){
                        padding:13px 8px;

                    }

                    img{
                        margin-bottom:4px;
                        width:30px;
                        @include breakpoint(large){
                            margin-bottom:9px;

                        }
                    }
                    p{
                        font-size:10px;
                        @include header-text;
                        text-align:center;
                        margin-bottom:0px;
                        line-height: 1!important;
                        @include breakpoint(large){
                            font: normal normal normal 13px/32px Lexend;
                        }
                    }
                }
            }
        }
        &__choice{
            @include breakpoint(large){
                display: flex;
                flex-wrap: wrap;
                gap:2%;
                & > div{
                    flex-basis:48%;
                    margin-bottom:15px;
                }
            }
        }
    }
    &__receipt{
        margin-bottom: 0px;
        @include breakpoint(large){
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 15px 40px rgba(148,148,148,0.909);
            box-shadow: 0px 15px 40px rgba(42,62,82,0.152);
            border-radius: 12px;
            padding:35px;
            min-height:40vh;
            max-height:60vh;
            overflow: scroll;
            height:60vh;
        }
        @media only screen and (max-height: 750px) {
            height:100%;
        }
        @media only screen and (max-height: 480px) {
            min-height:5vh;
        }
        @include breakpoint(xxlarge){
            padding:45px;
        }
        &__notice{
            color:$color-business-blue;
            margin-bottom:20px;
        }
        &__divider{
            border: 1px solid #E9E9E9;
            margin-bottom:20px;
        }
        &__totals{
            margin-bottom:35px;
            @include breakpoint(xlarge){
                display:flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom:0px;
            }
            &__type{
                background: #FFF500 0% 0% no-repeat padding-box;
                border-radius: 100px;
                display:inline-block;
                padding:4.5px 20.5px;
                @include header-text;
                font-size:rem-calc(15px);
                font-weight: 600;
                margin-bottom:20px;
                @include breakpoint(xlarge){
                    margin-bottom:0px;
                }
            }
            &__amounts{
                &__line{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint(large){
                    justify-content: flex-end;
                    }
                    gap:10px;
                    color:$color-business-blue;
                    .description{
                        font-size:1.1rem;
                        &.lowercase{
                            text-transform: lowercase;
                        }
                        @include breakpoint(large){
                            font-size: 0.95rem;
                            text-align: right;

                        } 

                    }
                    .price{
                        font-size:1.1rem;
                        min-width:85px;
                        &.bold{
                            font-weight:bold;
                        }
                        &.strikethrough{
                            text-decoration: line-through;
                        }
                        text-align: right;
                        @include breakpoint(large){
                            min-width:80px;
                            font-size:0.95rem;

                        }
                    }
                    &.paymentmethodprice{
                        span { font-style: italic;}
                    }
                }
            }
        }
        &__lines{
            margin-top:25px;
        }
        &__line{
            margin-bottom:20px;
            &__title{
                font: normal normal 800 20px/40px Lexend;
                @include header-text;
                letter-spacing: -0.4px;
                color: #1E225C;
                border-bottom: 1px solid #E9E9E9;
                padding-bottom:15px;
                line-height: 1.2;
            }
            &__details{
                margin-top:15px;
                &__row{
                    color:$color-business-blue;
                    display:flex;
                    align-items: center;
                    gap:15px;
                    margin-bottom:10px;
                    .icon{
                        img{
                            width:18px;
                            filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);
                        }
                    }
                    .description{
                        font-weight: 500;
                    }
                    .sublocation{
                        color:#86868E;
                        font-weight: 400;
                        &::before{
                            content:"\2022";
                            color:$color-business-blue;
                            padding-left:5px;
                            padding-right:10px;
                        }
                    }
                }
            }
            &__extra{
                border-top: 1px solid #E9E9E9;
                margin-top:15px;
                padding-top:15px;
            }

        }
    }
    &__notice{
        background: #F8F8FA 0% 0% no-repeat padding-box;
        border-radius: 2px;
        font-weight:400;
        padding:14px 20px;
        margin:20px 0px;
        font-size:rem-calc(16px);
        color:$color-business-blue;
        display: flex;
        gap:10px;
        align-items: center;
        &.centered{
            text-align: center;
            @include breakpoint(large){
                text-align:left;
            }
        }
        .icon{
            img{ width:20px; filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);}
        }
        &__icon{
            .good{
                filter: invert(48%) sepia(74%) saturate(433%) hue-rotate(81deg) brightness(94%) contrast(99%);
            }
            .warning{
                filter: invert(84%) sepia(26%) saturate(4194%) hue-rotate(354deg) brightness(101%) contrast(101%);
            }
        }
        &__text{
            
        }
        &--totals{
            padding:14px 20px;
        }
    }
    &__legend{
        font: normal normal medium 15px/19px Lexend;
        letter-spacing: -0.15px;
        @include header-text;
        font-weight:500;
        margin-bottom:10px;
        color:$color-business-blue;
    }
    &__switchinformation{
        display: flex;
        flex-wrap: wrap;
        margin-top:20px;
        margin-bottom:20px;
        @include breakpoint(xlarge){
            margin-top:40px;
            flex-wrap: nowrap;
            gap:50px;
        }
        .checkout__switch{
            flex-basis:100%;
            @include breakpoint(xlarge){
                flex-basis:50%;
            }
        }
        .checkout__notice{
            flex-basis:100%;
            @include breakpoint(xlarge){
                margin:0px;
                flex-basis:64%;
            }
        }
    }
    &__switch{
        &__item{
            padding:7px 30px;
            @include header-text;
            font-weight:medium;
            color:$color-business-blue;
            @include transition(all 400ms);
            border:1px solid $color-business-blue;
            border-radius: 8px;
            margin-bottom:8px;
            text-align: center;
            @include breakpoint(large){
                border:none;
                text-align: left;
            }
            input{
                margin-bottom:0px;
                transform: scale(1.3);
                margin-right:12px;
                display: none; 
                @include breakpoint(large){
                    display:inline;
                }
            }
            &.selected{
                background: #2D85DD 0% 0% no-repeat padding-box;
                border:1px solid #2D85DD;
                border-radius: 8px;
                color:white;
                &:hover{
                    background: #2D85DD 0% 0% no-repeat padding-box;
                }
            }
            &:hover{
                cursor: pointer;
                background: #E9E9E9 0% 0% no-repeat padding-box;
            }
        }
    }
    &__item{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #E9E9E9;
        border-radius: 8px;
        box-sizing: border-box;
        padding:20px 25px;
        position: relative;
        margin-bottom:20px;
		@include transition(all 400ms);
        &.selected{
            box-shadow: 0px 3px 10px rgba(25,50,75,0.2);
            border: 2px solid $color-business-blue;
        }
        &:hover{
            cursor: pointer;
            @include breakpoint(large){
            box-shadow: 0px 3px 10px rgba(25,50,75,0.2);
            border: 2px solid $color-business-blue;
            }
        }
        &__selection{
            position:absolute;
            input{
                transform: scale(1.7); 
            }
            &.left{
                left:25px;
                top:20px;
            }
            &.right{
                right:25px;
                top:20px;
            }
        }
        &__trash{
            position:absolute;
            right:15px;
            top:15px;
        }
        &__information{
            padding-left:43px;
            .title{
                font: normal normal 400 16px/22px Poppins;
                color:$color-business-blue;
                text-align: left;
            }
            .text{
                margin-top:10px;
                color:$color-business-blue;
                .icon-list{
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li{ display: flex; align-items: center; gap:11px;
                        img {
                            filter: invert(15%) sepia(29%) saturate(3794%) hue-rotate(219deg) brightness(81%) contrast(98%);
                            width: 16px;
                        }
                    }
                }
            }
            .details{
                display:flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap:30px;
                row-gap:10px;
                margin-top:13px;

                color:$color-business-blue;
                &__item{
                    display:inline-block;                    
                    &__icon{
                        img{
                            height:13px;
                            filter: invert(9%) sepia(50%) saturate(4280%) hue-rotate(233deg) brightness(86%) contrast(92%);
                        }
                    }
                    &__text{
                        font-size:rem-calc(15px);
                        @include header-text;
                    }
                    &--notice{
                        margin:0px;
                    }
                }

            }
        }
        &__badge{
            position: absolute;
            background-image: linear-gradient(to top right, transparent 50%, $color-business-blue 50%); /* Creates a triangle effect */
            width: 43px;
            height: 43px;
            &.right{
                top:0px;
                right:0px;
                border-top-right-radius: 6px;
                text-align: right;
                img { margin-right: 7px;}
            }
            &.premium{
                background-image: linear-gradient(to top right, transparent 50%, $color-orange 50%); /* Creates a triangle effect */
            }
            img { filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(111deg) brightness(104%) contrast(101%); width:16px; height:16px; }
        }
        &--training{
            &:hover{
                border: 2px solid #E9E9E9;
                box-shadow: none;
                cursor: default;
            }
            .checkout__item__information{
                padding-left:0px;
                .title{
                    font: normal normal 800 18px/23px Lexend;
                    letter-spacing: -0.18px;

                }
            }

        }
        &--paymentmethod{
            .checkout__item__information{
                padding-left:0px;
                .title{
                    display: flex;
                    align-items: center;
                    gap:15px;
                    .icon{
                        img{ width:50px; max-height:33px; object-fit: contain; }
                    }
                    .text{
                        margin-top:0px;
                    }
                }
            }
        }
    }
    &__subtitle{
        font: normal normal 800 20px/40px Lexend;
        letter-spacing: -0.4px;
        color: $color-business-blue;
        font-weight:800;
    }
    &__form{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #E9E9E9;
        border-radius: 8px;
        padding:20px 25px;
        margin-bottom:30px;
        .form-error{
            margin-top: 0px;
        }
        &.selected{
            background: #F8F8FA 0% 0% no-repeat padding-box;
        }
        &__header{
            display:flex;
            justify-content: space-between;
            margin-bottom:20px;
            &__title{
                font: normal normal 800 20px/40px Lexend;
                letter-spacing: -0.4px;
                color: $color-business-blue;
                font-weight:800;
            }
            &__subselection{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #CBCBCB;
                border-radius: 8px;
                padding:10px 15px;
            }
        }
        &__row{
            display: flex;
            gap:20px;
            margin-bottom:20px;
            &:last-of-type{
                margin-bottom:0px;
            }
            & > div{
                flex-grow: 1;
            }
            &__inner{
                display:flex;
                gap:20px;
                align-items: flex-start;
                margin-top:5px;
                label{margin-bottom:0px; padding:0px;}
            }
            &.nomargin{
                margin-bottom:0px;
            }
            &.margintop{
                margin-top:20px;
            }

        }
        input[type="radio"], input[type="checkbox"] {
            transform: scale(1.7); 
            margin-bottom:0px;
            margin-right: 22px;
            margin-left:5px;
        }
        input[type="radio"]{
            margin-right:12px;
        }
        input[type="text"],input[type="email"],input[type="tel"],input[type="date"],input[type="password"]{
            margin-bottom: 0px;
            border: 1px solid #CBCBCB;
            border-radius: 8px;
            padding:7px 13px;
            padding:16px 30px;
            font-size:rem-calc(15px);
            height:unset; 
            color:$color-business-blue;
            &::placeholder{
                color:#9798A2;
            }
            width:100%;
        }
        label{
            font-weight:600;
            color:$color-business-blue;
        }
    }
}