.scroll-up .keuzehulp__mainbar, .scroll-submenu .keuzehulp__mainbar{
	top:60px;
	@include breakpoint(large){
		top:70px;
	}
}
.scroll-up .keuzehulp__sidebar, .scroll-submenu .keuzehulp__sidebar{
	@include breakpoint(large){
		top: 170px;
		
	}
}
.cluster-header{
	&.keuzehulp-header{
		@include breakpoint(large){
			//height: 750px !important;
			//margin-top: -50px;
		}
	}
}
.outer-padding{
	padding:0rem 2rem!important;
}
.categorie__container .tab-head{
	padding-left:2rem;
	padding-right:0;
	margin-left:0px;
	@include breakpoint(large){
		padding:0 2rem;
	}
	#keuzehulpTabbing{

		overscroll-behavior-x: contain;
		-ms-overflow-style: none;
		scrollbar-width:none;
		&::-webkit-scrollbar{
		  display: none;
		}
	}
	.tabbing{
		&:first-of-type{
			margin-left:0px!important;
		}
	}
}
.titleTopCatogorytrainingen div h2{
	margin-bottom:0px!important;
}
.keuzehulp{
	.twocol {
		padding-top:30px;
		@include breakpoint(large){
			padding-top:0px;
		}

	}
	background-color:#F8F8FA;
	@include breakpoint(large){
		background-color: transparent;
	}
	&__offcanvas{
		width: 320px;
		background-color:white;
		.keuzehulp__sidebar__container{
			padding:20px;
			&__top{
				padding-right: 0.625rem;
    			padding-left: 0.625rem;
			}
		}
	}

    // main bar mobile
    &__profile{
		//margin:0px 2rem;
		padding:2rem 0rem;
		padding-left: 1.7rem;
		padding-right: 1.7rem;
		@include breakpoint(large){
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			overflow: scroll;
    		height: 100%;
		}
		&__bar{
			position:sticky;
			z-index: 5;
			top:0px;
			@include breakpoint(medium){
				top:30px;
			}
			display:flex;
			justify-content:space-between;
			align-items:center;
			padding:10px 20px;
			background: #FFFFFF 0% 0% no-repeat padding-box;
			box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
			border-radius: 8px;
			column-gap:10px;
			@include breakpoint(medium){
				column-gap:40px;
			}
			@include breakpoint(large){
				top: -35px;
			}
			&__mobile-item{
				flex-grow:1;
				flex-basis: 0;
				display:flex;
				align-items:center;
				justify-content:center;
				@include breakpoint(large){
					display:none;
				}
				text-align:center;
				svg{
					transform:scale(0.75);
					margin-right:2px;
				}
				span{
				
				}
				&.menu{
					justify-content:flex-start;
				
				}
				&.search{
				
				}
				&.filter{
					justify-content:flex-end;
				
				}
			}
			&__filter{
				flex-grow:1;
				flex-basis: 0;
				display:none;
				@include breakpoint(large){
					display:block;
					position:relative;
				}
				&.show{
					@include breakpoint(large){
						display:flex;
					}
					align-items:center;
					span.title{
						letter-spacing: 0px;
						color: #1E225C;
						padding-right:20px;
					}
					select{
						border: 1px solid $color-blue;
						border-radius: 2px;
						font-size:0.8rem;
						height:inherit;
						margin-bottom:0px;
					}
					.mobile-filter__expand{
						li.current{
							color:white;
						}
					}
				}
				&.search{
					input{
						background: #FFFFFF 0% 0% no-repeat padding-box;
						box-shadow: 0px 2px 6px rgba(60,84,110,0.2);
						border-radius: 8px;
						border:none;
						height:37px;
						margin-bottom:0px;
						padding:7px 20px;
						padding-right:40px;
						background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z'/></svg>");
						background-repeat:no-repeat;
						background-position: 95% 50%;
						background-size:18px;
						
						
					}
					input::placeholder{
						color:$color-blue;
					}
					.close-button{
						right: 0.1rem;
						top: 1rem;
					}
				}
				&.view{
					ul.tabbing{
						display:flex;
					}
					ul.tabbing li{
						flex-basis:0;
						flex-grow:1;
						margin-right:0px;
						border:none;
						display:flex;
						align-items:center;
						column-gap:16px;
						font-size:0.9rem;
					}
					ul.tabbing li.current svg path{
						fill:white;
					}
				}
			}
			&__filter-menu{
				display:none;				 
			}
		}
		&__menu{
			//position:sticky;
			//top:15px;
			display:flex;
			justify-content:space-between;
			align-items:center;
			
			&__button{
				padding:10px 20px;
				background: #FFFFFF 0% 0% no-repeat padding-box;
				box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
				border-radius: 8px;
				display:flex;
				align-items:center;
				justify-content:center;
				svg{
					transform:scale(0.75);
					margin-right:2px;
				}
			}
			&__header{
				text-align: right;
				font-size: 1.5rem;
				font-weight:800;
				color: $color-blue;
			}
		}
		&__content{
			margin-top:30px;
			
			h1{
				font-weight:800;
				font-size:20px;
				margin-bottom:17px;
				&.saved{
					border-bottom:1px solid #AFB7C9;
					margin-bottom:0px;
					padding-bottom:17px;
					@include breakpoint(large){
						display:none;
					}
				}
			}
			h2{
				font-weight:600;
				font-size:1rem;
				margin:17px 0px;
				@include breakpoint(large){
					font-size:1.5rem;
					
				}
			
			}
			&__saved-content-block{
				padding-bottom:17px;
				border-bottom:1px solid #AFB7C9;
				& > .row.grid-view {
					padding-bottom:15px;
					& .grid-item{
							padding:0px;
						}
					@include breakpoint(medium){
						column-gap: 1.875rem;
						& .grid-item{
							padding:0px;
							flex: 0 0 45%;
						}
					}
					@include breakpoint(large){
						column-gap: 1.875rem;
						& .grid-item{
							padding:0px;
							flex: 0 0 31.2%;
						}
					}
				}
				
				& > .row.list-view{
					& .grid-item{
						padding:0px;
					}
				}
			}
			&__dashboard{
				&__mainblock{
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
					border-radius: 8px;
					padding:35px 22px;
					margin-bottom:30px;
					@include breakpoint(large){
						padding:60px;
						text-align:center;
					}
					h1{
						@include header-text;
						text-align:center;
						font-size:1.5rem;
						@include breakpoint(large){
							text-align:left;
							margin-bottom:10px;
						}
					}
					p{
						text-align:center;
						margin-bottom:30px;
						@include breakpoint(large){
							text-align:left;
							margin-bottom:20px;
						}
					
					}
					div{
						text-align:center;
						@include breakpoint(large){
							text-align:left;
						}
					}
				}
			}
			&__settings{
				&__menu{
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
					border-radius: 8px;
					margin-bottom:30px;
					@include breakpoint(large){
						position:sticky;
						top: 30px;
						margin-bottom:0px;
						height: 100%;
					}
					&__header{
						font-weight:500;
						padding:19px 22px;
						border-bottom:1px solid #EAEAEA;
						color:$color-blue;
						@include header-text;
					}
					&__items{
						list-style-type:none;
						margin:0px;
						padding:19px 22px;
					}
				}
				&__sections{
					@include breakpoint(large){
						padding-left: 2.5rem!important;
					}
				}
				&__section{
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
					border-radius: 8px;
					padding:35px 22px;
					margin-bottom:30px;
					@include breakpoint(large){
						padding:60px;
					}
					&__header{
						@include header-text;
						text-align:left;
						font-size:1.3rem;
						font-weight:800;
						color:$color-blue;
						@include breakpoint(large){
							margin-bottom:10px;
							font-size:1.5rem;
						}
					}
				}
			}
			&__notifications{
				
			}
		}
	}



    // main bar desktop
    &__mainbar{
        position: sticky;
        top: 0px;
        z-index:10;
        background-color: white;
		transition: all 400ms;
        @include breakpoint(large){
            top:0px;
            
        }
        &__title{
            display:none;
            @include breakpoint(large){
                display:block;
                font-size: rem-calc(39px);
                color:$color-business-blue;
                font-weight: bold;
                @include header-text;
				margin-top:30px;
				margin-bottom:30px!important;
            }
            &__variable{
                display: none;
            }

        }
        &__content{
            // background-color: red;
            margin-top: 10px;
            &__search{
                position: relative;
                img{
                    position: absolute;
                    left: 16px;
                    // padding: 10px 12px;
                    pointer-events: none;
                    top: 1rem;
                    width: rem-calc(16px);
                    
   
                }
                
                  &__input{
                    &::-webkit-input-placeholder {
                    color: $color-business-blue;
                    opacity: 1; 
                    }
                    text-align: left;
                    padding-left: 50px;
                    border-radius: 8px;
                    border: 1px solid #D6D6D6;
                    // font-size: 15px;
                    //width: 315px;
                    height: 47px;
                    text-align: left;
                }


            }
            &__menu{
                display: flex;
                justify-content: space-between;
                // background-color: #106bc6;
                width: 83%;
                &__option{
                    background-color:#fff;
                    color: #191e58;
                    // border:1px solid $color-lightest-gray;
                    text-align:center; 
                    // width:33.3%;
                    width:100%;
                    padding:5px 0px;
                    font-weight:600;
                    font-size:0.9rem;
                    cursor:pointer;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
					border-bottom: 2px solid white;
                    @include transition(background 400ms, color 400ms);
                    &.selected, &:active, &:hover, &:focus {
                        background-color: #fff;
                        color:$color-business-blue;
                        border: none;
                        border-bottom: 2px solid $color-business-blue;
    
                        // border:1px solid $color-darker-gray;
                    }
                    &__button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        &__icons {
                            @include breakpoint(large){
                                padding:5px 10px;
                                font-size:0.9rem;
                            }
                          
                            padding:2px 10px;
                            font-size:1.5rem;
                            display:inline-block;
                            border-radius: 2px;
                            @include transition(background 400ms, color 400ms);
                            margin:0px;
                            &.selected, &:active, &:hover, &:focus {
                                background-color:$color-business-blue-light;
                                color:white;
                                cursor:pointer;
								svg path { fill: white; }
                            }
                            
                        }

                    }
                }
                
                

            }
        }
        &__line{
            width: 100%;
            height: 1px;
            background-color: #F8F8FA;
            margin: 0px 0px 30px 0px;
			max-width: 100%;
			margin-top: -1px;
			//z-index: 11;
			//position: relative;
        }

    }
    &__sidebar{
		display:none;
		@include breakpoint(large){
			display:block;
		}
        position: sticky;
        top:90px;
		transition: all 400ms;
        &__container{
            &__top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
            }          
            &__title{                
                    font-size: rem-calc(20px);
                    color:$color-business-blue;
                    font-weight: bold;
                    @include header-text;
                    margin-bottom: 1px;   
                 }
            &__clear{
                font-size:rem-calc(15px);
                color: #9D9FB9;
                font-weight:medium;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: rem-calc(15px);
                }
                cursor: pointer;
                display: none;
            }
            &__filter{
                &__selectOption{
                    select{
                        height: 50px;
                        border: 1px solid #1E225C;
                        border-radius: 4px;
                        color: $color-business-blue;
                        font-size: 15px;
                        letter-spacing: 0px;
						-webkit-appearance: none;
                    }
                }
    
            }
    
        }
        
    }
    &__maincontent{
        // background-color: red;
        &__resultcount{
            font-size:rem-calc(14px); 
            background-color: #f8f8fa; 
            color: $color-business-blue;
            padding: 7px 15px 7px 15px;
            //width: 214px;
            //height: 33px;
            //text-align: center;
			border-radius: 2px;
            margin-bottom: 15px;
            display: none;
			margin-top:15px;
            @include breakpoint(large){
				margin-top:0;
                display: none;
            }

        }
        &__grid{

        }
    }
}




.scroll-down .categorie__submenu > .row {
  margin-top:0px!important;
  transform: none;
  max-width:100%!important;
}

.scroll-up .sub-category__filter__container.sticky{
	top:150px;
}
.scroll-up .categorie__submenu > .row:not(.is-anchored) {
  margin-top:74px!important;
  transform: none;
  max-width:100%!important;
}
body:not(.scroll-up):not(.scroll-down).scroll-submenu .categorie__submenu > .row:not(.is-anchored) {
  transform: translate3d(0, -100%, 0);
  margin-top:0px!important;
  max-width:100%!important;

}
body .categorie__submenu{
	display:none;
}
body.scroll-up .categorie__submenu, body.scroll-down .categorie__submenu, body:not(.scroll-header).scroll-submenu .categorie__submenu{
	display:block;
}
.categorie__submenu{
	& > .row{
		background:white;
		transition: all 0.4s;
		z-index:95;
	}
}
.cluster-header {
  //background: white;
  height: 100%;
  position:relative; 
  padding-bottom:60px;
  //@include breakpoint(large){
  //	height: 600px;
  //}
  /*@include breakpoint(xlarge) {
  	background-position: center 40%;
  }*/
  &__text-background{
	display:none;
	.container{
		padding-right:0px;
	}
	@include breakpoint(large){
		display:block;
	  width:100%;
	  height:100%;
	  position: absolute;
	  z-index: -1;
	  overflow:hidden;
	  text-align:right;
	  @include header-text;
	  span{
		@include header-text;
		color:#00C4C2;
		font-size: 750px;
		font-weight:800;
		letter-spacing: -90px;
		line-height: 675px;
		display: inline-block;
		width: 50%;
	  }	
	  
		
	}
		@include breakpoint(xlarge){
			span{
				width:50%;
			}
		}
  }
  &__background{
		position:relative;
		height:350px;
		padding-right:0px;
		@include breakpoint(medium){
			height: 430px;
		}
		@include breakpoint(large){
			height:700px;
			padding-right: 0.9375rem;
		}
		&__text{
			overflow:hidden;
			//background:rgba(32, 27, 102, 0.5);
			//background:rgba(0, 0, 0, 0.4);
			color:#00C4C2;
			font-size: 300px;
			font-weight:800;
			letter-spacing: -32px;
			line-height: 255px;
			text-align:right;
			@include header-text;
			span{
				@include header-text;
				display: inline-block;
				overflow: hidden;
				font-size: 350px;
				width: 90%;
				color:#00C4C2;
				@include breakpoint(medium){
					font-size: 400px;
				}
			}
			@include breakpoint(medium){
				line-height: 400px;
			}
			@include breakpoint(large){
				display:none;
			}
		}
		&__overlay{
			position:absolute;
			display:flex;
			left:0;
			bottom:20px;
			z-index:30;
			justify-content:center;
			width:100%;
			@include breakpoint(medium){
				width:unset;
				bottom:20px;
			}
			&__reviews{
				width: 87%;
				display:flex;
				position:relative;
				z-index:30;

				@include breakpoint(large){
					margin-left:70px;
					&.trainingpage{
						margin-left:0px;
					}
				}
				img{
					width:80px;
					margin-right: -1px;
					//object-fit:cover;
				}
				&__text {
					padding:15px;
					padding-right:10px;
					background-color:$color-blue;
					display:inline-block;
					color:white;
					display:flex;
					width: 100%;
					@include breakpoint(medium){
						width: 67%;
						padding: 15px;
					}
					@include breakpoint(large up){
						padding:15px;
						width: 67%;
					}
					.start__reviews__ratings__text {
						font-size:rem-calc(14);

						&.keuzehulp_startekst{
						}
					}
					align-content:center;
					a{ color:white; font-weight:bold; text-decoration:underline; font-size: 14px;
						&:hover{
							text-decoration:none;
						}
					}
					&__icon{
					margin-top:5px;

					.start__reviews__ratings__star-full{
						width: 21px;
						height: 21px;
					}
					}
				}

				&.trainingpage{
					.cluster-header__background__overlay__reviews__text{
						@include breakpoint(large up){
							width:85%;
						}
					}
				}
			}
			
		} 
		&__image{
			width: 55%;
			@include breakpoint (medium){
				width: 50%;
				height:350px;
				margin-left: 70px;
			}
			@include breakpoint (large){
				width: 55%;
				height: 75vh;
				min-height:450px;
			}
			position:absolute;
			overflow: hidden;
			bottom:60px;
			@include breakpoint (medium){
				bottom:60px;
			
			}
			@include breakpoint (large){
				bottom:60px;
			
			}
			
			z-index:5;
		
			img{
				max-height: 280px;
				@include breakpoint (medium){
					position: absolute;
					bottom: 0px;
					//height:100%;
					max-width:90%;
					max-height: 280px;
					&.subcluster{
						max-height: 357px;
					}
				}
				@include breakpoint(large){
					bottom: 0px;
					max-height: 70vh;
				}
			}
		}
		&__container{
			position:relative;
			img{
				object-fit: cover;
				width:100%;
				@include breakpoint(large){
				z-index:10;
				}
			}
		}
		&__designation{
			position:absolute;
			color:$color-blue;
			z-index:11;
			p{
				color:$color-blue;
				padding:10px 15px;
				margin-bottom:0px;
				font-size:rem-calc(14);
				line-height: 1.3;
				background-color:#fff;
				box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
				display:inline-block;
				margin-left:5px;
			}
			p:first-of-type{
				margin-left:0px;
			}
			strong {
				@include header-text;
			}
			left: 9%;
			bottom:85px;
			@include breakpoint(medium up){
				top:initial;
				left: 7.5%;
				bottom:85px;
			}
			@include breakpoint(large){
				left: 12.5%;
			}
		}
		&__badge{
			width: 150px;
    		margin-bottom: 20px;
		}
	}
  
  &__content{
  	position: relative;
    z-index: 2;
  	&__text{
  		margin-bottom:25px;
  		padding:0 2rem;
  		@include breakpoint(large){
  			margin-bottom:0px;
  			padding:0 0.9375rem;
			padding-left:2rem;
  			padding-top:40px;
  		}
  		&__container{
  			@include breakpoint(large){
  				width:90%;
  			}
  			h1 {
  				@include breakpoint(medium){
  					font-size:2.4rem;
  					padding-right:0px!important;
  					line-height: 1.2!important;
  					letter-spacing: -1px;
  					margin-bottom:1rem;
  				}
  			}
			p {
				@include breakpoint(medium){
					margin-bottom:32px;
				}
			}
  		}
  		/*&__buttons{
  			margin:35px 0px; 
  			@include breakpoint(medium){
  				margin:20px 0px 20px; 
  			}
  			&__button{
  				a{ width:100%; text-align:center; margin-bottom:20px;}
  				&:last-of-type a{ margin-bottom:0px; }
  				@include breakpoint(medium){
  					display:inline-block;
					&::after{
						content:"\2022";
						margin:0px 20px;
						color:$color-business-blue;
					}
					&:last-of-type::after{
						display:none;
					}
					
					a {
						width:inherit;
						text-align:left;
						margin-bottom:0px;
					}
					margin-bottom:15px;
				}
  			}
  			
  		}*/
		&__buttons{
			@include breakpoint(medium){
				margin-bottom:40px;
			}
		}
  		&__more{
  			display:none;
  		}
  		&__subclusters{
  			font-weight:600;
  			padding-bottom:8px;
  			border-bottom:1px solid #D4D4D8;
  			width:100%;
			margin-bottom: 25px;
  		}
  	}
  }
  &__container {
    height: 100%;
    padding-top: 50px;
    position:relative;
    z-index:2;
    @include breakpoint(medium){
    	height: 600px;
    }
	& .row{
		height:100%;
	}
	&__left{
		padding-right: 0.625rem;
    	padding-left: 0.625rem;
    	margin-bottom:20px;
    	@include breakpoint(medium){
    		margin-bottom:0px;
    	}
	
	}
	&__right{
		background-color:white;
    	@include breakpoint(medium){
    		background-color:transparent;
    	}
	
	}
  }
  
  &__subtext {
  		@include raleway;
  		font-weight:500;
    	
		@include breakpoint(medium) {
		  text-align:left;
    	  padding-bottom:20px;
    	  max-width:80%;
		}
		
		&.rating{
			border-radius: 10px;
    		background: #f2f8ff;
    		display:flex;
    		margin-bottom:20px;
    		.start__reviews__ratings__text{
    			display:inline-block;
    		}
    		@include breakpoint (medium){
    			margin-bottom:initial;
    			background:transparent;
    			display:block;
    		}
		}
  }
  .inline-spacer { display:block; }

  &__buttons {
	.cf-button--orange-flat, .cf-button--blue-alt {
		display: inline-flex!important;
		width: 100%!important;
		justify-content: space-between;
		@include breakpoint(large up){
			width: 70%!important;
		
		}
	}
	.cf-button.shadow {
		margin-right:0px;
		margin-bottom:10px;
	}  	
	@include breakpoint(400px) {
		.cf-button.shadow {
			margin-right:40px;
			margin-bottom:0px;
		}
		.inline-spacer { display:inline; }
  	}  	
	@include breakpoint(medium) {
	  text-align:left;
	}
	
  }
  &__date {
  	font-weight:600;
  	color:$color-orange;
  }
  
	
  @include breakpoint(400px) {
    //height: 550px;
  }

  @include breakpoint(medium) {
    
  position:relative; 
    //height: 575px;
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 250px;
    }
    
    &__container {
      height: 575px;
      padding-top: 50px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 45%;
	  background-position: 95% 100%;
    }
    
  }

  @include breakpoint(large) {
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      padding-top: 60px;
      padding-left: 10px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 35%;
	  background-position: 95% 100%;
    }
    &__subtext {
    	display:block;
    }
    &__date{
    	margin-bottom: 0.5rem;
    }
  }

  @include breakpoint(xlarge) {
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      padding-top: 70px;
      padding-left: 20px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 35%;
	  background-position: 95% 100%;
    }
  }

  @include breakpoint(xxlarge) {
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      padding-top: 70px;
      padding-left: 20px;

    }
  }
  
  &__title {
    @include raleway;
    font-family:"Lexend",sans-serif;
    font-weight: 800;
    width: 100%;
    font-size: 3.1rem;
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    @include breakpoint(medium){
    	padding-right: 20px;
    } 

    @include breakpoint(medium) {
      font-size: 3.4rem;
      text-align:left;
    }
    @include breakpoint(large) {
      font-size: 4rem;
      max-width: 1200px;
	  padding-bottom:20px;
    }
    @include breakpoint(xlarge) {
      font-size: 4rem;
    }

  }
  
  &__subtitle {
    @include raleway;
    font-weight: 500;
    width: 100%;
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    text-align:center;
    font-size: 1rem;

    @include breakpoint(360px) {
      font-size: 1rem;
    }

    @include breakpoint(420px) {
      font-size: 1rem;
    }

    @include breakpoint(medium) {
      font-size: 1rem;
      font-weight: 800;
      text-align:left;
    	
    }
    @include breakpoint(large) {
      font-size: 1.5rem;
      max-width: 1200px;
      padding-bottom:20px;
    }
    @include breakpoint(xlarge) {
    }

  }

  &__action {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: rem-calc(24px);
  }

  &__button {
    width: 280px;
    position: absolute;
    top: 250px;
    left: 350px;
  }
	&--subcluster{
    	@include breakpoint(large) {
		height: inherit;
		}
		.cluster-header__text-background span{
		    font-size: 600px;
			font-weight: 800;
			letter-spacing: -60px;
			line-height: 450px;
			width:55%;
		}
		.cluster-header__background{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__overlay{
    		@include breakpoint(large) {
				bottom:-20px; 
			}
		}
		
		.cluster-header__background__image{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__image img{
			max-height:260px;
    		@include breakpoint(medium) {
				max-height:360px;
    		
    		}
    		@include breakpoint(large) {
				max-height:380px;
    		
    		}
		}
	}
	&--stap{
		background-color:#00BEBC;
    	@include breakpoint(large) {
		height: inherit;
		}
		.cluster-header__text-background{
			z-index: 0;
		}
		.cluster-header__text-background span{
		    font-size: 600px;
			font-weight: 800;
			letter-spacing: -60px;
			line-height: 450px;

			color:#00C4C2;
			width:70%;
		}
		.cluster-header__background{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__designation{
			bottom:20px;
			left:20px;
    		@include breakpoint(large) {
				right:20px;
				left:unset;
			}
		}
		.cluster-header__background__overlay{
    			width:100%;
    		@include breakpoint(large) {
				bottom:0px; 
			}
		}
		.cluster-header__background__text{
			line-height: 310px;
			@include breakpoint(medium){
				line-height: 400px;
				
			}
			span{

				color:#00C4C2;
				@include breakpoint(medium){
					font-size:500px;

				
				}
			
			}
		}
		.cluster-header__background__image{
			bottom:0px;
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__image img{
			max-height:450px;
		}
		.cluster-header__content{
		position:relative;
		z-index:2;
		}
		.cluster-header__content__text{
			&__container{
				padding-top:40px;
				@include breakpoint(large){
					padding-top:0px;
					width:100%;
				}
				h1,p{ color:white; }
				h1 { }
				p { font-size:rem-calc(18px);}
				p a {color:white; font-weight:bold;}
				a { width: unset;}
			}
		}
	}
	  
}

.categorie-header {
  margin-bottom: 1rem;
  //@include linear-gradient(top, #ffffff, $color-mobile-gradient 350px);
  //border-bottom: 2px solid $color-light-primary;

  @include breakpoint(medium) {
    min-height: 284px;
    margin-bottom: 1.5rem;
    background: none;
    border-bottom: none;
  }

  &__title {
    font-weight: 800;
    font-size: rem-calc(24);
    color: $color-business-blue;
    margin-bottom:25px;
    margin-top:25px;
    @include breakpoint(medium) {
      margin-top:0px;
      font-size: rem-calc(28);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4), 1px 1px 5px rgba(255, 255, 255, 0.05);
    }

    img {
      float: right;
      margin: 0 0 0 15px;

      @include breakpoint(medium) {
        float: none;
        display: inline-block;
        margin: -3px 0 0 0.5rem;
      }
    }
  }
  .page__intro{
  	font-size:1rem;
  	color: $color-black;
  }
}

.categorie {
	&__container {
		
	}
	
	&__banner {
      	@include breakpoint(medium) {
			margin-bottom:1.5rem;
		}
		&__image{
			background-size: cover;
    		background-position: center center;
    		width: 100%;
    		height: 300px;
    		border-radius:10px;
    		@media print {
				/*background-size: cover!important;
				background-position: center center!important;
				-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
				/*color-adjust: exact !important;                 /*Firefox*/
				display:none;
			}
		}
	}
	&__sidebar {
		.training-links{
			&__title{
				
			}
			&__list{
				font-size:1rem;
			}
			@include breakpoint(medium){
				margin-bottom:1.5rem;
			}
		}
		
		&__readmore{
			cursor:pointer;
			text-decoration:underline;
			color:$color-business-blue-lighter;
			text-align:center;
			@include breakpoint(medium){
				text-align:left;
				margin-bottom:1.5rem;
			}
			&:hover, &:active{
				text-decoration:none;
			}
		}
	}
	&__overview{
		background-color:#fafafb;
		padding-top:25px;
		position:relative;
      	@include breakpoint(medium) {
      		border: 1px solid $color-medium-light-gray;
			border-radius: 8px 0px 0px 8px;
			padding:30px;
      	}
		
		&__title{
			font-weight:750;
			margin-bottom:25px;
			font-size:1.5rem;
			text-align:center;
			color: $color-black;
      		@include breakpoint(medium) {
      			text-align:left; 
      		}
		}
		
		&__filter{
			 
			// &__option{
			// 	background-color:#fff;
			// 	color: #191e58;
			// 	// border:1px solid $color-lightest-gray;
			// 	text-align:center; 
			// 	// width:33.3%;
			// 	width:100%;
			// 	padding:5px 0px;
			// 	font-weight:600;
			// 	font-size:0.9rem;
			// 	cursor:pointer;
            //     height: 100%;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
			// 	@include transition(background 400ms, color 400ms);
			// 	&.selected, &:active, &:hover, &:focus {
			// 		background-color: #fff;
			// 		color:$color-business-blue;
            //         border: none;
            //         border-bottom: 2px solid $color-business-blue;

			// 		// border:1px solid $color-darker-gray;
			// 	}
				
			// }
			// &__option:not(:first-of-type):not(:last-of-type){
			// 	border-left:0px;
			// }
			// &__option:first-of-type{
			// 	border-right:1px solid $color-lightest-gray;
			// 	border-radius: 10px 0px 0px 10px;
			// }
			// &__option:last-of-type{
			// 	border-left:0px;
			// 	border-radius: 0px 10px 10px 0px;
			// }
			
			
			&__grid {
				row-gap:25px;
				&.mobile-container{
					padding-left:1rem!important;
					padding-right:1rem!important;
				}
			}
			&__list {
				display:none;
			}
			&__barebonelist {
				display:none;
			}
			
			
		}
		
		
	}
	
}
.mobile-filter{
		position:relative;
		&__button{
			margin-bottom:0px;
			height:32px;
			font-size: 12px;
			font-weight:600;
			padding-left:2rem;
			padding-top:7px;
			padding-bottom:7px;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 34, 92%29'></polygon></svg>"), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style="fill: rgb%2830, 34, 92%29" d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>');
			background-origin: content-box, content-box;
			background-position: right -1rem center, left -1.5rem center;
			background-repeat: no-repeat, no-repeat;
			background-size: 9px 6px, 18px 15px;
			//border: 1px solid $color-blue;
			color:$color-blue;
		}
		&__expand{
			display:none;
			z-index: 2;
			&__close-button{
			text-align:right;
			}
			position:absolute;
			right:20px;
			background:white;
			border-radius:8px;
			width:70%;
			padding:10px;
			box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
			.tabbing.categories{
				display:block;
				white-space:initial;
				li{
					display:flex;
					width:100%;
					justify-content:space-between;
					border:none;
				}
			}
		}
	}
.sub-category{
	position:relative;
	//margin-top:25px;
	//padding-top:25px;
	&__banner{
		& > .row {
			position:relative;
		}
		&__background{
			position:absolute;
			height:100%;
			width:98%;
			right:0px;
			background:beige;
			border-radius: 0px 0px 0px 70px;
			z-index:-1;
		}
		&__text{ 
			margin:0 auto;
			padding:75px 5%;
			@include breakpoint(medium){
				padding:100px 15%;
			
			}
			@include breakpoint(large){
				padding:100px 30%;
			
			}
		}
		&__title{
			text-align:center;
		}
		&__subtext{
			text-align:center;
		}
	}
	
	&__filter{
		position:relative;
		margin-bottom:25px;
		@include breakpoint(medium up){
			margin-bottom:0px;
		}
		& > .row{
			justify-content: center;
			align-content: center;
		}
		&__container{
			//border-radius: 4px;
			//box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
			//border:1px solid #1C2663;//border: solid 0.3px rgba(2,37,67,0.419);
			background-color: #fff;
			&.sticky{
			position: sticky;
    		top: 90px;
    		@include transition(top 400ms, box-shadow 400ms);
			}
		}
		/*tabbing */
		.tab-head {
			display: flex;
			justify-content: space-between;
			column-gap:10px;
			padding: 0px;
			border-bottom:1px solid #D6D6D6;
			overflow:scroll;
			&.view{
				//padding: 15px;
				padding: 15px 0px;
			}
			@include breakpoint(medium up){
				padding: 15px 0px;
				flex-wrap:wrap;
				overflow:visible;
			}
			&:first-of-type{
				padding-top:0px;
			}
			&.noborder{
				border-bottom:0px!important;
			}
		} 
		.tab-head h6 {
			font-size: 15px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			padding-bottom: 10px;
		   /* border-bottom: 2px solid #747AA557;*/
			margin-right: 40px;
			margin-bottom: 5px;
		}

		ul.tabbing{
			margin: 0px;
			padding: 0px;
			list-style: none;
			display:flex;
			@include breakpoint(medium up){
				flex-wrap:wrap;
			}
		}
		.tab-title{
			flex-basis: 100%;
			margin-top: 0px;
		}
		.tab-title:nth-child(2) {
			//flex-basis: 40%;
		}
		ul.tabbing li.current {
			background: #106bc6;
			color: #fff;
			span {
				color: white;
			}
			svg {
				position:relative;
				top:2px;
				path{
					fill:white;
				}
			}
		}
		.verticle-tabbing ul.tabbing li.current {
			color: #fff;
			border:none; //border: solid 0.5px #707070;
			background-color: #1a2c6c;   
			color: #fff;
		}
		.verticle-tabbing .tab-content{
			margin-top: 0px;
		}
		.verticle-tabbing .tab-content strong {
			padding: 20px 0px;
			display: block;
		}

		ul.tabbing li {
			background: none;    
			display: inline-block;
			white-space: nowrap;
			padding: 5px 15px;
			cursor: pointer;
			font-size: 12px;
			line-height: 20px;
			border:none; //border: solid 0.5px rgba(30,34,92,0.462);
			background-color: #fff;
			margin-right: 0px;
			flex-grow:1;
			flex-basis: 50%;
			svg {				
				position:relative;
				top:2px;
			}
			&:hover{
				background: #106bc6;
				color: #fff;
				span { color: white; }
				svg {		
					path{
						fill:white;
					}
				}
				@include transition(all 400ms);
			}
			&.type{
				@include breakpoint(large up){
					flex-basis:100%;
				}
			}
		}
		ul.tabbing.categories li {
			display:flex;
			justify-content:space-between;
		}
		.verticle-tabbing ul.tabbing li {
			display: block;
			border-radius: 2px;
			border: transparent;
			font-weight: 800;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #1e225c;
			font-size: 20px;
			position: relative;
			width: 350px;
			padding: 15px;
			margin-left: 50px;
			-webkit-transition: all .4s ease; 
			-o-transition: all .4s ease;
			-moz-transition: all .4s ease;
			transition: all .4s ease;

		}
		.verticle-tabbing li.tab-link:hover {
			background-color: #1a2c6c;
			color: #fff !important;
		}
		.verticle-tabbing ul.tabbing li span{
			 font-size: 16px;


		}
		
		.tab-bottom{
			display: flex;
			gap:10px;
			flex-wrap:wrap;
			&__item{
				border: 1px solid #C2C7CC;
				border-radius: 8px;
				padding:15px;
				width:100%;
				&.usps{
					font-size:0.8rem;
					div{
						margin-bottom:5px;
						&:last-of-type{
							margin-bottom:0px;
						}
					}
					div > span{
						padding-left:25px;
						display:inline-block;
					}
					
				}
				&.keuzehulp{
					font-weight:600;
					font-size:0.8rem;
					color:$color-blue;
					a {
						color:$color-orange;
						&:hover{
							text-decoration:none;
						}
					}
				}
			}
			
		}
	
	}
}
.opleiding-container{
	.snap-slider { padding:0; }
}
.wij-trainers-container{
	.snap-slider { padding:0; }
}
.keuzehulp-wijtrainers{
	&__text{
		width: 100%;
		@include breakpoint(medium){
		}
		@include breakpoint(large){
			width: 60%;
			max-width:563px;
		}
		h2{

			font-size:30px!important;
			font-weight: 600!important;
			padding-bottom: 20px !important;
			font-family:"Lexend",sans-serif;
			width: 100%;
		}
	}
	.snap-slider { padding:0; }

}
.keuzehulptrainerboxwidth{
	width:300px !important;
	height:400px !important;

	@include breakpoint(medium){
		width:400px !important;
		height:500px !important;
	}
}
.keuzehulptrainerbox{
	height:100%;
	&__box{
		display: grid;
		height:100%;
		h3{
			font-size:20px;
			font-weight: 800;
			font-family:"Lexend",sans-serif;
		}
		p{
			font-size:14px;
			font-family:"Poppins",sans-serif;
			//display:block;
			//overflow: hidden;
			//text-overflow: ellipsis;
		}

		&__imgbox{
			display:grid;
			grid-template-columns: 1fr 1fr;
			padding-left:20px;
			padding-right:20px;
			height: 100%;
			&__link{
				font-size:15px;
				color: #FF9900;
				text-decoration: none;
				font-weight:500;
				min-height: 180px;
				@include breakpoint(medium){
					min-height: 270px;
				}
			}
			img{
				align-self: end;
			}
		}
	}

}
.keuzehulp-trainingen-scroller{
	&.trainerkeuzehulp{
		height: fit-content;
	}
}
.keuzehulp-trainer_USP{
	display: flex;
	padding-bottom:5px !important;
	flex-direction: column;
	list-style-type: none;
	justify-content: center;
	@include breakpoint(medium){
		justify-content: flex-start;
		border-bottom: 1px solid #CBCFD9;
		flex-direction: row;
		margin-bottom:45px !important;
		gap: 100px;
		margin-top: 50px !important;
	}
	li{
		margin-right:35px;
		margin-left:35px;
		display: flex;
		align-items: center;
		gap:10px;
		@include breakpoint(medium){
			margin-right:0px;
			margin-left:0!important;
		}
		img{ width:16px; }
	}
}

.Inspiratie-cat-slider{
	margin-top:50px;
	background-color: #F8F8FA;
	&__container{
		padding:80px 0px;

		.row{
			width: 100%;
			@include breakpoint(medium){
			}
		}
		&__title{
			&__text{
				h2{
					font-size:30px;
					font-weight: 600;
					padding-bottom: 20px;
					font-family:"Lexend",sans-serif;
					width: 100%;
				}
			}
		}

		&__button{		
			@include breakpoint(large){
				margin-right:auto;
				margin-left:auto;
			}
			&__container{
				@include breakpoint(medium){
					padding-bottom: 40px !important;
					border-bottom: 1px solid #CBCFD9;
				}	
			}
			button{
				padding:20px;
				text-align: start;
				&:hover{
					padding:20px !important;
					padding-right:0px!important;
				}
			}
			&__text{
				font-size:16px !important;
				font-weight: 600;
			}
			&__icon{
				font-size:16px !important;
			}
		}
	}
	.snap-slider { padding:0; }
}
.verdiepenInCat{
	padding: 100px 0px;

	&__title{
		text-align: center;
		margin-bottom: 70px;
		width: 100%;
		display: flex;
		justify-content: center;
		h2{
			font-size:30px;
			font-weight:600;
			width: 100%;
			@include breakpoint(medium){
				width: 50%;
			}
		}
	}
	&__container{
		  @include breakpoint(medium){
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-template-rows: 1fr;
			grid-auto-rows: 1fr;

		}

		&__whitepaper{
			box-shadow: 10px 10px 40px hsla(222, 11%, 42%, 0.133);
			border: 1px solid #1E225C;
			border-radius: 12px;
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			padding: 30px;
			margin-right:0px;
			height: fit-content;
			height: 100%;
			 @include breakpoint(medium){
				grid-template-columns: 1fr 2fr;
				grid-template-rows: 1fr;
				margin:0;
				padding: 50px;
				margin-right:50px;
				min-height: 440px;
			}
			.magazine-pro-home__imgbox{
				width: auto;
  				height: auto;
				img{
					position: absolute;
					top: 0px;
					left: 0px;
					object-fit:contain;
					@include breakpoint(medium){
						top: -20px;
						left: -120px;
						height: auto;
						width: auto;
						top: 50%;
    					transform: translateY(-50%) rotate(-7deg);
					}
				}
			}
			&__textbox{
				@include breakpoint(large){
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				
				&__title{
					color: #1E225C;
					font-weight: 800;
					font-size:20px;
					font-family:"Lexend",sans-serif;
					margin-bottom:30px;
				}
				&__text{
					margin-bottom:30px;
				}
				&__button{
					padding: 20px 20px !important;
					text-align: left;
					@include breakpoint(large){
						text-align: center;

					}
				}
			}
		}
		&__richtingen{
			box-shadow: 10px 10px 40px hsla(222, 11%, 42%, 0.133);
			border: 1px solid #1E225C;
			border-radius: 12px;
			padding: 30px;
			margin-top: 50px;
			@include breakpoint(medium){
				padding: 50px;
				margin-top: 0px;
				min-height: 440px;
			}
			&__title{
				color: #1E225C;
				font-weight: 800;
				font-size:20px;
				font-family:"Lexend",sans-serif;
				margin-bottom:20px;
			}
			&__richtingdiv{
				.cf-button--blue-flat-alt{
					padding-left:0px!important;
					padding-right:0px!important;
					&:hover{
						background-color: transparent;
						color:$color-business-blue;
						.cf-button__icon{
							color:$color-business-blue;
							svg path{fill:$color-business-blue;}
						}
						.cf-button__text{
							color:$color-business-blue;

						}
					}
				}
				.incompany__intro__container__text__subclusters__row{
					display: flex;
  					flex-direction: column;

					div{
						flex: 0 0 100%;
  						max-width: 100%;
						padding-right: 0 !important;
						border-bottom: 1px solid #DDDDDD;
						&:last-of-type{
							border:none;
						}
						a{
							box-shadow:none;
							padding:10px 0px!important;

							span{
								font-size:16px;
							}
						}
					}
				}
			}
		}
	}
}
.keuzehulpCTA{
	&.incompany__intake{
		.incompany__intake__text{
			h2{
				font-size:30px !important;
				font-family:"Lexend",sans-serif;
				font-weight:600;
				letter-spacing: -0.45px;
				margin-bottom:27px;
			}
			p{
				font-size:18px;
				color: #181B4B;
				margin-bottom:27px;
			}
			.cta-block__intake__text__callout__title{
				background: none;
				color: #1E225D;
				font-family:"Lexend",sans-serif;
				font-size:20px;
				font-weight: 600;
				padding:0;
				margin-top: 50px;
				text-align: start;
			}
			.cta-block__intake__text__callout__content{
				padding:0;
				padding-top:20px;

				span{
					color: #181B4B;
					font-size:18px;
					font-weight:400;
				}
			}
		}
		.cluster-header__background__designation{
			@include breakpoint(large){
				margin-left:140px;
			}
		}
		.cluster-header__background__overlay__reviews{
			@include breakpoint(large){
				margin-left:140px;
			}

		}
	}
}
.keuzehulptrainingdata-table{
	&__legenda{
		td{
			color:white;
			font-size:18px;
			font-family:"Lexend",sans-serif;
			font-weight: 600;
		}
		.column-start{width:6%;}
		.column-name{width:32%;}
		.column-duration{width:8%;}
		.column-price{width:8%;}
		.column-offer{width:25%;}
		.column-button{width:17%;}
		.column-favorite{width:4%;}
	}
	&__favtraining{
		position: relative;
		height:100%;
		&::after{
			content:none !important;
		}
	}
}
.cluster-header__background{
 &.keuzehulp_subluster{
	.cluster-header__background__overlay{
		width:100%;
		border: 2px solid #1E225D;
		bottom:0px;
	}

	.cluster-header__background__designation{
		bottom: 20px;
		left: 40px;
    	width: 300px;

		p{
			width: 100%;
			font-weight:500;

			strong{
				font-weight:800 !important;
			}
			span{
				font-weight:500 !important;
			}
		}
	}
	.cluster-header__background__image{
		bottom: 0;
  		right: auto;
		height:fit-content;
		@include breakpoint(medium){
			right: -50px;
		}
	}
	.cluster-header__background__text{
		line-height:440px !important;
		@include breakpoint(medium){
			line-height:590px !important;
		}
	}
 }
}
.cluster-header__content__text{
	&.keuzehulp-subcluster_text{
		.cluster-header__content__text__container{
			h1{
				font-size:39px !important
			}
		}
	}
}
.subcluster-andererichtingen{
	margin-top:50px;
	&__title{
		font-size:16px;
		font-weight:600;
		color: #1B2A68;
		border-bottom: 1px solid #D4D4D8;
		padding-bottom: 10px;
	}
	&__bottondiv{
		.incompany__intro__container__text__subclusters__row{
			display: flex;
			flex-direction: column;
			@include breakpoint(medium){
				display: flex;
				flex-direction: row;
			}
			div{
				flex:50%;
				@include breakpoint(large){
					padding-right: 0.9375rem !important;
					flex:0 0 25%;
				}
			}
		}
	}
}
.keuzehulpsubcluster-eerstvolgende{
	background-color:transparent;
	padding:0px;
	&__tekstvoorvlakken{
		td{
			color:white;
			font-size:18px;
			font-weight: 600;
		}
	}
}
.keuzehulpblogding{
	width: 300px !important;
	@include breakpoint(medium){
		width: 400px !important;
		height: 500px !important;
	}
	.training-grid-block__content__text{
		@media only screen and (max-device-width: 1030px) {
			height:fit-content !important;
		 }
	}
	.training-grid-block{
		min-height:550px;
		max-height:550px;
	}
}