.admin-evaluatie {
  padding-bottom: 15px;


  &__title {
    @include raleway;
    font-size: rem-calc(21px);
    font-weight: 600;
    color: $color-dark-primary;
    margin: 0 0 25px;
    strong {
      font-weight: 700;
      color: $color-light-primary;
    }
  }

  &__subtitle {
    @include open-sans;
    color: $color-dark-primary;
    font-weight: bold;
    font-size: rem-calc(15px);
    margin: 15px 0 15px 12px;
  }

  &__table {

    td, th {
      padding: 0.25rem 0.625rem 0.35rem;

      p {
        margin-bottom: 0;
      }
    }
    th:first-child, td:first-child {
      text-align: left;
      @include breakpoint(medium) {
        width: 300px;
      }
    }
  }
}