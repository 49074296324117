.twocol {
  position: relative;
  padding: 0 0 10px;
  @include breakpoint(medium) {
    padding: 0 0 50px;
  }
  
  > .row {
  	> .column:nth-child(1){
  		@media print{
  			-ms-flex: 0 0 100%;
    		flex: 0 0 100%;
    		max-width: 100%;
  		}
  	}
  }
  &__banner {
    @include show-for(medium);
    position: absolute;
    left: 0;
    width: 100%;
    height: 320px;
    overflow: hidden;
    background-position: center center;
    background-size: cover; 
    background-color: $color-blue-ultralight;
    background: rgb(242,248,255);
	background: linear-gradient(180deg, rgba(242,248,255,1) 0%, rgba(255,255,255,0) 100%);
	
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      //@include linear-gradient(top, #f8f9fb 0%, #f7f8fa 175px, #f0f1f2 350px);
      z-index: 2;
    }

    &--sharper {
		
	  height: 320px;	
      .twocol__banner-overlaywithoutimage {
        opacity: 1;
        background:none;
      }
      
    	@media print{
    		height:175px;
    	}
    }
    @media print{
    	top:0px;
    }
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 400px;
    z-index: 1;
    @media print {
    	/*background-size: cover!important;
    	background-position: center center!important;
   		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    	/*color-adjust: exact !important;                 /*Firefox*/
    	display:none;
	}
  }

  &__content {
    position: relative;
    //z-index: 3;
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__sidebar {
    position: relative;
    z-index: 3;

    @include breakpoint(medium down) {
      .sticky {
        position: static;
      }
    }
    @include breakpoint(large) {
      margin-top: 5rem;  
    	
    }
    @media print {
    	display:none;
    }

  }
  
  &__sidebar-training {
    position: relative;
    z-index: 3;
    
    &__background {
  		@include breakpoint(medium down) {
  			padding:0px;
  		}
	}

    @include breakpoint(large) {
      margin-top: 1.5rem;  
    	
    }
    @media print {
    	display:none;
    }

  }
  
}