.kalender {

  @include breakpoint(medium) {

    &__content {
      border-left: 1px solid $color-line;
      padding-left: 1.5rem;
    }

    &__title {
      margin-bottom: 2rem;
      border-bottom: 2px solid $color-line;
    }

    &__maand {
      border-bottom: 2px solid $color-line;
      margin-bottom: 2rem;
    }
  }

  &__maand-naam {
    color: $color-header;
    font-weight: 500;
    font-size: rem-calc(26);
    margin: 1rem 0 1rem;
    padding: 0.8rem 0 0;
    border-top: 2px solid $color-line;

    @include breakpoint(medium) {
      margin: 0 0 1rem;
      padding: 0;
      border-top: none;
    }
  }

  &__dag {
    margin-bottom: 1rem;
  }

  &__dag-list {
    line-height: 1.2;
    li {
      margin: 0 0 0.3rem;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .kalender {
      &__training {
        em {
          font-style: normal;
          font-weight: 500;
          color: $color-light-primary;
        }
      }

      &__datum-plaats {
        font-size: rem-calc(14);
      }

      &__plaats {
        color: $color-dark-gray;
      }

      &__plaats-secondary {
        color: $color-medium-gray;
      }
    }
  }

  &__datum-plaats {

  }

  &__dagnummer {
    display: block;
    text-align: center;
    @include raleway;
    font-size: rem-calc(18);
    font-weight: 600;
    color: $color-header;
  }

  &__maand-list {

  }

  &__maand-jaar {
    display: block;
    @include raleway;
    font-size: rem-calc(18);
    margin: rem-calc(15px) 0 rem-calc(5px);
  }
}