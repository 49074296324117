#training-links{
	@media print {
      display:none;
	  page-break-after: always;
    }
    margin-bottom:45px;
}
#inhoud {
  @media print{
	page-break-after: always;
	font-size: rem-calc(13);
	column-count:2;
	column-gap:40px;
	column-width:148mm;
	-webkit-column-count:2;
	-webkit-column-gap:40px;
	-webkit-column-width:148mm;
	-moz-column-count:2;
	-moz-column-gap:40px;
	-moz-column-width:148mm;
  }
  h2 { column-span:all; }
  p.title {
  	font-weight:bold;
  }
}
#lesmateriaal, #onderdelen {
  @media print{
	page-break-after: always;
  }
}
#onderdelen {
	@media print{
		display:none;
	}
}
.training-intro {
  &__titel{
  	font-size:1.35rem!important;
  	margin:20px 0px;
  	padding-bottom:0px!important;
  	line-height:initial!important;
  }
  &__omschrijving {
  	@media print{
		font-size: rem-calc(13);
  	}
	font-size: rem-calc(15);
  }
  &__springest {
    margin: 0 auto 0.5rem;
    width: 230px;

    @include breakpoint(400) {
      float: right;
      margin: -15px 0 0 1rem;
    }
    @include breakpoint(medium) {
      margin-left: 2rem;
    }
    @media print {
      display:none;
    }
  }

  &__pe-punten {
    float: right;
    width: 125px;
    height: 180px;
    margin: 0 0 0 2rem;

    img {
      display: block;
      width: 125px;
      height: 180px;
    }
  }
  
  &__nieuwsbrief {
  	clear:both;
  }
}
.training-opleidingsvoordeel{
	box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
	border: 1px solid $color-blue;
	border-radius: 12px;
	background:white;
	margin: 30px 0px;
	//padding: 30px;
	//text-align: center;
	& > .cf-button{
    	@include breakpoint(medium) {
			//width:60%;
		}
		@include breakpoint(large) {
			//width:40%;
		}
	}
	display:flex; 
	&__content{
		padding:20px;
		@include breakpoint(large){
			padding:30px;
		}
		.label--yellow{ 
			background-color:yellow;
			padding:0px 5px;
		}
		h3{
			font-size:rem-calc(20px);
		}
		a { font-weight:bold;}
		.readmore-link { cursor:pointer;}
		flex-grow:1;
		flex-basis:70%;
		&.adviesblok{
			@include breakpoint(large){
				padding: 50px 80px;
			}
		}
	}
	&__image{
		flex-basis:30%;
		position:relative;
		img{
			position:absolute;
			right:0;
			bottom:0;
			max-height:100%;
			border-radius:12px;
			object-fit: contain;
    		height: 100%;
			&.hover{
				height: auto;
			}
			&.ready{
				height: auto;
			}
			&.bottom{
				object-position: bottom;
			}
			/*-webkit-transition: opacity 1s ease-in-out;
			-moz-transition: opacity 1s ease-in-out;
			-o-transition: opacity 1s ease-in-out;
			transition: opacity 1s ease-in-out;*/
		}
		img.hover{
			opacity:0;
		}
	}

	&.opleidingsbudget-banner{
		border: 1px solid #cccccc;
		box-shadow: none;
		h3{
			font-size:1rem;
		}
		p{
			margin-bottom:0px;
		}
	}

	&:hover{
		.training-opleidingsvoordeel__image{
			img.ready{
			  opacity:0;
			}
			img.hover{
				  opacity:1;
			}
		}
	}
	&--trainer{
		.training-opleidingsvoordeel__content{
			.inner div{
				height:75px;
				overflow:hidden;
				text-overflow:ellipsis;
				width: calc(100%);
				margin-bottom:8px;
			}
			.label{
				background-color:yellow;
				color:white;
			}
			p { font-size:rem-calc(15px); line-height : 23px; }
		}
		/*&:hover{
			.training-opleidingsvoordeel__content .inner p{
				height:100%;
				@include transition(all 400ms);
			}
		}*/
	}
	&--centered{
		width: 100%;
		margin: 30px auto !important;
		@include breakpoint(medium){
			width:80%;
		}
		@include breakpoint(large){
			width:70%;
		}
	}
}
.training-relateerdetrainingen{
	&__title{
		padding-bottom: 10px;
		p{
			font-size: 1.5625rem;
  			font-weight: bold;
			margin-bottom: 0;
		}
	}
	&__container{
		.rf-cards-scroller-crop{
			height:36rem;
			@include breakpoint(medium){
				height:33rem;
			}
			.rf-cards-scroller-platter{
				padding-top: 0;
				.rf-cards-scroller-itemview{
					transform:none;
					height:100%;

					.as-util-relatedlink{
						height: 100%;
						.rf-ccard-content{
							height: 100%;

							.trainingen-grid__item{
								height: 100%;
							}
						}
					}
				}
			}
		}
		//
		//&.job-container{
		//	.rf-cards-scroller-crop{
		//		height:40rem;
		//		@include breakpoint(medium){
		//			height:33rem;
		//		}
		//	}
		//}
	}
}
