@mixin styledrilldowntekst {
    color: #1E225C;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    outline: none;

    a{
        font-family: 'lexend';
        font-size:16px;
        text-decoration: none;
        text-align: start;
        font-weight: 500;
        position: relative;
        padding-right: 7vw;
        outline: none;
        padding-bottom: 2vh;
        padding-top: 2vh;
        &:focus {outline:none;}
    }
    a + ul > li > a {
        font-size:14px;
        outline: none;
    }
    .new-back{
        padding-right:15px;
        padding-top: 10px!important;
        padding-bottom:10px !important;
    }
}


#offCanvasMobileMenu{
    margin:0;
    padding:0;

    .OffcanvasUsername{
        float: left;
        margin-left: 3vw;
        font-size: 13px;
        font-family: Poppins;
        color: #9D9D9D;
        margin-top:13px;
        cursor: pointer;
    }

    .menu.nested{
        margin:0;
        padding-left:0rem;
    }

    .TopbarMobileOffcanvasNav{
        height:20px;
        width:100%;
        padding:0;
        margin-top:3vh;

        .close-button{
            color:$color-business-blue;
            outline: none;
            &:focus,&:hover { outline: none; }
            
        }
    }

    .closebuttonMobileOffcanvasNav{
        float:right;
        margin-right:5vw;
    }

    .MenuItems{
        margin-top:5vh;
        outline: none;
    
        .is-drilldown{
           max-width: fit-content !important;
          }
        .tekstdrilldown{
            @include styledrilldowntekst;

            outline: none;
            & .drilldownclicked a{
                margin-left:5vw;
                font-size:14px;
                outline: none;
                &:focus {outline:none;}
            }
            img{
                object-fit: contain;
                height:70px;
                border-bottom: 1px solid black;
                width: 70%;
                margin-right: 25px;
            }
            .link_noimage{
                display:grid;
                outline: none;
                span{
                    color: #888995;
                    font-size: 12px;
                    font-family: 'Lexend';
                    margin-top:0.5vh;
                }
            }
            .menudescription{
                display: grid !important;
                grid-template-columns: 1fr 1.5fr;
                justify-items: center;
                position: relative;

                .MobileMenu_Catogorieletter{
                    position:absolute;
                    width: 100%;
                    height:100px;
                    z-index: -1;
					font-size: 70px;
					overflow: hidden;
					font-family: 'Lexend', sans-serif;
					font-display: fallback;
					font-weight: 800;
                    letter-spacing: -5px;
                    line-height: 100px;
                    padding-left: 10px;
                }

                span{
                    font-family:'lexend';
                }

                div{
                    display: grid;
                    grid-template: auto;
                    align-content: center;
                    height: 100%;
                    width:100%;
                }
                .div_tekstMenu{
                    padding-right: 5vw;
                }
            }
            .menudescriptiontext{
                font-size:12px;
                color: #888995;
            }
            .menudescriptiontitel{
                font-size:14px;
                margin-bottom: 5px;
            }
            /*
            .HeeftGeenTabindex::after{
                content: ' \276F';
                position: absolute;
                right: 0;
                margin-right: 15px;
                display: flex;
                align-items: center;
                height: 100%;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                top:0;
                font-size:1.5rem;t
            }*/
        }
        .topleveltesting a{
            font-weight:600;
            outline: none;
            &:focus {outline:none;}
        }
        .catogorietekst{
            @include styledrilldowntekst;
            a{
                margin-left:0 !important; 
                font-size:16px !important;
            }
        }
    }

    .is-drilldown-submenu-parent > a::after{
        margin-right:3px;
        border-left-color: rgba(0, 0, 0, .5);
    }

    .js-drilldown-back{
        width:fit-content;
        border:1px solid #1E225C;
        border-radius: 100px;
        padding-left:1vw;
        padding-right:1vw;
        margin-bottom: 30px;
        margin-left:13.6px;
    
        a{
            align-items:center !important;
        }
    }
    .new-back{
        margin-left:0 !important;
        font-size:16px !important;
    }
}