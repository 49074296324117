.terms{
	font-size:14px;
}
.stap-budget{
	&__callout{
		&__image{
			display:none;
			@include breakpoint(medium){
				display:block;
				width:15%;
			}
		}
		&__text{
			width:100%;
			padding-left:20px;
			@include breakpoint(medium){
				width:85%;
			}
			form{
				margin-top:0px;
			}
			button{
				padding-top:10px;
				cursor:pointer;
				span{
					font-weight:bold;
					text-decoration:underline;
				}
				&:hover{
					span{
						text-decoration:none!important;
					}
				}
			}
		}
	}
}
.training-container {
	@media print{
		margin-top:100px;
	}
}
.scroll-up .training__navigation {
    top: 73px;
    transition: top 400ms;
}
.training-bottom-navbar{
	transition: all 400ms;
	width:100%;
	position: fixed;
	bottom: 0px;
	background-color: white;
	height:51px;
	border-top:1px solid #E6E6E6;
	z-index: 40;
	padding:5px 20px;
	@include breakpoint(large){
		display: none;
	}
	&__items{
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__buttons{
			display: flex;
			gap:10px;
			a, button{
				background-color: white;
				padding:7px 15px;
				font-weight: 600;
				border-radius: 4px;
				border:1px solid $color-business-blue;
				text-decoration: none;
				color: $color-business-blue;
				transition: all 400ms;
				line-height: 20px;
				font-size: 14px;
				display: flex;
				align-items: center;
				gap:10px;
				.icon{
					img{
						width:16px;
						filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(250deg) brightness(101%) contrast(103%);
					}
				}
				&:hover, &:active{
					color:white;
					background-color:  $color-business-blue;
					.icon{
						img{
							filter: invert(11%) sepia(32%) saturate(4821%) hue-rotate(226deg) brightness(94%) contrast(94%);
						}
					}
					
				}
			}
		}
		&__icons{
			display: flex;
			gap:15px;
			a {
				position: relative;
				img{
					width:24px;
					filter: invert(11%) sepia(32%) saturate(4821%) hue-rotate(226deg) brightness(94%) contrast(94%);
				}
				&.working-hours{
					&::after{
						width:8px;
						height: 8px;
						background-color: #9DD08B;
						border-radius: 100%;
						content:" ";
						position: absolute;
						right:-3px;
					}
				}
			}
		}
	}
	& ~ #drift-frame-controller{
		bottom: 50px!important;
		display: none;
		@include breakpoint(large){
			bottom:24px!important;
			display: block;
		}
	}
} 
body.scroll-down .training-bottom-navbar{
	opacity: 0.5;
}
.training-bottom-navbar.mouseenter{
	opacity: 1!important;
}
#introductie {
	p, strong { font-weight: 400;}
}
.training {
	&__navigation{
		border-bottom:1px solid #D6D6D6;
		position:sticky;
		top:0px;
		z-index:95;
		background-color: white;
		transition: top 400ms;
		.container{
			display:flex;
			justify-content:space-between;
			background-color:white;
			margin-top:0px!important;
		}
		.menu{
			.menu-item a{
				cursor:pointer;
				@include header-text;
				text-decoration:none;
				border-bottom:1px solid white;
				font-size:15px;
				padding:22px 16px;
				min-height:70px;
				align-items:center;
				color:#9b9dad;
				&:hover,&.active{
					border-bottom:1px solid $color-blue;
					color:$color-business-blue;
					&::before{
						background-color:$color-business-blue;
					}
				}
			}
			.data-inschrijven a {    
				/*transition: all 400ms;*/
				display: flex;
				align-items:center;
				&::before{
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 15px;
					content: "";
					background-color:#9b9dad;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTAuMTQ5IDcuMzgxYy0xLjQ4Mi0uMDA4LTMuMzQzLjc5NS00Ljc2MyAyLjIxNC0uNTQxLjU0MS0xLjAxOSAxLjE3My0xLjM4NiAxLjg4MiAxLjI4MS0uOTY5IDIuNjQ3LTEuMjczIDQuMjQ3LS4zNzUuNDcxLTEuMjQ1IDEuMDk5LTIuNTEyIDEuOTAyLTMuNzIxem04LjQ3NyA4LjQ2MWMtMS4zMTMuODU4LTIuNjA0IDEuNDc4LTMuNzIzIDEuOTE0Ljg5NyAxLjYuNTk0IDIuOTY0LS4zNzYgNC4yNDQuNzEyLS4zNjcgMS4zNDMtLjg0NCAxLjg4NC0xLjM4NiAxLjQyNS0xLjQyMyAyLjIyOS0zLjI4OCAyLjIxNS00Ljc3MnptNS4zMjktMTMuODA1Yy0uMzU3LS4wMjUtLjcwNy0uMDM3LTEuMDUxLS4wMzctNy40NTEgMC0xMS42OTIgNS42NzgtMTMuMjM1IDEwLjI0NWw0LjA5NSA0LjA5NGM0LjcwMi0xLjY4NSAxMC4yMzYtNS43NjcgMTAuMjM2LTEzLjEzNiAwLS4zOC0uMDE1LS43NjgtLjA0NS0xLjE2NnptLTguNTY1IDguNTc5Yy0uMzM5LS4zMzgtLjMzOS0uODg2IDAtMS4yMjQuMzM4LS4zMzguODg2LS4zMzggMS4yMjQgMCAuMzM5LjMzOC4zMzkuODg2IDAgMS4yMjQtLjMzOS4zMzktLjg4Ni4zMzktMS4yMjQgMHptMi40NDktMi40NDhjLS42NzctLjY3Ni0uNjc3LTEuNzcyIDAtMi40NDlzMS43NzMtLjY3NiAyLjQ0OSAwYy42NzcuNjc2LjY3NyAxLjc3MiAwIDIuNDQ5LS42NzYuNjc2LTEuNzcyLjY3Ni0yLjQ0OSAwem0tNi4wODkgOC43MjNjLTEuNDY5IDEuNzk3LTMuODEyIDMuMDg0LTYuMTI2IDMuMDg0LTMuMiAwLTUuNjI0LTIuMzU0LTUuNjI0LTUuMzExIDAtMS40ODUuNjExLTMuMTIyIDIuMDQzLTQuNjg5LTMuMjAxIDUuMTM3IDEuNTMyIDkuODMxIDcuMTAxIDQuMzFsLjc2NS43NjVjLS45MSAxLjA2MS0xLjg0IDEuOC0zLjE0OSAyLjQ2MSAxLjM0NC4wNTcgMy4wNDktLjU2OCA0LjE5NS0xLjQxNmwuNzk1Ljc5NnoiLz48L3N2Zz4=") no-repeat 0 0;
					mask-size: cover;
					position:relative;
				
				}
				&:hover,&:active{
					&::before{
						background-color: $color-business-blue;
					}
				}
			}
			.favorite a {    
				/*transition: all 400ms;*/
				display: flex;
				align-items:center;
				&::before{
					display: inline-block;
					width: 25px;
					height: 25px;
					margin-right: 15px;
					content: "";
					background-color:$color-blue;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuNSAxMGMtMi40ODMgMC00LjUgMi4wMTUtNC41IDQuNXMyLjAxNyA0LjUgNC41IDQuNSA0LjUtMi4wMTUgNC41LTQuNS0yLjAxNy00LjUtNC41LTQuNXptMi41IDVoLTJ2MmgtMXYtMmgtMnYtMWgydi0yaDF2MmgydjF6bS02LjUyNyA0LjU5M2MtMS4xMDggMS4wODYtMi4yNzUgMi4yMTktMy40NzMgMy40MDctNi40My02LjM4MS0xMi0xMS4xNDctMTItMTUuODA4IDAtNC4wMDUgMy4wOTgtNi4xOTIgNi4yODEtNi4xOTIgMi4xOTcgMCA0LjQzNCAxLjA0MiA1LjcxOSAzLjI0OCAxLjI3OS0yLjE5NSAzLjUyMS0zLjIzOCA1LjcyNi0zLjIzOCAzLjE3NyAwIDYuMjc0IDIuMTcxIDYuMjc0IDYuMTgyIDAgLjc0Ni0uMTU2IDEuNDk2LS40MjMgMi4yNTMtLjUyNy0uNDI3LTEuMTI0LS43NjgtMS43NjktMS4wMTQuMTIyLS40MjUuMTkyLS44MzkuMTkyLTEuMjM5IDAtMi44NzMtMi4yMTYtNC4xODItNC4yNzQtNC4xODItMy4yNTcgMC00Ljk3NiAzLjQ3NS01LjcyNiA1LjAyMS0uNzQ3LTEuNTQtMi40ODQtNS4wMy01LjcyLTUuMDMxLTIuMzE1LS4wMDEtNC4yOCAxLjUxNi00LjI4IDQuMTkyIDAgMy40NDIgNC43NDIgNy44NSAxMCAxM2wyLjEwOS0yLjA2NGMuMzc2LjU1Ny44MzkgMS4wNDggMS4zNjQgMS40NjV6Ii8+PC9zdmc+") no-repeat 0 0;
					mask-size: cover;
					position:relative;
				}
				&:hover{
					&::before{
						background-color:#FF4F57;
					
					}
				}
			}
			.download-brochure a {    
				/*transition: all 400ms;*/
				display: flex;
				align-items:center;
				&::before{
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 15px;
					content: "";
					background-color:$color-blue;
					mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjIgMGgtMTdjLTEuNjU3IDAtMyAxLjM0My0zIDN2MThjMCAxLjY1NyAxLjM0MyAzIDMgM2gxN3YtMjBoLTR2OGwtMi0yLTIgMnYtOGgtOC41MDVjLTEuMzc1IDAtMS4zNzUtMiAwLTJoMTYuNTA1di0yeiIvPjwvc3ZnPg==") no-repeat 0 0;
					mask-size: cover;
					position:relative;
				}
			}
		}
		&__submenu-right{
			display: flex;
 			align-items: center;
			 &.working-hours::after{
				 width:10px;
				 height: 10px;
				 background-color: #9DD08B;
				 border-radius: 100%;
				 content:" ";    
				 align-self: flex-start;
				 margin-top: 10px;
				 margin-left: 5px;
			 }
			&__bel{
				display: grid;
				font-size:15px;
				font-weight: 600;
				color: #1E225C;
				font-family: 'Lexend', sans-serif;
				margin-bottom: 0px;
				span.kantoortijd{
					color:#9B9DAD;
					font-size: 14px;
					font-family: 'Poppins', sans-serif;
					font-weight: 400;
					text-align: right;
				}
				span.kantoor-telnummer{
					text-align: right;
					display:flex;
					flex-direction: row-reverse;
					

					span{
						display:flex;
					}
				}
				svg{
					filter: invert(17%) sepia(11%) saturate(3354%) hue-rotate(197deg) brightness(102%) contrast(109%);
					width: 16px;
				}
			}
		}
		&__submenu-left{
			display: flex;
 			align-items: center;
			.button-bewaar-blog{
				span{
					color:#9b9dad;
				}
				img{
					width:18px;
					filter: invert(73%) sepia(19%) saturate(187%) hue-rotate(196deg) brightness(84%) contrast(91%);
				}
				&:hover{
					span{
						color:$color-business-blue;
						font-weight:400!important;
					}
					img{
						filter: invert(10%) sepia(35%) saturate(4627%) hue-rotate(227deg) brightness(97%) contrast(93%);
					}
				}
			}
		}
		&.maatwerk{
			.data-inschrijven { display:none; }
		}
	}
	&__body{
		margin: 60px 0px;
		overflow: hidden;
		@include breakpoint(large){
			overflow: unset;
		}
		&__container{
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			@include breakpoint(991px){
				flex-wrap:nowrap;
			}
			&__left{
				width:100%;
				order:2;
    			@include breakpoint(767px){
					padding-right: 100px;
    				width: 70%;
    				order:1;
    			
    			}
			}
			&__right{
				width:100%;
				order:1;
    			@include breakpoint(767px){
					flex-basis: 30%;
					order:2;
    			
    			}
			}
		}
	}
	&__actiebalk {
		//background:#f8f8fa;
		//background:#FFF500;
		background:$color-business-blue;
		//background-color:$color-orange;
		.counter .clock{
		flex-grow: unset; 
		}
		.counter .digit span, .counter .digit{
			font-size:12px;
		}
    	@include breakpoint(medium) {
			padding:0px 20px;
			.counter.center{
				display: inline-flex;
			}
			.counter .counter__item{
				margin-left:15px;
			}
			.counter .digit span, .counter .digit{
				font-size:14px;
			}
    	}
    	@include breakpoint(large) {
    		height:inherit;
    	}
		&__row{
			.column {
				text-align:center;
				//color:$color-business-blue;
				color:white;
				padding: 5px 0px;
				font-size:12px;
				font-weight:500;
				a { color:white; }
				.bold{
					font-weight:bold;
				}
    			@include breakpoint(medium) {
					font-size:14px;
    				
    			}
			}
		}
	}
	&__banner{
		@include show-for(medium);
		height: 200px;
		overflow: hidden;
		background-position: center center;
		background-size: cover; 
		&__image{
			@include show-for(medium);
			height: 200px;
			overflow: hidden;
			background-position: center center;
			background-size: cover; 
		}
		&--sharper {
		
		  height: 200px;	
		  .training__banner-overlaywithoutimage {
			opacity: 1;
			background:none;
		  }
	  
			@media print{
				height:175px;
			}
		}
	}
	&__imageslider{
		margin-right:-20px;
		@include breakpoint(medium){
			margin-right:0px;
		}
		.snap-slider .rf-cards-scroller-crop{
			height:20rem;
			@include breakpoint(large){
				height: 33rem;
			}
		}
		.snap-slider  .rf-ccard-40 .rf-ccard-content {
			width: 24rem;
    		height: 17rem;
			@include breakpoint(large){
				width: 45rem;
				height: 30rem;
			}
		}
		.snap-slider .rf-cards-scroller-itemview{
			transform: none!important;
		}
		.snap-slider .rf-ccard-img-full-wrapper img{
			//@include breakpoint(large){
				//height:max-content;
				object-fit: cover;
				height: 100%;
				width: 100%;
			//}
		}
	}
	&__section{
		clear:both;
		margin:80px 0px;
		&#opleidingsvoordeel{
			margin-bottom:0px;
			margin-top:0px;
			h2.headerOpleidingvoordeel, p.tekst-headerOpleidingVoordeel{
				max-width: 300px;
				margin:0 auto!important;
				@include breakpoint(medium){
					max-width: unset;
				}
			}
		}
		&:first-of-type{
			margin-top:20px;
			@include breakpoint(large){
				margin-top:0px;
			}
			//p, strong { font-weight: 400; }
		}
		&__title, h2{
			margin-bottom:20px;
			font-size:20px;
			font-weight: 600;
			letter-spacing: -0.1px;
			line-height: 26px;
			@include breakpoint(large){
				font-weight: 700;
				font-size:22px;
				line-height: 28px;
			}
		}
		&--sub{
			border-bottom:1px solid #E3E3E3;
			margin:0px;
			margin-bottom:25px;
			&:first-of-type{
				margin-top:80px!important;
				margin-bottom:25px !important;
			}
			&:last-of-type{
				margin-bottom:80px;
			}
			&__content{
				font-size:16px;
				//padding-bottom:10px;
				&.closed{
					display:none;	
				}
			}
		}
		iframe {
			width:100%;
			border:0;
			border-radius:8px;
			aspect-ratio: 16 / 9;
			@include breakpoint(large){
			width: 95%;
			height:400px;
			}
		}
		p { 
			line-height: 25px; 
			font-size:15px;
			color:#141743;
			@include breakpoint(large){
				font-size:16px;
			}
		}
		li { 
			line-height: 25px; 
			font-size:15px;
			color:#141743;
			@include breakpoint(large){
				font-size:16px;
			}
		}
	}
	&__section-header{
		font-size:rem-calc(25);
		margin-bottom:25px;
		color: $color-blue;
		&--sub{
			//margin-bottom:15px;
			cursor:pointer;
			//font-size:rem-calc(17);
			//@include open-sans;
			position:relative;
			padding-right:25px;
			&::after{
				content:"-";
				width:15px;
				display:inline-block;
				margin-right:15px;
				content: ' \276F';
    			transform: rotate(270deg);
    			position:absolute;
    			right:0px;
			}
			&.closed{
				&::after{
    				transform: rotate(90deg);
				}
			}
		}
	}
	&__upsell{
		margin: 92px 0px;
    	padding: 92px 0px;
		background:#F5F5F5;
		.page__section-header{
			@include breakpoint(medium){
				font-size:2rem;
			}
		}
		> .row > .small-12 {
			padding:0 10px;
			@include breakpoint(medium){
				padding: unset;
			}
		}
	}
	&__crosssell{
		margin: 92px 0px;
    	padding: 92px 0px;
		.page__section-header{
			@include breakpoint(medium){
				font-size:2rem;
			}
		}
	}

	&__download-off-canvas{
		width: 100%;
		transform: translateX(100%);
		padding: 20px 30px;
		z-index: 9999 !important;
		background-color: white;
		box-shadow: none;

		.close-button{
			outline: none;
			font-size: 4rem;
			color:$color-business-blue;right: 2rem;
			top: 2rem;
			span{
				outline: none;
				font-size: 4rem;
				color:$color-business-blue;
			}
		}
		@include breakpoint(medium){
			width: 700px;
			transform: translateX(700px);
			padding: 0px 90px 50px 90px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
		}

		&__title{
			margin-top: 40px;
  			margin-bottom: 20px;
			@include breakpoint(medium){
				margin-top: 140px;

			}
			h2{
				font-size:25px;
				font-family:'Poppins', 'sans-serif';
				font-weight:800;
				letter-spacing: normal;
				@include breakpoint(medium){
					font-size:39px;

				}
			}
		}

		&__points{
			display:flex;
			margin-top:60px;
			align-items: center;
			&__tekst{
				p{
					font-size:16px;
					color: #1E225C;
					font-family:'Poppins', 'sans-serif';
					margin-bottom:5px;

				}

				img{
					filter: invert(10%) sepia(84%) saturate(2201%) hue-rotate(222deg) brightness(99%) contrast(94%);
					margin-right: 10px;
					height: 19px;
				}
			}
			&__img{
				img{
					width: 120px;
					object-fit: contain;
					margin-left: 20px;
				}
			}
		}

		&__divider{
			border: 1px solid #CCCCCC;
			margin-top: 20px;
		}

		&__stap{
			margin-top: 20px;
			p{
				color: #1E225C;
				font-size:15px;
				font-family:'Lexend', 'sans-serif';
				margin-bottom: 3px;
			}
			&__bol{
				display:flex;
				gap: 15px;
				span{
					width: 60px;
					border: 1px solid #009DFF;
					display: block;
					height: 10px;
					border-radius: 100px;
					cursor: pointer;
					&.selected{
						background-color:#009DFF;
					}
				}
			}
		}
		&__formulier{
			margin-top: 20px;
			.form-col{
				margin-bottom:15px;
			}
			&__form{
				label{
					font-size:14px;
					font-family:'Lexend', 'sans-serif';
					color: #1C2663;
					font-weight: 400;
					margin-bottom: -3px;
				}
				input{
					border: 1px solid #1C2663 !important; 
					border-radius: 4px !important;
					padding:25px 10px;
					margin-bottom:0px;
					color:$color-business-blue;
				}
				input[type="radio"]{
					transform: scale(1.2)!important;
					padding: 8px;
				}
				input[type="checkbox"]{
					display: inline!important;
					padding: 8px;
				}
				select{
					border: 1px solid #1C2663 !important;
					border-radius: 4px !important;
					padding-top: 12.5px;
					padding-bottom: 12.5px;
				}
				textarea{
					border: 1px solid #1C2663 !important;
					border-radius: 4px !important;
					padding:10px;
					color:$color-business-blue;
				}
			}
			&__verder-knop{
				padding: 13px 70px !important;
				cursor: pointer;
				position: relative;
				span{
					margin-left: -30px !important;
				}
				img{
					width: 24px;
					filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(28deg) brightness(109%) contrast(101%);
					position: absolute !important;
  					right: 10px;
				}
			}
		}
		&__account{
			margin-top: 20px;
			&__title{
				color: #1C2663;
				font-size:20px;
				font-weight: 900;
				font-family:'Lexend', 'sans-serif';
			}
			&__text{
				letter-spacing: -0.32px;
				color: #1C2663;	
				font-size:16px;
				font-family:'Poppins', 'sans-serif';
			}
			&__span{
				font-size:12px;
				color: #1D2052;
				font-family:'Poppins', 'sans-serif';
			}
			&__submitknop{
				margin-top: 50px;
				@include breakpoint(medium){
					margin-top: 105px;
				}
				button{
					position: relative;
					padding: 13px 60px !important;
				}
				span{
					margin-left: -30px !important;
				}
				img{
					width: 24px;
					filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(28deg) brightness(109%) contrast(101%);
					position: absolute !important;
					right: 10px;
				}
			}
		}
	}
	&__offerte-off-canvas{
		width: 100%;
		transform: translateX(100%);
		padding: 20px 30px;
		z-index: 9999 !important;
		background-color: white;
		box-shadow: none;
		
		.close-button{
			outline: none;
			font-size: 4rem;
			color:$color-business-blue;right: 2rem;
			top: 2rem;
			span{
				outline: none;
				font-size: 4rem;
				color:$color-business-blue;
			}
		}
		@include breakpoint(medium){
			width: 650px;
			transform: translateX(650px);
			padding: 0px 90px 50px 90px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
		}

		.training__review-off-canvas__review-intro{
			h2{
				font-size:20px;
				font-family:'Poppins', 'sans-serif';
				font-weight:800;
				letter-spacing: normal;
				@include breakpoint(medium){
					font-size:30px;

				}
			}
		}
		&__werkdagen{
			font-size:14px;
			font-family:'Poppins', 'sans-serif';
			color: #9B9DAD;
		}
		&__divider{
			border: 1px solid #CCCCCC;
		}
		&__formulier{
			margin-top: 30px;
			.form-col{
				margin-bottom:15px;
			}
			&__form{
				label{
					font-size:14px;
					font-family:'Lexend', 'sans-serif';
					color: #1C2663;
					font-weight: 400;
				}
				input{
					border: 1px solid #CBCBCB !important;
					border-radius: 8px !important;
					padding:25px 10px;
					margin-bottom:0px;
					color:$color-business-blue;
				}
				input[type="radio"]{
					transform: scale(1.2)!important;
					padding: 8px;
				}
				input[type="checkbox"]{
					display: inline!important;
					padding: 8px;
				}
				select{
					border: 1px solid #CBCBCB !important;
					border-radius: 8px !important;
					padding-top: 12.5px;
					padding-bottom: 12.5px;
				}
				textarea{
					border: 1px solid #CBCBCB !important;
					border-radius: 8px !important;
					padding:10px;
					color:$color-business-blue;
				}
			}
		}
		&__organisatie{
			margin-top: 20px;
  			padding: 12px 15px;

			&__hiddenparts{
				display:flex;
				flex-direction: column;
				gap: 10px;

				&__inputs{
					height: 42px !important;
					border: 1px solid #CBCBCB !important;
					border-radius: 8px !important;
					padding:25px 10px !important;
				}
				&__formslabel{
					display: grid;
					grid-template-rows:1fr 1fr;
					grid-template-columns: initial;
					column-gap: 20px;
					row-gap:10px;
					@include breakpoint(medium){
						grid-template-rows: initial;
						grid-template-columns: 6fr 4fr;
					}
				}
			}
			&__radiolabels{
				.checkout__form__row__inner{
					flex-direction: row;
					margin-top:0px;
					label{
						display:flex;
						align-items: center;
					}
					@include breakpoint(medium){
						flex-direction: row;
					}
				}
			}
		}
	}
	&__review-off-canvas{
		background-color:white;
		padding:20px 40px;
		padding-bottom: 200px;
		box-shadow: none;
		@include breakpoint(large){
			padding-bottom: 20px;

		}
		width:100%;
		transform: translateX(100%);
		@include breakpoint(large){

			width: 560px;
			transform: translateX(560px);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
		}
		z-index:10000!important;
		//width:400px;
		.close-button{ 
			right: 2rem;
			top: 2rem;
			font-size: 4rem;
			color:$color-business-blue;
			span{
			font-size: 4rem;
			color:$color-business-blue;
			
			}
		}
		&__review-intro{
			margin-top:40px;
			margin-bottom:20px;
			h2{
				@include breakpoint(medium){
					font-size:2rem;
				}
			}
			@include breakpoint(medium up){
				margin-top:140px;
			}
		}
		&__callout{
			margin-top:40px;
			border: 1px solid $color-blue;
			border-radius: 12px;
			padding:20px 30px;
			display:flex;
			justify-content:space-between;
			column-gap:25px;
			&__icon{
				color:rgb(245, 200, 11);
				svg path{ fill: #F5C80B;}
			}
			.external{
				margin-top:4px;
				font-weight:600;
				display:flex;
				align-items:center;
				&::after{
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-left: 15px;
				content: "";
				background-color: $color-blue;
				mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTN2MTBoLTIxdi0xOWgxMnYyaC0xMHYxNWgxN3YtOGgyem0zLTEyaC0xMC45ODhsNC4wMzUgNC02Ljk3NyA3LjA3IDIuODI4IDIuODI4IDYuOTc3LTcuMDcgNC4xMjUgNC4xNzJ2LTExeiIvPjwvc3ZnPg==) no-repeat 0 0;
				mask-size: cover;
				position: relative;
				}
			}
		}
		&__title{
			font-weight:bold;
			@include header-text;
			font-size:rem-calc(20px);
			color:$color-blue;
			&.second{
				margin:50px 0px;
				margin-bottom:0px;
			}
		}
		.training__reviews__item__header{
			padding:20px 0px;
			border-bottom:0px;
		}
		.training__reviews__item__body{
			padding:20px 0px;
		
		}
		.training__reviews__item, .training__reviews__item--overview{
			padding:50px 0px;
			padding-bottom:40px;
			border-right:none;
			border-bottom:1px solid $color-dark-gray;
			
		}
		@include breakpoint(medium up){
			padding:20px 90px;
			width:560px;
		}
	}
	&__programma{
		&__accordion{
			list-style-type:none;
			list-style: none;
			padding:0px;
			margin:0px;
			border:1px solid $color-blue;
			border-radius:5px;
			&__item{
				display:block;
				border-bottom:1px solid $color-blue;
				margin-bottom:0px;
				.toggle{
					display:block;
					padding:20px;
					text-decoration:none;
					font-weight:bold;
					&:after{
					    content: ' \276F';
						transform: rotate(90deg);
						display: inline-block;
						position: absolute;
						right: 20px;
						top: 24px;
					}
				}
				&__count{
				    display:inline-block;
					left: -50px;
					height: 35px;
					width: 35px;
					border: 3px solid;
					text-align: center;
					border-radius: 100%;
					line-height: 30px;
					color: $color-blue;
					background-color:white;
					top: 12px;
					font-weight:bold;
					margin-right:20px;
				}
				&:last-of-type{
					border-bottom:none;
				}
				&.current{
					.toggle{
						background-color:$color-blue;
						color:white;
						&:after{
							transform: rotate(270deg);
						}
					}
					.show{
						display:block;
					}
				}
				.inner{
					padding:20px;
					display:none;
					&.current{
						display:block;
					}
				}
			}
		}
		&__cta{
			margin-top:30px;
			border:1px solid $color-business-blue;
			padding:20px;
			border-radius: 12px;
			span{
				display:inline-block;
				margin-right:30px;
				font-weight:bold;
				color:$color-business-blue;
				font: normal normal 800 20px Lexend;
			}
			p{
				margin-bottom: 0;
			}
		}
	}
	&__cursusdata{
		background:#F8F8FA;
		margin:40px 0px;
		padding:50px 0px;
		@include breakpoint(large){
			padding:125px 0px;
			margin:125px 0px;
		}
		&__load-more{
			margin-top:50px;
			text-align:center;
			button{
				max-width: 200px;
				margin: auto;      
				margin-bottom:0px;
				@include breakpoint(medium){
					max-width:unset;
				}
			}
		}
	}
	&__reviews{
		margin: 40px 0px;
		&__intro{
			margin-bottom:30px;
			&__title{
				@include breakpoint(medium){
					font-size:2rem;
				}
			}
		}
		&__items{
			display:flex;
			justify-content: space-between;
			& > div.large-6{
				@include breakpoint(large){
					flex: 0 0 48%;
				}
			}
		}
		&__overview{
			margin-bottom:50px;
			&__trainer{
				position:relative;
				padding:0px;
				display:flex;
				min-height:350px;
				.cta-block__intake__image__lettersbg{
					display:block;
					height: inherit;
					width: 100%;
					z-index: -1;
					font-size: 400px;
					overflow: visible;
					font-family: 'Lexend', sans-serif;
					font-display: fallback;
					font-size: 350px;
					font-weight: 800;
					letter-spacing: -30px;
					line-height: 250px;
					position: absolute;
					left:unset;
					right:0px;
					top:unset;
					bottom:40px;
					text-align: right;
					color:#00c4c2;
					@include breakpoint(large){
						overflow: visible;
					}
				}
				& > img{
					height:320px;
					position:absolute;
					right:0px;
				}
				.cta-block__intake__image__textbox{
					width:100%;
					margin-left:0px;
					.cluster-header__background__designation{
						left:2%;
						@include breakpoint(medium){
							margin-left: 70px;
						}
					}
					.cluster-header__background__overlay__reviews{
						width:100%;
					}
					.cluster-header__background__overlay__reviews__text{
						@include breakpoint(medium){
						width:75%;
						}
					}
				}
			}
		}
		&__item{
			@include breakpoint(medium){
				padding:0px;
			}
			&__container{
				box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
				border: 1px solid $color-blue;
				border-radius: 12px;
				background:white;
				padding:0px;
			}
			&__header{
				display:flex;
				align-items:center;
				border-bottom:1px solid rgba(30,34,92,0.078);
				padding:20px;
			}
			&__body{
				padding:20px;
			}
			&__title{
				font-weight:bold;
				font-size:rem-calc(18px);
				color: $color-business-blue;
			}
			&__date{
				flex-grow:1;
				text-align:right;
				color: $color-business-blue;
				font-size:rem-calc(15px);
			}
			&__score{
				margin-bottom:20px;
				display:flex;
				align-items:center;
				&__number{
					font-size:rem-calc(15px);
					margin-right:20px;
					color: $color-business-blue;
					strong{
						margin-left:20px;
						font-size:rem-calc(16px);
					}
				}
				&__stars{
					margin-right:20px;
					color:#F5C80B;
					font-size:15px;
					svg path{ fill: #F5C80B;}
					.fa-star{
						margin-right:3px;
					}
				
				}
			}
			&__text{
				margin-bottom:20px;
				color: $color-business-blue;
				font-size:rem-calc(15px);
			}
			&__training{
				margin-bottom:10px;
				color: $color-business-blue;
				font-size:rem-calc(15px);
				font-weight:bold;
			}
			&__recommend{
				margin-bottom:20px;
				color: $color-business-blue;
				font-size:rem-calc(15px);
			}
			&__rating{
				&__element{
					display:flex;
					justify-content:space-between;
					&__title{
						margin-bottom:10px;
						color: $color-business-blue;
					}
					&__score{
						min-width:110px;
						color:#F5C80B;
						font-size:15px;
						svg path{ fill: #F5C80B;}
						.fa-star{
							margin-right:3px;
						}
					}
				}
			}
			&:last-of-type{
				//border-right:none;
			}
			&--overview{
				border-right:0px;
				padding:20px 0px;
				padding-bottom:0px;
				.training__reviews__item{
					&__header{
						border:0px;
						padding:0px;
						padding-bottom:20px;
					}
					&__body{}
					&__title{
						font-size:rem-calc(25px);
					}
					&__score{
						&__number{
							font-size:rem-calc(30px);
							font-weight:bold;
						}
						&__stars{
							color:$color-business-blue;
						}
					}
				}
			}
		}
		&__cta{
			margin-top:30px;
		}
	}
}
.js-off-canvas-overlay{
	background: rgba(0, 0, 0, 0.25);
	z-index:9998;
}
.off-canvas.is-transition-overlap.is-open {
}
.training_image_slider{
	.img-box{
		height: 200px;
		margin-left: 10px;
		margin-right: 10px;
	}
	.slider__counter{
		position: initial;
	}
	.slick-cloned:last-child {
		display:none !important
	}
	@include breakpoint(medium){
		.slider__counter{
			position: initial;
		}
		.img-box{
			height: 230px;
			margin-left: 0;
			margin-right: 0;
		}
	}
	@include breakpoint(large){
		.slider__counter{
			position: absolute;
		}
		.img-box{
			height: 400px;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
.terugleesSectie{
}
.tekst_teruglezen{
}
.img_teruglezen{
	justify-content: center;
	display: flex;

	img{
		height:180px;
		object-fit: cover;
	}
}
@include breakpoint(medium){
	.terugleesSectie{
		display:flex;
	}
	.tekst_teruglezen{
		flex-basis:50%;
	}
	.img_teruglezen{
		flex-basis:50%;
		justify-content: center;
		display: flex;

	}
}
@include breakpoint(large){
	.terugleesSectie{
		display:flex;
	}
	.tekst_teruglezen{
		flex-basis:70%;
	}
	.img_teruglezen{
		flex-basis:30%;
		justify-content: center;
		display: flex;
	}
}
.undertext_cf-button{
	.cf-button__text{
		text-align: start;
		span{
			display: flex;
			font-size: 14px;
			font-weight: 400;
			padding-top: 6px;
			&.grey{
				color:#9B9DAD;
				font-weight:300;
				letter-spacing: normal;
			}
		}
	}
	&:hover{
		.cf-button__text{
			text-align: start;
			span{
				&.grey{
					color:white;
				}
			}
		}

	}
}