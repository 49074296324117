.page {
	&__section {
	  border-bottom: 2px solid $color-line;
	  padding: 0 0 1rem;
	  margin: 0 0 2.5rem;
	  @media print{
		  border-bottom:0px;
		  padding-bottom:0px; 
	  }
  
	  &--noborder {
		border: none;
	  }
	}
  
	&__section-header {
	  font-size: rem-calc(28);
	  font-weight: 800;
	  color: $color-business-blue;
	  @include breakpoint(medium){
		  padding-bottom: 30px;
	  }
	}
  
	&__title {
	  margin-top: 1.5rem;
	  margin-bottom: 1.5rem;
	}
  
	&__intro {
	  width: 100%;
	  font-size: rem-calc(18);
	  color: $color-header;
	  text-shadow: 0 0 20px #ffffff;
	  margin-bottom: 1rem;
  
	  @include breakpoint(medium) {
		margin-bottom: 0;
	  }
	}
	tbody th,tbody td {
		white-space: unset!important;
	}
	#gerelateerd {
		@media print{
			display:none;
		} 
	}
		&.digitalisering-en-regelingen h2 {
		  font-size: 2rem;
		  margin-top: 60px;
	  }
  
  
	  &.digitalisering-en-regelingen h3 {
		  font-size: 1.6rem;
		  margin-top: 50px;
	  }
  
	  &.digitalisering-en-regelingen  h4 {
		  font-size: 1rem;
		  margin-top: 25px;
	  }
  
	  &.digitalisering-en-regelingen hr {
		  margin-top: 60px;
	  }
	  
	  
  }
  .onderzoek-leven-lang-leren-2022{
	  .page__sidebar{
		  visibility: hidden;
	  }
	  .o-image{
		  border:1px solid #ddd;
		  box-shadow:1px 1px 10px #eee;
	  }
  
	  table tbody th, table tbody td {
		  padding: 0.15rem;
		  border: 1px solid #ddd;
		  font-family:Arial,sans-serif!important;
	  }
	  table td b, table td strong, table td span{
		  font-family:Arial,sans-serif!important;
	  }
	  tbody {
		  box-shadow: 1px 1px 10px #eee;
	  }
  
	  table tr {
		  box-shadow: none;
		  border: none;
	  }
  }
  .doorway_header{
	  display:grid !important;
	  grid-template-columns: 1fr;
  
	  @include breakpoint(large) {
		  grid-template-columns: 1fr 1fr;
		  column-gap:30px;
	  }
	  padding:0px 2rem;
	  min-height: 60vh; 
	  .doorway_header__text{
		  //margin-left: 20px;
		  //margin-right: 20px;
		  @include breakpoint(large) {
			  margin-top:60px;
			  margin-left: 0px;
			  margin-right: 0px;
			  margin-bottom:20px;
		  }
		  .doorway_header__text__title{
			  h1{
				  font-weight: 800;
				  font-size:25px;
				  color: #181C4D;
				  font-family:'Lexend',sans-serif!important;
				  margin-bottom: 30px;
  
				  @include breakpoint(medium up) {
					  font-size:39px;
				  }
			  }
		  }
		  .doorway_header__text__subtext{
			  p{
				  margin-bottom: 30px;
				  span{
					  font-size:16px;
					  font-family:'Lexend',sans-serif!important;
					  font-weight:600;
					  margin-bottom: 30px;
					  color: $color-business-blue;
					  margin-right: 40px;
  
					  img{
						  margin-right:10px;
						  width:16px;
						  height:16px;
						  filter: invert(10%) sepia(36%) saturate(4948%) hue-rotate(228deg) brightness(95%) contrast(92%);
					  }
				  }
			  }
		  }
		  .doorway_header__text__omschijving{
			  margin-bottom: 30px;
			  p{
				  @include breakpoint(medium up) {
					  margin-right:50px;
				  }
				  margin-right:0;
			  }
		  }
		  .doorway_header__text__checkmarks{
			  margin-bottom: 50px;
			  ul{
				  margin-left:0;
				  list-style-type: none;
				  li{
					  display: flex;
					  align-items: center;
					  gap:14px;
					  @include breakpoint(large){
						  gap:20px;
  
					  }
					  margin-top: 20px;
					  svg{
						  //margin-right:20px;
						  min-width:24px;
					  }
				  }
			  }
		  }
		  .cf-button__text{
			  font-size:1rem;
		  }
	  }
  }
  .doorway_knop{
	  padding: 20px 30px!important;
  
	  &:hover{
		  padding: 20px 30px !important;
		  padding-right:20px!important;
		  .cf-button__icon{
			  margin-right:0px;
			  padding-right:0px;
		  }
	  }
	  &.noicon{
		  &:hover{
			  padding-right:30px!important;
		  }
	  }
  }
  .doorway_header__background{
	  position: relative;
	  height:unset;
	  @include breakpoint(large){
		  height:100%;
	  }
	  .doorway_header__background__overlay{
		  width:100%;
		  position: relative;
		  bottom:0px;
		  @include breakpoint(large){
			  position: absolute;
		  }
		  .doorway_header__background__overlay__naam{
			  left:0;
			  top: 200px;
			  bottom:unset;
			  @include breakpoint(medium){
				  top: 200px;
				  bottom:unset;
			  }
			  @include breakpoint(large){
				  bottom:260px;
				  top:unset;
			  }
		  }
		  .doorway_header__background__overlay__aankomendetraining{
			  background-color: white;
			  box-shadow: 0px 15px 40px rgba(69,76,83,0.098);
			  border-radius: 8px;
			  z-index: 10;
			  width: 100%;
			  margin-top: 250px;
			  margin-bottom: 35px;
			  @include breakpoint(medium){
				  margin-top: 270px;
			  }
			  @include breakpoint(large){
				  margin-top:0px;
			  }
  
			  .doorway_header__background__overlay__aankomendetraining__tekst{
				  img{
					  height:18px;
					  width:18px;
					  margin-right:10px;
				  }
				  color:$color-business-blue;
				  font-weight: 600;
				  font-size:18px;
				  margin-left: 20px;
				  margin-top: 20px;
			  }
			  .doorway_header__background__overlay__aankomendetraining__trainingbox{
				  display: grid;
				  grid-template-columns: 1fr;
				  @include breakpoint(medium){
				  grid-template-columns: 1fr 1fr;
				  }	
				  padding:20px;
				  padding-top:0px;
				  column-gap: 12px;
				  row-gap:12px;
  
				  p, a{
					  padding: 15px 10px;
					  background-color: red;
					  //margin-left: 20px;
					  //margin-right: 20px;
					  display: block;
					  margin-bottom:0px;
					  background-color:#F8F8FA;
					  border-radius: 8px;
					  text-decoration: none;
					  transition: all 400ms;
					  &:hover{
						  background-color:$color-business-blue;
						  color:white;
					  }
				  }
			  }
		  }
		  &__backgroundimage{
			  bottom:0px;
			  top:0px;
			  @include breakpoint(medium){
  
				  top:unset;
				  height:100%;
  
			  }
			  @include breakpoint(large){
				  top:unset;
				  width:68%;
				  img{ max-height:550px;}
			  }
		  }
	  }
  }
  .doorway_header__background__overlay__gotthisimage{
	  width: 100px;
	  margin-left: 50px;
	  padding-top: 50px;
  }
  .doorway_header_img{
	  max-height:550px!important;
	  @include breakpoint(large){
		  bottom:0px!important;
  
	  }
  }
  
  
  .cursistenreview{
	  padding-top:50px;
	  background-color: #F8F8FA;
	  overflow: hidden;
	  @include breakpoint(medium up) {
		  padding-top:120px;
		  padding-bottom:120px;
	  }
  
	  .uitlijncuristreview{
		  max-width: 86.5rem;
		  margin:auto;
		  display:grid;
		  @include breakpoint(medium up) {
			  grid-template-columns: 1fr 1fr;
			  grid-template-rows: 1fr;
		  }
		  grid-template-columns: 1fr;
		  grid-template-rows: 1fr 1fr;
		  padding-left: 20px;
	  }
	  &__reviewbericht{
		  padding-left: 0rem;
		  margin-right: 40px;
		  margin-bottom:30px;
		  @include breakpoint(large) {
			  margin-right: 80px;
			  margin-bottom:0px;
		  }
		  &__titel{
			  color: $color-business-blue;
			  font-size:20px;
			  font-weight: 800;
			  font-family:"Lexend",sans-serif;
  
			  @include breakpoint(large) {
				  font-size: rem-calc(21px);
				  font-size:25px;
			  }
		  }
		  &__message{
			  color: $color-business-blue;
			  margin-bottom: 0;
			  font-size:16px;
		  }
		  &__leesmeer{
			  text-decoration: underline;
			  font-weight:500;
			  display: inline-block;
			  margin-top: 10px;
			  margin-bottom: 20px;
			  @include breakpoint(large) {
				  margin-top: 0px;
			  }
			  p{
				  font-family:"Lexend",sans-serif;
				  color: $color-business-blue;
				  font-size:16px;
			  }
		  }
		  &__review-company{
			  display: grid;
			  grid-template-columns: 1fr 1fr 1fr;
  
			  &__imageblock{
				  display: grid;
				  justify-items: center;
				  @include breakpoint(medium up) {
					  margin-right: 30px;
				  }
				  &__star{
					  svg{
						  color:#F5C80B;
						  font-size: 10px;
						  @include breakpoint(large) {
							  font-size: 16px;
						  }
					  }
				  }
				  &__image{
					  padding:0;
					  mix-blend-mode: luminosity;
				  }
				  span{
					  color:$color-business-blue;
					  font-weight: 600;
					  font-family:"Lexend",sans-serif;
					  margin: 0;
					  font-size: 16px;
					  margin-bottom: 20px;
					  @include breakpoint(large) {
						  margin-top:15px;
						  font-size:20px;
						  margin-bottom:0;
					  }
					  
				  }
			  }
		  }
	  }
	  &__photocarousel{
		  max-height: 100vh;
		  max-width: 100vw;
		  //height: 30vh !important;
		  @include breakpoint(medium up) {
			  position: absolute !important;
			  right: 0;
			  //height: 35vh !important;
			  max-width: 50vw;
		  }
		  .slick-list{
  
		  }
		  .prev-arrow{
			  right: 60px !important;
  
			  @include breakpoint(medium up){
				  right: 75px !important;
			  }
		  }
		  .slide-arrow{
			  margin-right: 40px;
			  height: 45px !important;
			  padding: 6px;
			  bottom: -60px;
			  width: 50px !important;
  
			  @include breakpoint(medium up){
				  height: 50px !important;
				  padding: 8px;
				  width: 60px !important;
			  }
		  }
		  .slider__counter{
			  bottom: -50px;
			  top:auto;		
			  @include breakpoint(medium up){
				  margin-right: 40px;
			  }
		  }
		  &__imgbox{
			  height:100%;
			  margin-right: 30px;
			  @include breakpoint(medium up) {
				  margin-left: 20px;
				  margin-right:0;
			  }
			  img{
				  height: 100%;
				  object-fit: cover;
				  border-radius: 12px;
			  }
		  }
		  .slick-list{
			  height:100%;
			  background: #f8f8fa;
			  .slick-track{
				  height:100%;
			  }
		  }
		  //@include breakpoint(medium up) {
		  //	height:30vh;
		  //}
		  //.slick-list{
		  //	height: 100%;
		  //	width: 100%;
		  ///
		  ////	.slick-track{
		  //		height: 100%;
		  //		.slick-slide {
		  //			margin-left:20px;
		  //			width:30vw !important;
		  //		}
		  //	}
		  //}
	  }
  }
  .clustersection{
	  margin-bottom: 50px !important;
	  @include breakpoint(large){
		  margin-bottom: 75px !important;
  
	  }
  }
  .home-content-divider{
	  &--grey{
		  border-bottom: 1px solid #F8F8FA !important;
  
		  &::after{
			  border-top: 30px solid #F8F8FA !important;
		  }
	  }
	  
  }
  .doorway_trainers{
	  display:grid;
	  grid-template-columns: 1fr;
	  margin-top:50px;
	  margin-bottom: 50px;
	  padding-left: 2rem;
	  padding-right: 2rem;
  
	  @include breakpoint(large){
		  grid-template-columns: 1fr 1fr;
		  margin-top:100px;
		  margin-bottom: 50px;
	  }
	  .doorway_trainers_text{
		  margin-bottom: 50px;
		  @include breakpoint(large){
			  border-right:1px solid #E0E0E0;
			  padding-right:60px;
			  margin-bottom: 0px;
		  }
		  .doorway_trainers_text_title{
			  font-size:20px;
			  font-weight:800;
			  color: $color-business-blue;
			  font-family:'Lexend',sans-serif;
			  margin-bottom: 30px!important;
			  @include breakpoint(large){
				  font-size:25px;
			  }
		  }
		  .doorway_trainers_text_textblock{
			  font-size:16px;
			  color: $color-business-blue;
			  margin-bottom: 30px;
		  }
	  }
	  .doorway_trainers_trainerpart{
		  @include breakpoint(large){
		  padding-left:60px;
		  }
		  .doorway_trainers_trainerpart_title{
			  font-size:20px;
			  font-weight:800;
			  color: $color-business-blue;
			  font-family:'Lexend',sans-serif;
			  @include breakpoint(large){
				  font-size:25px;
			  }
		  }
	  }
  }
  #trainersbox{
	  p{
		  font-weight:800;
		  font-size:20px;
  
		  span{
			  font-weight:500;
			  font-size:16px;
		  }
	  }
  }
  .doorway_content{
	  background-color:#f8f8fa;
	  padding-left:2rem;
	  padding-right:2rem;
  
	  .doorway_title{
		  font-size:20px!important;
		  text-align: center;
		  background: transparent;
		  color: $color-business-blue !important;
  
		  @include breakpoint(large){
			  margin-bottom: 50px !important;
			  font-size:39px !important;
			  margin-right: 250px;
			  margin-left: 250px;
		  }
	  }
	  .doorway_container{
		  padding-left:0 !important;
		  padding-right:0 !important;
	  
		  .doorway_container_content{
			  padding-left:0 !important;
		  }
	  }
	  .doorway_content_item_baby{
		  @include breakpoint(large){
			  padding: 50px;
		  }
	  }
  }
  
  .doorway-video-block{
	  background-color:white;
  }
  .doorway_verwachtingen{
	  background-color:#F8F8FA;
	  padding:0px 2rem;
  
	  .doorway_verwachtingen__title{
		  text-align: center;
		  font-size:20px;
		  font-weight:800;
		  color:$color-business-blue;
		  width: 100%;
		  margin-top: 40px;
		  margin-bottom:30px!important;
		  @include breakpoint(large){
			  font-size:39px;
			  margin-top: 80px;
			  margin-bottom:75px!important;
		  }
	  }
	  .doorway_verwachtingen_box{
		  width:100%;
		   ul{
			  display:grid;
			  grid-template-columns:1fr;
			  margin:0px;
			  @include breakpoint(large){
				  grid-template-columns: 1fr 1fr;
				  //justify-items: center;
				  max-width:80%;
				  margin:0 auto;
			  }
			  li{
				  display:flex;
				  align-items: center;
				  list-style-type: none;
				  margin-bottom: 20px;
				  column-gap:16px;
				  @include breakpoint(large){
					  margin-bottom:45px;
				  }
  
				  svg{
					  
					  min-width:24px;
				  }
				  p{
					  font-size:16px;
					  color:$color-business-blue;
  
				  }
				  &:last-of-type{
					  margin-bottom:0px;
					  @include breakpoint(large){
						  margin-bottom:45px;
					  }
				  }
			  }
		   }
	  }
	  .doorway_verwachtingen_button{
		  width:100%;
		  text-align: center;
		  margin-top: 50px;
		  margin-bottom: 50px;
		  .cf-button__text{
			  font-size:1rem;
		  }
		  @include breakpoint(large){
			  margin-left:0px;
			  margin-right:0px;
			  margin-bottom: 100px;
		  }
	  }
  }
  .Alles_over{
	  & > .row{
		  padding-left:2rem;
		  padding-right:2rem;
	  }
	  .Alles_over_title{
		  text-align: center;
		  font-size:20px;
		  font-weight:800;
		  color:$color-business-blue;
		  width: 100%;
		  margin-top: 100px;
		  margin-bottom:50px!important;
		  @include breakpoint(large){
			  font-size:39px; 
		  }
	  }
	  .Alles_over_trainingen{
		  display:grid;
		  grid-template-columns: 1fr;
		  justify-content: center;
		  row-gap:50px;
		  margin-bottom:90px;
		  @include breakpoint(large){
			  grid-template-columns: 1fr 1fr 1fr;
			  column-gap:100px;
		  }
		  a{
			  //margin-right: 50px;
			  //margin-left: 50px;
			  //margin-top:50px;
  
			  @include breakpoint(large){
				  //margin-right: 100px;
				  margin-left: 0px;
			  }
		  }
	  }
  }
  .doorway_reviews{
	  background-color:#F8F8FA;
	  padding-top: 70px;
	  padding-bottom: 70px;
	  @include breakpoint(large){
		  padding-top: 100px;
		  padding-bottom: 100px;
  
	  }
	  .doorway_reviews_boxs{
		  display:grid;
		  grid-template-columns: 1fr;
		  padding:0 2rem;
	  
		  @include breakpoint(large){
			  grid-template-columns: 1fr 1fr;
		  }
  
		  .doorway_reviews_box_tekst{
			  //margin-left: 20px;
			  //margin-right:20px;
			  @include breakpoint(large){
				  margin-left: 0;
				  margin-right:0;
			  }
			  .doorway_reviews_box_tekst_title{
				  font-size:20px;
				  font-weight:800;
				  font-family: 'lexend', sans-serif;
				  margin-top: 0px;
				  margin-right: 0px;
				  margin-bottom:25px;
				  @include breakpoint(large){
					  margin-right: 150px;
					  margin-top: 50px;
					  font-size:30px;
				  }
			  }
			  .doorway_reviews_box_tekst_text{
				  @include breakpoint(large){
					  margin-right:50px;
				  }
				  margin-bottom:25px;
			  }
		  }
  
		  .doorway_reviews_boxs_reviewcard{
			  .testimonial-block__card{
				  //margin-left: 20px;
				  //margin-right: 20px;
				  margin-bottom:25px;
  
				  @include breakpoint(large){
					  margin-left: 150px;
					  margin-right: 150px;
					  margin-bottom:0px;
				  }
			  }
		  }
	  }
  }
  .custom-blog-header{
		  display: grid;
		  grid-template-columns: 1fr 1fr;
		  .likes_images{
			ul{
				margin-left: 0;
				list-style-type: none;
				display:flex;
				margin-bottom: 0;
				float:right;
				li{
				  display: inline-block;
				  margin-left: 3px;
				  img{
					  width: 50px;
					  height: auto;
					  border-radius: 50%;
				  }
				}
	  
				.custom_rating{
				  display: grid;
				  grid-template-columns: 1fr 1fr;
				  border: 1px solid;
				  border-radius: 25px;
				  padding: 4px 15px;
				  align-content: center;
				  height: auto;
				  align-self: center;
				  margin-right:15px;
					  p{
						  margin:auto;
						  font-size: 14px;
						  font-weight: bold;
					  }
	  
					  img{
						  width:20px;
					  }
				  }
			  }
		  }
	  }
		.home-leader-new{
		  height:auto;
		}
		.training{
		  &__banner{
			  &__image{
				  &_blog{
					  height:500px;
					  .videotext-block__video__container {
						  height:100%;
					  }
					  &.top {  
						  background-position: top !important;}
					  &.center { 
						  background-position: center !important;}
					  &s.bottom { 
						  background-position: bottom !important;}
				  }
			  }
		  }
		}