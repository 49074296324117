.training-links {

  &__title {
    @include raleway;
    font-size: rem-calc(19px);
    font-weight: 700;
    margin: 0 0 15px;
  }

  &__list {
    list-style: none;
    font-size: rem-calc(19px);
    padding: 0;
    margin: 0;
  }

  &__item {
    padding: 0;
    margin: 0;
  }

  &__link {
    color:$color-business-blue-lighter;
    text-decoration: none;

    i {
      position: relative;
      top: 2px;
      font-size: rem-calc(22px);
    }
    & .download{
    	background-image:url('images/downloadicon.png');
    	background-size:contain;
    	background-position:center center;
    }

    span {
      text-decoration: underline;
    }

    &:hover, &:focus, &:active {
      color: $color-light-primary;
      text-decoration: none;
    }
  }
}