.zoeken {

  &__title {
    @include raleway;
    color:$color-business-blue;
    margin-top:30px;
  }

  &__section {
    margin: 1rem 0 0.5rem;
    @include raleway;
    font-weight: 600;
    color: $color-business-blue;
    font-size: 1.15rem;

    @include breakpoint(medium) {
      font-size: 1.5rem;
      margin: 2rem 0 1rem;
    }
  }
  &__trainingen{
  	#training-zoekresultaten > .row{
  		row-gap:30px;
  	}
  }
  &__overview{
		background-color:#fafafb;
		padding-top:25px;
		position:relative;
      	@include breakpoint(medium) {
      		border: 1px solid $color-medium-light-gray;
			border-radius: 8px 0px 0px 8px;
			padding:30px;
      	}
		
		&__title{
			font-weight:750;
			margin-bottom:25px;
			text-align:center;
			color: $color-black;
      		@include breakpoint(medium) {
      			text-align:left;
      		}
		}
		
		&__filter{
			display:flex;
			margin-bottom:25px;
			&__option{
				background-color:white;
				border:1px solid $color-lightest-gray;
				text-align:center; 
				width:33.3%;
				padding:5px 0px;
				font-weight:600;
				font-size:0.9rem;
				cursor:pointer;
				@include transition(background 400ms, color 400ms);
				&.selected, &:active, &:hover, &:focus {
					background-color:$color-business-blue-light;
					color:white;
					border:1px solid $color-darker-gray;
				}
				
			}
			&__option:not(:first-of-type):not(:last-of-type){
				border-left:0px;
			}
			&__option:first-of-type{
				border-right:1px solid $color-lightest-gray;
				border-radius: 10px 0px 0px 10px;
			}
			&__option:last-of-type{
				border-left:0px;
				border-radius: 0px 10px 10px 0px;
			}
			
			
			&__grid {
			
			}
			&__list {
				display:none;
			}
			&__barebonelist {
				display:none;
			}
			
		}
		
		&__icons {
			padding:5px 10px;
			font-size:0.9rem;
			display:inline-block;
			@include transition(background 400ms, color 400ms);
			margin:0px;
			&.selected, &:active, &:hover, &:focus {
				background-color:$color-business-blue-light;
				color:white;
				cursor:pointer;
			}
		
		}
		&__icons:first-of-type{
			border-right:1px solid $color-lightest-gray;
			border-radius: 10px 0px 0px 10px;
		}
		&__icons:last-of-type{
			border-left:0px;
			border-radius: 0px 10px 10px 0px;
		}
		&__icons:not(:first-of-type):not(:last-of-type){
			border-right:1px solid $color-lightest-gray;
			border-radius: 0px;
		}
		
	}
}