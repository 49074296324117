@font-face {
  font-family: 'Gelasio';
  src: url('/fonts/Gelasio/Gelasio-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}
@font-face {
  font-family: 'Lexend';
  src: url('/fonts/Lexend/Lexend-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}
@font-face {
  font-family: 'Caveat';
  src: url('/fonts/Caveat/Caveat-VariableFont_wght.ttf') format("truetype-variations");
  font-weight: 1 999;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Black.ttf') format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-BlackItalic.ttf') format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-ExtraBold.ttf') format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-MediumItalic.ttf') format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Italic.ttf') format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-ExtraLight.ttf') format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Thin.ttf') format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-ThinItalic.ttf') format("truetype");
  font-weight: 100;
  font-style: italic;
}

@mixin poppins {
  font-family: 'Poppins', sans-serif;
  font-display: fallback;
}

@mixin raleway {
  font-family: 'Poppins', sans-serif;
  font-display: fallback;
}

@mixin header-text {
  font-family: 'Lexend', sans-serif;
  font-display: fallback;
}

@mixin body-text {
  font-family: 'Poppins', sans-serif;
  font-display: fallback;
}

@mixin shadows-into-light($sledge-hammer: "") {
  font-family: 'Shadows Into Light', cursive;
  font-display: fallback;
}

@mixin open-sans($sledge-hammer: "") {
  font-family: 'Poppins', sans-serif;
  font-display: fallback;
}

@mixin condensed($sledge-hammer: "") {
  font-family: 'Open Sans Condensed', sans-serif;
  font-display: fallback;
}

@mixin custom-button-base {
  @include disable-mouse-outline;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
}



@page {
  size: A4;
  margin-top: 15mm;
  margin-left: 15mm;
  margin-right: 15mm;
  margin-bottom: 15mm;
}

@page:first{
	margin-top:0px;
}

* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
    @include poppins;
    //color:$color-business-blue;
}
*{ 
  color: $color-business-blue;
}
span, a, strong{
  color:inherit;
}

html {
  font-size: 85%;
  @include breakpoint(medium) {
    font-size: 100%;
  }
  
}

h1, h2, h3, h4, h5, h6 {
  @include header-text;
  color: $color-header;
  font-weight: 800;
}

h1 {
  font-size: 1.5rem;
  letter-spacing: -1px;
  @include breakpoint(medium) {
    font-size: 2rem;
  }
}
h2 {
	line-height:1.2;
	letter-spacing: -1px;
    margin-bottom: 1rem!important;
}
h2, h3, h4, h5, h6 {
  font-size: 1.35rem;

  @include breakpoint(medium) {
    font-size: 1.5rem;
  }
}

h4 {
	font-size: 1.2rem;
    margin-bottom: 2px;
	@include breakpoint(medium) {
		font-size: 1.25rem;
	  }
}

h5 {
	font-size: 1.1rem;
	@include breakpoint(medium) {
		font-size: 1.2rem;
	  }
}

a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

p, ul, li { 
	color: $color-business-blue;
}

.divider{
	margin:40px 0px;
}
button {
  @include body-text;
  cursor:pointer;
}

@media print {
   html, body {
    width: 210mm;
    height: 297mm;
   }
   a[href]:after{
      display:none;
      /*visibility: hidden;*/
   }
}

.button {
  text-decoration: none;
}

#block-ui {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @include transition(opacity 300ms);

  &.visible {
    display: block;
    opacity: 0.3;
  }
}

.handwritten-title {
  line-height: 1;
  em {
    @include shadows-into-light;
    font-weight: bold;
    font-style: normal;
    font-size: 110%;
  }

  strong {
    font-size: 90%;
    text-transform: uppercase;
    font-weight: 800;
  }
}

[class^="icon-"], [class*=" icon-"] {
  text-decoration: none;
}

.opensans {
  @include open-sans;
}

.column {

  @include breakpoint(medium only) {
  	padding-left:1.3rem;
  	padding-right:1.3rem;
  }
  
}

.is-invalid-input:not(:focus){
  background-color:unset;
}

.algn-left{
  text-align: left;
}
.flx{
  display: flex;
}
.flx-jst-cntr{
  justify-content: center;
}
.flx-algn-cntr{
  align-items: center;
}
.flx-direction-clmn{
  flex-direction: column;
}
.h3-desktop{
  @include breakpoint(medium){
    @include header-text();
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.24px;
  }
}
.h2-mobile{
  @include breakpoint(small){
    @include header-text();
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: -0.2px;
  }
}
.h2-desktop{
  @include breakpoint(medium){
    font-family: Lexend;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 114.286% */
    letter-spacing: -0.35px;
  }
}
/* Headline 3 | body 1 Mobile */
.h3-body1-mobile{
  @include breakpoint(small only){
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }
}
.h4-mobile{
  @include breakpoint(small only){
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  }
}
.h4-desktop{
  @include breakpoint(medium){
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  }
}
.h4-body2-mobile{
  @include breakpoint(small only){
    /* Headline 4 | body 2 Mobile */
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
  }
}
.h5-desktop{
  @include breakpoint(medium){
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }
}
.h5-mobile{
  @include breakpoint(small only){
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }
}
.body1-mobile{
  @include breakpoint(small){
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
  }
}
.body2-mobile{
  @include breakpoint(small){
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }
}
.body2{
  @include breakpoint(small){
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.body1-desktop{
  @include breakpoint(medium){
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
  }

}
.body2-desktop{
  @include breakpoint(medium){
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

}
.caption-mobile{
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.checklist-header{
  color: #262B56;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  margin-bottom:12px;
  @include breakpoint(medium){
    font-family: Caveat;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 92.308% */
    letter-spacing: -0.26px;
    margin-bottom:24px;
  }
}
.checklist-items{
  display: flex;
  flex-direction: column;
  gap:12px;
}
.checklist-item{
  display: flex;
  align-items: center;
  gap: 12px;
  .input{
    line-height: 1;
    input{
      margin-bottom: 0px;
      transform: scale(1.2);
      pointer-events: none;
    }
  }
  .text{
    a { text-decoration: none; }
    .lnk{
      position: relative;
      z-index:1;
      &::after{
        content: '';
        position: absolute;
        bottom: -8px; /* Positioning the stroke near the bottom */
        left: 0;
        width: 100%; /* Stretches across the full width of the text */
        height: 16px; /* Height of the stroke (adjust as needed) */
        background-image: url('/images/orange-stroke.svg'); /* Use the stroke image */
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1; /* Ensures the stroke stays behind the text */
      }
    }
  }
}
.hyperlink{
  color:#106BC6;
  font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
text-decoration-line: underline;
display: inline-flex;
gap:6px;
align-items: center;
  &:hover{
    text-decoration-line: none;
    color:#106BC6;
  }
}

.dwnld-naked-button{
  display: flex;
  align-items: center;
  gap: 6px;
  .text{
    color: var(--CF-highlight-color, #106BC6);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
}

.gry-divider{
  background: #DFE4EA;
  &.horizontal{
    width: 100%;
    height: 1px;
  }
  &.vertical{
    height: 100%;
    width: 1px;
  }
}
.tbs-select{
  select{
    border-radius: 10px;
    border: 1px solid var(--Stroke, #DFE4EA);
    background: var(--CF-WHITE, #FFF);
    padding: 12px 16px;
  }
}
.tbs-bar{
  display: flex;
  gap:10px;
  margin-bottom:25px;
  margin-right:-22px;
  @include breakpoint(medium){
    gap:14px;
    margin-bottom:46px;
  }
  @include breakpoint(large){
    margin-right: 0px;
  }
  align-items: center;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .tab-item{
    display: flex;
    padding: 10px 20px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius:10px;
    background:#F8F8FA;
    transition: all 400ms;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    .text{
      color:#637381;
    }
    &.selected, &:hover{
      cursor: pointer;
      background:#106BC6;
      .text{
        color:white;
      }
      .icon{
        img{
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(101%) contrast(104%);
        }
      }
    }
  }
}
div.training-sections{
  margin-bottom:46px;
  @include breakpoint(medium){
    margin-bottom:66px;
  }
  &:last-of-type{
    margin-bottom: 0px;
  }
  .section-title{
    margin-bottom:12px;
    @include breakpoint(large){
      margin-bottom:16px;
    }
  }
}
.notice{
  display: inline-flex;
  padding: 3px 8px;
  padding: 6px 10px;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  &.error{

  }
  &.alert{
    color: #9a6409;
    background-color: #FFFBEB;
    
  }
  &.success{
    color:#22AD5C;
    background-color: #DAF8E6;
  }
}
.breadcrumbs-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:-15px;
  .title{

  }
  .crumbs{
    color: var(--Text-color, #64748B);
    text-align: right;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    .item{
      transition: all 400ms;
      a { text-decoration: none; transition: all 400ms;}
    }
    .current, a:hover, .item:hover{
      color:#106BC6;
    }
  }
  .crumbs-mobile{

  }
}
.tgl-item{
  display: flex;
  gap:6px;
  @include breakpoint(large){
    align-items: center;
  }
  .icon{
    width: 24px;
    height: 24px;
    border-radius:6px;
    background: rgba(48, 86, 211, 0.06);
    position: relative;
    @include breakpoint(large){
      width: 42px;
      height: 42px;
      border-radius: 10px;
    }
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height:10px;
      @include breakpoint(large){
        height:19px;
      }
      transition: all 400ms;
    }
  }
  .content{
    .title{
      text-align: left;
    }
    .info{
      margin-top:12px;
      display: flex;
      gap:24px;
    }
  }
  &.open{
    .icon{
      img{
        transform-origin: center;
        transform: rotate(90deg) translateX(-4px) translateY(2px);
        @include breakpoint(large){
          transform: rotate(90deg) translateX(-8px) translateY(5px);
        }
      }
    }
  }
}
.main-block{ 
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap:24px;
  div { text-align: left!important;}
  @include breakpoint(medium){
    flex-direction: row;
    align-items: stretch;
    height: 360px;
  }
  @include breakpoint(large){
    height: 270px;
  }
  .left-part{
    display: flex;
    flex-grow: 1;
    width:100%;
    @include breakpoint(medium){
      height: 360px;
      width:unset;
    }
    @include breakpoint(large){
      height: 270px;
    }
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .type{
      @include breakpoint(large){
      margin-bottom:6px;
      }
    }
    .training-title{
      margin-bottom:24px;
      @include breakpoint(large){
      margin-bottom:16px;
      }
    }
    .info-grid{
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      @include breakpoint(large){
        align-items: center;
        row-gap: 11px;
        column-gap: 30px;
        flex-direction: row;
        flex-wrap: wrap;
        max-width:550px;
      }
      align-self: stretch;
      margin-bottom:6px;
      .item{
        display: flex;
        align-items: center;
        gap:8px;
        @include breakpoint(large){
          gap:16px;
          flex: 0 0 calc(50% - 15px);
        }
      }
    }
    .info-bar{
      display: flex;
      flex-direction: column;
      row-gap:3px;
      @include breakpoint(large){
        align-items: center;
        gap: 36px;
        flex-direction: row;
      }
      align-self: stretch;
      margin-bottom:6px;
      .item{
        display: flex;
        align-items: center;
        gap:8px;
      }
    }
    .change-date{
      margin-bottom:24px;
    }
    .notice {
      margin-bottom:12px;
    }
    .action-btns{
      display: flex;
      flex-direction: column;
      gap:6px;
      @include breakpoint(large){
        flex-direction: row;
        gap:12px;
      }
    }
    .bottom-part, .top-part{
      width:100%;
    }
  }
  .right-part{
    width:100%;
    @include breakpoint(medium){
      flex-grow: 1;
      width: initial;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Centers content vertically */
      align-items: center; /* Centers content horizontally (optional) */
      //flex: 1;
    }

  }
  &.detail-page{
    height:unset;
    .left-part{
      height:unset;
      .gry-divider.horizontal{
        margin-top:24px;
      }
      .checklist{
        margin-top:24px;
        margin-bottom:36px;
      }
    }
    .right-part{
      .trainer-photo{
        position: relative;
        .designation{
          position:absolute;
          padding: 8px 16px;
          bottom:15px;
          background-color: white;
          box-shadow: 0px 15px 30px 0px rgba(42, 62, 82, 0.10);
          & > span { display: block; }
        }
        img{ 
          max-width:350px; 
          margin-bottom:-22px;
          @include breakpoint(large){
            margin-bottom:-36px;
          }
        }
      }
    }
  }
}
.width-45{ width:45%; }
.width-20{ width:20%; }
.width-15{ width:15%; }
.width-10{ width:10%; }
table.tbl-stacked-clmn{
  thead{
    background-color: white!important;
    tr{
      box-shadow: none;
    }
    th{
      box-shadow: none;
      color:#A3A3A3;
      padding-left:0px;
      padding-right:0px;
      @include breakpoint(large){
        padding-left:initial;
        padding-right:initial;
      }
    }
    border: none;
    border-bottom: 1px solid #EBEBEB;
  }
  tbody{
    border:none;
    tr{
      box-shadow: none;
      border-bottom: 1px solid #EBEBEB!important;
      background-color: white;
      &.selected {
        border-bottom: none!important;
      }
    }
    td{
      text-align: left;
      padding-left:0px;
      padding-right:0px;
      padding: 16px 0px;
      @include breakpoint(large){
        padding-left:initial;
        padding-right:initial;
      }
    }
    .main-row { cursor: pointer; }
    .details-row{
      td{
        padding-top:0px;
        padding-left:30px;

        @include breakpoint(medium){
          .main-block, .left-part{ height: unset;}
        }
        @include breakpoint(large){
          .main-block, .left-part{ height: 250px;}
          padding-top:10px;
          padding-left:48px;
        }
      }
    }
  }
}
.clr-gray{
  color:#8899A8;
}
.clr-blue{
  color:#262957!important;
}
.back-button{
  position: absolute;
  margin-top:8px;
  right: 0;
  .text{text-wrap: nowrap;}
}
.nkd-btn{
  display: flex;
  padding: 0;
  gap:6px;
  text-decoration: none;
  transition: all 400ms;
  .text { text-decoration: none; }
  .icon { transition: all 400ms; }
  &.hasicon{
    &.left{
      padding-left:10px;
    }
    &.right{
      padding-right:10px;
    }
  }
  &.orange{
    color:#FF9900;
  }
  &:hover{
    &.hasicon{
      &.left{
        padding-left:0px;
        .icon{
          padding-right:10px;
        }
      }
      &.right{
        padding-right:0px;
        .icon{
          padding-left:10px;
        }
      }
    }
    .text{
      text-decoration-line: underline;
    }
  }
}
.new-btn{
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px;
  @include breakpoint(medium){
    justify-content: unset;
    align-self: unset;
  }
  text-decoration: none;
  .text { text-decoration: none; }
  transition: all 400ms;
  &.primary{
    border:1px solid #262957;
    background-color: #262957;
    .text{
      color:white;
    }
  }
  &.primary-orange{
    border:1px solid #F90;
    background-color: #F90;
    .text{
      color:#262957;
    }
    .icon{
      filter: invert(1) sepia(1) saturate(1000%) hue-rotate(200deg) brightness(80%);
    }
  }
  &.secundary{
    border:1px solid #262957;
    background-color:white;
    .text{
      
    }
    .icon{
      filter: invert(1) sepia(1) saturate(1000%) hue-rotate(200deg) brightness(80%);
    }
  }
  &:hover{
    &.hasicon{
      &.right{
        //padding-right:20px;
        .icon{
          //padding-left:10px;
        }
      }
    }
    &.primary{
      background-color: white;
      .text{
        color:#262957;
      }
      .icon{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(101%) contrast(104%);
      }
    }
    &.primary-orange{
      background-color: white;
      .text{
        color:#F90;
      }
      .icon{
        filter: invert(81%) sepia(48%) saturate(6610%) hue-rotate(0deg) brightness(104%) contrast(104%);
      }
    }
    &.secundary{
      background-color:#262957;
      .text{
        color:white;
      }
      .icon{
        filter:none;
      }
    }
  }
}


.drpdwn-container {
  @include breakpoint(small only){
    position: relative;
    display: block;
    margin-bottom:24px;
  }
}

.drpdwn-button {
  @include breakpoint(small only){
    background-color: #ffffff;
    border: 1px solid #DFE4EA;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.arrow {
  margin-left: 10px;
  font-size: 14px;
}

/* Dropdown menu hidden by default */
.drpdwn-menu {
  @include breakpoint(small only){
    display: none;
    position: absolute;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid var(--Stroke, #DFE4EA);
    margin-top: 5px;
    width: 100%;
    box-shadow: 0px 15px 30px 0px rgba(42, 62, 82, 0.10);
    z-index: 5;
  }
}

.drpdwn-menu .tab-item {
  @include breakpoint(small only){
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.drpdwn-menu .tab-item:hover {
  //background-color: #f1f1f1;
}

.drpdwn-menu .icon {
  @include breakpoint(small only){
    margin-right: 10px;
  }
}

/* Show dropdown when the 'show' class is added */
.show {
  display: block;
}

.even-overleggen{
  display: flex;
  flex-direction: column;
  gap:100px;
  @include breakpoint(large){
    flex-direction: row;
    gap:0px;
  }
  .content{
    flex: 1; /* Keeps the 50% width */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    @include breakpoint(large){
      align-items: flex-start; /* Prevent items from stretching horizontally */
    }
    & > .title{
      margin-bottom:24px;
      text-align: left;
    }
    & > .text{
      margin-bottom:36px;
      max-width:420px;

    }
    .action-button{
      margin-bottom:43px;

    }
    .info-box{
      & > .title{
        padding: 20px 24px;
        border-radius: 8px 8px 0px 0px;
        background: #1E225C;
        border: 1px solid #1C2663;
        color:white;
        text-align: left;
      }
      .items{
        border-radius: 0px 0px 8px 8px;
        border: 1px solid #1C2663;
        display: flex;
        padding: 16px 24px 16px 24px;
        @include breakpoint(large){
          padding-right:64px;
        }
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        .item{
          display: flex;
          gap: 24px;
          .icon{
            width: 45px;
            height: 45px;
            border-radius: 5px;
            background: rgba(48, 86, 211, 0.06);
            position: relative;
            img{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              //height: 10px;
              transition: all 400ms;
            }
          }
          a { text-decoration: none; &:hover{ text-decoration: underline;}}
        }
      }
    }
  }
  .image{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .bg-letters{
      position: absolute;
      top: 15%;
      left: 44%;
      @include breakpoint(medium){
        left: 48%;
      }
      font-size: 270px;
      letter-spacing: -30px;
      @include breakpoint(large){
        top: 40%;  /* Centers it vertically */
        left: 46%; /* Centers it horizontally */
        font-size: 370px;
        letter-spacing: -40px;
      }
      transform: translate(-50%, -50%); /* Shifts it back by 50% of its own size to perfectly center */
      z-index: 1; /* Ensure it's above the image */
      pointer-events: none; /* Make sure it doesn't interfere with clicks if necessary */
      font-family: 'Lexend', sans-serif;
        font-display: fallback;
        font-weight: 800;
        line-height: 45px;
        color: #00c4c2;
    }
    img {
      position: relative;
      z-index: 3;
      margin-bottom:-22px;
      max-width:310px;
      @include breakpoint(large){
        margin-bottom:-36px;
        max-width:500px;
      }
    }
  }
}
.account-blocks{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 30px rgba(42, 62, 82, 0.098);
    border-radius: 8px;
    padding: 22px;
    @include breakpoint(large){
      padding: 35px;
    }
}
.faqs{
  .main-row{
    padding: 16px 59px 16px 24px;
    border-bottom: 1px solid #F3F4FE;
    &.selected{
      border-bottom:0px;
    }
    .tgl-item{ gap: 24px; }
  }
  .details-row{
    padding: 16px 59px 16px 24px;
    border-bottom: 1px solid #F3F4FE;
  }
}
.new-form{
  input, textarea, select{
    border-radius: 6px;
    border: 1px solid var(--Stroke, #DFE4EA);
  }
}
.bxd-content{
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--CF-primary-style-color, #262957);
  @include breakpoint(large){
    flex-direction: row;
    padding: 0px 24px 0px 24px;
    //align-items: center;
    gap: 50px;
    //align-self: stretch;
  }
  .content{
    @include breakpoint(large){
      padding: 24px 0px 24px 0px;
    }
    & > .title{
      margin-bottom:20px;
      text-align: left;
    }
    & > .description{
      margin-bottom:26px;
      @include breakpoint(large){
        margin-bottom:46px;

      }
    }
    .grades{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      @include breakpoint(large){
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        @include breakpoint(large){
          align-items: flex-start;
          align-self: normal;
          gap: 16px;
          width:168px;
        }
      }
    }
  }
  .image{
    align-self:flex-end;
  }
}
.hover-image{
  .hover {
    display: none;
  }
  img{
    transition: visibility 0.3s ease;
  }
}
.btns{
  display: flex;
  flex-direction: column;
  gap:12px;
  @include breakpoint(large){
    flex-direction: row;
  }
}
.text-image-block{
  display: flex;
  flex-direction: column;
  gap:24px;
  @include breakpoint(large){
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  .text{

    //flex: 1 1 50%; /* Adjust as needed to control the width */
    & > img{ border-radius:8px;  }
  }
  .image{

    //flex: 1 1 50%; /* Adjust as needed to control the width */
    display: flex;
    align-items: stretch;
    justify-content: center; /* Optional: Center the image horizontally */
    .equal-height-container{
      position: relative;
      height: 100%;
      min-width: 400px;
      & > img{
        border-radius:8px;
        position: absolute;
        height: 100%;
        width:100%;
        object-fit: cover;
      }
    }
    & > img{
      border-radius:8px;
      height:100%;
      width:auto;
      object-fit: cover;
    }
  }
}
.location-img{
  margin-bottom:24px; max-height: 200px; object-fit: cover; width:100%;
}
.link-grid{
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  @include breakpoint(large){
    flex-direction: row;
    flex-wrap: wrap;
  }
  .item{
    @include breakpoint(large){
      flex: 0 0 calc(50% - 8px);
    }
  }
  .item > a{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F3F4FE;
    padding: 12px;
    gap: 12px;
    @include breakpoint(large){
      padding: 16px 59px 16px 24px;
      gap: 46px;
    }
    align-items: center;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    .icon{
      width: 25px;
      height: 25px;
      position: relative;
      @include breakpoint(large){
        width: 42px;
        height: 42px;
      }
      border-radius: 10px;
      background: rgba(48, 86, 211, 0.06);
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        @include breakpoint(large){
          height:18px;
        }
        transition: all 400ms;
      }
    }
  }
}