@keyframes slidein {
  0% {
    opacity: 0;
	transform: translateY(100vh);
  }
  100% {
    opacity: 1;
	transform: none;
  }
}

@keyframes slideout {
  0% {
    opacity: 1;
	transform: none;
  }
  100% {
    opacity: 0;
	transform: translateY(-100vh);
  }
}
#9r6ous-tooltip{
	max-width:20rem;
}
.fullscreenform{
	height:100vh;
	position:relative;
	&__progress{
		position:absolute;
		top:0px;
		left:0px;
		right:0px;
		width:100%;
		height:15px;
		z-index:10;
		background-color:#FFD9A0;
		&__number{
			display:none;
		}
		&__current{
			position:absolute;
			top:0px;
			left:0px;
			width:0%;
			height:100%;
			background-color:$color-orange;
		}
	}
	&__header{
		position:absolute;
		top:15px;
		left:0px;
		right:0px;
		width:100%;
		z-index:10;
		display:flex;
		padding:0 15px;
		background-color:$color-business-blue;
		@include breakpoint(large){
		background-color:transparent;
		padding:0 30px;
		padding-top:15px;
		}
		padding-top:15px;
		justify-content:space-between;
		align-items:center;
		&__logo{
			img{
				width:150px;
				@include breakpoint(large){
					width:250px;
				}
			}
			text-align:center;
			width:100%;
			@include breakpoint(large){
				text-align:left;
				width:unset;
			}
		}
		&__reviews{
			p.home-leader-new__subtext{ 
				color: white;max-width:100%; @include header-text; font-size:16px;
				span{
					@include header-text;
				} 
				.start__reviews__ratings__text { color:white; strong { color:white;} }
			}
			display:none;
			@include breakpoint(large){
				display:block;
			}
		}
	}
	&__footer{
		position:absolute;
		bottom:15px;
		left:50%;
		transform: translateX(-50%);
		right:0px;
		width:100%;
		max-width:1024px;
		z-index:10;
		display:flex;
		padding:20px 15px;
		border-top: 1px solid #939393;
		background-color:$color-business-blue;
		@include breakpoint(large){
			background-color:transparent;
			//margin:0px 20px;
		}
		@include breakpoint(xlarge){
			padding:20px 0px;
			bottom:50px;
		}
		padding-top:20px;
		justify-content:space-between;
		align-items:center;
		&__button{
			a {
				border: 1px solid #FFFFFF;
				border-radius: 4px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color:white;
				padding: 8px 15px;
				text-decoration: none;
				gap:15px;
				transition: all 400ms;
				&:hover{
					background:white;
					color:$color-business-blue;
					span{
						color:$color-business-blue; }
					img{
						filter: invert(13%) sepia(24%) saturate(4675%) hue-rotate(220deg) brightness(87%) contrast(97%);
					}
				}
			}
			&__text{
				font-weight:600;
				color:white;
			}
			&__icon{
				height:16px;
				line-height: 16px;
				img{
					width:16px;
					filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(263deg) brightness(103%) contrast(102%);
				}
			}
		}
		&__text{
			font-size:14px;
			font-weight:medium;
			color:white;
			@include breakpoint(large){
				font-size:17px;
			}
		}
	}
	&__container{
		height:100%;
		display: flex;
    	flex-direction: column;
			justify-content: center;
		@include breakpoint(medium){
			-webkit-box-pack: center;
			justify-content: center;
		}
		&__section{
			width:100%;
			height:100%;
			max-height:100vh;
			overflow:hidden;
			overflow-y: scroll;
			&__container{
				width:100%;
				max-width:1024px;
				margin:0 auto;
				.step-module__section{
					padding-top: 0px;
					//pointer-events: none;
					//transition: transform 600ms ease, opacity 300ms ease;
					//opacity: 0;
					//transform: translateY(-100vh);
					img.logo{ width:50%; margin-bottom:30px; margin-left:auto; margin-right:auto; display:block; max-width:350px; }
					h3,p { text-align:center; @include header-text; }
					h3 { 
						margin-top:25px;
						font-size:1.4rem;
						@include breakpoint(large){
							margin-top:70px;
						}
						@include breakpoint(xlarge){
							font-size:rem-calc(39px)!important;
						}
					}
					&.category_section{
						h3{ 
							padding-top:100px; 
							@include breakpoint(large){
								margin-top:70px;
							}
						}
					}
					.home-leader-new__subtext {max-width:100%; margin-top:80px; font-size:1rem; background:transparent; align-items:center; justify-content:center;}
					label { display:none; }
					.input-group-field{
						border: 1px solid #707070;
						margin-top:10px;
						border-radius: 8px;
						padding:25px 45px;
						@include header-text;
						&::placeholder{
							color:$color-business-blue;
						}
						@include breakpoint(large){
							width:52%;
							margin:0 auto;
						}
					}
					//transition: all 1s ease-out;
					transition: all 600ms ease-out;
					&.slidein{
						animation-duration: 1s;
  						animation-name: slidein;
  						animation-fill-mode: forwards;
					
					}
					&.slideout{
						animation-duration: 1s;
  						animation-name: slideout;
  						animation-fill-mode: forwards;
					
					}
					&.current{
						transform: none;
						opacity:1;
					}
				}
				.step-module.test .step-module__section h3{
					width:100%;
				}
				.step-module__section__choices{
				margin-top:10px;
					gap: 10px;
					@include breakpoint(large){
						gap: 25px;
					
					}
				}
				
				
				.step-module__section__choices > div{
					font-size:16px;
					p{ text-align:left; }
					&.category{
						min-height:70px;
						padding: 10px 30px;
						font-size:14px!important;
						@include breakpoint(large){
							min-height:50px;
							font-size:16px;
						}
						input{
							top:16px;
							accent-color: #1E225C;
						}
						@include breakpoint(xlarge){
							min-width: 20%;
							max-width: 32%;
						}
						span.category__tooltip{
							position: absolute;
							bottom: -3px;
							@include breakpoint(medium){
								//top: 2px;
							}
							@include breakpoint(large){
								//top: 0px;
							}
							right: -3px;
							padding:3px 8px;
							@include header-text;
							color: white;
							//height: 18px;
							line-height: 1;
							border: 1px solid white;
							border-radius: 4px;
							background: $color-business-blue;
							font-size:rem-calc(18px);
							.fa-info-circle{
								border: 1px solid $color-business-blue;
								border-radius: 100px;
							}
						}
					}
					&.time{
						min-height:180px;

					}
					@include breakpoint(xlarge){
						min-width: 40%;
						max-width: 50%;
					}
				}
				.step-module__section__choices > div p.subtitle{
					font-size:14px;
				}
				.step-module__buttons{
					margin-top:30px;
					justify-content:center;
					gap:10px;
					margin-bottom:20px;
					@include breakpoint(medium){
						gap:20px;
					}
					button, button:first-of-type, a, a:first-of-type{
						margin:0px;
						flex-basis:49%;
						@include breakpoint(large){
							flex-basis:25%;
							
						}
					}
					&.single{
					
						button, button:first-of-type, a, a:first-of-type{
							margin:0px;
							flex-basis:100%;
							@include breakpoint(large){
								flex-basis:50%;
							
							}
						}
					}
				}
			}
		}
	}
	&--blue{
		background:$color-business-blue;
		.logo{
		}
		.step-module__section{
			h3,p { color:white; }
		}
		.step-module__section__choices > div{
			background:rgba(255,255,255,0.082);
			border:1px solid white;
			box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
			img { filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(251deg) brightness(101%) contrast(102%); }
			&.checked,&:hover{
				background:white;
				color:$color-business-blue;
				box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
				p{ color:$color-business-blue;}
				img { filter: invert(13%) sepia(55%) saturate(1783%) hue-rotate(217deg) brightness(97%) contrast(102%); }
			}
		}
	}
	&.test{
		position:relative;
		.step-module__timeline{
			@include breakpoint(large){
				position: absolute;
    			right: 0;
			}
		}
		.step-module__section h3{
			@include breakpoint(large){
				font-size: 2rem;
				width:50%;
				line-height: 1.2;
    			letter-spacing: -1px;
    			font-weight:800;
			}
		}
	}
	&__result{
		margin-top:25px;
		.trainingen-grid__item:hover{
			.training-grid-block__image__overlay{
				opacity:0;
			}
		}
	}
	&__timeline{
		display:flex;
		column-gap:30px;
		margin-bottom:50px;
		&.small{
			column-gap:10px;
			.step-module__timeline__item{
				padding:4px;
				height:35px;
				width:35px;
				&::after{
					width:12px;
					margin-left:11px;
				}
			}
			
			@include breakpoint(medium up){
				column-gap:30px;
				.step-module__timeline__item{
					padding:10px;
					height:50px;
					width:50px;
					&::after{
						width:30px;
						margin-left:19px;
					}
				}
			}
		}
		&__item{
			border-radius:100%;
			border:1px solid $color-blue;
			@include header-text;
			font-size:1.2rem;
			font-weight:800;
			padding:10px;
			text-align:center;
			width:50px;
			height:50px;
			cursor:pointer;
			&.filled{
				background:$color-blue;
				color:white;
			}
			&::after{
				position:absolute;
				border:2px solid $color-blue;
				width:30px;
				content:" ";
				margin-top:12px;
				margin-left:19px;
				
			}
			&:last-of-type{
				&::after{
					display:none;
				}
			}
			
			@include breakpoint(medium up){
				&::after{
					width:80px;
					content:" ";
				
				}
			}
		}
		@include breakpoint(medium up){
			column-gap:80px;
		}
	}
	&__section{
		display:none;
		margin-bottom:50px;
		&.current{
			display:block;
		}
		h3{
			font-size:1.2rem;
			font-weight:bold;
			margin-bottom:20px;
		}
		p{
		}
		a{
			color:$color-orange;
		}
		&__choices{
			display:flex;
			gap:25px;
			flex-wrap:wrap;
			& > div{
				cursor: pointer;
				flex: 1 1 calc(25% - 10px);
				min-width: 90%;
				background-color:#fff;
				box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
					border: 1px solid white;
				border-radius: 8px;
				position:relative;
				padding:20px;
				z-index:2;
				text-decoration:none;
				display:block;
				height:100%;
				font-weight:bold;
				font-size:18px;
				justify-content: space-between;
				align-items:center;
				@include breakpoint(medium){
					padding:30px;
					display:block;
					min-width: 40%;
					max-width: 50%;
				}
				@include breakpoint(xlarge){
					min-width: 20%;
					max-width: 32%;
					&.category{
						min-height:170px;
					}
				}
				&:hover{
				
					border: 1px solid $color-blue;
					box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
						@include transition(all 400ms);
					.category-section__container__categories__card__title{
						color:$color-orange;
						@include transition(all 400ms);
					}
					.category-section__container__categories__card__link > .cf-button--orange-link{
						@include cf-button-orange-link-hover;
					}
					
				}
				&.checked{
					border: 1px solid $color-blue;
				}
				&.disabled{
					border: 1px solid gray;
					opacity:0.5; 
				}
				input { position:absolute; left:10px; top:10px;}
				p { margin-bottom:0px;}
				p.subtitle { font-weight:normal; font-size:16px;}
				img { width:50px; filter: invert(19%) sepia(31%) saturate(1553%) hue-rotate(201deg) brightness(83%) contrast(106%); margin-bottom:10px; }
				
			}
		}
	}
	&__buttons{
		display:flex;
		button:first-of-type(){
			margin-right:20px;
		}
	}
	&.light{
		color:white;
		.step-module__timeline{
			&__item{
				border:1px solid white;
				&.filled{
					background:white;
					color:$color-blue;
				}
				&::after{
					border:2px solid white;
				
				}
			}
		}
		.step-module__section{
			color:white;
			h3{
				color:white;
			}
			p{
				color:white;
			}
		}
	}
}