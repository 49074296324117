.headerOpleidingvoordeel, .tekst-headerOpleidingVoordeel{
    text-align: center;
    padding-bottom: 25px !important;
    margin:0 !important;
}
.tekst-headerOpleidingVoordeel{
    padding-bottom:50px!important;
}
.training-grid-block__price{
    flex-basis: 73%;
    margin:0;
}
/*.training-grid-block__bottom__info__days::after{
    content: "\2022";
    margin-left: 5px;
}*/