.sidebar-inschrijven {

  &__button {
    margin: 0;
    $path: none;
    -webkit-clip-path: $path;
    clip-path: $path;
    box-shadow: none;
    width: 100%;
    text-align: left;
    height: 50px;

    .cf-button__text {
      margin-left: 20px;
    }

    .cf-button__icon {
      margin-right: 20px;
    }
  }
}