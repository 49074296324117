.start-subsite {
  &__row{
  }
  
  &__title {
    height:90px;
    text-align: center;
    img {
    	height:90px;
    }

    @include breakpoint(medium) {
      
    }
  }

  &__training-list {
    &__button {
    	margin:10px 0px;
    	@include breakpoint(medium) {
    		margin:15px 0px;
    		font-size:0.9rem;
    	}	
    }
  }

  &__button {

    @include breakpoint(medium) {
      text-align:center;

      .cf-button {
        padding-right: 40px;

        i {
          font-size: 115%;
          margin-left: 20px;
        }
      }
    }
  }
  &--factory {
  	@include breakpoint(small only) {
  		margin-top:1.5rem;
  	}
  }
  &--business-school {
    @include breakpoint(medium) {
  		 border-right: 1px solid $color-light-gray;
    }
  }  
}