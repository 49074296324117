.stap-menu-counter{
	display:flex; justify-content:flex-end; align-items:center;
	&__announcement{
		margin-right:15px;
		color:$color-business-blue;
		@include header-text;
		font-weight:500;
	}
}
$halfHeight: 0.72em;
$borderRadius: 4px;
.counter{
	display:inline-flex;
	align-items:center;
	color:white;
	font-weight:bold;
	@include header-text;
	&__item{
		margin-right:15px;
    color:white;
		flex-shrink:2;
		flex-basis:33%;
		@include header-text;
		@include breakpoint(medium){
			flex-basis:auto;
		
		}
	}
	&.center{
		display:flex;
		justify-content:center;
		.counter__item{
			flex-shrink:auto;
		}
	}
	&--blue{
		background:#F2F8FF;
		border-radius:4px;
		padding:10px 15px;
	}
}
.card-item {
  display: block;
  position: relative; 
  padding-bottom: $halfHeight;
  font-size: rem-calc(20px);
  line-height: 0.95;
  text-align:center;
}
.counter__item__card{
	&__top,&__bottom,&__back::before,&__back::after{
		display: block;
		height: $halfHeight;
		color:  $color-business-blue;
		background: #fff;
		padding: 0.25em 0.25em;
		border-radius: $borderRadius $borderRadius 0 0;
		backface-visiblity: hidden;
		transform-style: preserve-3d;
		width: 1.4em;
		transform: translateZ(0);
	}
	&__bottom{
	  color:$color-business-blue ;
	  position: absolute;
	  top: 50%;
	  left: 0;
	  //border-top: solid 1px #000;
	  background: #FFF; 
	  border-radius: 0 0 $borderRadius $borderRadius; 
	  pointer-events: none;
	  overflow: hidden;
		&::after{
		  display: block;
		  margin-top: -$halfHeight;
		}
	}
	
	&__back::before,
	&__bottom::after {
  		content: attr(data-value);
	}
	
	&__back {
	  position: absolute;
	  top: 0;
	  height: 100%;
	  left: 0%;
	  pointer-events: none;
	  &::before{
		  position: relative;
		  z-index: -1;
		  overflow: hidden;
	  }
	}
}

.flip-clock__slot { display:none; }
.flip .counter__item__card__back::before {
  animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .counter__item__card__back .counter__item__card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);// 0.3s; 
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%, 99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%, 50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}


$flipColour: #fff;
$flipColourDark: darken($flipColour, 35%);
$textColour: $color-business-blue;
$textColourDark: darken($textColour, 35%);

.digit {
  position: relative;
  float: left;
  width: 15px;
  height: 25px;
  background-color: $flipColour;
  border-radius: 4px;
  text-align: center;
  font-family:Lexend,open-sans;
  font-weight:600;
  font-size: 12px;
  @include breakpoint(medium){
    font-size:14px;
  }
}

.base {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $textColour;
  
}
  
.flap {
  display: none;
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: $flipColour;
  left: 0;
  top: 0;
  border-radius: 4px 4px 0 0;
  transform-origin: 50% 100%;
  backface-visibility: hidden;
  overflow: hidden;
  
  &::before {
    content: attr(data-content);
    position: absolute;
    left: 50%;
  }
  
  &.front::before,
  &.under::before {
    top: 100%;
    transform: translate(-50%, -50%);
  }
  
  &.back {
    transform: rotateY(180deg);
    &::before {
      top: 100%;
      transform:  translate(-50%, -50%) rotateZ(180deg);
    }
  }

  &.over {
    z-index: 2;
  }
  
  &.under {
    z-index: 1;
  }
  
  &.front {
    animation: flip-down-front 300ms ease-in both;
  }
  
  &.back {
    animation: flip-down-back 300ms ease-in both;
  }
  
  &.under {
    animation: fade-under 300ms ease-in both;
  }
  
}

@keyframes flip-down-front {
  0% {
    transform: rotateX(0deg);
    background-color: $flipColour;
    color: $textColour;
  }
  100% {
    transform: rotateX(-180deg);
    background-color: $flipColourDark;
    color: $textColourDark;
  }
}

@keyframes flip-down-back {
  0% {
    transform: rotateY(180deg) rotateX(0deg);
    background-color: $flipColourDark;
    color: $textColourDark;
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
    background-color: $flipColour;
    color: $textColour;
  }
}

@keyframes fade-under {
  0% {
    background-color: $flipColourDark;
    color: $textColourDark;
  }
  100% {
    background-color: $flipColour;
    color: $textColour;
  }
}

.clock {
  //position: absolute;
  //width: 70vw;
  //top: 50%;
  //left: 15vw;
  //transform: translateY(-50%);
  perspective: 100vw;
  perspective-origin: 50% 50%;
  
  flex-grow:2;
  @include breakpoint(medium){
  	flex-grow:initial;
  }
  
  .digit {
    margin-right: 0px;
    border-radius:4px 0px 0px 4px;
    padding-left:15px;
    margin-right:-1px;
    @include header-text;
    margin-left:0px;
    &.even { border-radius:0px 4px 4px 0px; padding-right:15px; margin-left:-2px; margin-right:0px; padding-left:0px;}
    &:last-child { margin-right: 0; }
    font-size:12px;
  }
  .seperator{
  	margin:0px 5px;
	color:white;
	font-family:Lexend;
	font-weight:600;
	float:left;
    font-size:1rem;
  	
  }
  
}  
.counter.simple{
  .digit, .flap, .flap::before{
    background-color: $color-business-blue;
    font-family: "Poppins", sans-serif;
    width:unset;
    height:unset;
  }
  .base{
    top:unset;
    left:unset;
  }
  .digit{
    padding:0px;
    margin:0px;
    padding-right:5px;
    color:white;
    font-size:unset;
  }
  .base, .flap::before, .denominator{
    color:white;
    display: inline-block;
    position: relative;
    transform:none;
    font-family: "Poppins", sans-serif;
  }
  .flap::before{
    border-color: $color-business-blue;
  }
  .counter__item{
    margin-right:5px;
		flex-basis:auto;
    font-family: "Poppins", sans-serif;
    font-weight:600;
  }
  .counter__item__card{
    &__top,&__bottom,&__back::before,&__back::after{
      background:$color-business-blue;
    }
  }
}