
.training-grid-section{
	margin: 75px 0px;
	&__title{
		font-size:30px;
		font-weight:800;
		@include breakpoint(medium){
			font-size:30px;
		}
        margin-bottom: 20px;
	}
    &__text{
        margin-bottom: 25px;
        font-size:16px;
    }
	&__body{
		position: relative;
		padding: 0px 0px 0px;
		margin-bottom: 60px;
        & > div.row {
            row-gap: 30px;
            padding-left:20px;
            padding-right:20px;
            @include breakpoint(medium){
                padding-left:0px;
                padding-right:0px;

            }

        }
	}
    &__footer{
        & > div.row {
            padding-left:20px;
            padding-right:20px;
            @include breakpoint(medium){
                padding-left:0px;
                padding-right:0px;

            }

        }

    }
    
    & > div.row > .column.small-12, & > div.row > .small-12.columns{
        padding: 0 2rem;
        @include breakpoint(large){
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }
    }
    &--grey{
        padding:74px 0px;
        background-color: #F8F8FA;
    }
    &--blue{
        padding:74px 0px;
        background-color: $color-business-blue;

    }
}