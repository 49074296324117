.text-slider-block{
    margin: 50px 0px;
    @include breakpoint(large){
        margin: 100px 0px;
    }
    &__container{
        &__text{
            padding-left:2rem;
            padding-right:2rem;
            @include breakpoint(large){
                padding-right:90px;
            }
            &__title{
                font-size:32px;
                font-weight:800;
                margin-bottom:26px!important;
            }

            &__runningtext{
                margin-bottom:26px;
            }

            &__button{
                margin-top:20px;

                &.contactpagina{
                    svg{
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg) brightness(103%) contrast(103%);
                    }
                }
                &:hover{
                    &.contactpagina{
                        svg{
                            filter: invert(13%) sepia(31%) saturate(3879%) hue-rotate(215deg) brightness(100%) contrast(97%);
                        }
                    }
                }
            }
        }
        &__slider{
            padding-left:0rem;
            padding-right:0rem;

            .rf-cards-scroller-crop { height:220px; }
            @include breakpoint(large){
                position: absolute!important;
                right:0px;
                max-width: 50vw;
                .rf-cards-scroller-itemview {
                    transform:none;
                }
                .rf-ccard-40 .rf-ccard-content{ width:30rem; }
                .rf-ccard-img-full-wrapper { 
                    min-height:340px; 
                    img { height:100%; object-fit: cover; }
                }
                .rf-cards-scroller-crop { height:380px; }
                .paddlenav .paddlenav-arrow-next, .paddlenav .paddlenav-arrow-previous{
                    top:50%!important; 
                }
            } 

        }
    }
    &--graybackground{
        margin: 50px 0px;
        padding: 50px 0px;
        background-color: #F8F8FA;
        @include breakpoint(large){
            padding-top: 100px;
            padding-bottom: 100px;
            margin: 100px 0px;
        }
    }
}