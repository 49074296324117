.rf-cards-scroller {
    position: relative;
 }
 
 .rf-cards-scroller-crop {
    overflow: hidden;
    height: 31rem;
    padding-bottom: 26px;
 }
 
 .rf-cards-scroller-content {
    overscroll-behavior-x: contain;
    -ms-overflow-style: none;
    scrollbar-width:none;
    &::-webkit-scrollbar{
      display: none;
    }
 }
 [data-core-scroller] {
    position: relative;
    white-space: nowrap;
    scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
 }
 
 .rf-cards-scroller-platter {
    width: 100%;
    vertical-align: top;
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 40px;
 }
 
 [data-core-scroller-platter]>div {
    display: inline-block;
    scroll-snap-coordinate: left;
    scroll-snap-align: start;
 }
 
 .rf-cards-scroller-platter>div:first-child .rf-cards-scroller-itemview {
    margin-left: 0;
 }
 .rf-cards-scroller-itemview {
    display: flex;
    /*height: 29.41176rem;*/
    margin-right: 20px;
    transform: translateX(calc(max(1380px, 100vw)/2 - 660px));
 }
 
 .as-util-relatedlink {
    cursor: pointer;
 }
 
 .rf-ccard-40 .rf-ccard-content {
    width: 19.69rem;
 }
 .rf-ccard-content.rf-ccard-content-withfullimg {
    flex-direction: row;
    justify-content: center;
 }
 .rf-ccard-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border-radius: 2px 16px 16px 16px;
    width: 19.69rem;
    /*height: 26.41176rem;*/ /*24 rem for large */
    white-space: normal;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    transition: all .3s cubic-bezier(0,0,.5,1);
 }
 
 .rf-ccard-overlay{
   position: absolute;
   bottom: 0px;
   left:0px;
   width:100%;
   height: 100%;
   background: transparent linear-gradient(186deg, rgba(0,0,0,0) 0%, rgba(41,15,10,0.372) 100%) 0% 0% no-repeat padding-box;
 }
 .rf-ccard-txtoverlay{
   position: absolute;
   bottom: 0px;
   left:0px;
   width:100%;
   overflow-wrap: break-word;
   padding: 15px;
   color:white;
   z-index: 4;
   font-size:16px;
   font-weight:600;
   @include header-text;
   @include breakpoint(medium){
      padding:20px;
      font-size:20px;
   }
   @include breakpoint(large){
      padding:45px;
      font-size:30px;

   }
 }
 .rf-ccard-img-full-wrapper {
    width: 100%;
 }
 
 .rf-ccard-img-full-wrapper+.rf-ccard-content-info, .rf-ccard-video-full-wrapper+.rf-ccard-content-info {
    position: absolute;
    left: 0;
 }
 .rf-ccard-content-info {
    padding: 30px;
    width: 20rem;
 }
 
 .rf-ccard-img-full {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
 }
 
 .rf-ccard-darkbg .rf-ccard-content-eyebrow {
    color: #f5f5f7;
 }
 [class*=t-eyebrow] {
    display: block;
    margin-bottom: 0.4em;
 }
 .rf-ccard-content-eyebrow {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    font-family: SF Pro Text,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: 600;
    color: #6e6e73;
    width: 204px;
    padding-bottom: 8px;
 }
 .rf-ccard-content-eyebrow+.rf-ccard-content-header, .rf-ccard-content-icon+.rf-ccard-content-header {
    padding-top: 0;
 }
 .rf-ccard-darkbg .rf-ccard-content-header {
    color: #fff;
 }
 [class*=t-eyebrow]+* {
    margin-top: 0;
 }
 .rf-ccard-content-header {
    font-size: 28px;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;
    font-family: SF Pro Display,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #1d1d1f;
    padding-top: 24px;
    margin-top: 0;
 }
 
 .rf-ccard-darkbg .rf-ccard-content-desc {
    color: #fff;
 }
 .rf-ccard-content-desc {
    font-size: 17px;
    line-height: 1.23536;
    font-weight: 400;
    letter-spacing: -.022em;
    font-family: SF Pro Text,SF Pro Icons,AOS Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #1d1d1f;
    padding-top: 10px;
    margin-top: 0;
 }
 
 .nowrap {
    display: inline-block;
    text-decoration: inherit;
    white-space: nowrap;
 }
 
 .visuallyhidden {
    position: absolute;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0 0 99.9% 99.9%);
    clip-path: inset(0 0 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
 }
 
 .rf-cards-scroller:hover .paddlenav-arrow:not([disabled]) {
     transition-duration: 0.5s;
 }
 .rf-cards-scroller:hover .paddlenav-arrow:not([disabled]) {
     transform: scale(1);
     transition-duration: .5s;
     opacity: 1;
     background-color: rgba(0,0,0,.2);
     background-color: #1e225d;
 }
 
 .rf-cards-scroller .paddlenav-arrow {
     transition: transform 0.5s ease-in, opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
 }
 .rf-cards-scroller .paddlenav-arrow {
     transform: scale(.8);
     opacity: 0;
     transition: transform .5s ease-in,opacity .7s cubic-bezier(.15,0,.2,1) .1s;
     background-color: rgba(0,0,0,.2);
     color: #fff;
 }
 .rf-cards-scroller .paddlenav .paddlenav-arrow-next, .rf-cards-scroller .paddlenav .paddlenav-arrow-previous {
     top: 50%;
 }
 .paddlenav-framed .paddlenav-arrow-previous {
     left: 18px;
 }
 .paddlenav-framed .paddlenav-arrow {
     background-color: #1e225d;
     color: white;
 }
 .paddlenav-compact .paddlenav-arrow-next, .paddlenav-compact .paddlenav-arrow-previous {
     margin-top: -1.52941rem;
 }
 .paddlenav-compact .paddlenav-arrow {
     font-weight: 300;
     width: 3.05882rem;
     height: 3.05882rem;
     border-radius: 3.05882rem;
     font-size: 53px;
     line-height: 3.05882rem;
 }
 .paddlenav .paddlenav-arrow-previous {
     left: 18px;
 }
 .paddlenav-framed .paddlenav-arrow-next {
     right: 18px;
 }
 .paddlenav .paddlenav-arrow {
     background-color:  #1e225d;
     color: rgba(0,0,0,.56);
 }
 .paddlenav .paddlenav-arrow-next, .paddlenav .paddlenav-arrow-previous {
     margin-top: -2.64706rem;
 }
 .paddlenav .paddlenav-arrow {
     width: 5.29412rem;
     height: 5.29412rem;
     border-radius: 5.29412rem;
     font-size: 53px;
     line-height: 5.29412rem;
 
     width: 3.29412rem;
     height: 3.29412rem;
     border-radius: 3.29412rem;
     font-size: 25px;
     line-height: 3.29412rem;
     color:white;
 
 }
 .paddlenav .paddlenav-arrow-next, .paddlenav .paddlenav-arrow-previous {
     top: 50%;
     &:hover{
        cursor: pointer;
     }
 }
 .paddlenav .paddlenav-arrow {
     display: block;
     font-style: normal;
     overflow: hidden;
     position: absolute;
     text-align: center;
     transition: background-color .1s linear,color .1s linear,opacity .1s linear;
     display: none;
     @include breakpoint(large){
      display: block;
     }
 }
 .paddlenav-compact .paddlenav-arrow-previous:after {
     margin-left: -0.1em;
 }
 
 .paddlenav-compact .paddlenav-arrow-previous:after {
     /*font-family: "Font Awesome 5 Free";
     color: inherit;
     display: inline-block;
     font-style: normal;
     font-variant: normal;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;*/
     display: inline-block;
     line-height: 1;
     position: relative;
     z-index: 1;
     background-image:url('/images/chevron-left.png');
     background-position: center center;
     background-repeat: no-repeat;
     //alt: "";
     //text-decoration: none;
 }
 .paddlenav-compact .paddlenav-arrow-previous:after {
     content: " ";
     font-weight: 900;width: 25px;
     height: 25px;
     margin-top: 10px;
     @include breakpoint(large){
        margin-top: 13px;
     }
 }
 .paddlenav-compact .paddlenav-arrow-next:after {
     margin-right: -0.1em;
 }
 .paddlenav-compact .paddlenav-arrow-next:after {
     /*font-family: "Font Awesome 5 Free";
     font-style: normal;
     font-variant: normal;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     color: inherit;*/
     display: inline-block;
     line-height: 1;
     position: relative;
     z-index: 1;
     background-image:url('/images/chevron-right.png');
     background-position: center center;
     background-repeat: no-repeat;

     //alt: "";
     //text-decoration: none;
 }
 .paddlenav-compact .paddlenav-arrow-next:after {
     //content: "\f054";
     content: " ";
     width: 25px;
     height: 25px;
     margin-top: 10px;
     @include breakpoint(large){
        margin-top: 13px;
     }
     //font-weight: 900;
 }

 .keuzehulp-trainingen-scroller{
   height:35rem;
   @include breakpoint(medium){
      height: 32rem;
   }
   .rf-cards-scroller-platter > div:first-child .rf-cards-scroller-itemview{
      @include breakpoint(medium){
      }
   }
   .rf-cards-scroller-itemview{
      @include breakpoint(medium){
         //transform:none;
      }
   }
   &__contentbox{
      width: 300px !important;
      @include breakpoint(medium){
         width: 400px !important;
      }
   }
   .trainingen-grid__item{
      padding: 10px;
      height:100%;
   }
 }

 .keuzehulp-docenten-snap-slider{
   width:100%;
   height:35rem;
   @include breakpoint(medium){
      height: 29rem;
   }

   .as-util-relatedlink{
      cursor: auto;
   }

   .rf-ccard-content-withfullimg{
      @include breakpoint(medium){
         width: 400px;
         margin-left: 20px;
         margin-right: 20px;
      }

      .slide-box{
         margin:0 !important;
      }
   }
 }
 .keuzehulp-cursisten-review-slider{
   @include breakpoint(medium){
      margin-top:20px;
   }
   .rf-cards-scroller-crop{
      height: 50rem;
      @include breakpoint(medium){
         height: 38rem;
      }
      
      .rf-cards-scroller-itemview{
            
         .rf-ccard-content-withfullimg{
            @include breakpoint(medium){
               width: 26.5rem;
            }
         }
      }
   }
 }