.page-header {
  @include clearfix;
  padding-bottom: 0.5rem;
  //margin-bottom: 1rem;
  //@include linear-gradient(top, #ffffff, $color-mobile-gradient 350px);
  //border-bottom: 2px solid $color-light-primary;

  @include breakpoint(medium) {
    padding-bottom: 0;
    //margin-bottom: 3rem;
    background: none;
    border-bottom: none;
  }

  &__title {
    font-weight: 800;
    font-size: rem-calc(24);
    color: $color-business-blue;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
      margin-bottom: 1rem;
      font-size: rem-calc(38);
    }
  }
}

.header{
  &__title {
    font-weight: 800;
    font-size: rem-calc(24);
    color: $color-business-blue;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
      margin-bottom: 1rem;
      font-size: rem-calc(38);
    }
  }
}
