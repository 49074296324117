.keuzehulp-filters{
	& input[type="radio"] {
		margin-left:5px;
    	@include breakpoint(medium) {
    		margin-left:0px;
    	}
		
	}
}
.keuzehulp-layout{
	text-align:center;
	margin-bottom:20px;
    @include breakpoint(medium) {
    	position:absolute;
    	right:30px;
    	top:30px;
    }
}
.keuzehulp-layout-button{
	display:inline-block;
	background:white;
	border-radius: 10px;
	border:1px solid $color-lightest-gray;
	
	&__icons {
		padding:5px 10px;
		font-size:0.9rem;
		display:inline-block;
		@include transition(background 400ms, color 400ms);
		margin:0px;
		&.selected, &:active, &:hover, &:focus {
			background-color:$color-business-blue-light;
			color:white;
			cursor:pointer;
		}
		
	}
	&__icons:first-of-type{
		border-right:1px solid $color-lightest-gray;
		border-radius: 10px 0px 0px 10px;
	}
	&__icons:last-of-type{
		border-left:0px;
		border-radius: 0px 10px 10px 0px;
	}
	&__icons:not(:first-of-type):not(:last-of-type){
		border-right:1px solid $color-lightest-gray;
		border-radius: 0px;
	}
}

.checkbox-container{
	border:1px solid $color-business-blue;
	border-radius: 8px;
	position: relative;
	&__selection{
		position:absolute;
		right:15px;
		top:15px;
		input[type="checkbox"]{
			transform: scale(1.2);
			pointer-events: none;
		}
	}
	&__title{
		display: flex;
		gap:5px;
		align-items: center;
		img{
			width:57px;
		}
		font-weight:600;
		@include header-text;
		font-size:18px;
		padding-left:10px;
		padding-top:5px;
	}
	&__description{
		padding:15px;
		padding-top:0px;
	}
	&:hover{
		cursor: pointer;
	}
}