.omscholen{
	&__usps{
		margin:100px 0px;
		background:$color-gray-background!important;
		&__title{
			color:$color-blue!important;
			&.sum{
				padding-bottom:30px;
				@include breakpoint(medium){
					padding-bottom:60px;
				}
			}
			
		}
		
		&__item{
			color:$color-blue!important;
		
		}
		&__usp{
			color:$color-blue!important;
		
		}
	}
	
	&__possibilities{
		margin:100px 0px;
		position:relative;
		& > .row{
			row-gap: 20px;
		}
		&__title{
			h2 { text-align:center; font-size: 2rem; }
			p { max-width:75%; margin:0 auto; text-align:center; font-size: 1.15rem;}
		}
		&__background{
			z-index: -1;
			&::before{
				content: "";
				background-image: url(/images/dot-bgv2.png);
				height: 50%;
				width: 50%;
				position: absolute;
				background-repeat: repeat;
				top: 140px;
				right: 0px;
				z-index: -1;
				background-position: center;
				background-size: unset;
				@include breakpoint(medium){
					top: 120px;
				}
			}
		}
		&__container{
			background-color:white;
			border-radius:8px;
			box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
			border: 0.5px solid #022543;
			padding:40px;
			height:100%;
			p{ line-height:2; }
			h3 { margin-bottom:1rem;}
		}
	}
	&__process{
		background:$color-gray-background;
		margin-bottom:-75px;
		padding:150px 0px;
	}
	&__why{
		margin:100px 0px;
		position:relative;
		&::before{
			content: "";
			background-image: url(/images/dot-bgv2.png);
			height: 100%;
			width: 40%;
			position: absolute;
			background-repeat: repeat;
			top: 100px;
			right: 0px;
			z-index: -1;
			background-position: center;
			background-size: unset;
		}
		&__testimonial{
			
		}
	}
	&__companies{
		&__logoslider{ 
			background:$color-gray-background;
			padding-top:40px!important;
			padding-bottom:15px!important;
		}
	}
}