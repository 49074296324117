
.hero {
    &--stap {
        overflow: hidden;
        padding:30px 20px 80px 20px;
        @include breakpoint(large){
            padding:100px 0px;
        }
        .hero {
            &__container {
                @include breakpoint(large){
                    padding:0px 20px;
                }
                &__text{
                    align-self: center;
                    @include breakpoint(large){
                        padding-right:30px;

                    }
                    @include breakpoint(xlarge){
                        padding-right:90px;

                    }
                    h1 {
                        font-size:25px;
                        margin-bottom: 25px;
                        @include breakpoint(large){
                            font-size:40px;
                            margin-bottom: 30px;
                        }    

                    }
                    & > p {
                        a { color:$color-orange;}
                        margin-bottom: 25px;
                        font-size:16px;
                        @include breakpoint(large){
                            margin-bottom: 30px;
                        }    

                    }
                }
                &__image{
                    position: relative;
                    padding-top:100px;
                    @include breakpoint(large){
                        padding-top:unset;

                    }

                    img, iframe{
                        border-radius: 12px;
                        position: relative;
                        z-index: 5;
                        
                    }
                    iframe{
                        width:100%;
                        min-height:350px;
                    }
                    &::before{
                        content:"C";
                        position: absolute;
                        color:#00C4C2;
                        @include header-text;
                        top:20px;
                        left:-80px;
                        font-size: 300px;
                        font-weight: 900;
                        line-height: 200px;
                        @include breakpoint(large){
                            top:-100px;
                            left:-100px;
                            font-size: 350px;
                            font-weight: 900;
                            line-height: 200px;

                        }
                    }
                    &::after{
                        content:"F";
                        color:#00C4C2;
                        position: absolute;
                        @include header-text;
                        bottom:-60px;
                        right:-70px;
                        font-size: 300px;
                        font-weight: 900;
                        line-height: 200px;
                        @include breakpoint(large){
                            bottom:-70px;
                            right:-70px;
                            font-size: 350px;
                            font-weight: 900;
                            line-height: 200px;

                        }

                    }
                }
               
                
            }
            &__readmore{
                position: absolute;
                margin-top: 40px;
                a {
                    @include header-text;
                    font-weight:600;
                    text-decoration: none;
                    display: flex;
                    gap:10px;
                    .icon{
                        img{
                            width:16px;
                        }
                    }
                }
            }

        }
    }
  
  
}

  