.seo-page-content{
    padding-top:30px;
    @include breakpoint(large){
        padding-top:50px;
    }
    &__section{
        h1 { 
            font-size:20px;
            line-height: 1.2;
            @include breakpoint(large){
                font-size:39px;
                line-height:initial;
            }
        }
        h2{
            font-size:20px;
            line-height: 1.2;
            @include breakpoint(large){
                font-size:30px;
                line-height:initial;
            }
        }
        h1,h2,h3,h4 { margin-bottom:25px;}
        margin-bottom:70px;
        .cluster-header__content__text__buttons__button { margin-top:25px;}
        &__heading{

        }
    }
}