.admin-evaluaties {
  font-size: rem-calc(13px);
  line-height: 1.2;
  border: 1px solid $color-line;

  &__head {
    &--datum {
      min-width: 90px;
    }
    &--tijd {
      min-width: 70px;
    }
    &--training {
      min-width: 100px;

      @include breakpoint(xlarge) {
        min-width: 300px;
      }
    }
    &--gemiddelde {
      min-width: 100px;
    }
    &--data {
      min-width: 90px;
    }
    &--docent {
      min-width: 150px;
      @include breakpoint(xlarge) {
        min-width: 180px;
      }
    }
    &--cijfer-docent {
      min-width: 110px;
    }
    &--lokatie {
      min-width: 120px;
    }
    &--cursist {
      min-width: 120px;
    }
    &--belangstelling {
      min-width: 150px;
    }
  }

  &__row {
    cursor: pointer;
    &:hover {
      background: $table-row-hover;
    }

    &--striped {
      background: $table-striped-background;
    }

    &--striped:hover {
      background: $table-row-stripe-hover;
    }
  }

  &__details {
    background: #fff !important;
    display: none;
    &.visible {
      display: table-row;
    }
  }
}