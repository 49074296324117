.stap-show{
	display:none;
}
.form-information{
	font-size:14px;
	
}
.form-checkout {
  
  &__stap-budget-callout{
  	border:1px solid $color-blue;
  	box-shadow:0px 3px 6px rgba(0,0,0,0.16);
  	padding:20px!important;
  	border-radius:8px;
  	display:flex;
  	&__icon{
  		img {
  			width:100%;
  			//filter: invert(10%) sepia(58%) saturate(3259%) hue-rotate(229deg) brightness(85%) contrast(92%);
  		}
  	}
  	&__text{
  		@include breakpoint(medium up){
  			padding-left:20px!important;
  		}
  		& > div {
  			display:inline;
  		}
  		.warning{
  			filter: invert(65%) sepia(78%) saturate(983%) hue-rotate(342deg) brightness(101%) contrast(101%);
  		}
  		.good{
  			filter: invert(52%) sepia(62%) saturate(448%) hue-rotate(60deg) brightness(89%) contrast(90%);
  		}
  	}
  	&__checkbox{
  	/* The container */

	  display: inline-block;
	  position: relative;
	  padding-left: 35px;
	    margin-bottom: 22px;
	  cursor: pointer;
	  font-size: 22px;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;

		/* Hide the browser's default checkbox */
		input {
		  position: absolute;
		  opacity: 0;
		  cursor: pointer;
		  height: 0;
		  width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
		  position: absolute;
		  top: 0;
		  left: 0;
		  height: 25px;
		  width: 25px;
	  	  background-color:white;
	  	  border:1px solid $color-blue;
		  /* Create the checkmark/indicator (hidden when not checked) */
		  &:after{
			  content: "";
			  position: absolute;
			  display: none;
		  }
		}

		/* On mouse-over, add a grey background color */
		&:hover input ~ .checkmark {
		  background-color: #ccc;
		}

		/* When the checkbox is checked, add a blue background */
		input:checked ~ .checkmark {
		  background-color: $color-blue;
		}


		/* Show the checkmark when checked */
		input:checked ~ .checkmark:after {
		  display: block;
		}

		/* Style the checkmark/indicator */
		.checkmark:after {
		  left: 9px;
		  top: 5px;
		  width: 5px;
		  height: 10px;
		  border: solid white;
		  border-width: 0 3px 3px 0;
		  -webkit-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  transform: rotate(45deg);
		}
  	}
  }
  &__aanhef-dhr {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  &__aanhef-mvr {
    padding-top: 0 !important;
  }

  &__fieldset-factuuradres {
    max-height: 0;
    overflow: hidden;
    @include transition(max-height 500ms);

    &--expanded {
      max-height: 1400px;

      @include breakpoint(medium) {
        max-height: 700px;
      }
    }
  }

  &__payment-method {
  }

  &__mollie-van-voor {
    font-size: rem-calc(16);
    @include open-sans;
    margin: 3px 0 0 0;
  }

  &__mollie-total {
    font-size: rem-calc(18);
    color: $color-dark-primary;
  }

  &__mollie-korting {
    font-size: rem-calc(18);
    color: $color-light-primary;
  }

  &__img-ideal {
    height: 50px;
    width: auto;
    position: relative;
    top: -2px;
    left: -5px;
  }

  &__img-spraypay {
    height: 60px;
    width: auto;
    position: relative;
    top: -2px;
    left: -5px;
  }
  &__img-mollie {
    height: 25px;
    width: auto;
  }
  
  &__img-afm {
    height: 25px;
    width: auto;
  }
  &__remarks{
  	height:75px;
  }
  &__label-method {
    color: $color-dark-primary;
    @include raleway;
    font-size: rem-calc(18);
    font-weight: 600;
  }

  &__factuuradres {
    strong {
      color: $primary-color;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}