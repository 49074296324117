.site-switch {
  background: #dbdbdb;
  height: 25px;
  position: relative;

  @include breakpoint(medium) {
    height: 25px;
  }

  &__list {
    margin: 0;
    padding: 0;
    li {
      float: left;
      margin: 0;
      padding: 0;
    }
  }

  &__site {
    display: block;
    float: left;
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    font-weight: 300;
    color: $color-black;
    font-size: rem-calc(12);
    text-decoration: none;
    text-transform: uppercase;

    @include breakpoint(medium) {
      margin-top: 10px;
      padding: 0 20px;
      font-size: rem-calc(13);
    }

     &:hover, &:focus {
       color: $color-dark-primary;
    }

    &--active {
      color: $color-dark-primary;
      /*background: #fff;*/
      font-weight: bold;
    }
  }
  @media print {
  	visibility:hidden!important;
  }
}