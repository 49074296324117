$color-landing-heading: #181C4D;
$color-landing-blue: #00C4C2;
$color-landing-green: #9ED14E;
$color-landing-purple: #B579DB;
$color-landing-gray: #F8F8FA;

.landing-info {
    position: relative;
    padding: 60px 0 100px;

    @include breakpoint(xlarge) {
        padding: 80px 0 130px;
    }

    &__content-wrap {
        text-align: center;
        padding: 0 22px 10px;
        font-size: 15px;
        position: relative;
        height: 100%;

        @include breakpoint(medium down) {
            padding: 0 0 5px;
            max-width: 300px;
            margin: 0 auto;
        }

        &::after {
            content: "";
            position: absolute;
            width: 86px;
            max-width: 100%;
            height: 2px;
            transform: translateX(-50%);
            left: 50%;
            bottom: 0;
            background-color: $color-landing-blue;
        }

        h3 {
            font-size: 18px;
            line-height: 1.27;
            font-weight: 600;
            margin-bottom: 6px;
            letter-spacing: -0.7px;
        }

    }

    &__inner-row {
        >div:nth-child(3n+2) {
            .landing-info__content-wrap {
                &:after {
                    background-color: $color-landing-green;
                }
            }
        }

        >div:nth-child(3n+3) {
            .landing-info__content-wrap {
                &:after {
                    background-color: $color-landing-purple;
                }
            }
        }

        >div:not(:last-child) {
            @include breakpoint(small down) {
                margin-bottom: 25px;
            }
        }
    }

    &__inner {
        max-width: 900px;
        margin: 0 auto;
    }

    &__bottom-images {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: -130px;
        pointer-events: none;
        margin-top: 30px;

        @include breakpoint(medium) {
            margin-bottom: -176px;
            margin-top: 0px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: -283px;
            margin-top: -120px;
        }

        position: relative;
        z-index: 4;

        >div {
            max-width: 33.33%;
        }
    }

    &__right-image {
        transform: translateY(18%);
    }
}

.landing-button-wrap {
    text-align: center;
    margin-top: 43px;

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 194px;
        font-size: 16px;
        max-width: 100%;

        .cf-button__text {
            width: auto;
        }

        .cf-button__icon {
            margin-left: 10px;
            transform: translateX(-5px) !important;
        }

        &:hover {
            padding-right:10px!important;
            .cf-button__icon {
                margin-left: 40px;
            }
        }
    }
}

.competence-career {
    background-color: $color-gray-background;
    position: relative;
    padding: 70px 0 30px;

    @include breakpoint(medium) {
        padding: 140px 0 50px;
    }

    @include breakpoint(large) {
        padding: 100px 0 50px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        background-color: $color-landing-green;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        margin-bottom: 25px;

        @include breakpoint(medium down) {
            margin-bottom: 15px;
        }

        * {
            color: $color-white;
            fill: $color-white;
        }
    }

    &__icon-box {
        @include breakpoint(medium down) {
            max-width: 300px;
            margin: 0 auto;
        }

        h3 {
            font-size: 18px;
            line-height: 1.27;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .landing-section-head {
        h2 {
            margin-bottom: 25px !important;

            @include breakpoint(xlarge) {
                margin-bottom: 72px !important;
            }
        }
    }

    &__bottom-img {
        justify-content: center;

        @include breakpoint(large) {
            margin-top: -80px;
        }

        @include breakpoint(small down) {
            img {
                max-width: 70%;
                margin: 0 auto;
                margin-top: 30px;
            }
        }
    }

    &__top-image {
        position: relative;
        z-index: 2;
        margin-bottom: 50px;

        @include breakpoint(xlarge) {
            margin-top: -50px;
            margin-bottom: 100px;
        }

        @include breakpoint(xxlarge) {
            margin-top: -100px;
        }

        img {
            max-width: 50%;
        }

        @include breakpoint(xxlarge) {
            img {
                margin-left: -35%;
            }
        }
    }

    &__checklist {
        align-items: flex-start;

        >div:first-child,
        >div:last-child {
            @include breakpoint(large) {
                margin-top: 120px;
            }
        }

        >div:nth-child(3) {
            padding: 0;
        }

        >div:not(:last-child) {
            @include breakpoint(medium down) {
                margin-bottom: 20px;
            }
        }
    }

    .custom-shape-divider-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        display: flex;

        svg {
            position: relative;
            display: block;
            width: calc(100% + 1.3px);
            height: 77px;

            @include breakpoint(medium down) {
                height: 30px;
            }
        }

        .shape-fill {
            fill: #FFFFFF;
        }
    }
}

.landing-section-head {
    max-width: 728px;
    margin: 0 auto;

    h1,
    h2 {
        margin-bottom: 47px;
        color: $color-landing-heading;
        letter-spacing: -0.7px;
        font-size: 35px;

        @include breakpoint(medium down) {
            font-size: 25px;
            margin-bottom: 30px;
        }
    }

}

.how-works {
    padding: 60px 0 64px;

    @include breakpoint(xlarge) {
        padding: 120px 0 104px;
    }


    .landing-section-head {
        h2 {
            margin-bottom: 50px !important;

            @include breakpoint(large down) {
                margin-bottom: 25px !important;
            }
        }
    }

    .homepagevideo {
        background: none;

        
        .videotext-block__video.centre {
            @include breakpoint(medium) {
                padding-left: 5rem;
                padding-right: 5rem;
            }
            @include breakpoint(large) {
                padding-left: 10rem;
                padding-right: 10rem;
            }
        }

        .homepage-background-image-class {
            background-position: center !important;
            
            iframe {
                z-index: 99;
            }
        }

        .videotext-block__video__container {
            display: block !important;
        }
    }

    .landing-button-wrap {
        margin-top: 20px;
    }
}

.shape-images {
    position: relative;

    &::before {
        content: '';
        width: 60px;
        height: 60px;
        transform: rotate(329deg);
        position: absolute;
        top: 40px;
        left: -5px;
        background-color: rgba($color: $color-landing-blue, $alpha: 0.18);

        @include breakpoint(xxlarge) {
            top: 80px;
            left: -37px;
        }

        @include breakpoint(large down) {
            width: 40px;
            height: 40px;
        }
    }

    &::after {
        content: '';
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: -14px;
        background-color: rgba($color: $color-landing-green, $alpha: 0.18);

        @include breakpoint(xxlarge) {
            top: 30px;
            right: -47px;
        }

        @include breakpoint(large down) {
            width: 55px;
            height: 55px;
        }
    }
}

.dote-bg-image {
    position: relative;

    &:before {
        content: '';
        background-image: url(/images/landing/dot-bg-gray.svg);
        position: absolute;
        background-repeat: repeat;
        top: -19px;
        bottom: -12px;
        right: 0;
        left: 0;
        z-index: -1;
        background-position: center;
        background-size: 45px;
    }
}

.square-gray-shap {
    &:after {
        content: '';
        width: 84px;
        height: 84px;
        transform: rotate(329deg);
        position: absolute;
        bottom: 30px;
        right: 50px;
        background-color: $color-landing-gray;
        z-index: -1;

        @include breakpoint(large down) {
            width: 45px;
            height: 45px;
        }
    }

}

.our-vision {
    padding: 60px 0 50px;

    @include breakpoint(xlarge) {
        padding: 136px 0 50px;
    }

    .dote-bg-image {
        &:before {
            top: -25px;
        }
    }


    .landing-section-head {
        h2 {
            margin-bottom: 50px !important;

            @include breakpoint(large down) {
                margin-bottom: 25px !important;
            }
        }
    }

    .homepagevideo {
        background: none;
        padding-bottom: 118px;

        @include breakpoint(large down) {
            padding-bottom: 65px;
        }

        @include breakpoint(medium down) {
            padding-bottom: 30px;
        }
        .videotext-block__video.centre {
            @include breakpoint(medium) {
                padding-left: 5rem;
                padding-right: 5rem;
            }
            @include breakpoint(large) {
                padding-left: 10rem;
                padding-right: 10rem;
            }
        }

        .homepage-background-image-class {
            background-position: center !important;

            iframe {
                z-index: 99;
            }
        }

        .videotext-block__video__container {
            display: block !important;
        }
    }
}

.career-choice {
    padding: 60px 0 0;
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 91.4%;
        background-color: #F8F8FA;
        z-index: -1;
    }

    @include breakpoint(small down) {
        padding-left: 17px;
        padding-right: 17px;
    }

    @include breakpoint(xlarge) {
        padding: 98px 0 0;
    }

    .bottom-space {
        @include breakpoint(medium down) {
            margin-bottom: 20px;
        }
    }

    .landing-section-head {
        h2 {
            margin-bottom: 89px !important;

            @include breakpoint(large down) {
                margin-bottom: 25px !important;
            }
        }
    }

    .incompany__possibilities__container {
        padding: 20px 20px 4px;
        box-shadow: 0px 3px 6px rgba(158, 209, 78, 0.16);
        border-radius: 20px;
        width: 100%;
        max-width: 460px;
        margin: auto;

        @include breakpoint(small down) {
            padding: 20px 20px 4px;
        }

        @include breakpoint(small) {
            padding: 28px 36px 14px;
        }

        @include breakpoint(large) {
            padding: 38px 46px 14px;
            max-width: 389px;
        }


        &--sky-blue {
            border-color: #00C4C2;
        }

        &--light-green {
            border-color: #9ED14E;
        }

        &--purple {
            border-color: #B579DB;
        }

        &__top {
            height: 100%;
            display: flex;
            flex-direction: column;

            &__header {
                padding: 0 0 20px;
                border-bottom: none;
                flex-grow: 1;
            }

            &__body {
                padding: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__image {
                    img {
                        max-height: 140px;
                        margin: 0 auto 18px;
                        display: block;
                        object-fit: contain;

                        @include breakpoint(medium) {
                            max-height: 180px;
                        }
                    }
                }
            }
        }

        p {
            font-size: 15px;
        }

    }

    .career-choice-content {
        margin-top: 75px;

        @include breakpoint(small down) {
            margin-top: 40px;
        }

        @include breakpoint(medium) {
            margin-top: 115px;
        }

        .landing-section-head {
            h2 {
                margin-bottom: 16px !important;
            }
        }

        .career-choice-content--image {
            width: 100%;
            max-width: 340px;
            margin: 30px auto 0;

            @include breakpoint(medium) {
                max-width: 465px;
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .landing-button-wrap {
            margin-top: 28px;
        }
    }
}

.c-cta-block__intake {
    &>.row {
        max-width: 97.25rem;
    }
}