.start-why {
  display: flex;
  height: 170px;
  align-items: center;
  padding: 0 10px;

  .starty-why__item {
    flex-grow: 1;
    font-size: 15px;
    @include raleway;
    font-weight: 500;
    color: $color-purple;

    i {
      position: relative;
      top: 2px;
      font-size: 110%;
      margin-right: 5px;
    }
  }
}