.goodtoknow {
  background: #dcdcdc;
  min-height: 6rem;
  padding: 1.5rem 0;

  @include breakpoint(medium) {
    padding: 3rem 0;
  }

  &__title {
    color: $color-header;
    font-size: 1.7rem;
  }

  &__large-items {
    margin-top: 1rem;
    border-bottom: 2px solid $color-dark-primary;
    @include breakpoint(medium) {
      margin-top: 2rem;
    }
    @include breakpoint(large) {
      border: none
    }
  }

  &__small-items {
    margin-top: 1rem;

    @include breakpoint(medium) {
      margin-top: 2rem;
    }

    @include breakpoint(large) {
    }

    @include breakpoint(xlarge) {
      margin-top: 4rem;
    }
  }
}