.footer {
  border-top:1px solid $color-black;
  background: #fff;
  text-align: center;
  margin-bottom: -32px;
	@media print {
    	padding-top:0px;
    }
    
  &--admin {
    border-top: 10px solid $color-line;
    margin-top: 10px;
    @include breakpoint(large) {
      border-top: 50px solid $color-dark-primary;
      margin-top: 30px;
    }
  }
  &__follow{
  	padding-top: 1rem;
  	padding-bottom: 1rem;
  	&__logo{
  		img{
  			width:240px;
  		}
  	}
  	&__items{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		@include breakpoint(medium) {
		  flex-direction: row;
		}
	
		@media print {
			display:none;
		}
  	}
  	&__text{
		margin-right: 1rem;
		min-width: 25%;
		@include raleway;
		font-size: rem-calc(17);
		font-weight: bold;
		color: $color-black;
		line-height: 2.3;
		margin-top: 0;
  	
  	}
  	&__socials{
		margin-right: 1rem;
		min-width: 25%;
		font-size: rem-calc(26);
		line-height: 2.7;
  		a{
  			display:inline-block;
  			margin-right:10px;
  		}
  	}
  	&__reviews{
		margin-top: 0.5rem;
		margin-right: 1rem;
		min-width: 25%;
  	
  	}
  }
  &__middle{
  	padding-top:4rem;
  	padding-bottom:3rem;
  	background: $color-business-blue;
  	
	  a {
		color: $color-white;
	  }
  }
  @include breakpoint(medium) {
    text-align: left;
  }
  .__fbcw__bar-widget__content{
  	margin: 0 auto;
  }
  &__menus {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }
    
    @media print {
    	display:none;
    }
  }

  &__menu {
    margin-right: 1rem;
    min-width: 25%;
  }

  &__menu-nav-title {
    @include raleway;
    font-size: rem-calc(17);
    font-weight: bold;
    color: $color-white;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: .5rem;
  }

  &__menu-nav-list {
    li {
      a {
    	color: $color-white;
      }
    }
    margin-bottom:2rem;
  }

  &__contact {
    margin: 1rem 0 0;
    @include raleway;
    font-size: rem-calc(21);
    font-weight: bold;

    @include breakpoint(xlarge) {
      margin: 0;
      width: 100%;
      display: flex;
    }

    a {
      text-decoration: none;
    }
  }
  
  &__nieuwsbrief{
    margin-top:2rem;
    margin-bottom:2rem;
  	&__titel{
		color: $color-white;
		font-size: rem-calc(17);
		@include raleway;
		font-weight: bold;
		line-height: 1.4;
		margin-top: 0;
		margin-bottom: .5rem;
  	}
  	&__text{
		color: $color-white;
  	}
  	&__form{
		display:flex;
  		input {
			margin-bottom:15px;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			padding:0.65em;
			width:50%;
    		@include breakpoint(medium) {
    			width:75%;
    			display:block;
    		}
    		@include breakpoint(large) {
    			width:75%;
    			display:block; 
    		}
    		@include breakpoint(xlarge) {
    			width:75%; 
    		}
			display:inline-block!important;
		 }
		 input::placeholder{
			color:$color-darker-gray;
		 }
		 button {
			margin-bottom: 15px !important;
			display:inline-block!important;
			margin-bottom: 3px;
			border-radius: 0px !important;
			border-top-right-radius: 5px !important;
			border-bottom-right-radius: 5px !important;
			width: 40% !important;
			.cf-button__text{
				margin: -2px 15px 0 15px!important;
			}
			@include breakpoint(medium) {
				width: inherit !important;
    		}
		 }
	  }
	  @media print {
		display:none!important;
	  }
  }
  
  &__opleidingsgids{
  	text-align:center;
  	&__hoekje{
		width: 0px;
		height: 0px;
		border-top: solid 20px transparent;
		border-right: solid 10px white;
		border-bottom: solid 10px white;
		border-left: solid 5px transparent;
		margin-top: 20%;
		margin-right:-5px;
		display:inline-block;
    	vertical-align: bottom;
  	}
  	&__tekst{
  		text-align:left;
  		width:80%;
  		@include breakpoint(medium) {
			width:60%;
		}
		@include breakpoint(large) {
			width:80%; 
		}
  		background:white;
  		padding:20px;
  		display:inline-block;
  		&__titel{
			font-size: rem-calc(17);
			@include raleway;
			font-weight: bold;
			line-height: 1.4;
			margin-top: 0;
			margin-bottom: .5rem;
		}
		&__text{
		}
  		img {
  			width:40%;
  			margin:0 auto;
  			margin-bottom:10px;
  		}
  	}
  }

  &__locaties {
    margin: 2rem 0 0;
    flex-direction: column;
    @include breakpoint(medium) {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }

  }

  &__locatie {
    margin-right: 1rem;
    min-width: 25%;
  }

  &__locatie-titel {
    color: $color-white;
    font-size: rem-calc(17);
    @include raleway;
    font-weight: bold;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: .5rem;
  }

  &__locatie-text {
    color: $color-darker-gray;
    a {
    	text-decoration:none;
    	font-size: rem-calc(17);
    	.icon {
    		text-align:center;
    		display:inline-block;
    		width:20px;
    		margin-right:5px;
			color:white;
			svg path { fill:white; }
    	}
    }
  }
  &--stripped{
  	.footer__locatie-titel, .footer__nieuwsbrief__titel, .footer__menu-nav-title{
  		font-size:rem-calc(25px);
  	}
    @include breakpoint(large) {
		.footer__nieuwsbrief__form input {
			width: 65%;
		}
	}
  }
}
.copyright {
  background: $color-business-blue;
  &__row {
  	&__text {
    	padding: 1rem 0;
    	color: #fff;
    	background: $color-business-blue;
    	margin-top: 2rem;
    	text-align: center;
    	margin-bottom: 0;

    	a {
      		color: inherit;
      		text-decoration: underline;
    	}
    	&__aftercopyright {
  			@media print {
  				display:none;
  			}
  		}
  	}
  	&__menu {
  		margin:0px;
  		padding: 1rem 0;
  		li{text-align:center;}
    	@include breakpoint(medium) {
			justify-content:flex-end;
  			li{text-align:left;}
			display:flex;
			gap:15px;
  		}
  	}
  }
  &--stripped{
  	.copyright__row__text {
    	@include breakpoint(medium) {
  		text-align:left;
  		}
  		margin:0;
  	}
  }
}
.magazine-pro-footer{
	background-color:white;
	padding: 0px 20px;
	border-radius: 16px;
	height: 100%;
	display: grid;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	grid-template-rows: 1fr 1fr;
	&__boximg{
		height: 100%;
		position: relative;	
		&__img{
			height: 100%;
			position: absolute;
			width: initial;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			top: -20px;
			transform: rotate(-7deg);	
		}
	}
	&__textbox{
		display: grid;
		align-content: center;
		&__text{
			text-align: center;
			font-size:16px;
			color:$color-business-blue;
		}
		&__button{
			text-align: center;
			margin-bottom: 20px !important;
		}
	}
}