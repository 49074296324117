.sidebar-block {
  background: white;
  color: $color-business-blue;
  padding: 15px;
  margin: 0 0 1px;

  &__title {
    @include open-sans;
    font-weight: bold;
    font-size: rem-calc(23px);
  	color: $color-business-blue;
  }

  &__text {
    font-size: rem-calc(17px);
    font-weight: 600;
  	color: $color-business-blue;
  }

  &--light {
    background: $sidebar-block-background;
  	@include breakpoint(large) {
		border-radius: 8px;
	}
    .sidebar-block {
      &__title {
    	font-size: rem-calc(20px);
        color: rgba(255,255,255,1);
      }
      &__text {
    	font-size: rem-calc(16px);
    	font-weight: normal;
  		color: $white;
  	  }
    }
  }

  &--grey {
    background: $color-medium-gray;

    .sidebar-block {
      &__title {
        color: $color-dark-primary;
      }
    }
  }

  @include breakpoint(large) {
    padding: 20px;
  }
}