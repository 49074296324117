.start-overview {
	padding: 1rem 0;
	padding-bottom:3rem;
	background: $color-ultralight-gray;
	&__payoff {
		@include header-text;
		//background: $payoff-background;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem-calc(27px);
		margin: 0 0.625rem;
		padding: 0.625rem;

		@include breakpoint(medium) {
			font-size: rem-calc(27px);
			height: 100px;
			margin: 0;
			margin-bottom: 2rem;
			padding: 0;
		}
		@include breakpoint(large) {
			font-size: rem-calc(29px);
			height: 120px;
		}

		&-text {
			@include header-text;
			text-align: center;
			color: $color-business-blue;
			font-weight: 600;
			line-height: 1.25;
			margin: 0;

			&-link {
				text-decoration: underline;
				color: $color-orange;
				font-weight: 600;
				@include transition(color 500ms);

				&:hover {
					color: #e0e0e0;
				}
			}
		}

	}

	&__row{
		max-width: 100rem;
		display: grid;
		max-width: 100rem;
		padding-left: 20px;
		padding-right: 20px;
		grid-template-areas:
		"b"
		"c"
		"a";

		@include breakpoint(large) {
			grid-template-columns: 2.5fr 2fr 2fr;
			grid-template-rows:auto;
			grid-template-areas:
			"a b c";
		}

		&__Stapuitleg{
			box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
			border: 1px solid $color-business-blue;
			border-radius: 12px;
			margin-right:0px;
			max-height:100%;
			grid-area: a;
			margin-top:20px;
			@include breakpoint(large) {
				margin-right:50px;
				margin-top:0px;
			}
			&__text{
				margin-left: 20px;
				margin-right: 20px;
				color: $color-business-blue;
				font-size:16px;

				@include breakpoint(large) {
					margin-left: 50px;
					margin-right: 50px;
				}
			}
			&__title{
				margin-top: 50px;
				margin-left: 20px;
				margin-right: 20px;
				color: $color-business-blue;
				font-size:25px;
				font-family: 'Lexend', sans-serif;
				font-weight:800;
				
				
				@include breakpoint(large) {
					margin-left: 50px;
					margin-right: 50px;
				}
			}
			&__under{
				height:315px;
				display:grid;
				grid-template-columns: 1fr 1fr;

				&__imageblok{
					width:100%;
					height:315px;
					&__image{
						height: 100%;
						object-fit: cover;
						float:right;
					}
				}
				&__knoppen{
					display: flex;
					flex-direction: column-reverse;
					align-items: center;

					&__box{
						display: flex;
						flex-direction: column-reverse;
						width: fit-content;
						margin-bottom: 20px;

						&__Herinner{
							display:flex;
							justify-content: space-between;
							align-items: center;
							font-weight:bold;
							text-decoration: none;
							padding: 10px 0px;
							font-size:16px;
							&__icon{
								filter: invert(65%) sepia(61%) saturate(684%) hue-rotate(341deg) brightness(101%) contrast(103%);
								margin-left: 20px;
							}
						}
						&__leesmeer{
							display:flex;
							justify-content: space-between;
							align-items: center;
							font-weight:bold;
							text-decoration: none;
							padding: 10px 0px;
							border-bottom: 2px solid #E3E3E3;
							font-size:16px;
							&__icon{
								color:#FFA120;
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
		&__row1{
			grid-area: b;
		}
		&__row2{
			grid-area: c;
		}

		
	}
	&__faq {
		padding-top: 30px;
		text-align: center;
		a {
			font-size: 13px;
			color: #bbb;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	&--category{
		background: white;
		//background-size:100px;
		#bigHalfCircle {
			position:absolute;
			margin-top:400px;
		}
		#bigHalfCircle path {
			fill: $color-lighter-gray;
			stroke: $color-lighter-gray;
		}
		.start-overview{
			&__background {
				height:400px;
				width:100%;
				background: $color-lighter-gray;
				position:absolute;
			}
			&__payoff{
				display:block;
				margin:0;
				padding:1.8rem 0;
				padding-top:4rem;
				height:auto;
				position:relative;
				z-index:1;
				&__text{
					display:block;
					text-align: center;
					color: $color-business-blue;
					font-weight: 800;
					line-height: 1.25;
					font-size:1.6rem; 
					margin-bottom:20px;
					text-transform:uppercase;
				}
				&__subtext{
					display:block;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					font-size:1.4rem; 
					color: $color-black;
					
				
				}
			}
		}
		.start-subsite {
			background: white;
			border-radius: 8px;
			box-shadow: 0 0 10px 0 rgba(131,131,131,0.83);
			border: 1px solid #F1F1F1;
			min-height:415px;
			margin-right:38px;
			padding:0;
			background-image:url('/images/placeholder.png');
			background-repeat:no-repeat;
			background-size:100% 120px;
			/* Big half circle */

			&__row {
				padding:0px 15px;
				position:relative;
				z-index:1;
				
				@include breakpoint(medium) {
					padding:0px;
				}
			}
			&:last-of-type{
				margin-right:0px;
			}
			&__photo{
				
			}
			&__container{
				padding-right: 0.9375rem;
    			padding-left: 0.9375rem;
    			padding-top:85px;
			}
			&__icon{
				border-radius:50%;
				height: 69px;	
				width: 69px;
				border: 1px solid #FFFFFF;	
				background-color: white;	
				box-shadow: 0 2px 6px 0 rgba(0,0,0,0.32);
				background-repeat:no-repeat;
				background-position:50% 50%;
				display:inline-block;
				&__container{
					text-align:center;
					height:70px;
				}
			}
			&__title {
				text-align:center;
				@include raleway;
				color:$color-business-blue-normal;
				font-weight: 800;
				font-size:1.2rem;
				height:auto; 
				margin-top:15px;
			}
			&__text {
				text-align:center;
				color:$color-business-blue-normal;
				min-height: 120px;
			}
			
			&--business-school{
				background-image:url('/images/businessschool-background-120px.jpg')!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:url('/images/icons/targeting.png');
				}
				
			}
			&--business-school.lazy{
				background-image:none!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:none;
				}
				
			}
			&--factory{
				background-image:url('/images/digitaldesign-background-120px.jpg')!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:url('/images/icons/layers.png');
				}
			}
			&--factory.lazy{
				background-image:none!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:none;
				}
			}
			&--ux{
				background-image:url('/images/ux-background-120px.jpg')!important;
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:url('/images/icons/usability.png');
				}
				
			}
			&--ux.lazy{
				background-image:none!important;
				.start-subsite__photo{
				}
				.start-subsite__icon{
				 	background-image:none;
				}
				
			}
			&--utrecht{
				background-image:url('/images/utrecht-background-120px.jpg')!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				}
				
			}
			&--utrecht.lazy{
				background-image:none!important;
				
				.start-subsite__photo{
				}
				.start-subsite__icon{
				}
				
			}
		}
		
	}
	
	&--top10{
		background: white;
		.start-overview{
			&__row{
				padding-left:2rem;
				padding-right:2rem;
			}
			&__payoff{
				display:block;
				margin:0;
				padding:1.8rem 0;
				padding-top:4rem;
				height:auto;
				&__text{
					display:block;
					text-align: start;
					font-weight: 800;
					line-height: 1.25;
					color: $color-business-blue;
					font-size:25px;
					max-width:100%;
					margin:0 auto;
					margin-bottom:35px;
					padding-left: 20px;
					padding-right: 20px;
					@include header-text;

					@include breakpoint(large) {
						max-width:50%;
						font-size:42px; 
						text-align: center;
						padding-left: 0px;
						padding-right: 0px;
					}
				}
				&__smalltext{
					font-size: 16px;
					text-align: start;
					letter-spacing: 0px;
					padding-left: 20px;
					padding-right: 20px;

					@include breakpoint(large) {
						text-align: center;
						padding-left: 0px;
						padding-right: 0px;
					}
				}
				&__subtext{
					display:block;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					font-size:1.4rem; 
					color: $color-black;
					
				
				}
			}
			&__displaymore{
				text-align:center;
				margin-bottom:30px;
				& a {
					text-decoration:none;
					&:hover, &:active, &:focus {
						@include meerweergeven-button-hover;
					}
					& span {
						display:block; 
						margin-top:10px;
						-ms-transform: rotate(90deg); /* IE 9 */
  						-webkit-transform: rotate(90deg); /* Safari */
  						transform: rotate(90deg);
					}
				}
			}
			&__secondrow{
				margin-bottom:40px;
			}
			&__button{
				margin-top:40px;
				text-align:center;
				margin-bottom:40px;
			}
		}
		
	}
}