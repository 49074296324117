ul.ui-autocomplete{
	@media screen and (max-width: 769px) {
	width:95%;
	left:2.5%!important;
	}
}
ul.ui-autocomplete.ui-widget.ui-widget-content{
	padding:10px 20px;
  	box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
  	border-radius: 8px;
  	border:none;
}
.search-form {
  position: relative;
  height: 40px;
  right: 0;
  width:100%;
  transition: all 400ms;
	@include breakpoint(large){
		//position:absolute;
	}
  &__input {
    font-weight: regular;
    //position: absolute;
    left: 0;
    top: 0;
    @include raleway;
    width: 100%;
    background: white;
    border: 1px solid $color-blue;
    color: $color-blue;
    border-radius: 40px;
    box-shadow: none;
    padding-left: 40px;
    font-size: 0.8rem;
    height: 40px;
    transition: all 400ms;
    &:focus{
    	border: 1px solid $color-blue!important;
      box-shadow: none;
    }
  }
  
  &__input::placeholder {
    color: $color-business-blue;
  }

  &__placeholder {
    color: $color-business-blue;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    @include breakpoint(small only) {
      width: 85%;
      text-align: center;
    }

    strong {
      font-weight: inherit;
      @include breakpoint(medium) {
        color: $color-header;
      }
    }
  }

  &__button {
    position: absolute;
    left: 3px;
    top: 2.5px;
    width: 35px;
    height: 35px;
    //background:$color-orange;
    //border-radius:100%;
    color:$color-business-blue;
    //background-size: 100% 100%;
    img{
      width:15px!important;
      height:15px!important;
      filter: invert(7%) sepia(36%) saturate(6641%) hue-rotate(235deg) brightness(99%) contrast(91%)!important;
    }
  }
  &__clear {
    position: absolute;
    right: 3px;
    top: 2.5px;
    width: 35px;
    height: 35px;
    //background:$color-orange;
    //border-radius:100%;
    color:$color-business-blue;
    //background-size: 100% 100%;
    img{
      filter:invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
    }
  }
  &__autocomplete{
  	&__subtitle{
  		color:$color-blue;
  		font-size:rem-calc(14px);
  		margin-bottom:10px;
  		@include header-text;
  	}
  	&__suggestion{
  		.title{
  		
  		}
  		.type{
  			display:inline-block;
  			margin-left:12px;
  			color:rgba(30,34,92,0.501);
  			font-size:rem-calc(15px);
  			&:before{
  				content:"\2022";
  				margin-right:10px;
  				color:$color-blue;
  			}
  		}
      .ui-menu-item-wrapper{
        transition: background-color 400ms;
        border:none;
      }
      .ui-menu-item-wrapper.ui-state-active{
        background-color: #F7F7F7;
        border:1px solid #F7F7F7;
        border-radius:4px;
        .title{
          color:$color-business-blue;
        }

      }
      &:hover,&:active{
        .ui-menu-item-wrapper{
          background-color: #F7F7F7;
          border:1px solid #F7F7F7;
          border-radius:4px;
        }

        .title{
          color:$color-business-blue;
        }
      }
  	}
  	&__button{
  		border-top: 1px solid #F0F0F0;
  		padding-top:10px;
  		margin-top:10px;
  		color:inherit!important;
  		a,button {
  			color:inherit!important;
  			width:100%!important;
  			display:inline-flex!important;
  			justify-content: space-between!important;
  		}
  	}
  }
  &--start {
    height: inherit;

    .search-form {
      &__input {
        height: 41px;
        padding-left:20px;
        position:relative;
    	width: 100%;
    	border: 0px solid $color-orange;
    	border-radius: 5px;
    	display: inline-block;
    	margin:0;
    	@include breakpoint(small only) {
    		display:block;
    		margin-bottom:15px;
    	}
    	@include breakpoint(medium) {
    		margin-bottom:15px;
    	}
    	@include breakpoint(large) {
    		width: 75%;
    		margin-bottom:0px;
    	}
      }

      &__placeholder {
        height: 40px;
        font-weight: 400;
        font-style: italic;
        line-height: 40px;
        padding-left: 20px;
        @include breakpoint(small only) {
        	display:none;
        }
      }
	  
	  &__submitbutton {
         display: inline-block;
         .cf-button__text{
         	margin: -2px 15px 0 15px;
         }
    	 @include breakpoint(small only and medium only) {
    		width:100%;
    	 }
    	 @include breakpoint(large) {
    	 	width:24%;
    	 }
      }
      
      &__button {
        display:none;  
      }
    }
  }
}