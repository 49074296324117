.blog {
  padding: 1.5rem 1.1rem;

  @include breakpoint(medium) {
    padding: 3rem 1.1rem;
  }

  &__title {
    font-size: 2rem;
    text-align:center; 
    @include breakpoint(medium up) {
      font-size: 39px;
    }
  }

  &__large-items {
    margin-top: 1rem;;
    @include breakpoint(medium) {
      margin-top: 2rem;
    }
    @include breakpoint(large) {
      border: none
    }
    & > .row{
  		row-gap:20px;
		@include breakpoint(medium) {
		 row-gap:0px;
		}
    }
  }

  &__small-items {
    margin-top: 1rem;

    @include breakpoint(medium) {
      margin-top: 2rem;
    }

    @include breakpoint(large) {
    }

    @include breakpoint(xlarge) {
      margin-top: 2rem;
    }
    &__container{
		box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
		background:white;
		border-radius:8px;
		padding:30px;
		height:100%;
    }
    &__title{
    	font-size:1.4rem;
    	font-weight:500;
    }
  }
}

blockquote {
	color:black;
	padding: 0.5625rem 1.25rem 0 2rem;
	border-left:0px;
	position:relative;
	margin-bottom:30px;
  border-left: 1px solid $color-business-blue;
  margin-top:3rem;
}

blockquote p {
	color:black;
}

blockquote::before{
	content: "";
	font-weight: bold;
	color: #1e225d;
	position: absolute;
	margin-left: -35px;
	margin-top: -40px;
	font-family: Georgia, serif;
  height:25px;
  width:25px;
  background-image:  url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuMDkyIDYuODYzYy0xLjUwNCAyLjMxLTEuNzc5IDQuNDUtMS42ODEgNS42ODggNi4xMzItLjEwMSA1LjY5NiA2LjQ0OSAxLjM5IDYuNDQ5LTEuODMgMC0zLjgwMS0xLjMzOC0zLjgwMS00LjI3NSAwLTIuNzI0IDEuNDEyLTUuODQ1IDQuMDkyLTcuODYyem0tMTMgMGMtMS41MDQgMi4zMS0xLjc3OSA0LjQ1LTEuNjgxIDUuNjg4IDYuMTMyLS4xMDEgNS42OTYgNi40NDkgMS4zOSA2LjQ0OS0xLjgzIDAtMy44MDEtMS4zMzgtMy44MDEtNC4yNzUgMC0yLjcyNCAxLjQxMi01Ljg0NSA0LjA5Mi03Ljg2MnptMTYuOTA4LTMuODYzYy02LjEwOCAxLjIwNi0xMCA2LjU4NC0xMCAxMS43MjUgMCAzLjk3IDIuNzg2IDYuMjc1IDUuODAxIDYuMjc1IDIuNjE1IDAgNS4xOTktMS43OTcgNS4xOTktNC45NzkgMC0yLjYwMS0xLjkwNS00Ljc1Ny00LjM5Ni01LjE0OS4yMTctMi4wMDQgMi4xNjUtNC45MTEgNC4zOC01Ljc0NmwtLjk4NC0yLjEyNnptLTEzIDBjLTYuMTA4IDEuMjA2LTEwIDYuNTg0LTEwIDExLjcyNSAwIDMuOTcgMi43ODYgNi4yNzUgNS44MDEgNi4yNzUgMi42MTUgMCA1LjE5OS0xLjc5NyA1LjE5OS00Ljk3OSAwLTIuNjAxLTEuOTA1LTQuNzU3LTQuMzk2LTUuMTQ5LjIxNy0yLjAwNCAyLjE2NS00LjkxMSA0LjM4LTUuNzQ2bC0uOTg0LTIuMTI2eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  filter: invert(17%) sepia(44%) saturate(1161%) hue-rotate(197deg) brightness(94%) contrast(105%);
  background-size: cover;
}
/*
.quoteIconErvaring{
  margin-bottom:0.5rem;
  filter: invert(17%) sepia(44%) saturate(1161%) hue-rotate(197deg) brightness(94%) contrast(105%);
}
.ervaringtekst{
  border-left: 1px solid $color-business-blue;
  padding-left: 40px;
}
<svg xmlns="http://www.w3.org/2000/svg" class="quoteIconErvaring" width="20" height="20" viewBox="0 0 24 24"><path d="M19.092 6.863c-1.504 2.31-1.779 4.45-1.681 5.688 6.132-.101 5.696 6.449 1.39 6.449-1.83 0-3.801-1.338-3.801-4.275 0-2.724 1.412-5.845 4.092-7.862zm-13 0c-1.504 2.31-1.779 4.45-1.681 5.688 6.132-.101 5.696 6.449 1.39 6.449-1.83 0-3.801-1.338-3.801-4.275 0-2.724 1.412-5.845 4.092-7.862zm16.908-3.863c-6.108 1.206-10 6.584-10 11.725 0 3.97 2.786 6.275 5.801 6.275 2.615 0 5.199-1.797 5.199-4.979 0-2.601-1.905-4.757-4.396-5.149.217-2.004 2.165-4.911 4.38-5.746l-.984-2.126zm-13 0c-6.108 1.206-10 6.584-10 11.725 0 3.97 2.786 6.275 5.801 6.275 2.615 0 5.199-1.797 5.199-4.979 0-2.601-1.905-4.757-4.396-5.149.217-2.004 2.165-4.911 4.38-5.746l-.984-2.126z"/></svg>
*/
.blog-knop{
  margin: auto;
  margin-top:50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom:20px;
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  @include breakpoint(medium) {
    margin-bottom:0px;
    margin-right: auto;
    margin-left: auto;
  }
  &:hover, &:active{
    padding-left: 50px;
    padding-right: 50px;
  }

}