.evenementform {
	margin-bottom:50px;
}
.evenement-aanmelden {
  $padding: 20px;
  padding: $padding;

  &__titel {
    @include raleway;
    font-size: rem-calc(25);
    color: white;
    font-weight: 800;
    margin: 0;
    text-align:center;
  }

  &__text {
    color: white;
    text-align:center;
    margin-bottom:20px;
  }

  &__form {
	 input {
	 	margin-bottom:15px;
	 	border-radius:5px;
	 	padding:0.65em;
	 	@include breakpoint(medium) {
	 		width:39%;
	 		display:inline-block;
	 		margin-bottom:0px;
	 	}
	 }
	 input::placeholder{
	 	color:$color-darker-gray;
	 }
	 button {
	 	width:100%;
	 	@include breakpoint(medium) {
	 		width:20%;
	 		display:inline-block;
	 		margin-bottom: 3px;
	 	}
	 	.cf-button__text{
	 		margin: -2px 15px 0 15px;
	 	}
	 }
  }
  @media print {
  	display:none!important;
  }
}

.webinar-aanmelden {
  $padding: 20px;
  padding: $padding;

  &__titel {
    @include raleway;
    font-size: rem-calc(25);
    color: white;
    font-weight: 800;
    margin: 0;
    text-align:center;
  }

  &__text {
    color: white;
    text-align:center;
    margin-bottom:20px;
  }

  &__form {
	 input, select {
	 	margin-bottom:15px;
	 	border-radius:5px;
	 	padding:0.65em;
	 	@include breakpoint(medium) {
	 		width:27%;
	 		display:inline-block;
	 		margin-bottom:0px;
	 	}
	 }
	 select{
	 	line-height: 1.15;
	 	background-image:
		linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc);
	  background-position:
		calc(100% - 20px) calc(0.4em + 2px),
		calc(100% - 15px) calc(0.4em + 2px),
		calc(100% - 2.2em) 0em;
	  background-size:
		5px 5px,
		5px 5px,
		1px 1.5em;
	  background-repeat: no-repeat;
	 	@include breakpoint(medium) {
	 		margin-top:3px;
	 	}
	 
	 }
	 input::placeholder{
	 	color:$color-darker-gray;
	 }
	 button {
	 	width:100%;
	 	padding-top:2px!important;
	 	@include breakpoint(medium) {
	 		width:30%;
	 		display:inline-block;
	 		margin-top: 10px;
	 		margin-bottom: 3px;
	 	}
	 	.cf-button__text{
	 		padding-top:3px;
	 		margin: -2px 15px 0 15px;
	 	}
	 }
  }
  @media print {
  	display:none!important;
  }
}