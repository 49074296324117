.prijs-sticker {
  @include raleway;
  font-size: rem-calc(21);
  font-weight: bold;
  width: 60px;
  height: 60px;
  background: $color-orange;
  border-radius: 30px;
  display: block;
  line-height: 55px;
  text-align: center;
  border: 2px solid white;
  color: white;

  &--star {
    background: url(../images/factory/sticker.png) no-repeat left px;
    background-size: 100% 100%;
    border: none;
    width: 68px;
    height: 70px;
    background: $color-orange;
    border-radius: 35px;
    display: block;
    line-height: 70px;
  }
}