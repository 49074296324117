.category-section {
	
	&__container{
		margin:75px 0px;
		position: relative;
		@include breakpoint(medium) {
			margin:150px 0px;
			margin-top:100px;
		}
		&::before{
			content: "";
			background-image: url(/images/dot-bgv5.svg);
			height: 40%;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			top: 90px;
			bottom: auto;
			right: 0px;
			z-index: 2;
			background-position: center;
			background-size: 50px;
			@include breakpoint(medium){
				bottom: 0px;
				right: 10%;
				height: 40%;
				width: 20%;
				top:200px;
				background-size: 60px;
			}
		}

		&::after{
			content: "";
			background-image: url(/images/dot-bgv5.svg);
			height: 40%;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			top: auto;
			bottom: -30px;
			right: auto;
			z-index: 1;
			background-position: center;
			background-size: 50px;
			left:0;
			@include breakpoint(medium){
				bottom: 230px;
				right: 0px;
				left:10%;
				height: 40%;
				width: 20%;
				background-size: 60px;
			}
		}

		&__text{
			&__title{
				@include header-text;
				font-size:25px;
				font-weight:800;
				text-align:center;
				letter-spacing: 0;
				&.align-left{
					text-align:left;
				}
				width:60%;
				margin:0 auto;
				margin-bottom:35px;
				@include breakpoint(medium){
					width:80%;
				}
				@include breakpoint(large){
					width: 600px;
					font-size:35px;
				}

				&__mobile{
					color:$color-business-blue;
					font-size:20px;
					font-family: 'Lexend', sans-serif;
					//padding-left: 0.625rem;
					letter-spacing: 0px;
					font-weight:600;
					span{
						color: #77799c;
					}
				}
			}
			&__subtitle{
				font-size:1.125rem;
				text-align:center;
				max-width: 60%; margin: 0 auto;
				@include breakpoint(medium){
					max-width: 50%;
				}
				&.align-left{
					text-align:left;
					margin:0;
				}
				&.callout{
					display: inline-block;
   					margin-top: 10px;
   					max-width:unset;
					border: 1px solid $color-blue;
					border-radius: 4px;	
					padding:5px 17px;
					background:transparent;
					font-size:rem-calc(16px);
					img{ margin-right: 15px; filter:invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);}
				}
				&__mobile{
					color:$color-business-blue;
					font-size:16px;
					padding-left: 0.625rem;
				}
			}
		}
		
		&__categories{
			margin-top:30px;
			position:relative;
			@include breakpoint(large){
				margin-top:75px;
			}
			&__background{
				z-index: -1;
				&::before{
					content: "";
					//background-image: url(/images/dot-bgv2.png);
					height: 100%;
					width: 50%;
					position: absolute;
					background-repeat: repeat;
					top: 40px;
					left: 0px;
					z-index: -1;
					background-position: center;
					background-size: unset;
				}
			}
			& .row {
				max-width:100rem;
				padding:0;
				@include breakpoint(medium up){
					padding-left: 1rem;
					padding-right: 1rem;
					margin: 0px;
				}
			}
			&__card{
				border:none;
				margin-bottom:15px;
				@include breakpoint(medium){
					margin-bottom:2rem;
					padding-left:1rem;
					padding-right:1rem;
				}
				&__container{
					background-color:#fff;
					box-shadow: 6px 6px 30px rgba(157,171,192,0.2);
					//border-radius: 8px;
					//border:1px solid $color-business-blue;
					position:relative;
					padding:20px;
					z-index:2;
					text-decoration:none;
					display:flex;
					height:100%;
					justify-content: space-between;
					align-items:center;
					border-radius: 16px;	
					@include breakpoint(medium){
						//box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
						padding:30px;
						border-radius: 16px;	
						display:block;
					}
					&:hover{
						box-shadow: 6px 6px 30px rgba(157,171,192,0.2);
						//box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
							@include transition(all 400ms);
						.category-section__container__categories__card__title{
							color:$color-orange;
							@include transition(all 400ms);
						}
						.category-section__container__categories__card__link > .cf-button--orange-link{
							@include cf-button-orange-link-hover;
						}
						.category-section__container__categories__card__title__arrow{
							background-color:$color-business-blue;
							color: white;
							path{
								fill:white!important;
								//filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
							}
						}
						
					}
				}
				&__help{
					width: 100%;
					text-align: center;
					margin-top:20px;
					margin-bottom: 52px;
					font-size:18px;
					strong{
						font-weight:500!important;
					}

				}
				&__title {
					font-size:1.1rem;
					margin-bottom:0px;
					color: #1E225C;
					font-weight:600;
					display: grid;
					align-items: center;
					grid-template-columns: 9fr 1fr;
					@include breakpoint(medium){
						font-size:20px;
						margin-bottom:10px;
						font-weight:800;
					}

					&__arrow{
						width: 40px !important;
						height: 40px;
						padding: 10px;
						border-radius: 100px;
						border:1px solid $color-business-blue;
						background-color:white;
					}
				}
				&__text{
					font-size: 1rem;
					margin-bottom:0;
					@include breakpoint(large){
						padding-right:30px;

					}
				}
				&__link {
					font-size: 1rem;
				}
				&__icon{
					position:absolute;
					top:10px;
					right:10px;
					width: 40px;
					@include breakpoint(large){
						width: 50px;
					}
				}
				&__mobile-link{
					display:inline-block;
					//width:15%;
					font-size: rem-calc(16px);
					& > div {
    					line-height:30px;
						border-radius:50%;
						//background:$color-orange;
						color:$color-business-blue;
						width:30px;
						height:30px;
						vertical-align:center;
						text-align:center;
					}
				}
				&--alt{
					margin-bottom:1rem;
					@include breakpoint(medium){
							padding-left:1rem;
							padding-right:1rem;
					}
					.category-section__container__categories__card__container{
						background:#1E225C; 
					}
					.category-section__container__categories__card__title {
						color:white;
					}
					.category-section__container__categories__card__text{
						color:white;
						@include breakpoint(large){
							padding-right:50px;
						}
					}
					.category-section__container__categories__card__link {
					}
					.category-section__container__categories__card__icon{
				
					}
					.category-section__container__categories__card__mobile-link{
						& > div {
							color:$color-business-blue;
						}
					}
				}
			}
		}
	
	}
}
#trainingoverzicht-card{
	//background: linear-gradient(100deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) var(--c1, 85%), rgba(252,184,76,1) 0%, rgba(252,184,76,1) var(--c1, 85%)) !important;
	background-color:white !important;
	border-radius: 8px;
	border: none;
	position: relative;
	overflow:hidden;
	p{
		color:$color-business-blue;
	}
	&:hover{
		p{
			color:$color-business-blue;
		}
		.category-section__container__categories__shape{
			right: -60px;
		}
	}
	.category-section__container__categories__shape{
		position:absolute;
		width: 150px;
		height: 100%;
		transform: skew(-10deg);
		background: rgba(252,184,76,1);
		top: 0;
		right: -90px;
		z-index: -1;
		transition:right .3s ease-in-out;
	}
}
#keuze-card{
	//background: linear-gradient(100deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, #00C4C2 0%, #00C4C2 85%) !important;
	background-color:white !important;
	border-radius: 8px;
	border: none;
	position: relative;
	overflow:hidden;
	p{
		color:$color-business-blue;
	}
	&:hover{
		p{
			color:$color-business-blue;
		}
		.category-section__container__categories__shape{
			right: -60px;
		}
		&:hover{
			background-color:$color-business-blue;
		}
	}
	.category-section__container__categories__shape{
		position:absolute;
		width: 150px;
		height: 100%;
		transform: skew(-10deg);
		background: #00C4C2;
		top: 0;
		right: -90px;
		z-index: -1;
		transition:right .3s ease-in-out;
	}
}
#opleiding-card{
	//background: linear-gradient(100deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, #9DD08B 0%, #9DD08B 85%) !important;
	background-color:white !important;
	border-radius: 8px;
	border: none;
	position: relative;
	overflow:hidden;
	p{
		color:$color-business-blue;
	}
	&:hover{
		p{
			color:$color-business-blue;
		}
		.category-section__container__categories__shape{
			right: -60px;
		}
	}
	.category-section__container__categories__shape{
		position:absolute;
		width: 150px;
		height: 100%;
		transform: skew(-10deg);
		background: #9DD08B;
		top: 0;
		right: -90px;
		z-index: -1;
		transition:right .3s ease-in-out;
	}
}
#subsidie-card{
	background-color:white !important;
	border-radius: 8px;
	border: none;
	position: relative;
	overflow:hidden;
	p{
		color:$color-business-blue;
	}
	&:hover{
		p{
			color:$color-business-blue;
		}
		.category-section__container__categories__shape{
			right: -60px;
		}
	}
	.category-section__container__categories__shape{
		position:absolute;
		width: 150px;
		height: 100%;
		transform: skew(-10deg);
		background: #B24ED6;
		top: 0;
		right: -90px;
		z-index: -1;
		transition:right .3s ease-in-out;
	}
}