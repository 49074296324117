.trainingoverzicht {
	background:$color-blue-ultralight;
	padding-bottom:30px;
	&__content{
		padding:30px 0px;
		padding-top:20px;
		text-align:center;
		&__title{
		
		}
		& p{
		
		}
		&__logo{
			height:70px;
		}
		& .cf-button{
			margin-bottom:10px;
    		@include breakpoint(medium) {
			margin-bottom:0px;
    		}
		}
	}
	
	&__section{
		
		&__title{
			background:$color-business-blue;
			& h2 {
				color:white;
				padding:30px 0px;
			}
		}
		
		&__trainingen{
			& .training-nav__subnav {
				height:inherit;
				
			}
			@include breakpoint(medium){
				min-height:470px;
			}
		}
	}
}
