.about-cf {
  background: $color-ultralight-gray;
  padding: 25px 15px;
  
  .medium-offset-1{
  	@media print {
  		margin-left:0px;
  		-ms-flex: 0 0 25%;
    	flex: 0 0 100%;
    	max-width: 100%;
  	}
  }
  @include breakpoint(medium) {
    padding: 3rem 0;
  }
  @media print {
  	padding-top:0px;
  	padding-bottom:0px;
  }	
  &--small {
    @include show-for(medium);
    padding: 30px 10px 20px 25px;
  }
  &__title{
  	margin-bottom:30px;
  	h2{
    	color: $color-business-blue;
    	margin: 0 0 1rem;
    	font-size: 1.8rem;
    	text-align:center;
  	}
  	&__text{
  		color: $color-dark-gray;
    	margin: 0 0 1rem;
    	font-size: 1.1rem;
    	text-align:center;
    	padding:0px 25px;
  		@include breakpoint(medium) {
    	padding:0px 75px;
    	}
  		@include breakpoint(large) {
    	padding:0px 150px;
    	}
  	
  	}
  }
  
  &__subtitle{
  	color: $color-black;
  	font-size: 1.5rem;
  	font-weight:600;
  }

  &--small &__title {
    font-size: 1.5rem;
  }

  &__action {
    display: inline-block;
    margin: 2rem 0 0;
    color: $white;
    font-size: rem-calc(17);
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #e0e0e0;
    }
    i {
      font-size: 140%;
      position: relative;
      top: 4px;
      left: 10px;
    }
    @media print {
  		display:none;
  	}
  }
}