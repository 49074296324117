.training-data {

}

#trainingsdata-items {
  overflow: hidden;
  max-height: 10000px;
  @include transition(max-height 2s linear);

  &.closed {
    max-height: 1488px;

    @include transition(none);
    @include breakpoint(medium) {
      max-height: 783px;
    }
    @include breakpoint(large) {
      max-height: 520px;
    }
  }
}

#toon-alle-trainingsdata {
  text-decoration: none;
  font-size: rem-calc(19);
  text-align: center;
  display: block;
  margin: 0 0 2rem;
  .icon-arrow-right {
    font-size: 130%;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 10px;
    @include transform(rotate(-90deg));
  }

  &.closed {
    .icon-arrow-right {
      @include transform(rotate(90deg));
    }
  }
}