.opleidingsadvies {
  &__contact {
    @include open-sans;
    font-weight: bold;
    font-size: rem-calc(23px);
    margin: 0;
  }
  &__image{
  	margin-bottom:-15px;
  	@include breakpoint(large) {
  	margin-bottom:-20px;
  	}
  	text-align:center;
  	img{
		height:150px;
  	}
  }
  &__heads{
	li {
		width:55px; 
		height: 55px; 
		background: #ccc; 
		border-radius: 50%; 
		list-style-type: none; 
		position:relative; 
		overflow:hidden; 
		display:inline-block; 
		margin:0 0 0 15px;
		img {
			width:auto; 
			height:100%; 
			max-width:inherit;
		}
	}
	li:first-child {
		margin-left:0;
	}
}
  &__tel {
    a {
      color: rgba(255,255,255,0.9);
      span { color: white;}
      @include header-text;
      text-decoration: none;
      @include transition(color 400ms);
		display:flex;
		align-items:center;
		column-gap:16px;
      &:hover, &:focus {
        color: white;
      }
		img{
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(33deg) brightness(104%) contrast(105%);
		}
		span {
      		@include header-text;
		}
      i {
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
    }
  }
  
  &__print {
  	a {
      color: rgba(255,255,255,0.9);
      text-decoration: none;
      font-size: rem-calc(17px);
      font-weight: 600;
      @include transition(color 400ms);

      &:hover, &:focus {
        color: white;
      }

      i {
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
    }
  }
  
  &__brochure {
  	span{
      font-size: rem-calc(16px);
  	}
  }
}