.form-deelnemer {
  max-height: 0;
  overflow: hidden;
  @include transition(max-height 500ms);

  &--expanded {
    max-height: 1400px;

    @include breakpoint(medium) {
      //max-height: 700px;
    }
  }
}