body.print {
	> .footer{
	display:none!important;
  		@media print{
			display:none;
		}
	}
	> .copyright{
		display:none!important;
		@media print{
			display:none;
		}
	}
}

.training-intro--print {
	.training-intro__omschrijving{
		p:first-of-type {
			font-size: rem-calc(18);
			font-weight:700;
			margin-bottom:0px!important;
		}
		p:not(:first-of-type):not(:last-of-type){
			font-size: rem-calc(15);
			margin-bottom:0px!important;
		}
		p:last-of-type {
			margin-top:15px;
			margin-bottom:40px;
			font-size: rem-calc(13);
		}
	}
}

.training__section--print {
	font-size: rem-calc(13);
	.training {
		p.title {
			@include raleway;
			font-size:1.2rem;
			font-weight:bold;
		}
		table{
			width:100%!important;
		}
		table td {
			width:50%!important;
			vertical-align:top;
			padding:0px;
		}
		&:nth-of-type(even) {
	  		page-break-after: always;
		}
	}
}