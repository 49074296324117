.gadget {
  @include clearfix;
  cursor:pointer;
  text-align: center;
  background: #23213B;
  padding: 40px 0px 0px 40px;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  line-height: 1.2;
  margin-top: 10px;
  -webkit-box-shadow: 0px 2px 22px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 22px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 22px 1px rgba(0,0,0,0.75);

  img {
    float: right;
    width: 25%;
    height: auto;
    margin: 0 0 0 2%;
  }

  em, small {
    width: 73%;
    float: left;
  }

  em {
    font-style: normal;
    font-size: 110%;
    font-weight: bold;
    color:#fff;
  }

  small {
    font-size: 90%;
    margin-top: 5px;
    line-height: 1.1;
    color:#fff;
  }

  @include breakpoint(medium) {
    margin-top: 0;
	padding: 22px 0 0 39px;
    img {
      width: 28%;
    }

    em, small {
      width: 70%;
    }
  }

  @include breakpoint(large) {
	padding: 30px 0 0 36px;
    img {
      width: 23%;
    }

    em, small {
      width: 75%;
    }
  }

  @include breakpoint(xlarge) {

    img {
      width: 23%;
    }

    em, small {
      width: 75%;
    }
  }
  
  @media print {
  	display:none;
  }
}