.topnav-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid $color-line;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  padding: 0 0 15px;
  z-index: 9999999;
}

.no-js .topnav-mobile {
  display: none;
}

.topnav-desktop {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 35px;
  /*background: linear-gradient(
                  to right,
                  $top-menu-color-dark 0%,
                  $top-menu-color-dark 50%,
                  $top-menu-color-light 50%,
                  $top-menu-color-light 100%
  );*/

  .menu {
  	justify-content: flex-end;
    width: auto;
  }
  @media print {
	display:none!important;
  }
  &__menu-item {
    @include custom-button-base;
    float: right;
    height: 35px;
    font-size: 0.9rem;
    background: $top-menu-color-light;
    font-weight: 500;

    &:hover {
      background: $color-blue-light;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
    &:last-of-type{
  		border-left:1px dashed white;
  	}
  }

  .menu > li {
    & > a {
      padding: 0.6rem 0.9rem;
    }
  }
  
}