#feedbackWidget{
	position: fixed;
    right: -37px;
    top: 50%;
    /* Adjust for element's height/2 (if known) after rotation */
    /* Assuming the height of the element is 100px before rotation */
    transform: translateY(-50%) rotate(-90deg) translateX(0px);
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: $color-business-blue; /* Ensure you define this variable elsewhere in your CSS */
    /* Explicit width and height may help with alignment */
    width: 100px; /* Adjust as necessary */
    height: 27px; /* Adjust as necessary */
	text-align: center;
	a{
		display: inline-block;
		padding-top: 6px;
		color:white;
		font-size: 14px;
		text-decoration: none;
		line-height: 1;
	}
	

}
.jouwcode{
	&__title{
		font-size:12px;
		letter-spacing: -0.24px;
		margin:0;
		color:#1C2663;
		font-family: 'Poppins', sans-serif;
		margin-bottom:0px!important;
		text-align: left!important;
	}
	&__kortingscode{
		font-size:16px;
		color:rgba(28,38,99,0.517);
		border: 2px dashed rgba(28,38,99,0.517);
		border-radius: 8px;
		margin: 0;
		margin-top:5px;
		margin-bottom:0px!important;
		  width: auto;
		padding: 10px 15px;
		@include breakpoint(large){
			width: fit-content;
		}
		white-space: nowrap;
		display: flex;
		gap:10px;
		transition:all 400ms;
		img{
			width:24px;
			filter: invert(21%) sepia(12%) saturate(3912%) hue-rotate(197deg) brightness(83%) contrast(107%);
			opacity: 0.35;
			transition:all 400ms;
		}
		&:hover{
			color:rgba(28,38,99,1);
			cursor: pointer;
			img{
				opacity: 1;
			}
		}
	}
}
.diploma-container{
	box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
}
.changeuser{
	text-decoration: underline!important;
	&:hover{
		text-decoration: none!important; 
	}
}
.vrienduitnodigenEmailPopup{
	position: absolute;
	top:50%!important;
	transform: translateY(-50%);
	margin-left:20px;
	margin-right:20px;
	@include breakpoint(large){
	position: absolute;
	top:50%!important;
	left:50%;
	transform: translate(-50%,-50%);
	}
	&__box{
		
		background: white;
		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.129);
		border-radius: 12px;
		margin: auto;
		text-align: center;
		padding: 30px;
		position: relative;

		&__title{
			font-size:22px;
			letter-spacing: -0.44px;
			font-weight: bold;
			margin-bottom: 20px;
			text-align: left;
		}
		&__tekst{
			font-size:14px;
			margin-bottom: 20px;
			text-align: left;
		}
		.jouwcode__kortingscode{
			margin-bottom:20px!important;
		}
		.form-email{
			label{
				color:#1E225C;
			}
		}

		.cf-button{

			svg{
				filter: invert(9%) sepia(28%) saturate(6837%) hue-rotate(231deg) brightness(93%) contrast(92%);
			}

			&:hover{
				svg{
					filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(34deg) brightness(103%) contrast(106%);
				}
			}
		}

		@include breakpoint(medium){
			width:500px;
			top:0;
		}

	}
	.showInviteForm{
		text-decoration: underline;
		&:hover{
			cursor: pointer;
		}
	}
	.form-inviteEmail{
		.form-email{
			margin-bottom: 15px;
			position: relative;
		}
		input{
			border-radius: 8px;
			background-color: white;
			box-shadow: none;
			border: 1px solid #1E225C;
			margin-bottom: 0px;
			font-size: 14px;
			letter-spacing: -0.32px;
			padding: 22px;
			height: unset;
			font-weight: medium;
			color: #1e225d;
		}
		label{
			position: absolute;
			left: 18px;
			background-color: white;
			padding-left: 5px;
			padding-right: 5px;
			top: -10px;
		}

		.form-error{
			margin-top:10px;
		}
	}
}
.moreInfoPerson{
	position: absolute;
	top:50%!important;
	transform: translateY(-50%);
	margin-left:20px;
	margin-right:20px;
	@include breakpoint(large){
	position: absolute;
	top:50%!important;
	left:50%;
	transform: translate(-50%,-50%);
	}
	&__box{
		
		background: white;
		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.129);
		border-radius: 12px;
		margin: auto;
		text-align: center;
		padding: 30px;
		position: relative;

		&__title{
			font-size:22px;
			letter-spacing: -0.44px;
			font-weight: bold;
			margin-bottom: 20px;
			text-align: left;
		}
		&__tekst{
			font-size:14px;
			margin-bottom: 20px;
			text-align: left;
		}
		&__img{
			width: 100%;
			height: 250px;
		}
		&__bar{
			.realbar{
				margin-bottom: 15px;
			}
			&__title{
				text-align: start;
  				margin-bottom: 0;
			}
			&__div{
				height: 20px;
				width: 100%;
				position: relative;
				background-color:#9edcec;
				border-radius: 8px;
				div{
					position: absolute;
					background-color:#00c4c2;
					height: 20px;
					border-radius: 8px;
				}
				span{
					font-size: 12px;
					position: absolute;
					right: 10px;
				}
				&.time1{
					background-color:#e7f4d2;
					div{
						background-color:#9ed14e;
					}
				}
				&.time2{
					background-color:#e8dbee;
					div{
						background-color:#a472bd;
					}
				}
			}
		}

		.cf-button{

			svg{
				filter: invert(9%) sepia(28%) saturate(6837%) hue-rotate(231deg) brightness(93%) contrast(92%);
			}

			&:hover{
				svg{
					filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(34deg) brightness(103%) contrast(106%);
				}
			}
		}

		@include breakpoint(medium){
			width:500px;
			top:0;
		}

	}
}
.shareinterestpopup{
	background: white;
	box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.129);
	border-radius: 12px;
	outline: none;
	@include breakpoint(large){
		position: absolute;
		top:50%!important;
		left:50%;
		transform: translate(-50%,-50%);
		display:none;
	}
	&__box{
		
		margin: auto;
		text-align: center;
		padding: 30px;
		position: relative;

		&__title{
			text-align:center;
			font-weight:800;
			color: #1C2663;
			letter-spacing: -0.5px;
			font-family: 'Lexend', sans-serif;
			font-size:25px;
			@include breakpoint(large){
				padding-left: 20%;
				padding-right: 20%;
			}
		}

		&__forms{
			width: fit-content;
			border: 1px solid #1C2663;
			border-radius: 100px;
			padding: 7px 15px;
			cursor: pointer;
			font-size:14px;
			letter-spacing: 0px;
			color: #1E225C;

			&.interestselected{
				background-color:#1C2663;
				color:white;
			}
			&.interestnotselected{
				background-color:white;
				color:#1C2663;
			}
		}
		&__submitknop{
			flex-basis: 100%;
			display: flex;
			justify-content: center;
			margin-top: 20px;

			button{
				padding: 25px 10px;

				.cf-button__icon{
					filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(357deg) brightness(103%) contrast(101%);
				}
			}
		}
		&__undertekst{
			font-size:14px;
			color: #ADAEB5;
			margin-top:10px;
		}
	}
	.form-addinginterests{
		display: flex;
		flex-wrap: wrap;
		gap: 5px 10px;
		justify-content:center
	}
}
.account {
	background:#F8F8FA;
	@include breakpoint(large){
		height: 91vh;
	}
	&__sidebar{
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		background:white;
		width:300px;
		padding:0px;
		&::after{
			display:none;
		}
		&.off-canvas{
			z-index:9999;
		}
		@include breakpoint(large){
			position:relative;
			width:33%;
			transform:none;
			border-right:1px solid #EAEAEA;
			height:inherit;
			//position:sticky;
			//top:85px;
		}
		.top{
			.account__sidebar__section{
				border-bottom: none;
				&__title{
					@include breakpoint(large){
						margin-top:20px;
					}
				}
			}
		}
		&__section{
			border-bottom:1px solid #EAEAEA;
			padding:14px 0px;
			flex-basis:0;
			&__title{
				@include header-text;
				font-weight:600;
				font-size:15px;
				margin:13px 17px;
				margin-top:0px;
				a{
					display: flex;
					align-items: center;
					justify-content: space-between;
					text-decoration: none;

					outline: none;
					&:focus,&:hover { outline: none; }
				}
			}
			&__menu{
				list-style-type:none;
				margin:0px;
				li{
					padding:5px 17px;
					padding-left:27px;
					font-size:15px;
					a{
						display:flex;
						justify-content:space-between;
						width:100%;
						text-decoration:none;
						font-size:15px;
						&:hover{
							text-decoration:underline;
						}

						outline: none;
						&:focus,&:hover { outline: none; }
					}
					span{
						font-size:15px;
					}
					display:flex;
					justify-content:space-between;
					color:$color-blue;
					&.grid, &.list{
						justify-content: flex-start;
						gap:20px;
					}
					&.current{
						color:white;
						background-color:$color-blue-selected;
						a, span{ color:white; }
						svg{
							path{
								fill: white;
							}
						}
					}
				}
				&__submenu{
					&:first-of-type{
						& > p{
							padding-top:10px;
						}
					}
					display: block!important;
					padding-left:0px!important;
					padding-right:0px!important;
					&__title{
						margin-bottom:10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						@include header-text;
						font-size:15px;
						padding-left:27px!important;
						padding-right:17px!important;
						span{
							@include header-text;
							font-size:15px;
							font-weight: bold;
						}
						&__icon{
							img{
								width:14px;
								filter: invert(17%) sepia(7%) saturate(6119%) hue-rotate(201deg) brightness(99%) contrast(106%);
								margin-right:2px;
								transition: all(400ms);

							}
						}
						&:hover{
							cursor: pointer;
						}
					}
					ul{
						list-style: none;
						padding: 0;
						margin: 0;
						li{
							margin:2px 0px;
							padding:2px 0px;
							font-size:15px;
							padding-left:27px!important;
							padding-right:17px!important;
							span{
								font-size:15px;
							}
						}
					}
					&.closed{
						ul{
							display: none;
						}
					}
					&.open{
						.account__sidebar__section__menu__submenu__title__icon img{
							transform:rotate(180deg);
						}
						ul{
							display: block;
						}
					}
				}
				&__icon{
					height:18px;
					display:inline-block;
					svg{
						transform:scale(0.75);
						path{
							fill: $color-blue;
						}
					}
				}
				&__count{
					min-width:18px;
					text-align: center;
				}
			}
			&__showhide{
				font-weight:600;
				color: #7F819D;
				display:flex;
				align-items:center;
				justify-content:flex-end;
				column-gap:10px;
				padding-right:17px;
				
				svg{
					transform:rotate(180deg) scale(0.75);
					path{
						fill:#7F819D;
					}
				}
			}
			&__levelprogress{
				display: flex;
				align-items: center;
				gap:11px;
				margin-left:14px;
				&__circle{
					.circle{
						width:50px;
						height:50px;
						border-radius: 50%;
						border: 10px solid transparent;
						background-repeat: no-repeat;
						border-image-slice: 1;
						background-origin: content-box, border-box, border-box, border-box, border-box;
						background-clip: content-box, border-box, border-box, border-box, border-box;
						display: grid;
						-ms-flex-pack: center;
						justify-content: center;
						-ms-flex-line-pack: center;
						align-content: center;

						lottie-player{
							width:22px;
							height:22px;
						}

						position: relative;
						.locked{
							display: none;
							width:16px;
							height:16px;
							position: absolute;
							top:50%;
							left:50%;
							transform: translate(-50%, -50%);
							filter: invert(10%) sepia(54%) saturate(3706%) hue-rotate(226deg) brightness(90%) contrast(91%);
						}
						&.starter{
							lottie-player{
								opacity: 0.5;
							}
							.locked{
								display: inline;
							}

						}
					}
				}
				&__info{
					p{margin-bottom:0px; font-size:13px; letter-spacing: -0.26px;}
					&__currentlevel{
						font-weight: bold;
						font-weight: 14px;
						letter-spacing: -0.28px;
					}
				}
			}
			&--bottom{
				border-top:1px solid #EAEAEA;
				border-bottom:none;
				padding:14px 0px;
			}
		}
		&--filter{
			&.off-canvas{
				background:white;
				width:300px;
				padding:0px;
				z-index:6;
			}
			.account__sidebar{
			}
			&::after{
				display:none;
			}
			@include breakpoint(large){
				.account__sidebar{
					&__section{
						&__showhide{
							display:none;
						}
						&__title{
							display:none;
						}
						&__menu{
							li{
								padding-left:17px;
								&.current{
									color:white;
								}
							}
							&__count{
								padding-right:0px;
							}
						}
						&:first-of-type{
							border-bottom:none;
						}
					}
				}
				&::after{
					display:none;
				}
			
			}
		}
	}
	
	&__profile{
		//margin:0px 2rem;
		padding:2rem 0rem;
		padding-left: 1.7rem;
		padding-right: 1.7rem;
		@include breakpoint(large){
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			overflow: scroll;
    		height: 100%;
		}
		&__bar{
			position:sticky;
			z-index: 5;
			top:15px;
			margin-left: -5px!important;
    		margin-right: -5px!important;
			@include breakpoint(medium){
				top:30px;
				margin-left:0px!important;
				margin-right:0px!important;
			}
			display:flex;
			justify-content:space-between;
			align-items:center;
			padding:10px 20px;
			background: #FFFFFF 0% 0% no-repeat padding-box;
			box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
			border-radius: 8px;
			column-gap:10px;
			@include breakpoint(medium){
				column-gap:40px;
			}
			@include breakpoint(large){
				top: 0px;
			}
			&__mobile-item{
				flex-grow:1;
				flex-basis: 0;
				display:flex;
				align-items:center;
				justify-content:center;
				@include breakpoint(medium){
					display:none;
				}
				text-align:center;
				svg{
					transform:scale(0.75);
					margin-right:2px;
				}
				span{
				
				}
				&.menu{
					justify-content:flex-start;
				
				}
				&.search{
				
				}
				&.filter{
					justify-content:flex-end;
				
				}
			}
			&__filter{
				flex-grow:1;
				flex-basis: 0;
				display:none;
				@include breakpoint(medium){
					display:block;
					position:relative;
				}
				&.show{
					@include breakpoint(medium){
						display:flex;
					}
					align-items:center;
					span.title{
						letter-spacing: 0px;
						color: #1E225C;
						padding-right:20px;
					}
					select{
						border: 1px solid $color-blue;
						border-radius: 2px;
						font-size:0.8rem;
						height:inherit;
						margin-bottom:0px;
					}
					.mobile-filter__expand{
						li.current{
							color:white;
						}
					}
				}
				&.search{
					input{
						background: #FFFFFF 0% 0% no-repeat padding-box;
						box-shadow: 0px 2px 6px rgba(60,84,110,0.2);
						border-radius: 8px;
						border:none;
						height:37px;
						margin-bottom:0px;
						padding:7px 40px;
						padding-right:0px;
						background-repeat:no-repeat;
						background-position: 3% 50%;
						background-size:16px;
					}
					input::placeholder{
						color:$color-blue;
					}
					.close-button{
						right: 0.1rem;
						top: 1rem;
					}
				}
				&.view{
					ul.tabbing{
						display:flex;
					}
					ul.tabbing li{
						flex-basis:0;
						flex-grow:1;
						margin-right:0px;
						border:none;
						display:flex;
						align-items:center;
						column-gap:16px;
						font-size:0.9rem;
					}
					ul.tabbing li.current svg path{
						fill:white;
					}
				}
				.clickableCross{
					position: absolute;
					width: 10%;
					height: 100%;
					top: 0;
					left:25px;
					cursor: pointer;
					display: flex;
  					align-items: center;
					 @include breakpoint(medium){
						right: 0;
						left:initial;
					}
					img{
						filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
						width: 30px;
  						height: 30px;
					}
				}
				.Searchicon{
					position: absolute;
					width: 10%;
					height: 100%;
					top: 0;
					left:30px;
					display: flex;
  					align-items: center;
					@include breakpoint(medium){
						left:10px;
					}
					img{
						filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
						width: 20px;
						height: 20px;
					}
				}
			}
			&__filter-menu{
				display:none;				 
			}
		}
		&__menu{
			//position:sticky;
			//top:15px;
			display:flex;
			justify-content:space-between;
			align-items:center;
			position: sticky;
			top:15px;
			z-index: 6;
			
			&__button{
				padding:10px 20px;
				background: #FFFFFF 0% 0% no-repeat padding-box;
				box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
				border-radius: 8px;
				display:flex;
				align-items:center;
				justify-content:center;
				svg{
					transform:scale(0.75);
					margin-right:2px;
				}
			}
			&__header{
				text-align: right;
				font-size: 1.5rem;
				font-weight:800;
				color: $color-blue;
			}
		}
		&__content{
			margin-top:30px;
			@include breakpoint(medium){
				margin-top:50px;
			}
			max-width:100%;
			
			h1{
				font-weight:800;
				font-size:20px;
				margin-bottom:17px;
				&.saved{
					margin-bottom:0px;
					padding-bottom:17px;
					letter-spacing: -0.32px;
					@include breakpoint(large){
						display:none;
					}
				}
			}
			h2{
				font-weight:600;
				font-size:1rem;
				margin:17px 0px;
				@include breakpoint(large){
					font-size:1.5rem;
					
				}
			
			}
			.opleidingsbudgetedit{
				text-decoration: none;
				background: #F8F8FA 0% 0% no-repeat padding-box;
				border-radius: 100px;
				padding: 10px 20px;
				text-align: left;
				width:170px;
				padding-left:20px;
				@include breakpoint(medium){
					width:fit-content;
					padding-left:20px;
				}
				@include breakpoint(large){
				}
				.title{
					letter-spacing: -0.3px;
					color: $color-business-blue;
					
					font-size:12px;
					@include breakpoint(medium){
						font-size:14px;
					}
					text-align: left;
					margin: 0;
					line-height: 1.2;
					font-family: Poppins;
				}
				.money{
					//border:1px solid $color-business-blue;
					padding-left: 0px;
					border-radius: 4px;
					width: fit-content;
					padding-top: 0px;
					padding-bottom: 0px;
					letter-spacing: -0.32px;
					
					font-size:12px;
					@include breakpoint(medium){
						font-size:14px;
					}
					margin: 0;
					line-height: 1.2;
					font-family: Poppins;
					text-align: left;
					font-weight: 600;
					img{
						width:14px;
						filter: invert(11%) sepia(28%) saturate(4845%) hue-rotate(223deg) brightness(98%) contrast(96%);
					}
					display: flex;
    				align-items: center;
				}
			}
			&__saved-content-block{
				padding-bottom:17px;
				& > .row.grid-view {
					padding-bottom:15px;
					& .grid-item{
							padding:0px;
						}
					@include breakpoint(medium){
						column-gap: 1.875rem;
						& .grid-item{
							padding:0px;
                            flex: 0 0 45%;
						}
					}
					@include breakpoint(large){
						column-gap: 1.875rem;
						& .grid-item{
							padding:0px;
							flex: 0 0 30.7%;
						}
					}
					@include breakpoint(xxlarge){
						& .grid-item{
							flex: 0 0 25%;
						}
					}
				}
				
				& > .row.list-view{
					& .grid-item{
						padding:0px;
					}
				}
			}
			&__dashboard{
				&__mainblock{
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
					border-radius: 8px;
					padding:22px;
					@include breakpoint(large){
						padding:35px;
						text-align:center;
					}
					&.login{
						h1,p,div{ text-align: left;}
					}
					h1{
						@include header-text;
						text-align:center;
						font-size:1.625rem;
						margin-bottom:5px;
						@include breakpoint(large){
							text-align:left;
							margin-bottom:0px;
							margin-top:0px;
						}
					}
					p{
						text-align:center;
						margin-bottom:30px;
						font-family: 'Lexend', sans-serif;
						@include breakpoint(large){
							text-align:left;
							margin-bottom:30px;
						}
					
					}
					div{
						text-align:center;
						@include breakpoint(large){
							text-align:left;
						}
					}
					.Editprofile{
						display:flex;
						align-items: center;
						gap: 20px;
						flex-direction: column;
						@include breakpoint(medium){
							flex-direction: row;
						}
						@include breakpoint(large){
							justify-content: initial;
						}
						.EditProfileButton{
							border: 1px solid rgba(28,38,99,0.2);
							border-radius: 2px;
							padding: 10px 20px;
							text-decoration: none;
							height: fit-content;
							margin-right: 0px;
							margin-top:5px;
							@include breakpoint(medium){
								padding: 5px 20px;
							}
							@include breakpoint(large){
								margin-right: 20px;
								margin-top:0px;
							}
						}
						
						.huidiglevel{
							text-decoration: none;
							display: flex;
							align-items: center;
							gap:10px;
							background: #F8F8FA 0% 0% no-repeat padding-box;
							border-radius: 100px;
							width:170px;
							padding: 5px 20px;
							padding-left: 5px;
							@include breakpoint(medium){
								padding: 0px 5px;
								width:fit-content;
							}
							@include breakpoint(large){
								padding: 5px 20px;
								padding-left: 5px;
							}
							&__icon{
								position: relative;
								width:45px;
								lottie-player{
									width:45px;
								}

								.locked{
									display: none;
									width:20px;
									height:20px;
									position: absolute;
									top:50%;
									left:50%;
									transform: translate(-50%, -50%);
									filter: invert(10%) sepia(54%) saturate(3706%) hue-rotate(226deg) brightness(90%) contrast(91%);
								}
								&.starter{
									lottie-player{
										opacity: 0.5;
									}
									.locked{
										display: inline;

									}
								}
							}
							&__text{
								p{
									font-family: poppins;
									font-size:12px;
									@include breakpoint(medium){
										font-size:14px;
									}
									margin-bottom: 0px;
									letter-spacing: -0.3px;
									line-height: 1.2;
									text-align: left;
									&.bold{
										font-weight: bold;
									}
								}
							}
						}
					}
					.firstdashboard_balk{
						display: flex;
						flex-direction: column;
						gap:20xp;
						justify-content: center;
						@include breakpoint(large){
							flex-direction: row;
							justify-content: center;
							gap:0xp;
						}
						@media only screen and (min-width: 1460px){
							margin-left:auto;
							margin-top:0px;
							justify-content: initial;
						}
					}
					.personality{
						display:flex;
						flex-direction: column;
						margin-top:20px;
						@include breakpoint(large){
							flex-direction: row;
						}
						@include breakpoint(xlarge){
							margin-top:0px;
							margin-left:auto;
						}
						@include breakpoint(xxlarge){
						}
						&__container{
							//border: 2px dashed rgba(28,38,99,0.517);
							//padding:20px;
							@include breakpoint(large){
								padding-left:10px;
								padding-bottom:0px;
								min-width:200px;
								max-width:300px;
							}
							@include breakpoint(xlarge){
								max-width:220px;
							}
							@include breakpoint(xxlarge){
								max-width:300px;
							}
							border-radius: 8px;
							&__title{
								font-size:18px;
								margin-bottom:10px;
								font-weight: bold;
								text-align: left;
							}
							&__text{
								margin-bottom:20px;
								font-size:14px;
								font-family: 'Poppins', sans-serif;
								text-align: left;

								span{
									text-decoration: underline;
									color:#f90;
									cursor: pointer;
								}
							}
							.cf-button--blue{
								padding-top:7px;
								padding-bottom:7px;
								margin-bottom:0px;
								.cf-button__text{
									font-size:14px;
								}
								&:hover, &:focus, &:active{
									padding-top:7px;
									padding-bottom:7px;
									.cf-button__text{
										font-size:14px;
									}
									.cf-button__icon{
										font-size:130%;
									}
								}
							}
						}
						&__containerimg{
							position: relative;
							margin-bottom:20px;
							@include breakpoint(medium){
								width:180px;
							  	min-width: 180px;
								margin-bottom:0px;
							}

							@include breakpoint(xlarge){
								width:130px;
							  	min-width: 130px;

							}
							@include breakpoint(xxlarge){
								
								width:180px;
							  	min-width: 180px;
							}
							&.done{
								width:250px;
								min-width:250px;
								margin-left: auto;
    							margin-right: auto;
								@include breakpoint(xlarge){
									width:200px;
									min-width:200px;
								}
								@include breakpoint(xxlarge){
									width:250px;
									min-width:250px;
								}
							}
							img{

								min-width: 180px;
								width:180px;
								@include breakpoint(large){
									position: absolute;
									bottom: -35px;
								}

								@include breakpoint(xlarge){
									width:130px;
									min-width: 130px;

								}
								@include breakpoint(xxlarge){
									
									width:180px;
									min-width: 180px;
								}
							}
							&__quizgedaan{
								height: 100%;
								width:250px!important;
   	 							right: 0;

								@include breakpoint(large){
									margin-right:30px;
									bottom: 0px !important;
									width:250px!important;
									min-width: unset!important;
								}
							}
						}
					}
					.maindivcircle{
						display: flex;
						justify-content: center;
  						align-items: center;

						.circlewithfavourites{
							height: 50vw;
							border-radius: 50%;
							border: 20px solid transparent;
							background-repeat: no-repeat;
							border-image-slice: 1;
							background-origin: content-box, border-box, border-box, border-box, border-box;
							background-clip: content-box, border-box, border-box, border-box, border-box;
							display: grid;
							justify-content: center;
							align-content: center;
							width: 70%;

							@media only screen and (min-width: 500px) {
								width: 60%;
							}
							@include breakpoint(medium){
								margin-bottom: 0;
								&.kleinewidth{
									width: 160px;
									height: 160px;
								}
								&.grootewidth{
									width: 200px;
									height: 250px;
								}
							}
							@media only screen and (min-width: 1500px) {
								&.kleinewidth{
									width: 80%;
								}
								&.grootewidth{
									width: 100%;
									height:110%;
								}
								height:100%;
							}
	
							p{
								margin: 0;
							}
						}
					}
					.tesktcirclemaindiv{
						.tekstbijcirclefavourite{
							display: grid;
							align-content: center;
							height: 100%;
							width: 100%;
							p{
								margin: 0;
							}
						}
						margin-top: 25px;
  						margin-bottom: 25px;

						@include breakpoint(medium){
							margin:0;
						}

					}

				}
				&__mainblock.diplomas{
					padding-bottom: 0;
  					margin-bottom: 0;
					padding-right:0px;
					height:100%;
					.titlebox{
						display:flex;
						display: grid;
						grid-template-rows:none;
						grid-template-columns: auto;
						@include breakpoint(large){
							grid-template-columns: 2fr 1fr;
							grid-template-rows: auto;
							align-items: center;
							padding-right:40px;
						}
						.title{
							font-weight: 600;
							font-size:18px;
							font-family: 'Lexend', sans-serif;
							letter-spacing: -0.36px;
							color:#1C2663;
							text-align: start;
							@include breakpoint(large){
								text-align: start;
								margin-bottom:30px; 
							}
						}
						.allediplomas{
							display: none;
							font-family: 'Poppins', sans-serif;
							color:#1C2663;
							text-align: right;
							font-weight: 500;
							a{
								text-decoration: none;
								display: flex;
								align-items: flex-end;
								justify-content: start;
								@include breakpoint(large){
									justify-content: end;
								}
								svg{
									margin-left:10px;
									height: 24px;
									width: 24px;							  
								}
							}
						}
					}
					.diploma_sliderbox{

						.diplomas-trainingen-scroller{
							height: auto;
							.rf-cards-scroller-platter{
								padding-bottom:10px;
							}
							.rf-cards-scroller-platter > div .rf-cards-scroller-itemview{
								   transform: none;
							 }
						}
						
					}
				}
				&__mainblock.recenteartikelen{
					margin-left:0px;
					margin-top:30px;
					@include breakpoint(medium){
						margin-left:50px;
						margin-top:0px;
						height:100%;
					}
					.recenteartikelen{
						&__title{
							font-weight: 600;
							font-size:18px;
							font-family: 'Lexend', sans-serif;
							letter-spacing: -0.36px;
							color:#1C2663;
							text-align: start;
						}
						&__tekst{
							font-size:16px;
							color: #1E225C;
							text-align: start;
							a{
								text-decoration: none;
							}
							a:hover{
								text-decoration: underline;
							}
							&:last-of-type{
								margin-bottom:0px;
							}
						}
					}
				}
				&__mainblock.inspiratiesuggesties{
  					margin-bottom: 0;
					padding-right:0px;
					height:100%;
					.inspiratiesuggesties{
						&__title{
							font-weight: 600;
							font-size:18px;
							font-family: 'Lexend', sans-serif;
							letter-spacing: -0.36px;
							color:#1C2663;
							text-align: start;
							padding-right:22px;
							@include breakpoint(medium){
								padding-right:35px;
							}
						}
						&__sliderbox{
							transform: none !important;
						}
						
					}
					.rf-cards-scroller-platter{
						padding: 0px!important; 
						.training-grid-block{
							border-radius: none; 
							box-shadow: none;
							padding: 0;
							min-height: unset;
							height: fit-content;
						}
						.training-grid-block__favorite{display: none;}
					}
					.rf-cards-scroller-itemview{ margin-right:50px; }
					.rf-cards-scroller-crop { padding-bottom:0px; }
					.rf-ccard-content { box-shadow: none; height: unset!important; max-width:320px; }
					.training-grid-block__content__infotext{
						text-align: left;
						padding: 25px 0px 0px 0px;
					}
					.training-grid-block__image { height:175px; }
					.training-grid-block__image > img {height: 175px;}
					.training-grid-block__title { margin-bottom:14px; }
					.training-grid-block__content { height: unset; }
					.training-grid-block__title, .training-grid-block__intro{
						text-align: left;

					}
					.training-grid-block__title{
						font-size:17px;
					}
					.training-grid-block__intro{
						font-family:poppins;
						font-weight:400;
					}
					.training-grid-block__infolabel{
						bottom:10px;
					}
					.training-grid-block__bottom { display: none;}
					.training-grid-block__content__text { height: unset; }
				}
				&__mainblock.inspiratiefactory{
					margin-left:0px;
					height:100%;
					@include breakpoint(medium){
						margin-right: 25px;
					}
					@include breakpoint(medium){
						margin-right: 25px;
					}
					.inspiratiefactory{
						&__link{
							text-decoration: none;

							&:hover{
								.inspiratiefactory__eventbox{
									.inspiratiefactory__eventbox__titlebox__title{
										text-decoration: underline;
										img{
											margin-left:15px;
										}
									}
								}
							}
						}
						&__title{
							font-weight: 600;
							font-size:18px;
							font-family: 'Lexend', sans-serif;
							letter-spacing: -0.36px;
							color:#1C2663;
							text-align: start;
						}
						&__noEventText{
							text-align: start !important;
						}
						&__eventbox{
							display:flex;
							.trainingsdata-table__datebox{
								text-align: center;
								padding: 5px 10px;
								max-height: fit-content;
								width: auto;
								background-color: #1e225d;
								color:white;
								font-size: 1.375rem !important;
								font-weight: 800;
							}
							&__titlebox{
								display: grid;
								margin-left:20px;
								&__date{
									font-family: 'Lexend', sans-serif;
									font-size:14px;
									color: #ABACB7;
									font-weight: 600;
									text-align: start;
									align-self: end;
									@include breakpoint(medium){
										text-align: start;
									}
								}
								&__title{
									font-family: 'Poppins', sans-serif;
									font-size:15px;
									color: #1E225C;
									display:flex;
									align-items: start;
									text-align: start;
									img{
										margin-left:5px;
										width: 24px;
										transition: all 400ms;
										filter: invert(12%) sepia(48%) saturate(3419%) hue-rotate(223deg) brightness(85%) contrast(93%);
									}
								}
							}
						}
					}
				}
				&__mainblock.gerelateerdecontent{
					margin-left:0px;
					height:100%;
					@include breakpoint(medium){
						margin-left: 25px;
					}
					@include breakpoint(large){
						margin-left: 25px;
					}
					
					.gerelateerdecontent{
						&__title{
							font-weight: 600;
							font-size:18px;
							font-family: 'Lexend', sans-serif;
							letter-spacing: -0.36px;
							color:#1C2663;
							text-align: start;
							margin-bottom:25px;
						}
					}
					.recenteartikelen__div__schrijver{
						font-family: 'Lexend', sans-serif;
						font-size:14px;
						color: #ABACB7;
						font-weight: 600;
					}
					.recenteartikelen__div__tekst{
						font-family: 'Poppins', sans-serif;
						font-size:15px;
						color: #1E225C;
						text-align: start;
						margin-bottom:0px;

						a{
							text-decoration: none;
							&:hover{
								text-decoration: underline;
							}
							
						}
					}
					.gerelateerdecontent__div{
						text-align: start;
						margin-bottom:25px;
						&:last-of-type{
							margin-bottom:0px;
						}
					}
				}
				&__mainblock.voordeligsparen{
					@include breakpoint(large){
						margin-right:40px;
					}
					.title{
						font-family: 'Lexend', sans-serif;
						font-size:23px;
						font-weight: bold;
						letter-spacing: -0.36px;
						color:#1C2663;
						margin-bottom: 15px;
						text-align: left;
					}
					.text{
						p{ font-family:poppins; margin-bottom: 0!important; text-align: left; }

					}
				}
				&__mainblock.vrienduitnodigen{
					margin-top: 30px;
					@include breakpoint(medium){
						height:100%;
					}
					.titelvrienduitnodigen{
						font-family: 'Lexend', sans-serif;
						font-size:23px;
						font-weight: bold;
						letter-spacing: -0.36px;
						color:#1C2663;
						margin-bottom: 15px;
						text-align: left;
					}
					.codecontainer{
						@include breakpoint(large){
							display: flex;
							gap:20px;
							align-items: center;
						}
					}
					

					.deeljoucode{
						padding-top: 20px;
  						height: 100%;

						button{
							height: 100%;
							display: flex;
						}
						svg{
							filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(189deg) brightness(115%) contrast(100%);
						}

						&:hover{
							svg{
								filter: invert(10%) sepia(53%) saturate(3889%) hue-rotate(224deg) brightness(91%) contrast(90%);
							}
						}
					}

					.jouwbudget{

						&__title{
							font-size:12px;
							letter-spacing: -0.24px;
							margin:0;
							color:#1C2663;
							font-family: 'Poppins', sans-serif;
							text-align: left;
						}
						&__budget{
							font-size:18px; 
							border: 2px solid $color-business-blue;
							border-radius: 8px;
							margin: 0;
							margin-top:5px;
  							width: auto;
							padding: 10px 15px;
							gap:10px;
							display: flex;
							align-items: center;
							justify-content: center;
							@include breakpoint(large){
								width: fit-content;
							}
							white-space: nowrap;
							&__icon{
								background-color:$color-business-blue;
								border-radius: 100%;
								display: inline-flex;
								align-items: center;
								justify-content: center;
								width:26px;
								height:26px;
								padding:2px;
								text-align: center;
								&__inner{
									color:white;
									font-size:10px;
									display: inline-block;
									width:16px;
									height:16px;
									text-align: center;
									line-height: 1.5;
									border:1px solid white;
									border-radius: 100%;
								}
							}
						}
					}

					.vrienduitnodigenCodedescription{
						font-size: 14px;
						margin-top:20px;
						margin-bottom: 0;
						font-family: 'Poppins', sans-serif;
						text-align: left;

						.vrienduitnodigenEmail{
							text-decoration: underline;
							cursor: pointer;

							&:hover{
								text-decoration: none;
							}
						}
					}
					


					@include breakpoint(medium){
						margin-top:0;
						margin-left: 20px;
					}
				}

				&__mainblock.sparen{
					//display:flex;
					padding:0px!important;
					.sparen__top, .sparen__bottom, .sparen__middle{
						padding:35px 22px;
						@include breakpoint(large){
							padding:35px;
							padding-bottom: 0px;
						}
					}
					.sparen__top{

					}
					.sparen__bottom,.sparen__middle{
						padding-top:0px;

					}
					.sparen__middle{
						padding-bottom: 0px;
					}
					.sparen__loyaltylevels{
						padding:0;
						&__header{
							display: none;
							@include breakpoint(large){
								display: grid;
								grid-template-columns: 3fr 7fr 4fr;
								gap: 10px;
								align-items: center;
								margin-bottom:5px;
							}
							& > div{
								font-size:14px;
								font-family: 'Lexend';
								font-weight:bold;
							}
						}
						&__level{
							background:white;
							border: 2px solid $color-business-blue;
							border-radius: 12px;
							margin-bottom:20px;
							position: relative;
							cursor: pointer;
							&:last-of-type{
								margin-bottom: 0px;
							}
							@include breakpoint(large){
								gap: 10px;
								display: grid;
								align-items: center;
								grid-template-columns: 2.5fr 6fr 6fr;
							}
							&__Premiumbuybuttom{
								display:none;
								padding-right: 5px;
								padding-top: 5px;
								padding-bottom: 5px;
								.cf-button{
									float: right;
									min-width: 100%;
									max-width: 100%;
									height: 100%;
									display: flex;
									@include breakpoint(large){
										min-width: 80%;
										max-width: 80%;
									}
									.cf-button__text{
										text-align: start;
										color: white;
										font-size: 14px;
										font-family: 'Poppins', sans-serif !important;
										font-weight: 500;
										text-align: start;
										margin-left: 0;
									}
									div{
										margin-left: 20px;
										margin-top: 5px;
  										margin-bottom: 5px;
									}
								}
								.cf-button__icon{
									margin-right: 0;
									margin-left: 10px;
									img{
										width: 31px;
										height: 31px;
										filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(96deg) brightness(105%) contrast(101%);
										opacity: 0.57;
										max-width: inherit;
									}
								}

								&:hover{
									.cf-button{
										background-color:white;
									}
									.cf-button__text{
										color:$color-orange;
									}
									.cf-button__icon{
										img{
											filter: invert(53%) sepia(89%) saturate(1610%) hue-rotate(3deg) brightness(109%) contrast(103%);
										}
									}
									
								}
							}
							&__status{
								position: absolute;
								top:0px;
								left:0px;
								background: linear-gradient(to top left, transparent 0%, transparent 50%, $color-business-blue 50%, $color-business-blue 100%);
								width:45px;
								height:45px;
								border-radius: 10px 0px 0px 0px;
								img{
									filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(96deg) brightness(105%) contrast(101%);
									width:15px;
									position: absolute;
									top:5px;
									left: 5px;
									&.unlocked{display: none;}
								}

							}
							&__content{
								@include breakpoint(large){
									gap: 10px;
									display: grid;
									align-items: center;
									grid-template-columns: 3fr 7fr 4fr;
								}
							}
							&__name{
								padding:10px 20px;
								padding-top:20px;
								@include breakpoint(large){
									padding-left:27px;
									padding-top:22px;
									padding-bottom:22px;
									padding-right:0px;
								}
								display: flex;
								align-items: center;
								gap:15px;
								&__icon{
									position: relative;
									lottie-player{
										width: 45px;
										height: 45px;
									}
								}
								&__text{
									p { margin-bottom: 0; font-family: poppins; font-size:15px; font-weight: bold;}
								}
							}
							&__benefits{
								padding:5px 20px;
								@include breakpoint(large){
									padding: 0;
								}
								ul{
									list-style-type: none;
									margin: 0;
									padding: 0;
									li{
										.icon{
											display: flex;
  											align-items: center;
											margin-right:10px;
											min-width:14px;
										}
										.benefit-container{
											background: #F8F8FA;
											border-radius:100px;
											display: inline-flex;
											align-items: center;
											gap: 15px;
											padding: 4px 12px;

										}
										img{
											width:14px;
											height:14px;
											filter: invert(17%) sepia(7%) saturate(6159%) hue-rotate(200deg) brightness(97%) contrast(107%);
											&.unlocked{display: none;}
										}
										span, .text, .text a{
											font-size:14px;
											text-align: left;
											line-height: 1.2;
										}
										margin-bottom:5px;
										display: flex;
										align-items: center;
										&:last-of-type{
											margin-bottom:0px;
										}
									}
								}
							}
							&__todolist{
								justify-self: end;
								padding:5px 20px;
								padding-bottom:20px;
								min-width: 100%;
    							max-width: 100%;
								@include breakpoint(large){
									padding: 0;
									min-width: unset;
									width:377px;
									max-width: 377px;
									padding:19px;
									padding-left:0px;
								}
								margin-left:0px;
								ul{
									margin: 0;
									list-style-type: none;
									padding: 0;
									@include breakpoint(large){
										max-width: 358px;
									}
								}
								&__knop{
									background: #1C2663;
									border-radius: 8px;
									padding: 12px;
									display:flex;
									align-items: center;

									img{
										width:31px;
										height:31px;
										filter: invert(97%) sepia(3%) saturate(3040%) hue-rotate(225deg) brightness(113%) contrast(103%);
										align-self: center;
										opacity: 0.57;
									}

									&__tekstdiv{
										margin-left: 20px;
									}
									&.premium{
										background: $color-orange;
									}
								}
								li{
									list-style-type: none;
									display: flex;
									align-items: center;
									justify-content: space-between;
									gap:10px;
									margin-bottom: 5px;
									&:last-of-type{
										margin-bottom:0px;
									}
									.benefit-container{
										text-align: start;
										line-height: 1;
									}
									span{
										color: white;
										font-size:14px;
										font-family: 'Poppins', sans-serif !important;
										font-weight: 500;
										text-align: start;
									}
									text-align: left;
									&.achieved{
										div.text{
											text-decoration: line-through;
											text-align: left;
										}
										div.icon img{
											filter: invert(11%) sepia(48%) saturate(3214%) hue-rotate(225deg) brightness(95%) contrast(95%);
										}
									}
								}										
								div.icon {
									min-width:15px;
									img{
										filter: invert(56%) sepia(42%) saturate(1345%) hue-rotate(0deg) brightness(104%) contrast(105%);
										width:20px;
									}
									line-height: 1;
									margin-right:5px;
									@include breakpoint(large){
										margin-right:20px;
									}
								}
								div.text{
									font-size: 14px;
									font-weight: 400;
									font-family: 'Poppins', sans-serif;
									width: fit-content;
									line-height: 1;
								}
							}
							&.achieved{
								cursor:default;
								.sparen__loyaltylevels__level__status{ display: none;}
								.sparen__loyaltylevels__level__benefits img.benefit { display: none;}
								.sparen__loyaltylevels__level__benefits img.unlocked { display: block;}

								border-color:rgba(30, 34, 93, 0.5);
								.sparen__loyaltylevels__level{
									&__status {
										opacity: 0.5;
									}
									&__name{
										opacity: 0.5;
									}
									&__benefits{
										opacity: 0.5;
									}
									&__todolist{
										opacity: 0.5;
									}
								}
							}
							&.current{
								border-color:rgba(30, 34, 93, 1);
								.sparen__loyaltylevels__level{
									&__status {
										opacity: 1;
									}
									&__name{
										opacity: 1;
									}
									&__benefits{
										opacity: 1;
									}
									&__todolist{
										opacity: 1;
									}
								}
								
							}
							&.nextlevel{
								.sparen__loyaltylevels__level__status{
									display: block;
									img.locked{
										display: block;
									}
									img.unlocked{
										display: none;
									}
									img.premiumlocked{
										display: none;
									}
								}
							}
							&.locked{
								border-color:rgba(30, 34, 93, 0.5);
								.sparen__loyaltylevels__level{
									&__status {
										opacity: 0.5;
									}
									&__name{
										opacity: 0.5;
									}
									&__benefits{
										opacity: 0.5;
									}
									&__todolist{
										opacity: 1;
									}
								}
							}
							&.premium{
								border-color:$color-orange;
								.sparen__loyaltylevels__level__status{
									display: block;
									background: linear-gradient(to top left, transparent 0%, transparent 50%, $color-orange 50%, $color-orange 100%);
									img.locked{
										display: none;
									}
									img.unlocked{
										display: block;
									}
									img.premiumlocked{
										display: none;
									}
								}

							}
							&.premiumlocked{
								border-color:rgba(255, 153, 0, 1);
								.sparen__loyaltylevels__level__status{
									display: block;
									background: linear-gradient(to top left, transparent 0%, transparent 50%, $color-orange 50%, $color-orange 100%);
									img.locked{
										display: none;
									}
									img.unlocked{
										display: none;
									}
									img.premiumlocked{
										display: block;
									}
								}
								.sparen__loyaltylevels__level{
									&__status {
										opacity: 1;
									}
									&__name{
										opacity: 1;
									}
									&__benefits{
										opacity: 1;
									}
									&__todolist{
										opacity: 1;
									}
								}
								.sparen__loyaltylevels__level__todolist{
								}
								.sparen__loyaltylevels__level__Premiumbuybuttom{
									display:block;
								}
							}
						}
					}
					.sparenvoorextras{
						padding:0;
						@include breakpoint(large){
						}
						@include breakpoint(large){
							height: fit-content;
						}
						&__title{
							font-family: 'Lexend', sans-serif;
							font-size:23px;
							font-weight: bold;
							color:#1C2663;
							margin-bottom:25px;
							letter-spacing: -0.44px;
							text-align: left;

							span{
								color: #9DA2BF;
								font-weight:500;
							}
						}

						&__tekst{
							font-size:14px;
							font-family: 'Poppins', sans-serif;
							margin-top:0px;
							@include breakpoint(large){
								margin-right: 50px;
							}

						}
						&__huidiglevel{
							display: flex;
							justify-items: center;
							gap: 20px;
							flex-direction: row;
   							flex-wrap: wrap;
							text-align: initial;
							@include breakpoint(medium){
								flex-direction: row;
								gap: 30px;
								align-items: center;
								justify-items: start;
								text-align: center;
							}
							@include breakpoint(large){
								gap: 40px;
							}
							@include breakpoint(xlarge){
								gap: 60px;

							}
							@include breakpoint(xxlarge){
								gap: 100px;
							}
							&__img{
								justify-content: center;
								display: flex;
								@include breakpoint(large){
									justify-content: initial;
									display: block;
								}
							}
							&__niveau{
								display: flex;
								align-items: center;
								justify-content: center;
								@include breakpoint(medium){
									justify-content: flex-start;
									width: fit-content;
								}
								gap:10px;
								&__extralayer{
									border: 1px solid #1C2663;
									border-radius: 100px;
									padding: 7px;
								}
								&__icon{
									position: relative;
									border: 1px solid #1C2663;
									border-radius: 100px;
									lottie-player{
										width: 38px;
										height: 38px;
									}
									.locked{
										display: none;
										width:20px;
										height:20px;
										position: absolute;
										top:50%;
										left:50%;
										transform: translate(-50%, -50%);
										filter: invert(10%) sepia(54%) saturate(3706%) hue-rotate(226deg) brightness(90%) contrast(91%);
									}
									&.starter{
										opacity: 0.71;
										lottie-player{
											opacity: 0.5;
										}
										.locked{
											display: inline;

										}
									}
								}
								p{
									font-family: 'Poppins', sans-serif;
									text-align: left;
									margin-bottom:0;
									font-size:14px;
									letter-spacing: -0.28px;
									line-height: 1.4;
								}
								.huidigbold{
									font-weight: bold;
									font-size:14px;
								}
							}
							&__budget{
								.title, .money{ font-size:14px; line-height: 1.4; }
								.money { font-weight: normal; span { font-weight: bold;}}
								.opleidingsbudgetedit { padding:0px; }
							}
							&__progressbalkdiv{
								width:100%;
								@include breakpoint(medium){
									width:300px;
								}
									&__tekst{
										color: #1C2663;
										font-size:14px;
										font-family: 'Poppins', sans-serif;
										p{
											font-family: 'Poppins', sans-serif;
											margin-bottom: 0px;
											text-align: left;
										}
										span{
											font-weight: 700;
										}
									}
									&__balk{
										width: 100%;
  										height: 16px;
										position: relative;
										&__fullbalk{
											position: absolute;
											width: 100%;
											height: 100%;
											background: #EDEDED;
											border-radius: 4px;
										}
										&__userbalk{
											max-width: 100%;
											position: absolute;
											height: 100%;
											background: #FFBA00;
											border-radius: 5px;
											z-index: 3;
										}
									}
							}
							&__personalbudget{
								&__tekst{
									margin-bottom: 0px;
									font-size:14px;
									font-family: 'Poppins', sans-serif;
									text-align: left;
									svg{
										width: 14px;
										height: 14px;
										margin-left: 5px;
									}
								}
								&__budget{
									margin-bottom: 0px;
									font-size:14px;
									font-family: 'Poppins', sans-serif;
									font-weight:700;
									text-align: left;
									display: flex;
									gap:5px;
									align-items: center;

									img{
										width: 14px;
										height: 14px;
										filter: invert(9%) sepia(69%) saturate(3357%) hue-rotate(229deg) brightness(88%) contrast(91%);
									}
								}
							}
							&__text{
								p{
									max-width:356px;
									margin-bottom:0px;
									font-family: poppins;
									font-size:15px;
									display: flex;
  									align-items: center;
								}
							}
							&__punten{
								p{
									margin-bottom:0;
									font-size:14px;
									letter-spacing: -0.28px;
								}
								ul{
									list-style-type: none;
									margin:0;
									margin-top:10px;
									@include breakpoint(large){
										margin-top:0px;
									}
								}
								li{
									font-weight: 500;
									display: flex;
									gap: 10px;
									align-items: center;
									margin-bottom: 5px;
									font-size:14px;
									letter-spacing: -0.28px;
									&:last-of-type{
										margin-bottom:0px;
									}
								}
								img{
									width:14px;
									filter: invert(9%) sepia(70%) saturate(2195%) hue-rotate(226deg) brightness(104%) contrast(97%);
								}
								
							}
							&__voortgang{
								p{ margin-bottom: 0px; 
									font-size:14px;
									letter-spacing: -0.28px;}
								&__bardiv{
									display:flex;

									.sparenvoorextras__punten__bar{
									}
								}

							}
						}
						&__tierlist{
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 1fr;

							&__box{
								text-align: center;
								display:grid;
								justify-items: center;
								svg{
									width: 55px;
									height: 55px;
								}
								lottie-player{
									width: 55px;
									height: 55px;
								}
								&__title{
									margin-bottom: 0px;
									font-weight: bold;
									text-align: center;
								}
								&__tekst{
									margin-bottom: 0px;
									text-align: center;
									font-weight: 500;
									font-size: 14px;
								}
							}
						}

						.sparenvoorextras__punten{
							display:grid;
							grid-template-columns: 9fr 1fr;
							margin-top:25px;

							&__bar{
								margin-top:5px;
								width: 100%;
  								background-color: #ededed;
								border-radius: 4px;
								min-height: 16px;

								&__insidebar{
									width: 85%;
									height: 100%;
									background: #ffba00;
									border-radius: 4px;
								}
							}
							&__getal{
								margin-bottom: 0px;
								font-size: 16px;
								font-weight: bold;
							}
						}
						&__benefits{
							background-color:#F8F8FA;
							padding:20px;
							margin-bottom:11px;
							border-radius: 8px;
							display: flex;
  							flex-direction: column;
							gap:15px;
							p { margin-bottom:0; text-align: left;}
							ul { margin-bottom:0;}
							ul li { text-align: left;}
							&:last-of-type{
								margin-bottom:0px;
							}
							&.achieved{
								.sparenvoorextras__huidiglevel__img{
									img { display: none; }
								}
								.sparenvoorextras__huidiglevel__punten img.benefit{
									display: none;
								}
							}
							&.locked{
								.sparenvoorextras__huidiglevel__img{
									position: relative;
									lottie-player{ opacity:0.5; }
									img { position:absolute; top: 16px;
										left: 16px;
										filter: invert(21%) sepia(12%) saturate(3912%) hue-rotate(197deg) brightness(83%) contrast(107%);}
								}
								.sparenvoorextras__huidiglevel__niveau, .sparenvoorextras__huidiglevel__punten{
									opacity: 0.5;
								}
								.sparenvoorextras__huidiglevel__punten img.unlocked{
									display: none;
								}
							}
							@include breakpoint(large){
								display: grid;
								grid-template-columns: 1fr 2fr 7fr;

								.sparenvoorextras__huidiglevel__niveau, .sparenvoorextras__huidiglevel__punten{
									padding-top:5px;
								}
							}
						}
					}
					.hoesparen-lijst{
						@include breakpoint(large){
							padding-right:40px; 
						}

					}
					.hoesparen{
						margin-top:50px;
						border-radius: 20px;
						@include breakpoint(large){
							margin-top:0px;
							overflow: scroll;
							overflow-x: scroll;
							height: 100%;
							overflow-x: hidden;
							//margin-right: -60px;
							-ms-overflow-style: none;  /* IE and Edge */
							scrollbar-width: none;  /* Firefox */
							padding: 30px;
							//margin-left: 50px;
							background-color:#f8f8fa;
							&::-webkit-scrollbar{
								display: none;
							}
							p:not(.hoesparen__title){
								margin-bottom: 0 !important;
							}
						}

						&__title{
							font-family: 'Lexend', sans-serif;
							font-size:16px;
							font-weight: 600;
							color:#1C2663;
							margin-bottom:11px;
							text-align: start;
							margin-top:20px;
							letter-spacing: -0.32px;
							&:first-of-type{
								margin-top:0px;
							}
							text-align: left;
						}
						&__todolist{
							margin-left:0px;
							li{
								list-style-type: none;
								display: flex;
								align-items: center;
								justify-content: space-between;
								gap:10px;
								background-color: white;
								border-radius: 100px;
								padding:5px 10px;
								margin-bottom: 5px;
								&:last-of-type{
									margin-bottom:0px;
								}
								&.achieved{
									div.text{
										text-decoration: line-through;
									}
									div.points{
										min-width:45px;
										text-align: center;
										img{
											filter: invert(16%) sepia(36%) saturate(2705%) hue-rotate(214deg) brightness(86%) contrast(102%);
											width:16px;
										}
									}
								}
							}
							div.left{
								display: flex;
								align-items: center;
								gap: 15px;
							}
							div.right{

							}
							div.icon {
								min-width:20px;
								img{
									filter: invert(56%) sepia(42%) saturate(1345%) hue-rotate(0deg) brightness(104%) contrast(105%);
									width:20px;
								}
								line-height: 1;
							}
							div.text{
								font-size: 14px;
								font-weight: 400;
								font-family: 'Poppins', sans-serif;
								width: fit-content;
								line-height: 1;
							}
							div.points{
								border: 1px solid #D3D3D3;
								border-radius: 100px;
								font-size:14px;
								font-weight: 400;
								font-family: 'Poppins', sans-serif;
								width: fit-content;
								padding:5px 10px;
								line-height: 1;

							}
						}
					}
				}
			}
			&__settings{
				&__header{
					@include header-text;
						text-align:left;
						font-size:1.5rem;
						font-weight:800;
						color:$color-blue;
						@include breakpoint(large){
							margin-bottom:10px;
							font-size:30px;
						}
				}
				&__menu{
					background: #FFFFFF 0% 0% no-repeat padding-box;
					box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
					border-radius: 8px;
					margin-bottom:30px;
					@include breakpoint(large){
						position:sticky;
						top: 90px;
						margin-bottom:0px;
						height: 100%;
					}
					&__header{
						font-weight:500;
						padding:19px 22px;
						border-bottom:1px solid #EAEAEA;
						color:$color-blue;
						@include header-text;
					}
					&__items{
						list-style-type:none;
						margin:22px 0px;
						a{
							display: flex;
							align-items: center;
							gap:13px;
							text-decoration: none;
							img{
								width:16px;
								filter: invert(56%) sepia(42%) saturate(1345%) hue-rotate(0deg) brightness(104%) contrast(105%);
							}
							&:hover{
								span{
									text-decoration: underline;
								}
							}
						}
					}
				}
				&__sections{

					@include breakpoint(large){
						//padding-left: 2.5rem!important;
					}
					&__container{
						background: #FFFFFF 0% 0% no-repeat padding-box;
						box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
						border-radius: 8px;
						padding:35px 22px;
						@include breakpoint(large){
							padding:60px;
						}
					}
				}
				&__section{
					&__header{
						@include header-text;
						text-align:left;
						font-size:1.3rem;
						font-weight:800;
						color:$color-blue;
						border-bottom:1px solid $color-business-blue;
						padding:15px 0px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						@include breakpoint(large){
							margin-bottom:10px;
							font-size:22px;
						}
						& > div {
							display: flex;
							align-items: center;
						}
						&__message{
							display: flex;
							align-items: center;
							color:#7E8088;
							letter-spacing: -0.28px;
							font-size: 14px;
							font-weight:300;
							strong{
								color:$color-business-blue;
							}
						}
						.important-icon{
							filter: invert(55%) sepia(8%) saturate(279%) hue-rotate(190deg) brightness(91%) contrast(92%);

						}
						img{
							width:16px;
							margin-right:10px;
							margin-left:10px;
						}
						&:hover{
							cursor: pointer;
						}
					}
					&__container{
						display: none;
						padding:35px 0px;
						&__subtitle{
							@include header-text;
							font-size:16px;
							font-weight:600;
							margin-bottom:20px;
							margin-top:35px;
							&:first-of-type{
								margin-top:0px;
							}
						}
						.checkout__form{
							padding:0px;
							border:none;
						}
						.checkout__form__row{
							flex-wrap: wrap;
							@include breakpoint(medium){
								flex-wrap:initial;
							}
						}
						.shareinterestpopup{
							.form-addinginterests{
								justify-content: flex-start;
							}
							.shareinterestpopup__box__submitknop{
								justify-content: flex-start;
							}
						}
					}
				}
			}
			&__notifications{
				
			}
		}
	}
	.certificate-box{
		width: 150px;
		height: 200px;
		border: 2px dashed transparent;
		position: relative;
		border-radius: 8px;
		img {
			width:100%;
			height:100%;
			object-fit: cover;
			border-radius: 8px;
		}
		&:after {
			display: block;
			position: absolute;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
			content: '';
			top:0;
			right:0;
			bottom:0;
			left:0;
			opacity: 0.5;
			border-radius: 8px;
			}
		.certificate-overlay{
			position:absolute;
			bottom: 0;
			left:0;
			width:100%;
			white-space: initial;
			padding:5px 10px;
			margin-bottom: 0;
			color:white;
			border-radius: 8px;
			z-index: 3;
			line-height: 1.2;
		}
		&.unfilled{
			border: 2px dashed rgba(28,38,99,0.18);
			&:after{
				display: none;
			}
		}
	}
}
.login-modal{
	padding:0px;
	border-radius: 12px;
	border:none;
	@include breakpoint(medium){
		width: 75vw;
		max-width:789px;
	}
	&__switch{
		@include header-text;
		&__current{
			background:white;
			padding:26px;
			font-size:1rem;
			font-weight:800;
			text-align:center;
			color:$color-blue;
			letter-spacing: -0.4px;
		}
		&__option{
			background:$color-blue;
			color:white;
			padding:26px;
			font-size:1rem;
			font-weight:800;
			text-align:center;
			letter-spacing: -0.4px;
			cursor:pointer;
			&__focus{
				color:$color-orange;
				text-decoration:underline;
				padding-left:10px;
			}
			&:hover{
				&__focus{
					text-decoration:none;
				}
			}
		}
	}
	&__content{
		padding:20px;
		@include breakpoint(medium){
			padding:50px;
		}
		&__title{
			font-size:1.95rem;
			font-weight:900;
			letter-spacing: -0.62px;
			@include header-text;
			color: $color-blue;
			text-align: left;
		}
		label{
			font-size:0.9rem;
			letter-spacing: -0.28px;
			@include header-text;
			color:$color-blue;
		}
		input[type='text'],input[type='password'],input[type='email']{
			border: 1px solid #1C2663;
			border-radius: 4px;
			margin-bottom:13px;
			padding: 15px 10px 12px 10px;
			min-height:47px;
			&::placeholder{
				@include header-text;
			}
		}
	}
}
.account-dropdown{
	box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
	border-radius: 8px;
	border:none;
	padding:0px!important;
	left: auto !important;
	right: 30px;
	top: 80px !important;
	width: 220px;
	&__menu{
		
		&__section{
			border-bottom:1px solid #EAEAEA;
			padding: 12px 14px;
			ul{
				list-style-type:none;
				margin:0px;
				li{
					margin:5px 0px;
					a{
						img{
							width: 15px;
							height: 15px;
							margin-right: 10px;
							object-fit: contain;
							filter: invert(13%) sepia(67%) saturate(1543%) hue-rotate(216deg) brightness(95%) contrast(100%);
							&.membership{
								width:18px;
								margin-right:7px;
							}
						}
						text-decoration:none;
						&:hover{
							text-decoration:underline;
						}
					}
				}
				&.logout{
					margin-top:5px;
					li{
						a{
							display:flex;
							align-items:center;
							column-gap:10px;
							svg{
								transform:scale(0.75);
								path{
									fill:$color-blue;
								}
							}
						}
					}
				}
			}
			&.credits{
				a:hover{ text-decoration: none;}
				span{
					.money{
						border:1px solid #d9d9d9;
						margin-left: 5px;
						padding-left: 8px;
						padding-right: 8px;
						border-radius: 8px;
					}
				}
			}
			&.header{
				font-weight:500;
				@include header-text;
				color:$color-blue;
			}
			&:last-of-type{
				border-bottom:none;
			}
			
		}
	}
&.mobile{
	z-index: 11000;
	width: 100% !important;
	top: 75px !important;
	right: 0px !important;
	position: fixed;
	height:1000px;
	display: block;

	.mobile-name{
		display:flex;

		li{
			padding-top: 10px;

			a{
				font-size:16px;
			}
		}
		button{
			top:auto;
			padding-left: 22px;
			border: 1px solid #1E225C;
			border-radius: 100px;
			padding-bottom: 10px;
			.new-back{
				font-size: 16px;
				font-weight: 500;
  				position: relative;
				font-family: 'lexend';
				margin-left: 0 ;
				text-decoration: none;
				padding-right: 15px;
				padding-top: 10px ;
				padding-bottom: 10px;
				color: #1E225C;
				
			}
		}
	}

	.mobile-text-section{
		a{
			font-size:16px;
		}
		&.credits{
			span{
				font-size:16px;
			}
		}
	}

	@include breakpoint(medium){
		width: 560px !important;
		top: 80px !important;
	}
}
}

.profile-avatar-field{
	display:flex;
	justify-content: center;
	@include breakpoint(medium){
		display:block;
	}
	.avatar-container{
		max-width:120px;
		max-height:120px;
		width:120px;
		height:120px;
		margin-top:10px;
		@include breakpoint(medium){
			margin-top:0px;
		}
	}

	.hoverprofilebg, .hoverprofileimg{
		opacity: 0;
		transition: opacity 400ms;
		position: absolute;
		height: 120px;
		width: 120px;
		border-radius:100%;
	}
	.profile-avatar{
		width:100%;
		padding-bottom:100%;
		border-radius:100%;
		text-align:center;
		background-color:$color-blue;
		position:relative;
		span{
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			color:white;
			font-weight:bold;
			@include header-text;
			font-size: rem-calc(60px);
		}
		&__personimage{
			background-position: center; 
			background-repeat: no-repeat; 
			background-size: cover;
		}
		@include breakpoint(medium){
			width:100%;
			padding-bottom:100%;
		}
		&:hover{
			.hoverprofilebg{
				background-color:#000;
				opacity: 50%;
			}
			.hoverprofileimg{
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
				opacity: 50%;
				background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCAyMmgtMjR2LTIwaDI0djIwem0tMS0xOWgtMjJ2MThoMjJ2LTE4em0tMSAxNmgtMTlsNC03LjQ5MiAzIDMuMDQ4IDUuMDEzLTcuNTU2IDYuOTg3IDEyem0tMTEuODQ4LTIuODY1bC0yLjkxLTIuOTU2LTIuNTc0IDQuODIxaDE1LjU5M2wtNS4zMDMtOS4xMDgtNC44MDYgNy4yNDN6bS00LjY1Mi0xMS4xMzVjMS4zOCAwIDIuNSAxLjEyIDIuNSAyLjVzLTEuMTIgMi41LTIuNSAyLjUtMi41LTEuMTItMi41LTIuNSAxLjEyLTIuNSAyLjUtMi41em0wIDFjLjgyOCAwIDEuNS42NzIgMS41IDEuNXMtLjY3MiAxLjUtMS41IDEuNS0xLjUtLjY3Mi0xLjUtMS41LjY3Mi0xLjUgMS41LTEuNXoiLz48L3N2Zz4=");
				background-repeat: no-repeat;
				background-size: 100px 100px;
				background-position: center;
			}
		}
	}
	#selfimage{
		.changedimg{
			width: 100% !important;
			height: inherit !important;
		}
	}
}

.profile-name{
	margin-bottom:20px;
	@include breakpoint(medium){
		margin-bottom: 0;
	}
	@include breakpoint(large){
		padding-left:25px!important;
	}
	a.name { display: flex; align-items: center; justify-content:center; gap:10px; position: relative; @include breakpoint(large){ justify-content: flex-start;}}
	a img.edit { width:25px; opacity: 0.5; transition: all 400ms; filter: invert(18%) sepia(7%) saturate(5545%) hue-rotate(194deg) brightness(99%) contrast(107%); position: absolute; right: -35px; @include breakpoint(large){ position: relative; right:unset;} }
	a:hover img.edit { opacity: 1; }
	
	
}
.favorite-link{
	&.selected{
		svg.notselected{
			display:none;
		}
	}
	&.notselected{
		svg.selected{
			display:none;
		}
	}
}
.alert-box{
	ul{ margin:0px; list-style-type:none; padding:0px; }
	ul li{ color:white!important;}

}
.alert-box.alert{
	border:1px solid red;
  	background-color: #f44336;
  	padding:20px;
  	color:white;
  	display:none;
	border-radius: 8px;
	margin-bottom: 15px;
}
.alert-box.success{
	border:1px solid green;
  	background-color: #04AA6D;
  	padding:20px;
  	color:white;
  	display:none;
	  border-radius: 8px;
	  margin-bottom: 15px;
}
.login-off-canvas{
	background:white;
	padding: 100px 25px;
	width: 100%;
	box-shadow: none;
	height: 100%;
	transform: translateX(100%);
	@include breakpoint(medium){
		width: 560px;
		transform: translateX(560px);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
	}
	@include breakpoint(large){
		padding:175px 75px;
		width: 620px;
    	transform: translateX(620px);
	}
	.close-button{
		color:$color-business-blue;
		font-size:4rem;
		outline: none;
		right: 2rem;
		top: 2rem;

		&:focus { outline: none; }
		span{
			font-size:4rem;

		}
		
	}
	&__intro{
		h2{
			@include breakpoint(large){
				font-size:rem-calc(30px);
			}
		}
		margin-bottom:57px;
	}
	&__form{
		margin-bottom:75px;
		input[type="radio"], input[type="checkbox"] {
            transform: scale(1.7); 
            margin-bottom:0px;
            margin-right: 22px;
            margin-left:5px;
        }
        input[type="radio"]{
            margin-right:12px;
        }
        input[type="text"],input[type="email"],input[type="tel"],input[type="date"],input[type="password"]{
            margin-bottom: 0px;
            border: 1px solid $color-business-blue;
            border-radius: 4px;
            padding:7px 13px;
            padding:16px 30px;
            font-size:rem-calc(15px);
            height:unset; 
            color:$color-business-blue;
			box-shadow: none;
            &::placeholder{
                color:#9798A2;
            }
            width:100%;
        }
        label{
            font-weight:600;
            color:$color-business-blue;
        }
		&__input-withbutton{
			position: relative;
			& > input { 
				padding-right:100px!important;
			}
			& > button{
				position: absolute;
				right:20px;
				top:16px;
				font-weight:600;
				color:$color-business-blue;
			}
		}
	}
	&__text{
		color:$color-business-blue;
		a{
			font-weight:600;
		}
	}
	&__usps{
		ul {list-style-type: none; margin:0;}
		ul li {display: flex; align-items: center;}
		ul li span { font-size:14px; }
		ul li svg { margin-right:10px; margin-bottom:5px; min-width:20px;}
		ul li svg path { fill: $color-business-blue;}
	}
}
.alert{
	ul{ margin:0px; list-style-type:none; padding:0px; }
	ul li{ color:white!important;}
}
.alert-danger{
	border:1px solid red;
	background-color: #f44336;
	padding:20px;
	color:white;
}
.alert-success{
	border:1px solid green;
	background-color: #04AA6D;
	padding:20px;
	color:white;
}
.instellingen-accountprofile{
	@include breakpoint(large){
		overflow: unset !important;
		height: fit-content;
		background: #f8f8fa;
	}
}
.instellingenpagina{
	.account__sidebar{
		@include breakpoint(large){
			position: fixed;
			top:74px;
		}
	}
}
.underpart_loyality{
	display:block; 
	height: initial;
	@include breakpoint(large){
		display:flex; 
		//height: 550px;
	}
}
.account__profile__content__settings__section__header__alert{
	margin-bottom:20px;
}
.eerstecolominspiratiefactory{
	margin-bottom:30px;
	@include breakpoint(medium){
		margin-bottom:0px;
	}
}
.content-divider_clubmembership{
	border-bottom: 1px solid #DDDDDD;
	clear: both;
	height: 0;
	margin: 1.25rem auto;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	max-width: initial;
	margin-bottom: 45px;
	margin-top:45px;
}

.membership-smalltext{
	padding-top:30px;
	padding-bottom:10px;
	font-size:14px;
	margin-bottom: 0!important;
	@include breakpoint(large){
		padding-top:50px;
		padding-bottom:50px;

	}
	font-family: 'Poppins', sans-serif !important;
	letter-spacing: -0.28px;
	color: #8E95B5;
	font-weight:300;
	width: 100%;;
	text-align: start !important;
	@include breakpoint(medium){
		max-width: 800px;
	}
}
.smallprogresbar_memebership{
	display:flex;
	margin-top:5px;
	align-items: center;
	&__bardiv{
		width: 66px;
  		height: 5px;
		position: relative;
		&__userbar{
			position: absolute;
			background-color: #FFBA00;
			height: 100%;
			border-radius: 4px;
			z-index: 2;
			max-width: 100%;
		}
		&__fullbar{
			position: absolute;
			width: 100%;
			background-color: #EDEDED;
			height: 100%;
			border-radius: 4px;
		}
	}
	&__text{
		font-size:12px;
		color: #1E225D;
		font-family: 'Poppins', sans-serif !important;
		margin-left: 5px;
	}
}
.offCanvaslevelinfo{
		position: absolute;
		top:50%!important;
		transform: translateY(-50%);
		margin-left:20px;
		margin-right:20px;
		@include breakpoint(medium){
			left:50%;
			transform: translate(-50%,-50%);
		}

	&__box{
		background: white;
		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.129);
		border-radius: 12px;
		margin: auto;
		text-align: center;
		padding: 30px;
		position: relative;
		@include breakpoint(medium){
			width: 500px;
		}
		&__title{
			font-size:22px;
			font-family: 'Lexend', sans-serif;
			color: #1C2663;
			font-weight:bold;
			text-align: left;
			margin-bottom: 30px;
		}
		&__actions{
			text-align: left;
			display:flex;
			flex-direction: column;
			gap:10px;
			margin-bottom: 8px;
			font-size:14px; 
			.text{
				color:white;
			}
		}
		&__textitle{
			text-align: left;
			font-family: 'Lexend', sans-serif;
			font-size: 16px;
			font-weight: bold;
			color: #1C2663;
			margin-top: 30px;
 			margin-bottom: 10px;
		}
		&__text{
			text-align: left;
			font-size: 16px;
			color: #1C2663;
		}
	}
}

.modalCertificate{
	position: absolute;
	top:50%!important;
	transform: translateY(-50%);
	margin-left:20px;
	margin-right:20px;
	@include breakpoint(medium){
		left:50%;
		transform: translate(-50%,-50%);
	}

	&__box{
		background: white;
		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.129);
		border-radius: 12px;
		margin: auto;
		text-align: center;
		padding: 30px;
		position: relative;
		@include breakpoint(medium){
			width: 500px;
		}
		@include breakpoint(large){
			width: 733px;
		}
		&__title{
			font-size:22px;
			font-family: 'Lexend', sans-serif;
			color: #1C2663;
			font-weight:bold;
			text-align: left;
			margin-bottom: 30px;
		}
		&__divider{
			height: 1px;
			background-color: #DDDDDD;
			margin: 45px 0px;
		}
		&__actions{
			text-align: left;
			display:flex;
			flex-direction: column;
			gap:10px;
			margin-bottom: 8px;
			font-size:14px; 
			.text{
				color:white;
			}
			.cf-button--blue{
				width:260px;
			}
		}
		&__fields{
			margin-bottom:30px;
			.checkout__form__row{
				flex-wrap: wrap;
				@include breakpoint(large){
					flex-wrap: nowrap;
				}
				& > div {
					width:100%;
					.jouwcode__kortingscode{
						width:70vw;
						@include breakpoint(medium){
							width:100%;

						}
						justify-content: space-between;
					}

					.jouwcode__kortingscode > span{
						text-align: left;
						overflow-x: scroll;
						-ms-overflow-style: none;  /* IE and Edge */
						  scrollbar-width: none;  /* Firefox */
						&::-webkit-scrollbar { 
							display: none;  /* Chrome, Safari, Opera*/
						}
					}
					@include breakpoint(large){
						width:310px;
						.jouwcode{
							width:310px;
						}
						.jouwcode__kortingscode > span{
							width:260px;
							text-align: left;
							overflow-x: scroll;
							-ms-overflow-style: none;  /* IE and Edge */
  							scrollbar-width: none;  /* Firefox */
							&::-webkit-scrollbar { 
								display: none;  /* Chrome, Safari, Opera*/
							}
						}
					}
					
				}
			}
		}
		&__textitle{
			text-align: left;
			font-family: 'Lexend', sans-serif;
			font-size: 16px;
			font-weight: bold;
			color: #1C2663;
			margin-top: 30px;
			margin-bottom: 10px;
		}
		&__text{
			text-align: left;
			font-size: 16px;
			color: #1C2663;
		}
	}
}
.verzamelingblade{
	@media only screen and (min-width: 642px) {
		min-height:91.1vh;
	}
	@media only screen and (max-width: 642px) {
		min-height:92vh;
	}
	@media only screen and (max-width: 380px) {
		min-height: 93vh;
	}
	@media only screen and (min-width: 1010px) {
		min-height:auto;
	}
}
#MoreInfoPersonality{
	display: none;
}