.totalResult {
  font-size: 1.25rem;
  font-weight: 600;
	background-color: #f8f8fa; 
	padding: 15px;
	width: 33.3%;
	text-align: center;
	border-radius: 5px;
	margin-bottom: 15px;
	

}
.option_new{
	background-color: #1dc246; 
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 15px;
}

.scroll-down .categorie__submenu > .row {
  margin-top:0px!important;
  transform: none;
  max-width:100%!important;
}

.scroll-up .sub-category__filter__container.sticky{
	top:150px;
}
.scroll-up .categorie__submenu > .row:not(.is-anchored) {
  margin-top:74px!important;
  transform: none;
  max-width:100%!important;
}
body:not(.scroll-up):not(.scroll-down).scroll-submenu .categorie__submenu > .row:not(.is-anchored) {
  transform: translate3d(0, -100%, 0);
  margin-top:0px!important;
  max-width:100%!important;

}
body .categorie__submenu{
	display:none;
}
body.scroll-up .categorie__submenu, body.scroll-down .categorie__submenu, body:not(.scroll-header).scroll-submenu .categorie__submenu{
	display:block;
}
.categorie__submenu{
	& > .row{
		background:white;
		transition: all 0.4s;
		z-index:95;
	}
}
.cluster-header {
  //background: white;
  height: 100%;
  position:relative; 
  //@include breakpoint(large){
  //	height: 600px;
  //}
  /*@include breakpoint(xlarge) {
  	background-position: center 40%;
  }*/
  &__text-background{
	display:none;
	.container{
		padding-right:0px;
	}
	@include breakpoint(large){
		display:block;
	  width:100%;
	  height:100%;
	  position: absolute;
	  z-index: -1;
	  overflow:hidden;
	  text-align:right;
	  @include header-text;
	  span{
		@include header-text;
		color:#00C4C2;
		font-size: 900px;
		font-weight:800;
		letter-spacing: -90px;
		line-height: 675px;
		display: inline-block;
		width: 60%;
	  }	
	  
		
	}
		@include breakpoint(xlarge){
			span{
				width:60%;
			}
		}
  }
  &__background{
		position:relative;
		height:350px;
		padding-right:0px;
		@include breakpoint(medium){
			height: 430px;
		}
		@include breakpoint(large){
			height:700px;
			padding-right: 0.9375rem;
		}
		&__text{
			overflow:hidden;
			//background:rgba(32, 27, 102, 0.5);
			//background:rgba(0, 0, 0, 0.4);
			color:#00C4C2;
			font-size: 300px;
			font-weight:800;
			letter-spacing: -32px;
			line-height: 255px;
			text-align:right;
			@include header-text;
			span{
				@include header-text;
				display: inline-block;
				overflow: hidden;
				font-size: 350px;
				width: 90%;
				@include breakpoint(medium){
					font-size: 400px;
				}
			}
			@include breakpoint(medium){
				line-height: 400px;
			}
			@include breakpoint(large){
				display:none;
			}
		}
		&__overlay{
			position:absolute;
			display:flex;
			left:0;
			bottom:0px;
			z-index:30;
			justify-content:center;
			width:100%;
			@include breakpoint(medium){
				width:unset;
			}
			@include breakpoint(large){
				bottom:-15px;
			}
			&__reviews{
				width: 87%;
				display:flex;
				position:relative;
				z-index:30;
				&.training_overlay_review{
					@include breakpoint(medium){
						margin-left:-7%!important;
						width:80%;
					}
				}
				img{
					width:100px;
					margin-right: -1px;
					//object-fit:cover;
				}
				&__text {
					padding:15px;
					padding-right:10px;
					background-color:$color-blue;
					display:inline-block;
					color:white;
					display:flex;
					width: 100%;
					@include breakpoint(medium){
						width: 100%;
						padding: 15px;
					}
					@include breakpoint(large up){
						padding:15px;
						width: 100%;
					}
					.start__reviews__ratings__text {
						font-size:rem-calc(14);
					}
					align-content:center;
					a{ color:white; font-weight:bold; text-decoration:underline; font-size: 14px;
						&:hover{
							text-decoration:none;
						}
					}
					&__icon{
					margin-top:5px;
					}
				}
			}
			&__springest_rewards_2023{
				background-color: $color-business-blue;
				position: absolute;
				bottom: 25px;
				right: 2rem;
				z-index: 40;
				border-radius: 2px 12px 12px 12px;
				padding: 20px;
				justify-content: center;
				display: flex;
				flex-direction: column;
				&__title{
					color:white;
					font-weight:600;
					font-size:12px;
					font-family:'Lexend','sans-serif';
					margin-bottom:3px;
					text-align: center;
				}
				&__text{
					color:white;
					font-size:11px;
					font-family:'Poppins','sans-serif';
					text-align: center;
				}
				img{
					//filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(302deg) brightness(103%) contrast(103%);
					height: 60px;
  					object-fit: contain;
				}

				&.AI-campagne_spingest2023{
					position: relative;
					height: fit-content;
					padding: 10px;
					margin-top: 20px;

					@include breakpoint(large up){
						margin-top: 67px;
					}

					@media only screen and (min-width: 1380px) {
						margin-left: -5%;
					}


					.cluster-header__background__overlay__springest_rewards_2023__text{
						line-height: 1;
  						margin-bottom: 0.5rem;
					}
				}
			}
			
		} 
		&__image{
			width: 55%;
			@include breakpoint (medium){
				width: 50%;
				height:350px;
				margin-left: 70px;
			}
			&.training_overlay_image{
				@include breakpoint (medium){
					margin-left: 0px;
				}
			}
			@include breakpoint (large){
				width: 70%;
				height: 75vh;
				min-height:550px;
			}
			position:absolute;
			overflow: hidden;
			bottom:80px;
			@include breakpoint (medium){
				bottom:60px;
			
			}
			@include breakpoint (large){
				bottom:80px;
			
			}
			
			z-index:5;
		
			img{
				max-height: 260px;
				@include breakpoint (medium){
					position: absolute;
					bottom: 0px;
					//height:100%;
					max-width:90%;
					max-height: 320px;
					&.subcluster{
						max-height: 357px;
					}
				}
				@include breakpoint(large){
					bottom: 0px;
					max-height: 70vh;
				}
			}
		}
		&__container{
			position:relative;
			img{
				object-fit: cover;
				width:100%;
				@include breakpoint(large){
				z-index:10;
				}
			}
		}
		&__designation{
			position:absolute;
			color:$color-blue;
			z-index:11;
			p{
				color:$color-blue;
				padding:10px 15px;
				margin-bottom:0px;
				font-size:rem-calc(14);
				line-height: 1.3;
				background-color:#fff;
				box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
				display:inline-block;
				margin-left:5px;
			}
			p:first-of-type{
				margin-left:0px;
			}
			strong {
				@include header-text;
			}
			left: 9%;
			bottom:105px;
			@include breakpoint(medium up){
				top:initial;
				left: 7.5%;
				bottom:105px;
			}

			&.training_overlay_designation{
				@include breakpoint(medium up){
					left: 7.5% !important;
				}
			}
		}
		&__badge{
			width: 150px;
    		margin-bottom: 20px;
		}
	}
  
  &__content{
  	position: relative;
    z-index: 2;
  	&__text{
  		margin-bottom:25px;
  		padding:0 2rem;
  		@include breakpoint(large){
  			margin-bottom:0px;
  			padding:0 0.9375rem;
  			padding-top:40px;
  		}
  		&__container{
  			@include breakpoint(large){
  				width:80%;
  			}
  			h1 {
				font-weight: 800;
				font-size: 2.5rem!important;
				line-height: 1.1!important;
				letter-spacing: -2px;
				margin-top: 30px;
  				@include breakpoint(medium){
  					font-size:2.4rem;
					margin-top: 10px;
  					padding-right:0px!important;
  					line-height: 1.2!important;
  					letter-spacing: -1px;
  					margin-bottom:1rem;
					
  				}
				@include breakpoint(large){
					margin-top:0px;
					font-size: 45px!important;
				}
  			}
  		}
  		&__buttons{
  			margin:35px 0px; 
  			@include breakpoint(medium){
  				margin:20px 0px 20px; 
  			}
  			&__button{
  				a{ width:100%; text-align:center; margin-bottom:20px;}
  				&:last-of-type a{ margin-bottom:0px; }
  				@include breakpoint(medium){
  					display:inline-block;
					&::after{
						content:"\2022";
						margin:0px 20px;
						color:$color-business-blue;
						content:" ";
						margin:0px 5px;
					}
					&:last-of-type::after{
						display:none;
					}
					
					a {
						width:inherit;
						text-align:left;
						margin-bottom:0px;
					}
					margin-bottom:15px;
				}
  			}
  			
  		}
  		&__more{
  			display:none;
  		}
  		&__subclusters{
  			font-weight:600;
  			padding-bottom:8px;
  			border-bottom:1px solid $color-business-blue;
  			width:80%;
  		}
  	}
  }
  &__container {
    height: 100%;
    padding-top: 50px;
    position:relative;
    z-index:2;
    @include breakpoint(medium){
    	height: 600px;
    }
	& .row{
		height:100%;
	}
	&__left{
		padding-right: 0.625rem;
    	padding-left: 0.625rem;
    	margin-bottom:20px;
    	@include breakpoint(medium){
    		margin-bottom:0px;
    	}
	
	}
	&__right{
		background-color:white;
    	@include breakpoint(medium){
    		background-color:transparent;
    	}
	
	}
  }
  
  &__subtext {
  		@include raleway;
  		font-weight:500;
    	
		@include breakpoint(medium) {
		  text-align:left;
    	  padding-bottom:20px;
    	  max-width:80%;
		}
		
		&.rating{
			border-radius: 10px;
    		background: #f2f8ff;
    		display:flex;
    		margin-bottom:20px;
    		.start__reviews__ratings__text{
    			display:inline-block;
    		}
    		@include breakpoint (medium){
    			margin-bottom:initial;
    			background:transparent;
    			display:block;
    		}
		}
  }
  .inline-spacer { display:block; }

  &__buttons {
	.cf-button--orange-flat, .cf-button--blue-alt {
		display: inline-flex!important;
		width: 100%!important;
		justify-content: space-between;
		@include breakpoint(large up){
			width: 70%!important;
		
		}
	}
	.cf-button.shadow {
		margin-right:0px;
		margin-bottom:10px;
	}  	
	@include breakpoint(400px) {
		.cf-button.shadow {
			margin-right:40px;
			margin-bottom:0px;
		}
		.inline-spacer { display:inline; }
  	}  	
	@include breakpoint(medium) {
	  text-align:left;
	}
	
  }
  &__date {
  	font-weight:600;
  	color:$color-orange;
  }
  
	
  @include breakpoint(400px) {
    //height: 550px;
  }

  @include breakpoint(medium) {
    
  position:relative; 
    //height: 575px;
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 250px;
    }
    
    &__container {
      height: 575px;
      padding-top: 50px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 45%;
	  background-position: 95% 100%;
    }
    
  }

  @include breakpoint(large) {
    height: 700px;
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 700px;
      padding-top: 60px;
      padding-left: 10px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 35%;
	  background-position: 95% 100%;
    }
    &__subtext {
    	display:block;
    }
    &__date{
    	margin-bottom: 0.5rem;
    }
  }

  @include breakpoint(xlarge) {
    height: 700px;
	margin-top:0px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 700px;
      padding-top: 70px;
      padding-left: 20px;
      background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
      background-repeat:no-repeat;
      background-size: 35%;
	  background-position: 95% 100%;
    }
  }

  @include breakpoint(xxlarge) {
    height: 700px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 700px;
      padding-top: 70px;
      padding-left: 20px;

    }
  }
  
  &__title {
    @include raleway;
    font-family:"Lexend",sans-serif;
    font-weight: 800;
    width: 100%;
    font-size: 3.1rem;
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    @include breakpoint(medium){
    	padding-right: 20px;
    } 

    @include breakpoint(medium) {
      font-size: 3.4rem;
      text-align:left;
    }
    @include breakpoint(large) {
      font-size: 4rem;
      max-width: 1200px;
	  padding-bottom:20px;
    }
    @include breakpoint(xlarge) {
      font-size: 4rem;
    }

  }
  
  &__subtitle {
    @include raleway;
    font-weight: 500;
    width: 100%;
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    text-align:center;
    font-size: 1rem;

    @include breakpoint(360px) {
      font-size: 1rem;
    }

    @include breakpoint(420px) {
      font-size: 1rem;
    }

    @include breakpoint(medium) {
      font-size: 1rem;
      font-weight: 800;
      text-align:left;
    	
    }
    @include breakpoint(large) {
      font-size: 1.5rem;
      max-width: 1200px;
      padding-bottom:20px;
    }
    @include breakpoint(xlarge) {
    }

  }

  &__action {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: rem-calc(24px);
  }

  &__button {
    width: 280px;
    position: absolute;
    top: 250px;
    left: 350px;
  }
	&--subcluster{
		padding-bottom:0px!important;
    	@include breakpoint(large) {
		height: inherit;
		}
		.cluster-header__text-background span{
		    font-size: 600px;
			font-weight: 800;
			letter-spacing: -60px;
			line-height: 450px;
			width:55%;
		}
		.cluster-header__background{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__overlay{
    		@include breakpoint(large) {
				bottom:-20px; 
			}
		}
		
		.cluster-header__background__image{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__image img{
			max-height:260px;
    		@include breakpoint(medium) {
				max-height:360px;
    		
    		}
    		@include breakpoint(large) {
				max-height:380px;
    		
    		}
		}
	}
	&--stap{
		background-color:#00BEBC;
    	@include breakpoint(large) {
		height: inherit;
		}
		.cluster-header__text-background{
			z-index: 0;
		}
		.cluster-header__text-background span{
		    font-size: 600px;
			font-weight: 800;
			letter-spacing: -60px;
			line-height: 450px;
			width:70%;
		}
		.cluster-header__background{
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__designation{
			bottom:20px;
			left:20px;
    		@include breakpoint(large) {
				right:20px;
				left:unset;
			
			}
		}
		.cluster-header__background__overlay{
    			width:100%;
    		@include breakpoint(large) {
				bottom:0px; 
			}
		}
		.cluster-header__background__text{
			line-height: 310px;
			@include breakpoint(medium){
				line-height: 400px;
				
			}
			span{
				@include breakpoint(medium){
					font-size:500px;
				
				}
			
			}
		}
		.cluster-header__background__image{
			bottom:0px;
    		@include breakpoint(medium) {
				height:450px;
			}
		}
		.cluster-header__background__image img{
			max-height:450px;
		}
		.cluster-header__content{
		position:relative;
		z-index:2;
		}
		.cluster-header__content__text{
			&__container{
				padding-top:40px;
				@include breakpoint(large){
					padding-top:0px;
					width:100%;
				}
				h1,p{ color:white; }
				h1 { }
				p { font-size:rem-calc(18px);}
				p a {color:white; font-weight:bold;}
				a { width: unset;}
			}
		}
	}
	  
}

.categorie-header {
  margin-bottom: 1rem;
  //@include linear-gradient(top, #ffffff, $color-mobile-gradient 350px);
  //border-bottom: 2px solid $color-light-primary;

  @include breakpoint(medium) {
    min-height: 284px;
    margin-bottom: 1.5rem;
    background: none;
    border-bottom: none;
  }

  &__title {
    font-weight: 800;
    font-size: rem-calc(24);
    color: $color-business-blue;
    margin-bottom:25px;
    margin-top:25px;
    @include breakpoint(medium) {
      margin-top:0px;
      font-size: rem-calc(28);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4), 1px 1px 5px rgba(255, 255, 255, 0.05);
    }

    img {
      float: right;
      margin: 0 0 0 15px;

      @include breakpoint(medium) {
        float: none;
        display: inline-block;
        margin: -3px 0 0 0.5rem;
      }
    }
  }
  .page__intro{
  	font-size:1rem;
  	color: $color-black;
  }
}

.categorie {
	&__container {
		
	}
	
	&__banner {
      	@include breakpoint(medium) {
			margin-bottom:1.5rem;
		}
		&__image{
			background-size: cover;
    		background-position: center center;
    		width: 100%;
    		height: 300px;
    		border-radius:10px;
    		@media print {
				/*background-size: cover!important;
				background-position: center center!important;
				-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
				/*color-adjust: exact !important;                 /*Firefox*/
				display:none;
			}
		}
	}
	&__sidebar {
		.training-links{
			&__title{
				
			}
			&__list{
				font-size:1rem;
			}
			@include breakpoint(medium){
				margin-bottom:1.5rem;
			}
		}
		
		&__readmore{
			cursor:pointer;
			text-decoration:underline;
			color:$color-business-blue-lighter;
			text-align:center;
			@include breakpoint(medium){
				text-align:left;
				margin-bottom:1.5rem;
			}
			&:hover, &:active{
				text-decoration:none;
			}
		}
	}
	&__overview{
		background-color:#fafafb;
		padding-top:25px;
		position:relative;
      	@include breakpoint(medium) {
      		border: 1px solid $color-medium-light-gray;
			border-radius: 8px 0px 0px 8px;
			padding:30px;
      	}
		
		&__title{
			font-weight:750;
			margin-bottom:25px;
			font-size:1.5rem;
			text-align:center;
			color: $color-black;
      		@include breakpoint(medium) {
      			text-align:left; 
      		}
		}
		
		&__filter{
			display:flex;
			margin-bottom:25px;
			&__option{
				background-color:#fff;
				color: #191e58;
				// border:1px solid $color-lightest-gray;
				text-align:center; 
				// width:33.3%;
				width:100%;
				padding:5px 0px;
				font-weight:600;
				font-size:0.9rem;
				cursor:pointer;
				@include transition(background 400ms, color 400ms);
				&.selected, &:active, &:hover, &:focus {
					background-color:$color-business-blue-light;
					color:white;
					border:1px solid $color-darker-gray;
				}
				
			}
			// &__option:not(:first-of-type):not(:last-of-type){
			// 	border-left:0px;
			// }
			// &__option:first-of-type{
			// 	border-right:1px solid $color-lightest-gray;
			// 	border-radius: 10px 0px 0px 10px;
			// }
			// &__option:last-of-type{
			// 	border-left:0px;
			// 	border-radius: 0px 10px 10px 0px;
			// }
			
			
			&__grid {
				row-gap:25px;
				&.mobile-container{
					padding-left:1rem!important;
					padding-right:1rem!important;
				}
			}
			&__list {
				display:none;
			}
			&__barebonelist {
				display:none;
			}
			
			
		}
		
		
	}
	
}
.mobile-filter{
		position:relative;
		&__button{
			margin-bottom:0px;
			height:32px;
			font-size: 12px;
			font-weight:600;
			padding-left:2rem;
			padding-top:7px;
			padding-bottom:7px;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 34, 92%29'></polygon></svg>"), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style="fill: rgb%2830, 34, 92%29" d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>');
			background-origin: content-box, content-box;
			background-position: right -1rem center, left -1.5rem center;
			background-repeat: no-repeat, no-repeat;
			background-size: 9px 6px, 18px 15px;
			//border: 1px solid $color-blue;
			color:$color-blue;
		}
		&__expand{
			display:none;
			z-index: 2;
			&__close-button{
			text-align:right;
			}
			position:absolute;
			right:20px;
			background:white;
			border-radius:8px;
			width:70%;
			padding:10px;
			box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
			.tabbing.categories{
				display:block;
				white-space:initial;
				li{
					display:flex;
					width:100%;
					justify-content:space-between;
					border:none;
				}
			}
		}
	}
.sub-category{
	position:relative;
	//margin-top:25px;
	padding-top:25px;
	&__banner{
		& > .row {
			position:relative;
		}
		&__background{
			position:absolute;
			height:100%;
			width:98%;
			right:0px;
			background:beige;
			border-radius: 0px 0px 0px 70px;
			z-index:-1;
		}
		&__text{ 
			margin:0 auto;
			padding:75px 5%;
			@include breakpoint(medium){
				padding:100px 15%;
			
			}
			@include breakpoint(large){
				padding:100px 30%;
			
			}
		}
		&__title{
			text-align:center;
		}
		&__subtext{
			text-align:center;
		}
	}
	
	&__filter{
		position:relative;
		margin-bottom:25px;
		@include breakpoint(medium up){
			margin-bottom:0px;
		}
		& > .row{
			justify-content: center;
			align-content: center;
		}
		&__container{
			//border-radius: 4px;
			//box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
			//border:1px solid #1C2663;//border: solid 0.3px rgba(2,37,67,0.419);
			background-color: #fff;
			&.sticky{
			position: sticky;
    		top: 90px;
    		@include transition(top 400ms, box-shadow 400ms);
			}
		}
		/*tabbing */
		.tab-head {
			display: flex;
			justify-content: space-between;
			column-gap:10px;
			padding: 0px;
			border-bottom:1px solid #D6D6D6;
			overflow:scroll;
			&.view{
				//padding: 15px;
				padding: 15px 0px;
			}
			@include breakpoint(medium up){
				padding: 15px 0px;
				flex-wrap:wrap;
				overflow:visible;
			}
			&:first-of-type{
				padding-top:0px;
			}
			&.noborder{
				border-bottom:0px!important;
			}
		} 
		.tab-head h6 {
			font-size: 15px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			padding-bottom: 10px;
		   /* border-bottom: 2px solid #747AA557;*/
			margin-right: 40px;
			margin-bottom: 5px;
		}

		ul.tabbing{
			margin: 0px;
			padding: 0px;
			list-style: none;
			display:flex;
			@include breakpoint(medium up){
				flex-wrap:wrap;
			}
		}
		.tab-title{
			flex-basis: 100%;
			margin-top: 0px;
		}
		.tab-title:nth-child(2) {
			//flex-basis: 40%;
		}
		ul.tabbing li.current {
			background: #106bc6;
			color: #fff;
			span {
				color: white;
			}
			svg {
				position:relative;
				top:2px;
				path{
					fill:white;
				}
			}
		}
		.verticle-tabbing ul.tabbing li.current {
			color: #fff;
			border:none; //border: solid 0.5px #707070;
			background-color: #1a2c6c;   
			color: #fff;
		}
		.verticle-tabbing .tab-content{
			margin-top: 0px;
		}
		.verticle-tabbing .tab-content strong {
			padding: 20px 0px;
			display: block;
		}

		ul.tabbing li {
			background: none;    
			display: inline-block;
			white-space: nowrap;
			padding: 5px 15px;
			cursor: pointer;
			font-size: 12px;
			line-height: 20px;
			border:none; //border: solid 0.5px rgba(30,34,92,0.462);
			background-color: #fff;
			margin-right: 0px;
			flex-grow:1;
			flex-basis: 50%;
			svg {				
				position:relative;
				top:2px;
			}
			&:hover{
				background: #106bc6;
				color: #fff;
				
				svg {		
					path{
						fill:white;
					}
				}
				@include transition(all 400ms);
			}
			&.type{
				@include breakpoint(large up){
					flex-basis:100%;
				}
			}
		}
		ul.tabbing.categories li {
			display:flex;
			justify-content:space-between;
		}
		.verticle-tabbing ul.tabbing li {
			display: block;
			border-radius: 2px;
			border: transparent;
			font-weight: 800;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: left;
			color: #1e225c;
			font-size: 20px;
			position: relative;
			width: 350px;
			padding: 15px;
			margin-left: 50px;
			-webkit-transition: all .4s ease; 
			-o-transition: all .4s ease;
			-moz-transition: all .4s ease;
			transition: all .4s ease;

		}
		.verticle-tabbing li.tab-link:hover {
			background-color: #1a2c6c;
			color: #fff !important;
		}
		.verticle-tabbing ul.tabbing li span{
			 font-size: 16px;


		}
		
		.tab-bottom{
			display: flex;
			gap:10px;
			flex-wrap:wrap;
			&__item{
				border: 1px solid #C2C7CC;
				border-radius: 8px;
				padding:15px;
				width:100%;
				&.usps{
					font-size:0.8rem;
					div{
						margin-bottom:5px;
						&:last-of-type{
							margin-bottom:0px;
						}
					}
					div > span{
						padding-left:25px;
						display:inline-block;
					}
					
				}
				&.keuzehulp{
					font-weight:600;
					font-size:0.8rem;
					color:$color-blue;
					a {
						color:$color-orange;
						&:hover{
							text-decoration:none;
						}
					}
				}
			}
			
		}
	
	}

}


