.cart-gadget {
  &__image {
    width: 100%;
  }

  &__title {
    color: $black;
    font-size: 1.25rem;
    font-weight: 800;
    margin: 0;
  }

  &__text {
    margin: 0.5rem 0 0;
  }
}