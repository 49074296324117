.trainer-container {
	@media print{
		margin-top:100px;
	}
}
.trainerpage > .column {
	padding:0px;
}
.trainers{
	&__overview{
		&__trainer{
			h4{
				color:#ff4f57;
				font-size:1.2rem;
				margin-top:10px;
				margin-bottom:5px;
			}
			p{
				color:$color-business-blue;
			}
		}
	}
	&__nextup{
		margin-top:30px;
    	@include breakpoint(medium) {
			padding-left:2%!important;
			padding-right:2%!important;
		}
		&__text{
			@include breakpoint(large) {
				padding:0px;
			}
			&__container{
				margin:auto auto;
				width:94%;
				background-color:white;
				box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.16);
				padding:30px!important;
    			@include breakpoint(medium) {
					width:98%;
    			}
				@include breakpoint(large) {
    				margin:inherit;
					width:100%;
					padding:50px!important;
				}
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
		&__cta{
			padding-top:50px;
		}
		&__media {
			text-align:center;
    		@include breakpoint(large) {
				padding-left:0px;
				padding-right:0px;
			}
			&__movie, &__image {
				margin:auto auto;
				width:94%;
				height: 100%;
				
    			@include breakpoint(medium) {
    				margin:inherit;
					width:98%;
					height: 240px;
    			}
    			@include breakpoint(large) {
    				width:100%;
    				height:100%;
    			}
			}
			
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
				@include breakpoint(medium) {
    				height:100%;
				}
				@include breakpoint(xlarge) {
    				height:100%;
				}
			}
		
		}
		
		
	}
	
	&__upcoming{
		&__text {
			margin-bottom:20px;
		}
		&__event{
			margin-top:50px;
			&__container{
				width:94%;
				background-color:white;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
				border-radius: 5px;
				border: 1px solid #EDEDED;
				height:inherit;
				margin:auto auto;
			}
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
    			@include breakpoint(medium) {
					height:240px;
				}
    			@include breakpoint(xlarge) {
					height:340px;
    			}
			}
			&__information{
				padding:30px;
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
	}
	
	&__planned{
		background-color: $color-lighter-gray;
		&__event{
			margin-top:50px;
			&__container{
				width:94%;
				background-color:white;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
				border-radius: 5px;
				border: 1px solid #EDEDED;
				padding:30px;
				height:inherit;
				margin:auto auto;
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
	}
	&__images{
		&__row{
			padding-top:40px!important;
			@include breakpoint(large) {
				padding:40px 0px!important;
			}
			
		}
		&__slider{
			&__image{
				div {
					padding:20px;
				}
			}
		}
		.slick-dots li button:before {
			font-size:1.8rem;
		}
		.slick-prev:before, .slick-next:before{
			color:black;
			font-size:1.5rem;
		}
	}

}
.trainer {
	&__actiebalk {
		background:#02ABAC;
		&__row{
			.column {
				text-align:center;
				color:white;
				padding:7px 0px;
				font-size:0.95rem;
				.bold{
					font-weight:bold;
				}
			}
		}
	}
	&__banner{
		background-position: center center;
		background-size: cover; 
		&__image{
			padding:60px 0px; 
			background-position: center center;
			background-size: cover; 
			background: rgba(32, 27, 102, 0.5);
		}
		&__title{
			&__div{
				display:flex;
				justify-content: center;
			}
			h1{
				font-size:30px;
				text-align:center;
				color: #1E225D;
				font-family: 'Lexend', 'sans-serif';
				font-weight: 800;
				max-width: 100%;
				margin-top: 100px;

				@include breakpoint(large) {
					max-width: 60%;
					font-size:45px;
				}
			}
		}
		&__data{
			color:white;
			margin-top:20px;
			&__date, &__location, &__price{
 				@include raleway;
				font-size:1.15rem;
				text-align:center;
				font-weight:bold;
				.fa, .svg-inline--fa {
					font-size:1.5rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
				}
			}
		}
		&__intro{
			margin-top:20px;
			text-align:center;
  			@include raleway;
			p {
				font-size:1.15rem;
				color:white;
				font-weight:600;
			}
		}
		&__signup{
			text-align:center;
			margin-top:20px;
		}
		&__signup-subtext{
  			@include raleway;
			margin-top:15px;
			text-align:center;
			font-weight:bold;
			p {
				font-size:0.9rem;
			
			}
			color:white; 
		}
		.cf-button{
			@include breakpoint (small only){
				height: auto;
				padding:12px 7px!important;
				&__text{
					margin:0px!important;
					padding:0px;
				}
			}
			
		}
		&--sharper {
		
		  .training__banner-overlaywithoutimage {
			opacity: 1;
			background:none;
		  }
	  
			@media print{
				height:175px;
			}
		}
	}
	&__intro {
		&__text{
			padding-bottom:60px;
    		@include breakpoint(large) {
    			padding-bottom:0px;
    		}
			h2 {
				margin-bottom:25px;
			}
			p {
  				@include raleway;
				font-size:1.15rem;
				margin-bottom:25px;
			}
			a {
				font-size:1.15rem;
				font-weight:600;
				.fa, .svg-inline--fa {
					font-size:1.5rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
				}
			}
		}
		&__movie {
			small{ 
				font-style:italic;
				font-size:1.15rem;
				color: $color-dark-gray-light;
			}
			iframe {
				width:100%;
				height:315px;
			}
		}
		& .row-divider{
			position:absolute;
			display:block;
			width:0px;
			height:0px;
			content:'\00a0';
			border-left: 40px solid transparent;
			border-right: 40px solid transparent;
			border-top: 40px solid white;
			left:50%;
			margin-left:-40px;
			margin-top:60px;
		}
	}
	&__speakers{
		background-color:$color-blue-ultralight;
		&__row{
			padding:40px 0px;
			padding-bottom:0px;
		}
		&__speaker{
			&__padding{
				margin:10px;
			}
			&__photo{
				height:300px;
				border-radius:10px;
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center center;
				background-color:gray;
				margin-bottom:20px;
			}
			&__name{
  				@include raleway;
				font-size:1.2rem;
				font-weight:700;
			}
			&__function{
  				@include raleway;
				font-size:0.9rem;
			}
			padding-bottom:40px;
		}
		.slick-dots li button:before {
			font-size:1.8rem;
		}
		.slick-prev:before, .slick-next:before{
			color:black;
			font-size:1.5rem;
		}
		&__cta{
			text-align:center;
			a{
  				@include raleway;
				font-size:1.15rem;
				font-weight:600;
			}
		}
	}
	&__program{
		&__timeline{
			padding-bottom:60px;
    		@include breakpoint(medium) {
    			padding-bottom:0px;
    		}
    		h3{
    			text-transform:uppercase;
    		}
		}
		&__item{
			& .small-3{
				background-image:url('/images/timeline-dash.png');
				background-repeat:repeat-y;
				background-position:49% 0%;
				background-size:5px;
  				display: flex;
  				align-items: center;
			}
			& .small-3.starttimeline:before{
				position:absolute;
				content: '\00a0';
				background-color:white;
				width:50px;
				height:50px;
				margin-top:-40px;
    			@include breakpoint(medium) {
    			
    			}
				
			}
			& .small-3.endtimeline:before{
				position:absolute;
				content: '\00a0';
				background-color:white;
				width:50px;
				height:50px;
				margin-top:40px;
    			@include breakpoint(medium) {
    			
    			}
			
			}
			&__icon{
				width:64px;
				height:64px;
				z-index:4;
				position:relative;
    			@include breakpoint(medium) {
					width:80px;
					height:80px;
				}
				border-radius:50%;
				text-align:center;
				background-color:white;
				box-shadow: 0 1px 4px 1px rgba(0,0,0,0.26);
				& img{
					margin-top:12px;
					width:40px;
					height:40px;
    				@include breakpoint(medium) {
						margin-top:15px;
						width:50px;
						height:50px;
					}
				}
				
			}
			&__data{
				padding-top:20px;
				padding-bottom:20px;
    			@include breakpoint(medium) {
					padding-top:30px;
					padding-bottom:30px;
    			}
			}
			&__time{
 				 @include raleway;
				font-size:1.15rem;
			}
			&__title{
				
 				@include raleway;
				font-size:1.15rem;
				font-weight:700;
			}
		}
		&__who {
  			@include raleway;
			&__icon{
				margin-left:auto;
				margin-right:auto;
    			@include breakpoint(medium) {
					margin-left:0;
					margin-right:0;
    			}
				width:80px;
				height:80px;
				border-radius:50%;
				margin-bottom:10px;
				text-align:center;
				box-shadow: 0 1px 4px 1px rgba(0,0,0,0.26);
				& img{
					margin-top:15px;
					width:50px;
					height:50px;
				}
			}
		}
		&__where {
  			@include raleway;
			margin-top:50px;
			&__icon{
				margin-left:auto;
				margin-right:auto;
    			@include breakpoint(medium) {
					margin-left:0;
					margin-right:0;
    			}
				width:80px;
				height:80px;
				border-radius:50%;
				margin-bottom:10px;
				text-align:center;
				box-shadow: 0 1px 4px 1px rgba(0,0,0,0.26);
				& img{
					margin-top:15px;
					width:50px;
					height:50px;
				}
			}
		}
	}
	&__cta{
		background-color: $color-business-blue;
		background: 
			/* top, transparent red, faked with gradient */ 
			linear-gradient(
			  rgba(30, 34, 93, 0.9), 
			  rgba(30, 34, 93, 0.9)
			),
			/* bottom, image */
			url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2019/04/17fd32ac-e683-40d9-91d4-5be030745aba.jpg');
			background-repeat:no-repeat;
			background-size:cover;
		&__usps{
			list-style-type:none;
			margin-left:0px;
			&__usp {
				display:inline-block;
				width:80%;
			}
			& li {
  				@include raleway;
				font-size:1.15rem;
				color:white;
				font-weight:bold;
				margin-top:30px;
				.fa, .svg-inline--fa {
					font-size:1.5rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
					vertical-align:top;
				}
			}
		}
		&__form{
			padding-bottom:30px;
    		@include breakpoint(medium) {
    			padding-bottom:0px;
    		}
		}
		h2 { color:white; text-transform:initial; }
		& .evenementform {
			margin-top:35px;
			margin-bottom:0px;
			& .column { padding:0px; }
			& .evenement-aanmelden__form input, .evenement-aanmelden__form button{
				width:100%;
				margin-bottom:20px;
			}
			& .evenement-aanmelden__form button{
				padding-top: 0px !important;
			}
		}
	}
	&__speakers-about{
		text-align:center;
		h2{
			margin-bottom:35px;
		}
		&__speaker{
			margin-bottom:35px;
		}
		&__image{
			width:125px;
			height:125px;
			border-radius:50%;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center center;
			display:inline-block;
			margin-bottom:35px;
			
		}
		p {
			text-align:left;
  			@include raleway;
		}
	}
	&__final-cta{
		text-align:center;
		background-color: $color-business-blue;
		h2 { color:white; text-transform:initial;}
		p {
  			@include raleway;
			font-size:1.15rem;
			color:white;
			font-weight:normal;
			
			&.subtext {
				margin-top:10px;
				font-size:0.9rem;
				font-weight:600;
			}
		}
	}
	&__joinevent, &__planned{
		background-color: $color-lighter-gray;
	}
	&__section{
		padding:60px 0px; 
		& > .row {
			padding: 0px 15px;
    		@include breakpoint(medium) {
    			padding:0px;
    		}
		}
		&__title{
			font-size:1.5rem;
			text-transform:uppercase;
		}
		h3 {
			font-size:1.3rem;
		}
		.centered{
			text-align:center;
		}
		&--sub{
			border-bottom:1px solid $color-dark-gray;
			margin-bottom:10px;
			&:last-of-type{
				margin-bottom:40px;
			}
			&__content{
				&.closed{
					display:none;	
				}
			}
		}
	}
	&__section-header{
		font-size:rem-calc(20);
		margin-bottom:25px;
		color: $color-black;
		&--sub{
			margin-bottom:15px;
			cursor:pointer;
			font-size:rem-calc(17);
			@include open-sans;
			&::before{
				content:"-";
				width:15px;
				display:inline-block;
				margin-right:15px;
			}
			&.closed{
				&::before{
					content:"+";
				}
			}
		}
	}
	&__filtertrainers{
		margin-top:25px;
		@include breakpoint(large) {
			margin-top:50px;
		}
		.row{
			width: 100%;
			@include breakpoint(large) {
				width: 50%;
			}
		}
		#trainers_filter_category{
			border: 1px solid #1E225C;
			border-radius: 100px;
			color: #1E225C;
			font-size:14px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+');
			background-repeat: no-repeat, repeat;
			background-position: right 5px top 50%;
			background-size: 13px;
			text-indent: 10px;
			height:52px;
		}
		.trainers__filter{
			border: 1px solid #1E225C;
			border-radius: 100px;
			color: #1E225C;
			font-size:14px;
			//background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEuMTcyIDI0bC03LjM4Ny03LjM4N2MtMS4zODguODc0LTMuMDI0IDEuMzg3LTQuNzg1IDEuMzg3LTQuOTcxIDAtOS00LjAyOS05LTlzNC4wMjktOSA5LTkgOSA0LjAyOSA5IDljMCAxLjc2MS0uNTE0IDMuMzk4LTEuMzg3IDQuNzg1bDcuMzg3IDcuMzg3LTIuODI4IDIuODI4em0tMTIuMTcyLThjMy44NTkgMCA3LTMuMTQgNy03cy0zLjE0MS03LTctNy03IDMuMTQtNyA3IDMuMTQxIDcgNyA3eiIvPjwvc3ZnPg==');
			background-repeat: no-repeat, repeat;
			background-position: left 25px top 50%;
			background-size: 24px;
			text-indent: 40px;
			padding: 25px;

			&__zoekicon{
				position: absolute;
				left: 30px;
				top: 15px;
				//filter: invert(12%) sepia(86%) saturate(1515%) hue-rotate(220deg) brightness(88%) contrast(97%);
				width: 24px;
				height:24px;

				@include breakpoint(medium) {
					left: 40px;
				}
			}
		}
		::placeholder {
			color: #1E225C;
			opacity: 1; /* Firefox */
		}
	}

	&__trainersdiv{
		margin-bottom:50px;
		&__div{
			height: 400px;
			@include breakpoint(large) {
				padding-right: 2.5rem;
				padding-left: 2.5rem;
			}
			&__banner{
				height: 320px;
				position: relative;
				border-bottom: 2px solid #1E225C;
				img{
					position: absolute;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					height: 70%;
					bottom: 0;
				}
				span{
					position: absolute;
					font-size: 260px;
					font-weight: 900;
					letter-spacing: -15px;
					color: #00C4C2;
					text-align: center;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
					bottom:-90px;
				}
				.alt_span{
					left:-30px;
				}
			}

			&__textdiv{
				box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
				border-radius: 8px;
				height: 65px;
				margin-top: 15px;
				display: flex;
				align-items: center;
				&__text{
					flex:80;
					margin-left: 20px;
					width: 100%;
					p{
						margin:0;
						font-size:14px;
						font-family: 'Poppins', 'sans-serif';
						color: #1E225C;
					}
					&__title{
						font-family: 'Lexend', 'sans-serif';
						font-weight: 800;
					}
				}
				&__knop{
					flex:20;
					width: 100%;
					&__div{
						border: 1px solid #1E225C;
						border-radius: 100px;
						width: 50px;
						height: 50px;
						float: right;
  						margin-right: 20px;
						position: relative;
						cursor: pointer;
						transition: 0.5s;
						img{
							width: 16px;
							position: absolute;
							left: 0;
							right: 0;
							margin-right: auto;
							margin-left: auto;
							height: 100%;
							filter: invert(10%) sepia(34%) saturate(4768%) hue-rotate(228deg) brightness(98%) contrast(95%);
						}
					}
				}
			}
		}
	}
}
.trainer-pagina__bannertexts{
	p{
		font-size:16px;
		color: #1E225C;
		font-family: 'Poppins', 'sans-serif';
	}
	h1{
		font-size:25px;
		line-height: 30px;
		letter-spacing: -0.2px;
		font-weight: 700;
		color: #181C4D;
		font-family: 'Lexend', 'sans-serif';
		margin-bottom:20px;
		@include breakpoint(large){
			font-size: 39px;
			font-weight: 800;
			line-height: 44px;
		}
	}
}
#trainer__backgroundimgbanner{
	left: 50%;
	transform: translateX(-50%);
	bottom: 80px;
	@include breakpoint(xxlarge) {
		margin-left: 160px;
	}

}
.trainer__text-background{
	overflow: initial !important;
	span{
		letter-spacing: -60px !important;
	}
}
.backgroundimglijn{
	position: absolute;
	width: 100%;
	border: 2px solid #1E225D;
	bottom: 80px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;
	overflow:hidden;
	@include breakpoint(xxlarge) {
		margin-left: 160px;
	}
}
.trainer-infowidget{
	bottom: 100px !important;
	left: 0 !important;
	@include breakpoint(xxlarge) {
		margin-left: 160px !important;
	}
	
}
.trainer-bannerdiv{
	margin: 40px 20px;
  	margin-bottom: 0;
	@include breakpoint(large) {
		margin:initial;
		padding-bottom:0px;
	}
}
#trainer-idbanner{
		padding-bottom:0px;
}
.data-trainer{
	background: #F8F8FA;

	&__row{
		display:flex;
		gap:20px;
		flex-direction: column;
		padding: 40px 20px;
		@include breakpoint(large) {
			flex-direction: row;
			gap:130px;
			padding-top:40px;
			padding-bottom:40px;
			padding-left:initial;
			padding-right: initial;
		}
		&__avg-rating{
			width: 100%;
			text-align: center;
			@include breakpoint(large){
				text-align: left;
			}
			span{
				font-size: 25px;
				@include header-text;
				font-weight: 900;
				margin-bottom: 0;
				letter-spacing: 0px;
				line-height: inherit;
				color: #1E225C;
				@include breakpoint(large){
					font-size:40px;
				}
			}
			p{
				font-size:16px;
				font-weight: 500;
				margin:0;
				text-align: center;
				font-family: 'Poppins', 'sans-serif';
				letter-spacing: 0px;
				color: #1E225C;
				@include breakpoint(large){
					text-align: left;
					font-weight:600;
				}
			}
		}
		&__totaltrainings{
			width: 100%;
			text-align: center;
			@include breakpoint(large){
				text-align: left;
			}
			span{
				font-size: 25px;
				font-family: 'Lexend', 'sans-serif';
				letter-spacing: 0px;
				color: #1E225C;
				font-weight: 900;
				@include breakpoint(large){
					font-size:40px;
				}
			}
			p{
				font-size:16px;
				font-family: 'Poppins', 'sans-serif';
				letter-spacing: 0px;
				color: #1E225C;
				font-weight: 500;
				text-align: center;
				@include breakpoint(large){
					text-align: left;
					font-weight:600;
				}
			}
		}
		&__year{
			width: 100%;
			text-align: center;
			@include breakpoint(large){
				text-align: left;
			}
			span{
				font-size: 25px;
				font-family: 'Lexend', 'sans-serif';
				letter-spacing: 0px;
				color: #1E225C;
				font-weight: 900;
				@include breakpoint(large){
					font-size:40px;
				}
			}
			p{
				font-size:16px;
				font-family: 'Poppins', 'sans-serif';
				letter-spacing: 0px;
				color: #1E225C;
				font-weight: 500;
				text-align: center;
				@include breakpoint(large){
					text-align: left;
					font-weight:600;
				}
			}
		}
		&__button{
			a{
				text-decoration: none;
			}
			width: 100%;
			display: flex;
			@include breakpoint(large) {
				justify-content: center;
				align-items: center;
			}
			.cf-button{
				padding: 30px 50px;

				span{
					margin:0;
				}
			}
		}
	}
}
.trainertrainingen{
	margin-top:100px;
	padding: 0px 20px;
	padding-right:0px;
	@include breakpoint(large) {
		padding: initial;
	}
	.trainertrainingen__title{
		h2{
			font-size:25px;
			font-family: 'Lexend', 'sans-serif';
			color: #1E225C;
			font-weight: 600;
		}
	}

	.training-slider-block__body{
		padding:0;

		[data-core-scroller-platter] > div{
			margin-left: -30px;
			margin-right: 30px;
		}
	}
}

.footertrainers{
	margin-top:50px !important;
	@include breakpoint(large) {
		margin-top:100px;
	}
	.cluster-header__background__text{
		#trainer_letter_footer{
			font-size: 400px !important;
  			padding: 30px 0px !important;
			@include breakpoint(large) {
				font-size: 700px !important;
				padding: 60px 0px !important;
			}
		}
	}
	.cluster-header__background__image{
		img{
			bottom:-50px !important;
			@include breakpoint(medium) {
				bottom:0px !important;
			}
		}
	}
}
.trainer_footer_text{
	margin-left:-6px;
	@include breakpoint(medium) {
		margin-left:inherit;
	}

}
#trainer_footer{
	margin-top:30px;
	@include breakpoint(medium) {
		margin-top:initial;
	}
}
#trainer_footer-designation{
	bottom:10px;
	@include breakpoint(medium) {
		bottom:85px;
	}
}
.traineroverzichtopleidingen{
	height: 37rem !important;
	@include breakpoint(medium){
		height: 32rem !important;
	}
}
.alltrainers-link{
	text-decoration: none;
	.trainerimg-ready{
		display:block;
	}
	.trainerimg-hover{
		display:none;
	}
	&:hover{
		.trainerimg-ready{
			display:none;
		}
		.trainerimg-hover{
			display:block;
		}
		.trainer__trainersdiv__div__textdiv__knop__div{
			background-color:#1E225C;

			img{
				filter: invert(100%) sepia(97%) saturate(0%) hue-rotate(14deg) brightness(104%) contrast(103%);
			}
		}
	}
}