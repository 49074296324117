.mobile-nav {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  /*background: linear-gradient(
                  to right,
                  $top-menu-color-dark 0%,
                  $top-menu-color-dark 50%,
                  $top-menu-color-light 50%,
                  $top-menu-color-light 100%
  );*/

  @include breakpoint(medium) {
  	height:inherit; 
  }
  .menu {
  	justify-content: center;
    width: auto;
  }
  @media print {
	display:none!important;
  }
  &__menu-item {
    @include custom-button-base;
    float: right;
    font-size: 1.1rem;
    background: white;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    a {
      text-decoration: none;
      color: $color-black;
      @include header-text;
    }
  }

  .menu > li {
    & > a {
      padding: 0.9rem 0.9rem;
    }
  }
}