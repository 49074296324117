.cue{
	position: fixed;
	top:70px;
	left:0px;
	width:90%;
	margin:0% 5%;
	box-shadow: 0px 15px 30px rgba(42,62,82,0.149);
	border-radius: 6px;
	background-color: white;
	padding: 17px 20px;
	z-index: 9999;
	@include breakpoint(medium){
		width: unset;
		left: unset;
		margin: unset;
		right: 40px;
		top:30px;
	}
	&.closed {
		display: none;
	}
	&.opened {
		display: block;
	}
	&__container{
		display: flex;
		align-items: center;
		gap:10px; 
	}
	&__icon{
		img{
			width:20px;
			display: none;
		}
		&.messagetype{
			&.error{
				color:#FF4F57;
				img.error-icon {
					display: inline;
					filter: invert(49%) sepia(56%) saturate(5055%) hue-rotate(331deg) brightness(107%) contrast(103%);
				}

			}
			&.success{
				color:#FF4F57;
				img.success-icon {
					display: inline;
					filter: invert(49%) sepia(56%) saturate(5055%) hue-rotate(331deg) brightness(107%) contrast(103%);
				}

			}
			&.saved{
				color:#FF4F57;
				img.saved-icon {
					display: inline;
					filter: invert(49%) sepia(56%) saturate(5055%) hue-rotate(331deg) brightness(107%) contrast(103%);
				}
			}
		}
	}
	&__text{
		font-size: 16px;
		@include header-text;
	}
}
.popup {
  transform:unset!important;
  left:2%!important;
  top:10%!important;
  width:95%!important;
  height:inherit!important;
  @include breakpoint(medium) {
  	left:50%!important;
  	top:50%!important;
  	width:600px!important;
  	transform: matrix(1, 0, 0, 1, -300, -339.5) scale(1)!important;
  }
  color: $color-dark-primary;
  	&__content {
  		&__header {
  			padding:20px;
  			background-color:#201B66;
  			h4{
  				text-transform:uppercase;
  				color:#fff;
  				text-align:center;
  			}
  			p {
  				color:#fff;
  				text-align:center;
  			}
  			span.orange { color:#FF833B; font-weight:bold; }
  		}
  		&__form {
  			padding:20px;
  			background-color:#EFF5FC;
  			&__instructions {
  				font-size:0.85rem;
  				color:#201B66;
  			}
  			&__content {
  				&__form {
					 input {
						margin-bottom:15px;
						border-radius:5px;
						padding:0.65em;
						@include breakpoint(medium) {
							display:inline-block;
							margin-bottom:0px;
						}
					 }
					 input::placeholder{
						color:$color-darker-gray;
					 }
					 button {
						width:100%;
						@include breakpoint(medium) {
							display:inline-block;
							margin-bottom: 3px;
						}
						.cf-button__text{
							margin: -2px 15px 0 15px;
						}
					 }
				  }
  				& > .privacy {
  					font-size:0.9rem;
  					text-align:center;
  					line-height:0.9rem;
  				}
  			}
  		}
  	}
  	
  	&__photo{
  		background:url('../../images/popup-background.jpg') no-repeat left top;
  		background-size:cover;
  		background-position:20% center;
  	}
}