.post-summary-small {
  display: flex;
  margin: 0 0 1rem;
  min-height: 70px;

  &__image {
    width: 150px;
    overflow: hidden;
    min-width: 100px;
    //max-height: 70px;
    margin-right: 20px;
    border-radius: 8px;
    img {
      width: 150px;
      height: 100px;
      object-fit: cover;
    }
  }

  &__content {
    padding: 0 0 0 1rem;
    flex-grow: 1;
    text-decoration: none;
  }

  &__date {
    @include open-sans;
    display: block;
    font-size: rem-calc(16);
    //color: $color-darker-gray;
    color:$color-business-blue;
  }

  &__title {
    @include raleway;
    display: block;
    font-weight: 800;
    font-size:15px;
    color: $post-summary-header;
    
    @include breakpoint(medium up) {
      font-size:rem-calc(20);
    }
  }
  &:hover{
  	.post-summary-small__title {
  		color:$color-orange;
  		@include transition(all 400ms);
  	}
  }
}