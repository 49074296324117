.Wij-trainers-wrapper:before,
.online-training-slider-body:before{
    content: "";
    background-image: url(/images/dot-bgv4.png);
    height: 100%;
    width: 50%;
    position: absolute;
    background-repeat: repeat;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-position: center;
    background-size: unset;  
    @include breakpoint(medium){
    	display: none;
    } 
}
.Wij-trainers-slider{
	padding:20px 0px;

	.slick-slideshow__slide[data-slick-index = "1"]{
		@include breakpoint(large){
		margin-left:10vw;
		}
		@media only screen and (min-width: 1700px) {
			margin-left:12vw;
		}

	}
	.slideschanged[data-slick-index = "1"]{
		@include breakpoint(large){
		margin-left:0px;
		}
	}
	.slideschanged2{
		@include breakpoint(large){
		margin-left:12vw;
		}
	}
}
.design-training-slider{

	.slick-slideshow__slide[data-slick-index = "1"]{
		@include breakpoint(large){
		margin-left:12vw;
		}
	}
	.slideschangedopleiding[data-slick-index = "1"]{
		@include breakpoint(large){
		margin-left:0px;
		}
	}
	.slideschangedopleiding2{
		@include breakpoint(large){
		margin-left:12vw;
		}
	}
}
.filteractive{
	.slick-slideshow__slide[data-slick-index = "1"]{
		@include breakpoint(large){
		margin-left:0px;
		}
	}
}
.arrow_trainers{
	@include breakpoint(large){
	margin-right:200px;
	}
}
.Wij-trainers-wrapper{
    position: relative;
}
.Wij-trainers-wrapper:before{
    right: 0px !important;
    left: auto;
}
.Wij-trainers-slider .slide-box{
	width:400px;
}
.Wij-trainers-slider .box-content p {
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-height: 70px;
}
.Wij-slider-body {		  
    width: 55%;
}
.Wij-trainers-text {
    width: 35%;
    @include breakpoint(large){
		h2{
			font-size:rem-calc(39px);
		}
    }
}
.Wij-trainers-text .checkmark{
	list-style-type:none;
	margin-left:0px;
	li{
		margin-bottom:15px;
	}
}
.Wij-trainers-wrapper {
    padding: 80px 0px 70px 0px;
}
.Wij-trainers-slider .slick-track{
    display: flex !important;
}

.Wij-trainers-slider .slick-slide{
    height: inherit !important;
}

.Wij-trainers-slider  .slick-slide{
    display: flex !important;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
}
.Wij-slider-body h3 {
    padding-bottom: 20px;
}
.trainer-slider{
	margin-top:100px;
	padding:50px 0px 80px 0px!important;
	& > .container {
		padding:0px;
		@include breakpoint(medium){
			padding:0px 20px;
			padding-right:0px;
		}
		.Wij-trainers-text{
			padding:0px 20px;
			@include breakpoint(large){
				padding-top:40px;
			}
		}
	}
	.slide-box{
		margin-top:20px;
		margin-left: 10px;
		margin-right: 10px;
		background-color: #fff;
		position: relative;
		border-radius: 4px;
		box-shadow: 7px 7px 30px 0 rgba(42,62,82,0.129);
		background-color: #fff;
		margin-bottom: 40px;
		border-radius: 8px;
		overflow: hidden;
		@include transition(all 400ms);
	}
}
.training-slider-block{
	margin-top: 120px;
	&__title{
		font-size:rem-calc(25);
		font-weight:800;
		@include breakpoint(medium){
			font-size:rem-calc(39);
		}
	}
	&__body{
		position: relative;
		padding: 30px 0px 0px;
		margin-bottom: 80px;
		&:before{
			content: "";
			height: 65%!important;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			top: 30%!important;
			left: 0px;
			z-index: -1;
			background-position: center;
			background-size: unset; 
			@include breakpoint(medium){
				background-image: url(/images/dot-bgv4.png);
			
			}
		}
		
		.tab-title{
			margin-top:0px;
		}
		.tab-head {
			display: flex;
			justify-content: space-between;
			border-radius: 4px;
			box-shadow: none!important;
			border:none; //border: solid 0.3px rgba(2,37,67,0.419);
			background-color: transparent!important;
			padding-bottom:0px;
		}
		.tab-head h6 {
			font-size: rem-calc(16);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			padding-bottom: 10px;
		   /* border-bottom: 2px solid #747AA557;*/
			margin-right: 40px;
			margin-bottom: 10px;
		}
		.tab-title{
			padding:0px!important;
			@include breakpoint(medium up){
				//padding: 20px 20px;
				//padding-bottom:15px;
			}
			&.categories{
				overflow:scroll;
				flex-basis: 75%!important;
				@include breakpoint(medium){
					flex-basis: 50%!important;
				}
			}
			&.usps{
				border:none!important;
				.listing{
					padding-top:0px!important;
					column-gap:15px;
					& > div > div{
						margin-bottom:15px;
						//flex-basis:50%!important;
						&:last-of-type{
							margin-bottom:10px;
						}
					}
					img.checkmark-svgs {
						width: 20px;
						position: absolute;
					}
					span {
						padding-left: 25px;
						display: block;
					}
				}
			}
		}
		.tab-title:nth-child(2) {
			flex-basis: 50%!important;
		}
		.tab-title:nth-child(3) {
			flex-basis: 25%!important;
		}
		ul.tabbing li{
			margin-bottom:0px;
			margin-right:0px;
			@include breakpoint(medium up){
				margin-bottom:10px;
				margin-right:10px;
				font-size:rem-calc(14)!important;
			}
		}
		.tab-title.filter{
			border-left: 1px solid #D6D6D6;
			select{
				margin-bottom:0px;
				font-size: 12px;
				font-weight:600;
				padding-left:2rem;
				height: initial;
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 34, 92%29'></polygon></svg>"), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style="fill: rgb%2830, 34, 92%29" d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>');
				background-origin: content-box, content-box;
				background-position: right -1rem center, left -1.5rem center;
				background-repeat: no-repeat, no-repeat;
				background-size: 9px 6px, 18px 15px;
				border: 1px solid $color-blue;
				color:$color-blue;
			}
		}
		.loadmore{
			border-top:1px solid #D6D6D6;
			margin-top:25px;
			padding-top:25px;
			text-align:center;
		}		
		
		
		
		
		
		
	}
}

.tab-bottom__keuzehulp{
	a{
		color:$color-orange;
	}
}