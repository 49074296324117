.breadcrumbs {
	@include raleway;
	margin:0px;
	margin-top:7px!important;
	display:flex;
	li{
		float:none;
		display:flex;
		position:relative;
		margin:0px!important;
	}
	li a{
		text-decoration: none;
		padding:8px 13px;
		color: $color-blue;
		font-size:15px;
		line-height:1;
		font-weight: 400;
	}
	a span{
		vertical-align:middle;
	}
	a .hidden, li .hidden{
		display:none;
	}
	a.active,a:hover{
		text-decoration:underline;
		@include transition(all 400ms);
	}

	li.last.nolink, li.nolink{
		text-decoration: none;
		padding:8px 13px;
		color: $color-blue;
		font-size:15px;
		line-height:1;
		font-weight: 400;
	}
	li.nolink:not(.last){
		padding-left:5px;
		padding-right:10px;
	}
	li.nolink:not(.last)::after{
		margin-left:20px;
	}
	li.last .visible{
	max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	}
	li:not(.last) a{
		padding: 10px 13px;
		padding: 8px 11px;
	}
	li:not(.last)::after {
		content: "<";
		color: $color-blue;
		position: relative;
		top: 0px;
		font-size: 125%;
		margin: 0 0.35rem;
	}

	a {
		text-decoration: underline;
	}

	&__home a {
		width:36px;
		padding:8px!important;
		padding-top: 6px !important;
		text-decoration: none;
		text-align:center;
	}
	@media print {
		display:none!important;
	}
}