$show-optie: 1380px;
.zie-data{
  cursor:pointer;
  text-decoration:underline;
  display: inline-block;
  font-weight: medium;
}
// startdata_offCanvas
.startdata_offCanvas{
  z-index:9999; 
  background-color:#fff;
  width:350px;
  padding: 40px 20px 250px 20px;
  @include breakpoint(large){
  padding: 123px 87px;
  width: rem-calc(572);
  }
  // width:700px;
  .close-btn{
    right: 3rem;
    top: 3.8rem;
    font-size: 3em;
    line-height: 1;
    @include breakpoint(large){
      top: 7.8rem;
    }
  }
  h3{
    font-size: 39px;
    font-weight:bold;
    margin-bottom: 45px;
  }
  ul{
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 45px;
  }
  span{
    color:#1C2663;
    span{
      font-weight:bold;
    }
  }
  p.introtekst{
    margin-bottom:45px;
  }
  p.trainingtitle{
    font-size: 25px;
    font-weight:bold;
    margin-bottom: 12px;
    span{
      font-weight:bold;
    }
  }
}

#cursusdata{
	@media print {
      display:none;
      page-break-after: always;
      padding:0px;
    }
}
#gerelateerd {
	@media print {
      padding:0px;
    }
}
#opleidingsvoordeel{
	@media print {
      page-break-before: always;
      margin-bottom:0px;
    }
}
.training__cursusdata{
	.title h2{
		font-size:20px;
		text-align:center;
    max-width: 300px;
    margin:0 auto;
    line-height: 26px;
    letter-spacing: -0.1px;
    margin-bottom: 0px!important; 
		@include breakpoint(medium){
			text-align:center;
			font-size:39px;
      max-width: unset;
			width:60%;
			margin:0 auto;
			margin-bottom:16px!important;
		
		}
		@include breakpoint(large){

			font-size:45px;
      line-height: 50px;
    }
	}
  .title p { max-width:900px; margin:0 auto; padding-bottom:0px; }
}
.startgarantie{
  font-size:12px;
  padding: 2.5px 5.5px;
  color:#8689AD;
  border: 1px dashed #979AC4;
  border-radius: 4px;
  font-weight: normal;
  &.filled{
    border:1px solid #CDEDC2;
    background-color:#CDEDC2;
    color:$color-business-blue;
  } 
  .blue{
    border:1px solid #f2f8ff;
    background-color:#f2f8ff;
    color:$color-business-blue;

  }
}

.trainingsdata-table {
  @include clearfix;
  color: $calendar-date-dark;
  margin: 15px 0 15px;
  margin-top: 50px;
  margin-bottom:0px;

  
 @media(max-width: 800px) { 
	table {border: 0;}
	thead{display: none;}
	tr{display: block;margin: 0 0 20px 0;}
  tr:last-of-type{ margin-bottom:0px; }
	td{display: block;text-align: right; }
	td:before{content: attr(aria-label);float: left;font-weight: bold;}
	td:last-child{border-bottom: 0;}        
	tr:nth-child(odd){background-color: #F2F2F2;}
	tr:nth-child(even){background-color: #FFFFFF;border-width: 3px 0 3px 0;border-style: solid; border-color: #E5E5E5;}
	thead, tbody, tfoot{
		border: none !important;
	}
	tbody tr:nth-child(even) {
		 border-bottom: 1px solid rgba(30,34,92,0.46); 
		background-color: #ededed;
	}
	tbody tr:nth-child(even) {
		 border-bottom: 1px solid rgba(30,34,92,0.46) !important; 
		background-color: transparent !important;
		border: 1px solid rgba(30,34,92,0.46) !important;
	}
	tbody th, tbody td {
		padding: 0.5rem 0.625rem 0.625rem;
		border-bottom: 1px solid rgba(0,0,0,0.039);
	}
  tbody td.empty-cell{
    display: none;
    @include breakpoint(large){
      display: unset;
    }
  }
	tbody th, tbody td {
		padding: 15px !important;
	}
	table tr {
		box-shadow: none;
		border-radius: 10px;
	}
  }
  
  @include breakpoint(medium) {
    margin: 67px 0 15px;
	border:1px solid $color-business-blue;
	border-radius:12px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
  table{
  	border-collapse: collapse;
  	margin-bottom:0px;
  }
  table thead {
  	@include breakpoint(medium) {
  		border: none;
  	}
  }
  table thead tr td:first-of-type{ 
  	@include breakpoint(medium) {
  		border-radius: 12px 0px 0px 0px;
  	}
  }
  table thead tr td:last-of-type{ 
  	@include breakpoint(medium) {
  		border-radius: 0px 12px 0px 0px;
  	}
  }
  table thead tr td { color:white !important; }
  table tbody {
  	background:transparent;
	@include breakpoint(medium) {
		border:none;
		background:white;
	}
  }
  table tbody tr{
	  background-color: white!important;
	  border: 1px solid rgba(30, 34, 92, 0.46) !important;
	  @include breakpoint(medium) {
		box-shadow:none;
		border:none!important;
		border-bottom:1px solid #D5D6DB!important;
	  }
	  &:last-of-type{
		  @include breakpoint(medium) {
			border:none!important;
		  }
	  }
  }
  
  table tbody tr:last-of-type td:first-of-type{ 
  	@include breakpoint(medium) {
  		border-radius: 0px 0px 0px 12px;
  	}
  }
  table tbody tr:last-of-type td:last-of-type{ 
  	@include breakpoint(medium) {
  		border-radius: 0px 0px 12px 0px;
  	}
  }
  table tbody td[aria-label="Start"] {
  	position:relative;
    &.leergang{
      @include breakpoint(large){
        display: flex;
        align-items: center;
        gap:12px;

      }
    }
  }
  button{
    margin-bottom:0px;
  }
  &__button{
	@include breakpoint(large) {
		width:200px;
  	}
  }
  .threeprecent{
	@include breakpoint(large) {
		width:3%;
  	}
  }
  .fourpercent{
	@include breakpoint(large) {
		width:4%;
  	}
  }
  .fivepercent{
	@include breakpoint(large) {
		width:6%;
    &.leergang{
      width: 12%;
    }
  	}
  }
  .tenpercent{
	@include breakpoint(large) {
		width:9%;
    &.leergang{
      width: 12%;
    }
  	}
  }
  .twelvepercent{
	@include breakpoint(large) {
		width:12%;
  	}
  }
  .fifteenpercent{
	@include breakpoint(large) {
		width:15%;
  	}
  }
  .seventeenpercent{
	@include breakpoint(large) {
		width:17%;
  	}
  }
  .eighteenpercent{
	@include breakpoint(large) {
		width:18%;
  	}
  }
  .twentypercent{
	@include breakpoint(large) {
		width:23%;
  	}
  }
  .thirtypercent{
	@include breakpoint(large) {
		width:30%;
  	}
  }
  .btn-yellow.reversed{
  	border-color:$color-business-blue;
    color: $color-business-blue !important;
    background-color: #FFF;
    &:hover{
  		border-color:$color-orange;
    	background-color: $color-orange;
    	color:white!important;
    	
    }
  }
  &__datebox{
  	@include breakpoint(medium){
  		display:inline-block;
		background-color:$color-business-blue;
		@include header-text;
		text-align:center;
		color:white;
		font-weight:800;
		line-height:1;
		width:50px;
		max-height:50px;
		padding:5px 0px;
		border-radius: 2px;
		&__day{
			font-size:rem-calc(22)!important;
			display:block;
			color:white;
		}
		&__month{
			font-size:rem-calc(16);
			display:block;
			color:white;
		}
		&__year{
			display:none;
			color:white;
		}
		&__tooltip{
			position: absolute;
			top: 0px;
			@include breakpoint(medium){
				top: 2px;
			}
			@include breakpoint(large){
				top: 0px;
			}
			left: 50px;
			color: white;
			height: 18px;
			line-height: 1;
			border: 1px solid white;
			border-radius: 100px;
			background: $color-business-blue;
      svg path { fill:white;}
			.fa-info-circle{
				border: 1px solid $color-business-blue;
    			border-radius: 100px;
          color:white;
			}
		}
  	}
  }
  &__label{
  	background-color:#F2F8FF; 
  	font-size:rem-calc(15);
  	padding:5px 10px;
  	display:inline-block;
  	color:$color-business-blue;
  }
  &__locaties {
    margin-left: 80px;
    width: 120px;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;
    border-right: 1px solid $color-line;
    display: flex;
  }

  &__locatie {
    border-right: 1px solid $color-line;
    float: left;
    width: 40px;
    height: 135px;

    span {
      display: block;
      @include transform (rotate(-90deg) translateX(-90px));
    }

    &--br {
      border-right: none;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    height: 100px;
    &:last-child {
      border-bottom: 1px solid $color-line;
    }
  }
  &__favorite {
  	&.selected{
  		img.selected{
  			filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
  			display:block;
  		}
  		img.notselected{
  			display:none;
  		}
  	}
  	&.notselected{
  		img.selected{
  			display:none;
  		}
  		img.notselected{
  			filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
  			display:block;
  		}
  	}
  	&:hover{
  		cursor:pointer;
  		&.notselected{
			img.notselected{
				filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
				transition:(all 400ms);
			}
		}
  	}
  }
  &__cal {
    display: block;
    text-align: center;
    width: 80px;
    min-width: 80px;
    flex-grow: 0;
    height: 100px;
    padding: 25px 0;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    font-size: 16px;

    strong {
      color: $color-black;
      font-size: 24px;
      font-weight: 700;
    }

    @include breakpoint(medium) {
      font-size: 19px;

      strong {
        font-size: 24px;
      }
    }
  }

  &__locatie-checkmark {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 0;
    font-size: 22px;
    border: none;
    border-top: 1px solid $color-line;
    border-left: 1px solid $color-line;
    width: 40px;
    min-width: 40px;
    height: 100px;

    &--yes {
      color: $success-color;
    }

    &--no {
      color: $color-medium-gray;
    }

    &--br {
      border-right: 1px solid $color-line;
    }
  }

  &__dates {
    display: flex;

    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    height: 100px;
    padding: 0 15px;
    line-height: 1.2;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;
    text-align: left;

    @include breakpoint(small only) {
      font-weight: 600;
    }

    @include breakpoint(medium) {
      font-size: rem-calc(13px);
      border-left: none;
    }

    @include breakpoint(xlarge) {
      font-size: 1rem;
      max-width: 364px;
    }
  }
  
  &__availability {
    display: flex;

    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    height: 100px;
    padding: 0 15px;
    line-height: 1.2;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;
    text-align: left;

    @include breakpoint(small only) {
      font-weight: 600;
    }

    @include breakpoint(medium) {
      font-size: rem-calc(13px);
      border-left: none;
    }

    @include breakpoint(xlarge) {
      font-size: 1rem;
      max-width: 364px;
    }
    &__green {
    	width:15px;
    	height:15px;
    	border-radius:50%;
    	background-color:green;
    }
    &__blue {
    	width:15px;
    	height:15px;
    	border-radius:50%;
    	background-color:blue; 
    
    }
  }

  &__date {
  }
  
  &__discount {
  	font-style:italic;
  	font-size: 0.8rem;
  }

  &__mobile-locaties {
    color: $color-dark-gray;
    font-weight: 300;
  }

  &__prijs {
    display: flex;
    flex-grow: 0;
    height: 100px;
    padding: 0 15px;
    justify-content: center;
    flex-direction: column;
    border-right: none;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    width: 140px;

    color: $color-black;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;

    @include breakpoint(xlarge) {
      font-size: 26px;
    }
  }

  &__inschrijven {
    //display: flex;
    //flex-grow: 0;
    height: 100px;
    max-width: 216px;
    font-weight: normal;
    padding: 0 20px;
    border-left: 1px solid $color-line;
    border-top: 1px solid $color-line;
    border-right: 1px solid $color-line;

    .trainingsdata-table__inschrijven-no-btn {
      display: none;
    }

    .inschrijven-vol {
      margin: 0;
      display:block;
      padding:15px 0px;
      font-size:0.9rem;
    }
    .inschrijven-optie {
      margin: 0;
      display:block;
      padding:15px 0px;
    }

    @mixin no-button {
      font-size: 110%;
      color: $color-light-primary;
      text-align: right;
      font-weight: bold;
      .trainingsdata-table__inschrijven-no-btn {
        display: block;
      }
      i {
        font-size: 115%;
      }
      button {
        position: relative;
        top: -3px;
      }
    }

    @include breakpoint(small only) {
      @include no-button;

      width: 120px;
      max-width:112px;

      small {
        font-size: 1rem;
        font-weight: 300;
        position: relative;
        top: 6px;
        right: -3px;
      }
    }

    @include breakpoint(large only) {
      @include no-button;
      width: 110px;
      max-width: 110px;

      i {
        position: relative;
        left: 3px;
      }

      small {
        font-size: rem-calc(13px);
        font-weight: 600;
        position: relative;
        top: 6px;
      }
    }

    .inschrijven-optie__optie {
      display: none;
      @include breakpoint($show-optie) {
        display: block;
      }
    }
  }
  .wachtlijst{
    text-decoration: none;
    font-weight: 600;

    svg{
      margin-left: 5px;
    }
  }
}