.lastminutes .training-prijs-block{
	width:90%;
	margin-bottom:0;
      @include breakpoint(medium) {
      display:inline-block;
      width:45%;
      }
      @include breakpoint(large) {
      width:22%;
      }
}


.training-prijs-block {
  $padding: 20px;

  background: white;
  border: 1px solid $color-line;
  position: relative;
  padding: $padding;
  margin: 0 0 25px;
  cursor: pointer;

  @include transition(background 400ms, box-shadow 400ms);

  &__acties{
    $padding: 0px;
  }
  &__info {
  	$padding: 20px;
  }
  &__actie-naam {
    @include raleway;
    position: absolute;
    top: $padding;
    left: $padding;
    padding-right:60px;
    font-size: rem-calc(14);
    font-weight: 800;
    color: $color-orange;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__actie-percentage {
    position: absolute;
    right: 5px;
    top: -30px;
  }

  &__training-naam {
    @include raleway;
    margin: 50px 0 0;
    height: 56px;
    font-size: rem-calc(16);
    font-weight: 800;
    color: $color-darker-primary;
  }

  &__data {
    margin: 15px 0 0;
    color: $color-darker-primary;
  }
  &__plaats {
    margin: 0;
    color: $color-darker-primary;
  }

  &__prijs {
    @include raleway;
    font-weight: 500;
    margin: 15px 0 0;
    line-height: rem-calc(32);
    font-size: rem-calc(20);
    color: $color-dark-primary;
    a {
      @include open-sans;
      font-weight: 800;
      font-size: rem-calc(28);
      color: $color-dark-primary;
      text-decoration: none;
    }
  }

  &__actions {
    height: 50px;
    margin: 0;
  }

  &--training {
    .training-prijs-block {
      &__data {
        @include raleway;
        font-size: rem-calc(18);
        font-weight: 800;
      }
    }
  }
  
  &--home {
    .training-prijs-block {
      &__training-naam {
      	height:100px; 
      }
      &__data {

      }
    }
  }
  
  &--lastminute {
    .training-prijs-block {
      &__data {

      }
    }
  }

  &--sidebar {
    margin-bottom: 0;
  }

  &:hover, &:focus, &:active {
    background: darken($color-lighter-gray, 3%);
    box-shadow: $block-hover-shadow;
    border-color: $block-hover-border;

    .cf-button {
      @include cf-button-hover;
    }
    .cf-button--alt {
      @include cf-button-alt-hover;
    }
    .cf-button--orange {
      @include cf-button-alt-hover;
    }
  }
}
.training-prijs-block-action{
  background: #eef5ff;
  &:hover, &:focus, &:active {
    background: darken(#eef5ff, 3%);
    box-shadow: none;
    border-color: none;
  }
  
}
