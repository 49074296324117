/**
Bovenste balk, mobile only
*/
.ui-autocomplete{
	z-index:99999!important;
	width:200px;
}
.header {
  &--admin {
    .header {
      &__mobile {
        padding-bottom: 10px;
        border-bottom: 10px solid $color-line;
        background-color:#fff;
        
      }

    }
  }
  
  &__mobile {
  		box-shadow: 0px 3px 6px rgba(127,139,152,0.16);
  		.container{
  			padding:7px 7px;
  		}
        background-color:#fff;
  		&__left-button {
        	//border-right:1px solid $color-black;
        }
        &__right-button {
        	//border-left:1px solid $color-black;
        }
        &__firstrow {
        	//border-bottom:1px solid $color-black;
          position: relative;
        }
        &__middlesection{
        	display:flex;
        	column-gap:10px;
        }
        &__secondrow {
        }
  }

  &__topnav {
    position: relative;
  }
  
  &__mobilenav {
    position: relative;
  }

  &__logo-mobile {
    display: inline-block;
    //height: 45px;
    width:100%;
    max-width: 132px;

    img {
	    //max-height: 40px;
	    width:100%;
      max-width: 132px;
      top: 13px;
      position: relative;
      z-index: 10;
    }

    @include breakpoint(medium) {
      height: 55px;
    }
  }
  &__menu-button{
    img{ width:24px!important; height:24px!important; filter: invert(15%) sepia(11%) saturate(6183%) hue-rotate(209deg) brightness(96%) contrast(103%)!important; }
  }
  &__mobile-button {
    margin: 0;
    background: white;
    font-size: 1.5rem;
    color:white;
    border: 1px solid #999BBA;
    border-radius:8px;
    position: initial;
    height: 36px;
    padding-left:8px;
    padding-right:8px;
	@include breakpoint(medium){
    	padding-top: 8px;
    }
    img{
    	height:20px;
	  @include breakpoint(medium){
		height:20px;
		vertical-align:top;
	  }
    	filter: invert(10%) sepia(24%) saturate(7500%) hue-rotate(229deg) brightness(96%) contrast(92%);
      z-index:2;
      position: relative;
    }
  }
  
  &__contents {
    background-color:#fff;
    position:relative;
    z-index: 400;
    > .row {
    	background-color:#fff;
    	//border-bottom: 0.2px solid rgba(214, 214, 214, 0.2);
    	box-shadow: 0px 3px 6px rgba(49,77,101,0.16);
    	padding:12px 0px;
    	> .column:nth-child(1){
    		@media print{
    			-ms-flex: 0 0 50%;
    			flex: 0 0 50%;
    			max-width: 50%;
    		}
        padding-right:0px;
    	}
    	> .column:nth-child(2){
    		@media print{
    			-ms-flex: 0 0 50%;
    			flex: 0 0 50%;
    			max-width: 50%;
    		}
          padding-left:0px;
    	}
    }
  }

  &__logo-desktop {
    display: inline-block;
    width: 100%;
    margin: 0rem 0 0 0%;
    position:relative;

    img {
      height: auto;
      width: auto;
      max-width:170px;
      margin-top:0px;
      margin-bottom:7px;
      position: absolute;
      top: -25px!important;
    }
  }
  
  &__search-mobile {
    margin: 0 0 0px;
    display:inline-block;
    width:100%;
    @media print {
    	display:none;
    }
    .search-form{
    	height:36px;
    	//&.open{
			//position: absolute;
			//width: 95%;
			//right: 2%;
      //top:10px;
      //background-color:white;
    	//}
      &__input{
        border-radius:40px;
        position:relative;
        font-size:1rem;
        height: 36px;
        margin-bottom:0px;
        z-index: 3;
      }
      &__button{
        background:white;
        color:$color-business-blue;
        font-size:18px;
        border-radius:40px;
        width: 30px;
          height: 30px;
          z-index: 4;
      }
      &__clear{
        position: absolute;
        right: 8px;
        top: 8px;
        width: 20px;
        height: 20px;
        //background:$color-orange;
        //border-radius:100%;
        color:$color-business-blue;
        z-index: 3;
        //background-size: 100% 100%;
        img{
          filter:invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
        }
      }
    	transition: all 400ms;
    }
  }
  &__search-desktop {
    min-width: 120px!important;
    flex-grow:1;
    flex-shrink:1;
    max-width: 500px!important;
    margin-right:15px;
    padding-top:5px;
    position:relative;
  	@include breakpoint(large) {
    	//width: 120px!important;
    	.search-form.open{
			//position: absolute;
			//width: 500px;
			//right: 0;
			//background-color: white;
			//z-index: 99;
			//transition: all 400ms;
    	}
  	}
  	@include breakpoint(xlarge) {
    	//width: 120px!important;
    }
	display: inline-block;
    @media print {
    	display:none;
    }
    //transition: all 400ms;
  }

  &__phone-desktop {
    color: $color-header;
    font-weight: bold;
    margin: 62px 0 0 20px;
    float: left;
    text-decoration: none;

    &:hover {
      color: $color-header;
    }

    .icon-phone {
      font-size: 105%;
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
  }
  &__main-menu{
  	display:flex;
    align-items: center;
  	justify-content:flex-end;
  }
  &__cart-desktop {
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 0;
    position:relative;
    color: white;
    font-size: 0.9rem;
    padding: 4px 7px;
    border: 1px solid #1E225C;
    margin-left:15px;
    margin-top: 5px;

    &--filled {
      background: #1E225C;
	  .header__cart-button {
      	path{
      		fill: white;
	  		@include transition(all 400ms);
      	}
      	
      }
      &:hover, &:focus, &:active {
        background: scale-color(#1E225C, $lightness: -3%)
      }
    }

    &:hover, &:focus, &:active {
      color: white;
      background-color:#1E225C;
	  @include transition(all 400ms);

      .header__cart-button {
      	path{
      		fill: white;
	  		@include transition(all 400ms);
      	}
      	
      }
    }
    @media print {
    	visibility:hidden!important;
    }
    &.login{
    	text-align: center;
      color:white;
    
    }
  }

  &__cart-quantity {
    font-size: 0.8rem;
    position:absolute;
    width:25px;
    height:25px;
    padding:5px;
    padding-top:1px;
    border-radius: 50%;
    background: $color-orange;
    border:1px solid white;
    top:-8px;
    right:-5px;
    text-align:center;
    z-index:2;
    color:white;
  }

  &__cart-price {
    font-size: 80%;
    font-weight: bold;
    float: right;
    display:none;
  }

  &__cart-button {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    z-index:1;
  }

  &__cart-button-icon {
    float: left;
    font-size:160%;
    svg {
    	path{
    		fill:$color-blue;
    	}
    }
    &.login{
      @include breakpoint(medium){
        line-height: 1.35;
      }
    	width: 100%;
      color:white;
    }
  }
  
  &__cart-button-text {
    display:none;
  }
  
  &__cart-button-chevron {
    @include condensed;
    font-size: 160%;
    line-height: 95%;
    float: right;
    display:none;
  }
}
.search-open .mainmenu-desktop__menu a { /*transition: all 400ms; opacity:0.2;*/ }
.header-sticky {
  transition: transform 0.4s;
  z-index: 40 !important;
  top:0px!important;
}

.scroll-down .header-sticky {
  transform: translate3d(0, -100%, 0);
}

.scroll-up .header-sticky {
  transform: none;
}
.searchformmobileheader{
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #999bba !important;

  &::placeholder{
    color:$color-business-blue;
    opacity: 1;
  }
}
.cart-dropdown{
	box-shadow: 0px 15px 30px rgba(42,62,82,0.098);
	border-radius: 8px;
	border:none;
	padding:0px!important;
	&__menu{
		
		&__section{
			border-bottom:1px solid #F0F0F0;
      padding: 10.5px 0;
      margin: 0 19px;

			ul{
				list-style-type:none;
				margin:0px;
        display: flex;
          // align-items: center;
          justify-content: space-between;
				li{
          
					a{
						text-decoration:none;
            img{
              width: 20px;
              filter: invert(11%) sepia(81%) saturate(1819%) hue-rotate(223deg) brightness(89%) contrast(95%);
            }
						&:hover{
							text-decoration:underline;
						}
					}
				}
				&.logout{
					margin-top:22px;
					li{
						a{
							display:flex;
							align-items:center;
							column-gap:10px;
							svg{
								transform:scale(0.75);
								path{
									fill:$color-blue;
								}
							}
						}
					}
				}
			}
			&.header{
				font-weight:500;
				@include header-text;
				color:$color-blue;
			}
			&.bottom{
        display: flex;
        justify-content: center;
        padding: 40px 0 20px 0;

        .cf-button-cart{
          width: 100%;
          
        }
			
			}
			// &:last-of-type{
			// 	border-bottom:none;
			// }
			&:first-of-type{
				border-bottom:none;
        padding:22px 0;

			}
			
		}
	}
}

.header__contents__account{
    height: 100px !important; 

    .expanded{
      height:100%;
    }
    &__logodiv{
      border-right:1px solid #d6d6d6;
      border-bottom:1px solid #d6d6d6;
      height:100%;

      #logo-desktop{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        #logo{
          position: initial;
          margin-bottom: 0px;
        }
        svg{
          rotate:180deg;
          filter: invert(17%) sepia(10%) saturate(6263%) hue-rotate(207deg) brightness(88%) contrast(104%);
          margin-right: 10px;
        }
      }
    }

    &__membership{
      border-left:1px solid #d6d6d6;
      height: 100%;
      width: 100%;

      &__div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        &__circle{
          background-image: linear-gradient(white, white), conic-gradient( #1c2663 0deg, #1c2663 240deg, #f8f8fa 240deg, #f8f8fa 360deg);
          height: 75px;
          width: 75px;
          background-repeat: no-repeat;
          border-image-slice: 1;
          background-origin: content-box, border-box, border-box, border-box, border-box;
          background-clip: content-box, border-box, border-box, border-box, border-box;
          border-radius: 50%;
          border: 10px solid transparent;
        }
        &__tekst{
          p{
            margin-bottom: 0;
          }
        }
      }
    }
  }