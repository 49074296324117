.inschrijven-optie {
  margin-top: 15px;
  display: flex;
  align-items: center;

  &__inschrijven-button {
    &--small {
      .cf-button__icon {
        margin-right: 15px;
      }
      .cf-button__text {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  &__optie-link {
    @include raleway;
    font-weight: 600;
    margin-left: 20px;
    text-decoration: underline;
    display: none;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2);
  }
}