@import "admin-evaluaties";
@import "admin-evaluatie-gemiddelden";
@import "admin-evaluatie";
@import "admin-boekingtable";

.admin {
  margin-top: rem-calc(20px);
  padding-bottom: 10px;

  @include breakpoint(large) {
    margin-top: rem-calc(40px);
  }
}

.admin-header {
  @include open-sans;
  font-size: rem-calc(26px);
  font-weight: bold;
  color: $color-dark-primary;
  margin: 1rem 0;
}

.admin-subheader {
  @include open-sans;
  font-size: rem-calc(18px);
  font-weight: bold;
  color: $color-light-primary;
  margin: 1rem 0 0.5rem;
}