.singlecol {
  padding: 15px 0;
  @include breakpoint(medium) {
    padding: 50px 0;
    padding-top:0px;
  }
  &--opleidingoverzicht, &--account{
  	padding-bottom:0px;
  }
}
