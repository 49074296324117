@keyframes highlightbutton {
    0%   {
    	background: $color-orange;
    	color:white;
    }
    50%  {
    	background:none;
  		color:$color-white;
  	}
    100% {
    	background: $color-orange;
    	color:white;
    }
}
.scroll-up .training__sidebar{
	top:160px;
	@include transition(top 400ms);
}
	.training__sidebar{
		margin-bottom:20px;
		position:sticky;
		top: 90px;
		@include transition(top 400ms);
		border-bottom: 1px solid #D6D6D6;
		padding-bottom:40px;
		@include breakpoint(medium up){
			padding-bottom:0px;
			border-bottom:0px;
		}
		&.geenribbon{
			@include breakpoint(large) {
				border-radius:30px 0px 0px 0px;
			}
		}
  		&__title{
  			font-size:rem-calc(25px);
  			margin-bottom:15px;
  			line-height: 1.1;
  		}
		&__main{
			padding:25px 0px;
  			@include breakpoint(medium) {
				padding:25px;
  			}
  			@include breakpoint(large) {
				padding:30px;
  			}
			color:white;
			
			&__ribbon{
				@include breakpoint(large) {
					border-radius:20px 0px 0px 0px;
				}
				background-color:#f7fb00; 
				padding:25px 0px;
				padding-right: 0.625rem;
				padding-left: 0.625rem;
				padding-left: 48px;
				@include breakpoint(medium) {
					padding:25px;
					padding-left:89px;
					padding-top:10px;
					padding-bottom:10px;
				}
				@include breakpoint(large) {
					padding:30px;
					padding-left:94px;
					padding-top:10px;
					padding-bottom:10px;
				}
				font-size:0.9rem;
				&::before{
					position:absolute;
					margin-left:-48px;
					width:30px;
					height:00px;
					@include breakpoint(large) {
						margin-left:-69px;
						width:45px;
						height:45px;
					}
					background: url('../../images/icons/128/36_IDEA.png') no-repeat;
					background-position: center center;
					background-size: cover;
					content: " ";
					white-space: pre;
				}
			}
			&__title{
				h2{
					font-size: rem-calc(22);
					color:$color-white;
					margin-bottom:30px;
					
					
				}
			}
			&__dates{
				&__text {
					font-weight:bold;
					display:block;
				}
			}
			
			
			&__eerstvolgende-start {
    			font-weight: 400;
  			}
  			&__eerstvolgende-datum {
    			display:block; 
  			}
  			&__eerstvolgende-locatie {
    			display:block; 
    			
  			}
  			&__datumicoon {
    			width:25px;
    			height:25px;
    			display:inline-block;
    			background-image:url('/images/calender-icon-cf.png');
    			background-repeat:no-repeat;
    			background-size:contain;
    			background-position:left top;
    			margin-right:10px;
    			@media print{
    				display:none;
    			}
  			}
			&__bekijkdata{
				display:block;
				margin-top:5px;
			}
  			&__link-trainingsdata {
    			font-weight: 600;
    			//text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2);
    			text-decoration: underline;
    			color: $color-orange;
    			&:hover, &:focus, &:active{
    				text-decoration: none;
    				color: $color-orange;
    			}
  			}
  			&__locatieicoon {
    			width:25px;
    			height:25px;
    			display:inline-block;
    			background-image:url('/images/location-icon-cf.png');
    			background-repeat:no-repeat;
    			background-size:contain;
    			background-position:left top;
    			margin-right:10px;
    			@media print{
     				display:none;
    			}
  			}
  			.leergang-locatie {
  				@media print{
     				display:none;
    			}
  			}
  			
  			
  			&__certificaaticoon {
    			width:25px;
    			height:25px;
    			display:inline-block;
    			background-image:url('/images/academy-hat.png');
    			background-repeat:no-repeat;
    			background-size:contain;
    			background-position:left 6px;
    			margin-right:10px;
    			@media print{
     				display:none;
    			}
  			} 
  			.leergang-certificaat {
  				@media print{
     				display:none;
    			}
  			}

  			&__prijs {
    			display: flex;
    			flex-wrap: wrap;
    			line-height: 1.1;
    			margin-top: 30px;
    			margin-bottom: 30px;
    			//text-shadow: 1px 1px 0 rgba(0,0,0,0.5);
    			//text-shadow: 0 0 60px rgba(255, 255, 255, 1);
  			}

  			&__normaal {
    			width: 100%;
    			font-size: rem-calc(14);
    			font-weight: 700;
    			text-decoration: line-through;
    			color: $color-blue;
    			@media print{
     				font-size: rem-calc(14);
     				color: $color-white;
    			}
  			}
  			.leergang-normaal {
  				@media print{
     			display:none;
    			}
  			}

		  	&__kortingprijs {
				@include open-sans;
				font-size: rem-calc(22);
				color: $color-blue;
				font-weight: 700;
				width: 100%;
				@media print{
				 display:none;
				}
		  	}
			&__korting-bubble{
				margin-top:10px;
				flex: 1000px;
			}
			&__korting-arrow{
				height:0px;
				width:0px;
				display:block;
				margin-left:20px;
			}
		  	&__uwkorting {
				padding:7px 18px;
				font-size: rem-calc(14px);
				color: $color-blue;
				margin-left: 0rem;
				background-color:$color-blue-ultralight;
				display:inline-block;
				border-radius:5px;
				@media print{
				 display:none;
				}
		  	}

		  	&__vanaf {
				font-size: rem-calc(22);
				font-weight: 700;
				color: $color-blue;
				padding-right:7px;
		  	}

		  	&__vanafprijs, &__nuprijs {
				@include open-sans;
				font-size: rem-calc(22);
				color: $color-blue;
				font-weight: 700;
		  	}

		  	&__korting {
				@include raleway;
				font-weight: 700;
				flex-grow: 1;
				align-self: flex-end;
				font-size: rem-calc(20);
				color: $color-orange;
				padding-bottom: 3px;
				margin-left: 1rem;
		  	}

		  	&__btw {
				font-size: rem-calc(16px)!important;;
				color: $color-blue;
				font-style:italic;
		  	}
			&__betaalgemak {
				font-size:0.85rem;
				& a {
					color:$color-blue;
				}
			}
		  	&__actions {
		  		display:flex;
		  		column-gap:15px;
		  		justify-content: space-between;
				@media print {
					display:none;
				}
				margin-bottom:15px;
				&__optie{
					button{
						@include breakpoint (large){
							margin-top:0px!important;
						}
						&:hover{
							cursor:pointer;
						}
					}
				}
				& .cf-button {
					animation: highlight 2s;
				}
		  	}

		  	&__printwidget {
				display:none;
				@media print{
					display:block;
					font-size: rem-calc(10pt);
					p {
						margin:0px;
					}
				}
		  	}
		  	&__secondary {
		
				@include breakpoint(medium down) {
					padding-right: 0.625rem;
					padding-left: 0.625rem;
				}
				border-top:1px dashed white;
				padding:25px 0px;
				@include breakpoint(medium) {
					padding:25px;
				}
				@include breakpoint(large) {
					padding:30px;
				}
				color:white;
			} 
		  	
  
		}
		&__dowloadbrochure{
			&__tekst{
				font-size: 14px!important;
				font-weight: 400;
				text-decoration: none;
				color: #242A57;
				@include breakpoint(medium){
					font-size: 15px!important;
				}
				&:hover{
					text-decoration: underline;
				}
			}
			&__img{
				
			}
		}
		&__review{
			display:flex;
			column-gap:20px;
			border-bottom: 1px solid #D6D6D6;
			padding-bottom:15px;
			margin-bottom:20px;
			&__rating{
				margin-right:5px;
				&__number{
					@include header-text;
					font-weight:bold;
					margin-right:10px;
					color:$color-business-blue;
				}
				&__stars{
					color:#FFCE00!important;
					svg path { fill: #FFCE00;}
					font-size:rem-calc(14px);
					.fa-star{
						margin-right:2px;
						&:last-of-type{
							margin-right:0px;
						}
					}

				}
				&__count{
					font-size:14px!important;
					@include breakpoint(medium){
						font-size:15px!important;
					}
					font-weight: 500;
					@include header-text;
					letter-spacing: -0.1px;
				}
			}
			&__score{
			
			}
			&__readmore{
				button{
					font-weight:500;
					text-decoration:none;
					color:$color-business-blue;
					font-size:15px;
					@include header-text;
					letter-spacing: -0.1px;
					&:hover{
						text-decoration:underline;
					}
				}
			}
		}
		&__eerstvolgende{
			margin-bottom:18px;
			border-radius: 4px;
			padding: 14px 20px;
			border: 1px solid #CCCCCC;
			transition: border 400ms;
			position: relative;
			&:hover{
				border: 1px solid $color-blue;
				cursor: pointer;
			}
			.startgarantie{
				position: absolute;
				top:10px;
				right:10px;
			}
			&__title{
				font-size:16px;
				font-weight:700;
				color:$color-business-blue;
				@include header-text;
				margin-bottom:10px;
				padding-right:80px;
			}
			ul{
				margin-left:0px;
				margin-bottom:0px;
				li{
					margin-bottom:7px!important;
					display:flex;
					align-items:center;
					img{
						width:18px;
						margin-right:28px;
						filter: invert(9%) sepia(97%) saturate(2210%) hue-rotate(229deg) brightness(88%) contrast(91%);
					}
					span{
						font-size:rem-calc(14px);
						line-height:1;
						color:$color-business-blue;
					}
				}
			}
			&__label{
				background-color:#F2F8FF;
				padding:5px 18px;
				font-size:rem-calc(14px);
				color:$color-business-blue;
			}
			&__secondary{
				border-top:1px solid #D6D6D6;
			}
			&--stripped{
				padding:0px;
				ul{
					padding: 14px 20px;
				}
			}
		}
		&__buttons{
			&__main{
				flex-grow:1;
			}
			&__favorite{
				align-self:flex-end;
				.training-grid-block__favorite{
					position:relative;
					display: inline-block;
					right:unset;
					bottom:unset;
					margin-top:0px;
					width:45px;
					height:45px;
					top:-3px;
				}
			}
		}
		&__secondary{
			&__actions{
				&__item{
					display:flex;
					column-gap:15px;
					padding:15px 0px;
					position: relative;
					@include breakpoint(medium up){
						border-bottom: 1px solid #D6D6D6;
					}
					&:hover{
						.real-link{
							text-decoration: underline;
						}
					}
					&.link:hover{
						cursor: pointer;
					}
					&:first-of-type{
						padding-top:0px;
					}
					&:last-of-type{
						border-bottom:0px;
						padding-bottom:0px;
					}
					& > div { 
						line-height: 1;
						& > span.subtext { font-size:14px; color:#9B9EB9; line-height: 1.2; padding-top:7px; display: inline-block;}
					}
					.cover-link{
						position: absolute;
						width:100%;
						height:100%;
						top:0px;
						left:0px;
					}
					a{
						font-weight:500;
						color:$color-business-blue;
						text-decoration: none;
						font-size:15px;
						@include header-text;
						letter-spacing: -0.1px;
						&:hover{
							text-decoration: underline;
						}
					}
					img {align-self:flex-start; filter: invert(9%) sepia(97%) saturate(2210%) hue-rotate(229deg) brightness(88%) contrast(91%); width:16px;}
					span {
						font-size:15px;
						color:$color-business-blue;
					}
					&__image{
						background: transparent url('/images/nienke-rothuis-sidebar.png') 0% 0% no-repeat padding-box;
						background-size:cover;
						border: 1px solid $color-blue;
						border-radius: 8px;
						width:58px;
						height:68px;
						min-width: 58px;
						position: relative;
						.working-hours{
							width:8px;
							height:8px;
							background-color:#9DD08B;
							border-radius: 100%;
							position: absolute;
							top:-5px;
							left:-5px;
						}
					}
				}
			}
		}
		&__savelater{
			padding-bottom:0px;
			margin-bottom:25px;
			@include breakpoint(large){
				border:0px;
			}
			.favorite-link{ 
				button{
					display:block;
					margin:0 auto;
					@include breakpoint(large){
						margin:0;
					}
				}
				&.selected{
					button{
 						border: 1px solid #FF4F57;
 					}
					img.selected{
						filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
					}
					span.cf-button__text.selected{
						color:#FF4F57;
						display:inline-block;
					}
					img.notselected, span.cf-button__text.notselected{
						display:none;
					}
				}
				&.notselected{
					img.selected, span.cf-button__text.selected{
						display:none;
					}
					img.notselected{
						filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
						display:inline-block;
					}
					span.cf-button__text.notselected{
						color:$color-business-blue;
						display:inline-block;
					}
				}
				&:hover{
					cursor:pointer;
					&.notselected{
						img.notselected{
							
							filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
							transition:(all 400ms);
						}
						span.cf-button__text.notselected{
							transition:(all 400ms);
							color:$color-business-blue;

						}
					}
				}
			}
			&.mobile-savelater-training{
				padding-bottom:0px;
				margin-bottom:0px;
				.favorite-link{ 
					button{
						display:block;
						margin:0;
					}
				}
				.cf-button__icon{
					margin-right:0px;
				}
			}
		}
		&__springestaward23-mobile{
			border: 1px solid #1E225C;
			border-radius: 2px 12px 12px 12px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin: 0px 50px;
  			padding: 10px;
			margin-bottom:40px;
			&__textdiv{
				&__title{
					margin-bottom:0px;
					color: #1E225C;
					font-size:10px;
					font-weight:600;
					font-family: 'Lexend', 'sans-serif';
				}
				&__text{
					margin-bottom:0px;
					font-family: 'Poppins', 'sans-serif';
					font-size:11px;
					line-height: 1.3;
					color: #1E225C;
				}
			}
			&__imgdiv{
				img{
					height: 60px;
					width: 100%;
					object-fit: contain;
				}
			}
		}
		
	}
	.training__sidebar-other{
		margin-top:20px;
		
		&__gespreidbetalen {
			border:1px dashed black;
			padding:25px 0px;
			
			@include breakpoint(medium down) {
				border:1px dashed black;
				border-left:0px;
				border-right:0px;
				padding-right: 0.625rem;
				padding-left: 0.625rem;
			}
			
			background:white;
  			@include breakpoint(medium) {
				padding:25px;
  			}
  			@include breakpoint(large) {
				padding:30px;
  			}
			color:black;
			&__title{
				font-weight:bold;
				margin-bottom:0.25rem;
			}
			&__text {
				font-size:0.85rem;
				margin-bottom:0px;
			}
		}
		
		&__secondary {
		
			@include breakpoint(medium down) {
				padding-right: 0.625rem;
				padding-left: 0.625rem;
			}
			border-top:1px dashed white;
			padding:25px 0px;
  			@include breakpoint(medium) {
				padding:25px;
  			}
  			@include breakpoint(large) {
				padding:30px;
  			}
			color:white;
		}
		
		&__review{
			border:1px solid $color-business-blue-light;
			border-radius:5px;
			background-color:$color-white;
			padding:30px;
			margin-bottom:20px;
			&__rating{
				text-align:center;
				margin:10px 0px 20px 0px;
			}
			&__text{
			}
			&__text::before, &__text::after{
				content: '"';
			}
			&__author{
				font-size:rem-calc(14);
				font-weight:700;
				color:$color-business-blue;
				display:block;
				margin-top:5px;
			}
			.slick-dots {
				bottom:-35px;
			}
			.slick-dots li button:before {
				font-size:1.7rem;
				color:black;
			}
			.slick-dots li.slick-active button:before{
				color:black;
			}
		}
		
		&__teachers{
			border-radius:5px;
			margin-bottom:20px;
			border: 1px solid $color-blue-ultralight;
			background-color:$color-blue-ultralight;
			padding:30px;
		}
		
		&__accreditation{
			border: 1px solid $color-dark-gray;
			border-radius:5px;
			margin-bottom:20px;
			background-color:$color-white;
			padding:30px;
		}
		
		&__loading-spinner{
			width:100%;
			height:40px;
			background: url('/images/ajax-loader-zwart.gif');
			background-position:center center;
			background-repeat:no-repeat;
		}
		
		&__filter{
			margin-bottom:20px;
			& h2{
				margin-top:15px;
  				@include breakpoint(medium) {
  					margin-top:0px;
				}
				margin-bottom:20px;
			}
		}
		
		&__filters{
			&__zoekopdracht{
				position:relative;
			}
			&__zoekopdracht::before{
				position: absolute;
				right: 10px;
				top: 10px;
				background-image: url(/images/search.png);
				background-position: top left;
				width: 20px;
				height: 20px;
				content: '\a0';
				background-size: 100% 100%;
				
			}
			&__dividerborder{
				border-top:1px solid #979797;
				margin-bottom: 15px;
			}
			&__divider{
				position: relative;
				font-size: 1rem;
				z-index: 1;
				overflow: hidden;
				text-align: center;
				margin-bottom: 15px;
			}
			&__divider::before, &__divider::after{
				position: absolute;
				top: 51%;
				overflow: hidden;
				width: 37%;
				height: 1px;
				content: '\a0';
				background-color: #979797;
			}
			&__divider::before{
				margin-left: -40%;
    			text-align: right;
    		}
    		&__divider::after{
    			margin-left: 3%;
    		}
			
			&__subtitle {
				font-weight:bold;
				font-size:1.05rem;
			}
		}
	}