.training-container {
	@media print{
		margin-top:100px;
	}
}
.eventpage{
	overflow: hidden;
	@include breakpoint(large){
		overflow: initial;
	}
}
.eventpage > .column {
	padding:0px;
}

.scroll-up .event__submenu{
	top:0px;
	@include breakpoint(large){
		top:74px;
	}
}
.scroll-down .event__submenu{
	top:0px;
}
.step { margin:15px 0px; } 
.fatfrank { font-family:"Lexend",sans-serif;}
.events{
	&__nextup{
		padding:0px!important;
		margin-top:30px;
		box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
		border-radius:8px;
		@include breakpoint(large){
			min-height:400px;
		}
		&__container{
			width:100%;
    		@include breakpoint(medium) {
			display:flex;
			flex-direction:column;
			}
			@include breakpoint(large){
			flex-direction:row;
			
			}
		}
		&__fulllabel{
			display: inline-block;
			padding: 7px 30px;
			background-color: yellow;
			border-radius: 5px;
			font-size: 1.4rem;
			font-weight: bold;
			position: absolute;
    		right: 35px;
    		margin-top: 10px;
			@include breakpoint(medium) {
				right: 35px;
				margin-top:10px;
			}
			@include breakpoint(large) {
				right: 25px;
				margin-top:10px;
			}
		}
		&__text{
			border-radius:0px 0px 8px 8px;
			@include breakpoint(medium) {			
				border-radius:0px 8px 8px 0px;
			}
			@include breakpoint(large) {
				padding:0px;
				display: flex;
			}
			&__container{
				margin:auto auto;
				width:94%;
				background-color:white;
				padding:20px!important;
				background:white;
    			@include breakpoint(medium) {
					width:98%;
					padding:30px!important;
    			}
				@include breakpoint(large) {
    				margin:inherit;
					width:100%;
					padding:50px!important;
					align-self:center;
				}

				.cf-button__text{
					display:flex;

					svg{
						filter: invert(99%) sepia(6%) saturate(23%) hue-rotate(249deg) brightness(106%) contrast(100%);
					}
				}
				a{
					&:hover{
						svg{
							filter: invert(9%) sepia(40%) saturate(4457%) hue-rotate(230deg) brightness(100%) contrast(94%);
						}
					}
				}
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
				opacity: 0.53;
				margin-bottom:20px;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
			&__titlehome{
				font-size:30px !important;
			}
		}
		&__cta{
			padding-top:50px;
		}
		&__media {
			text-align:center;
			height:100%;
			position: relative; 
			@include breakpoint(medium){
				padding-top: 0.9375rem;
				padding-bottom: 0.9375rem;
			}
    		@include breakpoint(large) {
				padding-left:0px;
				padding-right:0px;
			}
			&__movie, &__image {
				margin:auto auto;
				width:94%;
				height: 100%;
				border-radius:8px 8px 0px 0px;
				@include breakpoint(large){
			
					border-radius:8px 0px 0px 8px;
				}
				
    			@include breakpoint(medium) {
    				margin:inherit;
					width:100%;
					height: 240px;
    			}
    			@include breakpoint(large) {
    				width:100%;
    				height:100%;
    			}
			}
			
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
				@include breakpoint(medium) {
    				height:100%;
				}
				@include breakpoint(xlarge) {
    				height:100%;
				}
			}
			&__image.lazy {
				background-image:none!important;
			}
		
		}
		
		
	}
	
	&__upcoming{
		&.home{
			padding-left:1.4rem;
			padding-right:1.4rem;
		}
		&__text {
			//margin-bottom:20px;
		}
		&__event{
			margin-top:50px;
			&__container{
				width:94%;
				background-color:white;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
				border-radius: 5px;
				border: 1px solid #EDEDED;
				height:inherit;
				margin:auto auto;
			}
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
    			@include breakpoint(medium) {
					height:240px;
				}
    			@include breakpoint(xlarge) {
					height:340px;
    			}
			}
			&__information{
				padding:30px;
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
	}
	
	&__planned{
		
		margin-top:30px;
    	@include breakpoint(medium) {
			padding-left:2%!important;
			padding-right:2%!important;
		}
		&__text{
			@include breakpoint(large) {
				padding:0px;
			}
			&__container{
				margin:auto auto;
				width:94%;
				background-color:white;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
				padding:30px!important;
    			@include breakpoint(medium) {
					width:98%;
    			}
				@include breakpoint(large) {
    				margin:inherit;
					width:100%;
					padding:30px!important;
				}
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
				margin-bottom: 20px;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
		&__cta{
			padding-top:50px;
		}
		&__media {
			text-align:center;
    		@include breakpoint(large) {
				padding-left:0px;
				padding-right:0px;
			}
			&__movie, &__image {
				margin:auto auto;
				width:94%;
				height: 100%;
				
    			@include breakpoint(medium) {
    				margin:inherit;
					width:98%;
					height: 240px;
    			}
    			@include breakpoint(large) {
    				width:100%;
    				height:100%;
    			}
			}
			
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
				@include breakpoint(medium) {
    				height:100%;
				}
				@include breakpoint(xlarge) {
    				height:100%;
				}
			}
		
		}
	}
	
	&__missed{
		margin-top:30px;
    	@include breakpoint(medium) {
			//padding-left:2%!important;
			//padding-right:2%!important;
		}
		&__text{
			@include breakpoint(large) {
				padding:0px;
			}
			&__title { 
				@include breakpoint(large) {
					min-height:87px;
				}
			}
			&__container{
				margin:auto auto;
				width:94%;
				background-color:white;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
				padding:30px!important;
    			@include breakpoint(medium) {
					width:98%;
    			}
				@include breakpoint(large) {
    				margin:inherit;
					width:100%;
					padding:30px!important;
				}
				
				& p {
					@include breakpoint(large) {
						min-height:225px;
					}
				}
			}
			&__date {
				font-size:1.15rem;
				font-weight:600;
			}
			p {
 				@include raleway;
 				margin:12px 0px 20px 0px;
			}
		}
		&__cta{
			padding-top:50px;
		}
		&__media {
			text-align:center;
			position: relative; 
    		@include breakpoint(large) {
				padding-left:0px;
				padding-right:0px;
			}
			&__movie, &__image {
				margin:auto auto;
				width:94%;
				height: 100%;
				
    			@include breakpoint(medium) {
    				margin:auto auto;
					width:98%;
					height: 240px;
    			}
    			@include breakpoint(large) {
    				width:100%;
					height: 240px;
    			}
			}
			
			&__image {
				background-repeat:no-repeat;
				background-size:cover;
				background-position:center center;
				height:160px;
				@include breakpoint(medium) {
    				height:100%;
					height: 240px;
				}
				@include breakpoint(xlarge) {
    				height:100%;
					height: 240px;
				}
			}
			&__image.lazy {
				background-image:none!important;
			}
		
		}
		
		
	}
	
	&__images{
		& > .row > .column{

			padding-left:20px;
			padding-right:20px;
		}
		&__row{
			padding-top:40px!important;
			@include breakpoint(large) {
				padding:40px 0px!important;
			}
			
		}
		&__slider{
			&__image{
				div {
					padding:20px;
				}
			}
		}
		.slick-dots li button:before {
			font-size:1.8rem;
		}
		.slick-prev:before, .slick-next:before{
			color:black;
			font-size:1.5rem;
		}

		.snap-slider .rf-cards-scroller-crop{
			height:18rem;
			@include breakpoint(large){
				height: 37rem;
			}
		}
		.snap-slider  .rf-ccard-40 .rf-ccard-content {
			width: 24rem;
			height: 17rem;
			@include breakpoint(large){
				width: 65rem;
				height: 36rem;
			}
		}
		.snap-slider .rf-ccard-img-full-wrapper img{
			//@include breakpoint(large){
				//height:max-content;
				object-fit: cover;
				height: 100%;
				width: 100%;
			//}
		}
	}

}
.event {
	&__actiebalk {
		background:#02ABAC;
		&__row{
			.column {
				text-align:center;
				color:white;
				padding:7px 0px;
				font-size:0.95rem;
				.bold{
					font-weight:bold;
				}
			}
		}
	}
	&__banner{
		background-position: center center;
		background-size: cover; 
		position: relative;
		@include breakpoint(large){
			//min-height:750px;
			//height:100vh;
		}
		&__image{
			padding:60px 20px; 
			@include breakpoint(large){
				//height:100vh;
				//min-height:750px;
				padding:60px 20px; 
			}
			background-position: center center;
			background-size: cover; 
			//background-color: rgba(32, 27, 102, 0.5);
			background-image: linear-gradient(to right, rgba(0,0,0,0.6), rgba(255,255,255,0));
			@include breakpoint(large){
				background-image: linear-gradient(to right, rgba(0,0,0,0.6), transparent, rgba(255,255,255,0));
			}
			&.metteaser{
				padding-bottom:0px;
			}
			&.banner_metTeaser_ai_campagne{
				@include breakpoint(small){
					padding: 0px 20px;
				}
			}
		}
		&.ai-campange{
			background-position: -50% !important;
			background-repeat: no-repeat;
			background-color: #bfbfbf;
			@include breakpoint(430px){
				background-position: -250% !important;
			}
			@include breakpoint(medium){
				background-position: center center !important;
			}
			.ai-campange{
				&__yougotthis{
					height: 30px;
					width: 35px;
					top: 5px;
					position: absolute;
					right: 50px;
					@include breakpoint(medium){
						height: 75px;
						width: 85px;
						top: 40px;
						right: 30px;
					}

				}
			}
		}
		&__title{
			h1{
				font-size:2rem;
				letter-spacing: 0px;
				text-align: center;
				line-height: 1.1;
				color:white;
				@include breakpoint(large){
					font-size:50px;
				}
				margin-bottom:1.4rem;
			}
			h2{ letter-spacing: 0px; font-weight: 600; margin-bottom:1.2rem!important; font-size:1.25rem; }

			&.title_AI_campagne{
				margin-top:35px;
				@include breakpoint(large){
					margin-top:50px;
				}
				&.hide-for-large .column{
					padding:0 2rem;
				}
				h1{
					font-size:20px;
					font-weight: 800;
					color: #1E225C;
					letter-spacing: -0.4px;
					font-family: 'Lexend', sans-serif;
					text-align: start;
					margin-bottom:10px;
					@include breakpoint(medium){
						text-align: start;
						font-size:35px;
						letter-spacing: -0.7px;
					}
					@include breakpoint(large){
						text-align: center;
						font-size:35px;
						letter-spacing: -0.7px;
						margin-bottom:25px;
					}
				}
				h2{
					margin-top:34px;
					color: #10133E;
					font-size:17px;
					font-family: 'Poppins', sans-serif;
					letter-spacing: 0px;
				}
			}
		}
		&__data{
			color:white;
			margin-top:20px;
			&__date, &__location, &__price{
 				@include raleway;
				font-size:1rem;
				text-align: center;
				font-weight:600;
				@include breakpoint(large){
					font-size:19px;
				}
				display: flex;
				align-items: center;
				gap:15px;
				span{ color:white; }
				.fa, .svg-inline--fa {
					font-size:1.1rem;
					display:inline-block;
					font-weight:normal;
					color: white;
					@include breakpoint(large){
						font-size:1.25rem;
					}
				}
				svg path { fill: white;}
			}
		}
		&__intro{
			margin-top:20px;
			text-align: center;
  			@include raleway;
			p {
				color:white;
				font-weight:500;
				font-size:14px;
				@include breakpoint(large){
					font-size:17px;
					font-weight:medium;

				}
			}
		}
		&__signup{
			text-align: center;
			margin-top:20px;
			margin-bottom:60px;
			&__container{
				background-color: white;
				border-radius:12px;
				padding:15px;
				@include breakpoint(large){
					display: flex;
					justify-content: space-between;
				}
				&__text{
					margin-bottom:15px;

					@include breakpoint(large){
						margin-bottom:0px;
					}
					.strong{
						font-weight: bold;
						font-size: 16px;
						@include breakpoint(large){
							font-size: 18px;
						}
					}
					p { margin-bottom:0px;}
					
				}
				&__button{
					a, button { margin-bottom: 0px;}
				}
			}
			&.banner-box_AI_campagne{
				margin-bottom: 0px;
				@include breakpoint(large){
					margin-top:200px;
					margin-bottom: 0px;
				}
				.split-header__container{
					border-radius: 4px 12px 12px 12px;
					width: fit-content;

					.training-header__uspblock__block{
						column-gap: 35px;
						&__item{
							width: fit-content;
							&__image{
								width: 16px;
							}
							&__text{
								font-weight:400;
								font-size:13px;
								@include breakpoint(medium){
									font-size: 0.9375rem;
								}
							}
						}
					}
					.banner-score-span{
						letter-spacing: -0.15px;
						color: #1E225C;
						font-weight: bold;
					}
					.split-header__container__lower{
						border-top: none;
						padding: 0px 20px;
					  	padding-bottom: 10px;
						@include breakpoint(medium){
  							padding-bottom: 0px;
							padding-left: 25px;
							padding-bottom: 25px;
						}
						&__button{
							display: flex;
							gap: 5px;
							flex-direction: column;
							@include breakpoint(medium){
								flex-direction: row;
								gap:15px;
							}

							button{
								margin-bottom:0px;
								padding: 7px 10px;
								@include breakpoint(medium){
									padding: 7px 10px;
									padding: 13px 20px;
								}
							}
							.ai-campange-button1{
								margin-bottom:0px;
								text-decoration: none;
								span{
									font-weight: 500;
								}
								&:hover{
									@include breakpoint(medium){
										padding-right: 20px;
									}
								}
							}
							.ai-campange-button2{
								border: 1px solid #DDDDDD;
								//padding-left: 0;
								@include breakpoint(medium){
									//padding-left: initial;
								}
								justify-content: start;
								span{
									width: 100%;
									@include breakpoint(medium){
										width: auto;
									}
									font-weight: 400;
								}
								.cf-button__icon{
									margin-left: 0px;
									margin-right: 5px;
									width: auto;
									@include breakpoint(medium){
										margin-right: 5px;
									}
								}
								&:hover{
									@include breakpoint(medium){
										padding-right: 20px;
									}
								}
							}
						}
					}
				}
			}
		}
		&__signup-subtext{
  			@include raleway;
			margin-top:15px;
			text-align: center;
			font-weight:bold;
			p {
				font-size:0.9rem;
				color:white;
			
			}
			color:white; 
		}
		&__teaser{
			margin-top:60px;
			@include breakpoint(large){
				margin-top:120px;
			}
			&__container{
				display: flex;
				align-items: center;
				gap:20px;
				&__image{
					img { width:80px; transform: rotate(-10deg); box-shadow: 0px 3px 6px rgba(0,0,0,0.16); }
				}
				&__text{
					p { color:white; margin-bottom:0px; font-weight: 600;
						@include breakpoint(large){
							font-size:17px;
						}
					}
				}
				&__icon{
					img { filter: invert(99%) sepia(22%) saturate(3%) hue-rotate(226deg) brightness(115%) contrast(100%); }
				}
			}
		}
		.cf-button{
			@include breakpoint (small only){
				height: auto;
				padding:12px 7px!important;
				&__text{
					margin:0px!important;
					padding:0px;
				}
			}
			
		}
		&--leftaligned {
			&.aitools{
				//background-position: 60% 10%;
				background-position: 45% 50%;
				@include breakpoint(medium){
					background-position: 0% 50%;
				}
			}
			.event__banner{
				&__title{
					h1{
						text-align: left;
					}
				}
				&__data{
					&__date, &__location, &__price{
						text-align: left;
					}
					&:not(:first-of-type){
						margin-top:5px;
					}
				}
				&__intro{
					text-align: left;
				}
				&__signup{
					text-align: left;

				}
				&__signup-subtext{
					text-align: left;

				}
				
			}
		}
	}
	&__submenu{
		margin-top:75px;
		border-bottom: 1px solid #D6D6D6;
		position: sticky;
		top:0px;
		@include breakpoint(large){
			top:74px;
		}
		background-color:white;
		z-index: 6;
		overflow-x: scroll;
		.submenu-desktop{
			width:100%;
			padding-left:20px;
			padding-right:20px;

    		@include transition(top 400ms, box-shadow 400ms);
			li.menu-item a {
				padding-bottom:8px;
			}

		}
		&__signup{
			display: flex;
			align-items: center;
			gap:25px;
			&__notice{
				p { margin-bottom:0px; }
				&__title{
					font-weight:bold;
				}
			}
			&__button{
				a, button{ margin-bottom:0px;}
			}
		}
	}
	&__intro {
		margin-top:50px!important;
		&__text{
			padding-bottom:60px;
			padding-left:20px;
			padding-right:20px;
    		@include breakpoint(large) {
    			padding-bottom:0px;
				padding-right:40px;
    		}
			h2 {
				margin-bottom:25px;
				letter-spacing: 0px;
			}
			p {
  				@include raleway;
				margin-bottom:25px;
			}
			a {
				font-weight:600;
				.fa, .svg-inline--fa {
					font-size:1.5rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
				}
			}
			#videoplayer-container{
				background-image:url(https://www.competencefactory.nl/wordpress/wp-content/uploads/2024/02/Richard-van-Hooijdonk2.jpg); 
				height: 200px; 
				background-position: 50% 40%; 
				border-radius:12px; 
				background-size:cover;
				@include breakpoint(large){
					height: 300px; 
					background-position: 50% 25%; 

				}
				.vid-icon{
					max-height: 120px;
					max-width: 120px;
					line-height: 1;
				}
			}
		}
		&__movie {
			padding-left:20px;
			padding-right:20px;
			small{ 
				font-style:italic;
				font-size:1.15rem;
				color: $color-dark-gray-light;
			}
			iframe {
				width:100%;
				height:315px;
			}
		}
		& .row-divider{
			position:absolute;
			display:block;
			width:0px;
			height:0px;
			content:'\00a0';
			border-left: 40px solid transparent;
			border-right: 40px solid transparent;
			border-top: 40px solid white;
			left:50%;
			margin-left:-40px;
			margin-top:60px;
		}
	}
	&__speakers{
		background-color:$color-blue-ultralight;
		&__row{
			padding:40px 0px;
			padding-bottom:0px;
			&__section{
				margin:20px 0px;
				padding:20px;
				background:white;
				border-radius:10px;
				&__title{
					font-size:1.4rem;
					text-transform:uppercase;
					color:#ff5055;
					text-align:center;
					font-weight:800;
					margin: 0 auto;
					letter-spacing: 0px;
				}
			}
		}
		&__speaker{
			&__padding{
				margin:10px;
			}
			&__photo{
				height:300px;
				border-radius:10px;
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center center;
				background-color:gray;
				margin-bottom:20px;
			}
			&__name{
  				@include raleway;
				font-size:1.2rem;
				font-weight:700;
				color:  $color-business-blue;
			}
			&__function{
  				@include raleway;
				font-size:0.9rem;
				color:  $color-business-blue;
			}
			padding-bottom:40px;
		}
		.slick-dots li button:before {
			font-size:1.8rem;
		}
		.slick-prev:before, .slick-next:before{
			color:black;
			font-size:1.5rem;
		}
		&__cta{
			text-align:center;
			a{
  				@include raleway;
				font-size:1.15rem;
				font-weight:600;
			}
		}
	}
	&__program{

		& > .row > .column{

			padding-left:20px;
			padding-right:20px;
		}
		&__timeline{
			padding-bottom:60px;
    		@include breakpoint(medium) {
    			padding-bottom:0px;
    		}
			h2{
				margin-bottom:50px!important;
			}
    		h3{
    			text-transform:uppercase;
    		}
			&__container{
				position:relative;
				&__background{
					background-image:url('/images/timeline-dash.png');
					background-repeat:repeat-y;
					background-position:9% 0%;
					background-size:5px;
					height:100%;
					width:100%;
					position: absolute;
				}
			}
		}
		//
		&__item{
			box-shadow: 2px 4px 15px rgba(46,60,75,0.101);
			border-radius:12px;
			background:white;
			position: relative;
			z-index: 1;
			margin-bottom:20px;
			& .small-3{
  				display: flex;
  				align-items: center;
			}
			& .small-3.starttimeline:before{
				position:absolute;
				content: '\00a0';
				background-color:white;
				width:50px;
				height:50px;
				margin-top:-40px;
    			@include breakpoint(medium) {
    			
    			}
				
			}
			& .small-3.endtimeline:before{
				position:absolute;
				content: '\00a0';
				background-color:white;
				width:50px;
				height:50px;
				margin-top:40px;
    			@include breakpoint(medium) {
    			
    			}
			
			}
			&__icon{
				//width:64px;
				//height:64px;
				z-index:4;
				position:relative;
    			@include breakpoint(medium) {
					//width:80px;
					//height:80px;
				}
				//border-radius:50%;
				text-align:center;
				background-color:white;
				//border: 1px solid #3BC3BE;
				& img{
					margin-top:12px;
					//width:40px;
					//height:40px;
    				@include breakpoint(medium) {
						margin-top:15px;
						//width:50px;
						//height:50px;
					}
				}
				&.red{
					background-color:#ff5055;
				}
				
			}
			&__data{
				padding-top:15px;
				padding-bottom:15px;
				padding-left:15px;
    			@include breakpoint(medium) {
					padding-top:15px;
					padding-bottom:15px;
    			}
    			&.notimeline {
    				padding:10px;
    				padding-top:5px;
    				@include breakpoint(medium) {
    					padding-top:25px;
    				}
    			}
			}
			&__time{
 				 @include raleway;
				font-size:1.15rem;
				color:$color-business-blue;
			}
			&__title{
				color:$color-business-blue;
				
 				@include raleway;
				font-size:1.15rem;
				font-weight:700;
				&.alt {
					color:white;
				}
			}
		}
		&__benefit{
			h3 {
				@include header-text; 
				font-size:20px!important;
				font-weight:600;
				@include breakpoint(large) {
					font-size:30px!important;
					margin-bottom:30px;

				}
			}
		}
		&__signup{
			margin-top:60px;
			@include breakpoint(large) {
				margin-top:100px;
			}
			box-shadow: 0px 10px 40px rgba(142,151,165,0.203);
			border-radius: 12px;
			&__header{
				border-bottom:1px solid #D6D6D6;
				padding:15px;
				padding-top:15px;
				padding-bottom:15px;
				display: flex;
				align-items: center;
				gap:25px;
				@include breakpoint(large) {
					padding:30px;
					gap:100px;
				}
				&__image{
					img { 
						width:80px; 
						margin-top:-30px;
						transform: rotate(-10deg); 
						box-shadow: 0px 3px 6px rgba(0,0,0,0.16); 
						@include breakpoint(large) {
							width:120px;
						}
					}
				}
				&__text{
					strong { color:$color-business-blue;}
					ul { list-style-type: none; margin: 0;}
					li { 
						font-weight:medium;
						@include breakpoint(large) {
							font-size:17px; 
						}
					}
				}
			}
			&__footer{
				padding:15px;
				padding-top:20px;
				padding-bottom:25px;
				@include breakpoint(large) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap:25px;
					padding:30px;
				}
				.event__program__signup__notice{
					margin-bottom:15px;
					@include breakpoint(large){
						margin-bottom:0px;
					}
					p { margin-bottom:0px; }
					&__title{
						font-weight:bold;
						font-size: 16px;
						@include breakpoint(large){
							font-size: 18px;
						}
					}
				}
				.event__program__signup__button{
					a, button { margin-bottom:0px; }

				}
			}
		}
		&__who {
  			@include raleway;
			&__icon{
				margin-left:auto;
				margin-right:auto;
    			@include breakpoint(medium) {
					margin-left:0;
					margin-right:0;
    			}
				width:80px;
				height:80px;
				border-radius:50%;
				margin-bottom:10px;
				text-align:center;
				background:white;
				border: 1px solid #3BC3BE;
				& img{
					margin-top:15px;
					width:50px;
					height:50px;
				}
				&.red{
					background-color:#ff5055;
				}
			}
		}
		&__where {
  			@include raleway;
			margin-top:50px;
			&__icon{
				margin-left:auto;
				margin-right:auto;
    			@include breakpoint(medium) {
					margin-left:0;
					margin-right:0;
    			}
				width:80px;
				height:80px;
				border-radius:50%;
				margin-bottom:10px;
				text-align:center;
				box-shadow: 0 1px 4px 1px rgba(0,0,0,0.26);
				& img{
					margin-top:15px;
					width:50px;
					height:50px;
				}
				&.red{
					background-color:#ff5055;
				}
			}
		}
	}
	&__cta{
		background-color: $color-business-blue;
		background: 
			/* top, transparent red, faked with gradient */ 
			linear-gradient(
			  rgba(30, 34, 93, 0.9), 
			  rgba(30, 34, 93, 0.9)
			),
			/* bottom, image */
			url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2019/04/17fd32ac-e683-40d9-91d4-5be030745aba.jpg');
			background-repeat:no-repeat;
			background-size:cover;
		.programitems {
			margin:10px 0px;
		}
		&__usps{
			list-style-type:none;
			margin-left:0px;
			&__usp {
				display:inline-block;
				width:80%;
				margin-left:0px;
				@include breakpoint(large){
					margin-left:15px;
				}
			}
			& li {
				display:flex;
				align-items:center;
				width:100%;
  				@include raleway;
				font-size:1rem;
				font-weight:medium;
				margin-top:16px;
				.fa, .svg-inline--fa {
					font-size:1rem;
					margin-right:15px;
					display:inline-block;
					font-weight:normal;
					vertical-align:top;
				}
				svg:not(.svg-inline--fa){
					path{
						fill:$color-blue;
					}
				}
			}
			&--light{
				.event__cta__usps__usp{
					color:white;
				}
				li { color:white; .fa, .svg-inline--fa { 
					color:white;} }
			}
		}
		&__form{
			padding-bottom:30px;
    		@include breakpoint(medium) {
    			padding-bottom:0px;
    		}
		}
		h2 { color:white; text-transform:initial; }
		& .evenementform {
			margin-top:35px;
			margin-bottom:0px;
			& .column { padding:0px; }
			& .evenement-aanmelden__form input, .evenement-aanmelden__form button{
				width:100%;
				margin-bottom:20px;
			}
			& .evenement-aanmelden__form button{
				padding-top: 0px !important;
			}
		}
	}
	&__speakers-about{
		overflow: hidden;
		h2{
			margin-bottom:60px!important;
		}

		.speaker-grid{
			gap:20px;
			@include breakpoint(large){
				gap:40px;

			}
			padding-left:20px!important;
			padding-right:20px!important;
			position: relative;
			&::before{
				height: 50%;
				width: 10%;
				left:-20px;
				bottom: -50px;
				content: "";
				background-image: url(/images/dot-bgv5.svg);
				position: absolute;
				background-repeat: repeat;
				z-index: -1;
				background-position: center;
				background-size: 45px;

			}
			&::after{
				top: -120px;
				right: -30px;
				height: 40%;
				width: 15%;
				content: "";
				background-image: url(/images/dot-bgv5.svg);
				position: absolute;
				background-repeat: repeat;
				z-index: -1;
				background-position: center;
				background-size: 45px;

			}
			&__item{
				box-shadow: 0px 10px 40px rgba(142,151,165,0.203);
				background-color: white;
				border-radius: 12px;
				padding:30px;
				flex-basis:100%;
				@include breakpoint(large){    
					flex-basis: 48%;
				}
				&__header{
					display: flex;
					align-items: center;
					gap:15px;
					@include breakpoint(large){ 
						gap:20px; 
					}
					&__image{
						flex-basis: 65px;
						@include breakpoint(large){  
							flex-basis:unset;
						}
						img{
							max-width: unset;
							width:65px;
							height:65px;
							@include breakpoint(large){  
								width:110px;
								height:110px;

							}
							border-radius:50%;
							object-fit: cover;
						}

					}
					&__title{
						@include header-text;
						font-weight:bold;
						font-size:20px;
						margin-bottom:0px;
						line-height: 1;
					}
					&__subtitle{
						margin-top:5px;
						margin-bottom:0px;
						font-size:14px;

					}
				}
				&__content{
					margin-top:25px;
					p{
						margin-bottom:0px;
						font-size:14px;
					}
					ul{
						font-size:14px;
						margin-bottom:0px;
						li{
							padding-left:10px;
						}
					}
				}
			}
		}
		&__speaker{ 
			margin-bottom:35px;
		}
		&__image{
			width:125px;
			height:125px;
			border-radius:50%;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center center;
			display:inline-block;
			margin-bottom:35px;
			
		}
		p {
			text-align:left;
  			@include raleway;
		}
	}
	&__final-cta{
		text-align:center;
		& > .row > .column{

			padding-left:20px;
			padding-right:20px;
		}
		h2 { text-transform:initial; text-align: left;
			
			@include breakpoint(large){
				text-align: center;
			}
		}
		p {
  			@include raleway;
			font-size:1.15rem;
			font-weight:normal;
			text-align: left;
			@include breakpoint(large){
				width:60%;
				margin-left:auto;
				margin-right:auto;
				text-align: center;
			}
			
			&.subtext {
				margin-top:10px;
				font-size:0.9rem;
				font-weight:600;
			}
		}
		&.yellow{
			background-color:#f7fb00;
			
			p,h2,a { color:black; }
		}
	}
	&__joinevent, &__planned{
		background-color: $color-lighter-gray;
	}
	&__section-divider{
		padding:0 20px;
		.divider-container{
			border-bottom:1px solid $color-business-blue;
			width: 100%;
		}
	}
	&__section{
		margin:60px 0px; 
		@include breakpoint(large){
			margin:100px 0px;
		}
		&.seo-landingpagev3{
			margin-bottom: 0px;
			@include breakpoint(large){
				margin-top:150px;
			}
			.seo-landingpagev3__container{
				padding-bottom: 50px;
  				padding-top: 50px;
			}
		}
		& > .row {
			padding: 0px 15px;
    		@include breakpoint(medium) {
    			padding:0px;
    		}
		}
		&__title{
			@include header-text;
			font-size:29px;
			letter-spacing: 0px;
			@include breakpoint(medium) {
    			font-size:39px;
    		}
			&.red{
				color:#ff5055;
			}
		}
		&__subtitle{
			font-size:1.3rem;
			text-transform:uppercase;
			color:#ff5055;
			font-weight:800;
			margin-bottom:5px;
		}
		h3 {
			font-size:1.3rem;
		}
		.centered{
			text-align:center;
		}
		.fullwidth {
			text-align: center;
			@include breakpoint(large){
				max-width:800px;
				margin-left:auto;
				margin-right:auto;
			}
		}
		.incompany__process__image{
			margin-bottom: -50px;
			@include breakpoint(large){
				margin-bottom: -100px;
			}
		}
		&--sub{
			border-bottom:1px solid $color-dark-gray;
			margin-bottom:10px;
			&:last-of-type{
				margin-bottom:40px;
			}
			&__content{
				&.closed{
					display:none;	
				}
			}
		}
		&.lastitem{
			margin-bottom:-15px;
			@include breakpoint(medium){
				margin-bottom:-50px;
			}
		}
		&--greybg{
			background-color:#F8F8FA;
			padding:50px 0px;
			@include breakpoint(large){
				padding:100px 0px;
			}
		}
	}
	&__section-header{
		font-size:rem-calc(20);
		margin-bottom:25px;
		color: $color-black;
		&--sub{
			margin-bottom:15px;
			cursor:pointer;
			font-size:rem-calc(17);
			@include open-sans;
			&::before{
				content:"-";
				width:15px;
				display:inline-block;
				margin-right:15px;
			}
			&.closed{
				&::before{
					content:"+";
				}
			}
		}
	}
}

.rf-ccard-content-withfullimg{
	&.eventscard{
		@include breakpoint(large){
			width: 731px !important;
		}
		box-shadow:none;
	}
}
.events-snapping{
	.rf-cards-scroller-itemview{
		transform: none !important;
		@include breakpoint(medium){
			transform: translateX(calc(max(1380px, 100vw)/2 - 660px)) !important;
		}
	}
}
.score-ai-campagne-row{
	.column{
		padding:0 2rem;
		.training-header__uspblock__block__item__text{
			font-size:14px;
		}
	}
}
.score-ai-campagne{
	gap:3px!important;
	margin-top:25px;
	margin-bottom: 25px;

	.start__reviews__ratings__star-full{
		width: 16px;
		height: 16px;
	}
	&__span{
		letter-spacing: -0.15px;
		color: #1E225C;
		font-weight: bold;
	}
}
.AI-campagne-trainingen{
	margin-top:25px;
	margin-bottom:25px;
	padding-top:0;
	padding-bottom:0;
	background-color:initial;
	@include breakpoint(large){
		margin-bottom:100px;
		margin-top:100px;
	}
	&__title{
		letter-spacing: -0.45px;
		color: #1E225C;
		font-size:20px;
		font-weight: 600;
		font-family: 'Lexend', 'sans-serif';
		padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 25px;

		@include breakpoint(medium){
		}
		@include breakpoint(large){
			font-size:30px;
			margin-bottom: 30px;
		}
		h2{
			letter-spacing: -0.45px;
			font-size:20px;
			font-weight: 600;
			font-family: 'Lexend', 'sans-serif';

			@include breakpoint(large){
				font-size:30px;
				margin-bottom: 30px;
			}
		}
		P, h2{
			margin-bottom: 0px;
		}
	}
	&__subtitle{
		padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 25px;

		@include breakpoint(medium){
		}
		@include breakpoint(large){
			margin-bottom: 30px;
		}
		h2{
			letter-spacing: -0.45px;
			font-size:20px;
			font-weight: 600;
			font-family: 'Lexend', 'sans-serif';

			@include breakpoint(large){
				font-size:30px;
				margin-bottom: 30px;
			}
		}
		P, h2{
			margin-bottom: 0px;
		}
	}
	&.posts{
		.rf-ccard-content{
			height:470px;
			@include breakpoint(large){
				height:520px;
			}
		}
	}
}
.ai-campagne-springestaward-mobile{
	padding-right: 2rem;
	padding-left: 2rem;
	margin-bottom: 25px;
}