.arrow-up {
  width: 0; 
  height: 0;  
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $color-blue-ultralight;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $color-blue-ultralight;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid $color-blue-ultralight;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; 
  border-right:5px solid $color-blue-ultralight; 
}