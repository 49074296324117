.post-summary-large {
  margin: 0 0 1rem;
  box-shadow: 0px 10px 30px 0px rgba(157,171,192,.2);
  border-radius:2px 16px 16px 16px;
  height:100%;
  position:relative;
  @include breakpoint(medium) {
    margin: 0 0 2rem;
  }

  @include breakpoint(xlarge) {
    margin: 0;
    min-height: 180px;
  }
  &__link{
    position: absolute;
    width:100%;
    height:100%;
    left:0px;
    top:0px;
  }
  &__image {
  	img{
  		border-radius:8px 8px 0px 0px;
  	}
    @include breakpoint(medium) {
      overflow: hidden;
      min-width: 260px;
      max-height: 180px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
  	padding:30px;
  	border-radius:0px 0px 8px 8px;
  	background:white;
    @include breakpoint(medium) {
      /*padding: 0 1rem 0 1rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;*/
    }
  }
  
  &__date {
    display: block;
    font-size: rem-calc(14);
    margin-bottom:15px;
  }

  &__title {
    @include raleway;
    font-weight: 800;
    font-size: 18px;
    color: $post-summary-header;
    margin-top: 0.5rem;

    @include breakpoint(medium) {
      margin-top: 0;
	  min-height:52px;
    }
    @include breakpoint(large){
      font-size: 22px;
    }
  }

  &__intro {
    @include open-sans;
    color: $post-summary-text;
    @include breakpoint(medium) {
      flex-grow: 1;
    }
    margin-bottom:15px;
  }

  &__action {
    @include breakpoint(medium) {

    }
    @include breakpoint(large) {
      width: 50%;
    }
    @include breakpoint(xlarge) {
      width: 85%;
    }

    i {

    }
  }
  
  &:hover{
  		box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.35);
  		cursor:pointer;
  		@include transition(all 400ms);
  		.post-summary-large__title {
  			color:$color-orange;
  			@include transition(all 400ms);
  		}
  		.cf-button--orange-link{
  			@include cf-button-orange-link-hover; 
  		}
  }
}