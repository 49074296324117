.grecaptcha-badge {
	display: none;
}

$primarycolor:#1E225C;
$fontpoppins:"Poppins",
sans-serif;
$font_16:16px;

form .form-col .form-error{
	margin-top:0;
	margin-bottom:10px;
}

.contact {
	&__banner {
		background-position: center center;
		background-size: cover;

		&__image {
			padding: 60px 0px;

			@include breakpoint(medium) {
				padding: 80px 0px;
			}

			@include breakpoint(xlarge) {
				padding: 100px 0px;

			}

			background-position: center center;
			background-size: cover;
			background: rgba(32, 27, 102, 0.5);
			min-height: 330px;

			@include breakpoint(xlarge) {
				min-height: 400px;
			}
		}

		&__title {
			h1 {
				font-size: 2.25rem;
				text-align: center;
				color: white;
			}
		}

		&--sharper {

			.training__banner-overlaywithoutimage {
				opacity: 1;
				background: none;
			}

			@media print {
				height: 175px;
			}
		}
		

	}

	&__cta {
		&__wbox {
			background: #fff;
			margin: 0 auto;
			max-width: 800px;
			display: inline-block;
			width: 100%;
			border-radius: 6px 6px 0 0;
			@include raleway;
			border: 1px solid #e8e8e8
		}

		padding: 0 15px;

		@include breakpoint(medium) {
			padding: 0px;
		}

		ul {
			margin-top: -27px;

			li {
				width: 55px;
				height: 55px;
				background: #ccc;
				border-radius: 50%;
				list-style-type: none;
				position: relative;
				overflow: hidden;
				display: inline-block;
				margin: 0 0 0 15px;


				img {
					width: auto;
					height: 100%;
					max-width: inherit;
				}
			}

			li:first-child {
				margin-left: 0;
			}
		}

		&__callus {
			max-width: 750px;
			margin: 0 auto 30px;

			p {
				width: 100%;
				display: inline-block;
				font-size: 18px;
				color: #1d215e;
				font-weight: 700;
				// float: left;
				margin: 0;

				@include breakpoint(medium) {
					width: calc(100% - 273px);
				}
			}
		}
	}

	&__main {
		&__form {
			//margin: 50px auto;
			//max-width: 90%;

			@include breakpoint(medium) {
				//max-width: 60%;
				margin-left:0px;
			}
		}

		&__information {}
	}

	&__sub {
		&__information {
			padding: 60px 0px;
			position: relative;
			z-index: 1;

			&__location {
				&__icon {
					img {
						width: 70%;
						height: 70%;
						margin-top: 10px;
					}
				}
			}

			&__text {
				img {
					width: 150px;
				}
			}
		}
	}

}


.contact-body {

	.cta-block__intake__text {
		padding-bottom: 20px;
		padding-top: 20px;

		.cta-block__intake__text h2 {
			font-size: 1.25rem;
		}

		.cta-block__intake {
			border-radius: 12px;
		}

	}

}


.contact-body {

	.incompany__intake {
		border-radius: 12px;
		margin-top: 35px;

		.incompany__intake__text {
			padding-left: 36px;
			overflow: hidden;

			.will-body-sec {
				.details_box {
					.heading_text {
						font-size: 20px;
						color: $primarycolor;
					}

					.links-box {
						display: inline-block;

						.text-links {
							font-size: 15px;
							text-align: center;
							font: normal normal 600 15px/23px Poppins;
							letter-spacing: 0px;
							color: #FFFFFF;
							text-transform: lowercase;
							opacity: 1;
							padding: 7px 14px;
							border: none;
						}

						.cf-button--blue:hover {
							background: #fff;
							color: $primarycolor;
						}

						.blue-text {
							color: #1b2a68;

							svg {
								transform: rotate(90deg);
								margin-right: 10px;
							}
						}

						.blue-text:hover {
							color: #fff;
						}
					}
				}

				.cta-block__intake__image {
					position: relative;
					bottom: -45px;
					right: -30px;

					&.cta-block__intake__image {
						img {
							max-width: 100%;
						}
					}
				}
			}
		}
	}

	.listing_points {


		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				color: $color-blue;
				text-align: left;
				letter-spacing: 0px;
				opacity: 1;
				font-size: 16px;
				text-shadow: 0px 0px 0px $primarycolor;
				font-family: $fontpoppins;
				margin-bottom: 10px;
				.fa-chevron-right {
					margin-left: 20px;
					font-size: 12px;
				}
				a { 
					display:flex;
					align-items:center;
					column-gap:10px;
					img{
						filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
						width:20px;
					}
					span{
						text-decoration:underline; 
						color: $color-blue!important; 
					}
					
				}
				a:hover{
					span{
						text-decoration:none;
					}
					.fa-chevron-right {
						@include transition(margin 400ms, box-shadow 400ms);
						margin-left: 30px;
					}
				}
			}

		}
	}

	.incompany__intro__container__image__container {
		//width: 720px;
		height: 630px;
		overflow: hidden;
		border-radius: 0px 0px 0px 70px;
	}

	.incompany__intro__container__image__container img {
		//height: 100vh;
		max-height: 100%;
	}
}

.enqform_section {
	.contact__main__information {
		
	}

}

.contact__main {
	
	margin-top: 50px;
	margin-bottom: 50px;
	@include breakpoint(medium){
		margin-top: 75px;
		margin-bottom: 100px;
	}
	.container{
		padding:0 1.5rem;
		@include breakpoint(medium){
		padding:0 1rem;
		}
	}
	h2 {
		margin-bottom: 60px;
	}
	.contact__main__form {
		margin-top: 0px;
		@include breakpoint(large){
			padding-left:70px;
		}
		h3{
			font-size:18px;
			margin-bottom:20px;
			margin-top:10px;
		}
	}
	label {
		color: $color-blue;
		font-size:14px;
		@include header-text;
	}
	input {
		border-radius: 8px;
		background-color: white;
		box-shadow: none;
		border: 1px solid $color-blue;
		margin-bottom: 0px;
		font-size:16px;
		letter-spacing: -0.32px;
		padding:22px;
		height:unset;
		font-weight:medium;
		color:$color-business-blue;
		&:placeholder{
			color:#BABABA;
			
		}
		&:focus{
			box-shadow: none;
		}
	}

	textarea {
		border-radius: 8px;
		background-color: white;
		font-size:16px;
		font-weight:medium;
		box-shadow: none;
		color:$color-business-blue;
		letter-spacing: -0.32px;
		margin-bottom: 0px;
		border: 1px solid $color-blue;
		padding:22px;
		&:focus{
			box-shadow: none;
		}
	}
	.form-error{
		margin-bottom: 0px;
		margin-top:5px;
	}
	&__information{
		&__sidebar{
			border-radius: 12px;
			background-color:#04D3D1;
			padding:30px 20px;
			margin-bottom:40px;
			@include breakpoint(large){
				margin-bottom:0px;
				padding:60px 50px;
			}
			.col-1{
				display: grid;
				grid-template-columns: 1fr 5fr;
				column-gap:15px;

				.contact-sidebar-icon{
					display: grid;
					justify-content: center;
					div{
						background-color: #1B2A68;
						width: 35px;
						height: 35px;
						display: grid;
						justify-content: center;
						align-content: center;
					}
					svg{
						filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(87deg) brightness(97%) contrast(111%);
						scale: 0.75;
					}
				}
			}
		}
		.col-1 {
			margin-bottom:20px;
			@include breakpoint(large){
				margin-bottom: 55px;
			}
							display: grid;
				grid-template-columns: 1fr 5fr;
			.contact_details_title {
				font-size: 18px;
				font-weight:bold;
				text-align: left;
				color:$color-business-blue;
				margin-bottom:0px;
			}

			p {
				text-align: left;
				font: normal normal normal 16px/25px Poppins;
				letter-spacing: 0px;
				opacity: 1;
				color:$color-business-blue;
				margin-bottom:0px;
				word-break: break-word;
			}
			a{color:$color-business-blue;}
			&:last-of-type{
				margin-bottom:0px;
			}
		}
	}
}

.video-block1 {
	.videotext-block__video__container > img {
		border-radius: 0px 0px 70px 0px;
	}
}



.videotext-block {
	.design-training-slider-contact {
		.slide-box {
			margin-right: 5px;

			.img-box {
				height: 481px;
			}
		}

		.slick-next {
			&:before {
				font-size: 48px;
				line-height: 54px;
				color: #1E225C;
				opacity: 1;
				font-weight: normal;
			}

			right: 66px;
			background: white;
			width: 51px;
			height: 50px;
			border: 1px solid #fff;
			border-radius: 50%;
			vertical-align: middle;
			line-height: 2;


		}
	}
}

.videotext-block-contact {

	h2 {
		font-size: 39px;
	}

	.cf-button--blue-flat-alt {
		align-items: center;
		border: none;
		padding: 0px;
		margin: 30px 0px;
	}

	.cf-button--blue-flat-alt:hover {
		background: transparent;
		border-color: #152050;
		color: #152050;
	}

}

.video-block1 {
	margin: 100px 0px;
}

.videotext-2 {
	margin: 0px 0px;

	.cf-button--blue-flat-alt {
		margin: 20px 0px 0px;
	}

}

.Slider-block {
	margin: 30px 0px;
	background: #F8F8FA 0% 0% no-repeat padding-box;
	padding: 30px 0;
	.container{
		padding-left:1.5rem!important;
		padding-right:1.5rem!important;
		@include breakpoint(medium){
			padding-left:1rem!important;
			padding-right:1rem!important;
		}
	
	}
	h2 {
		padding-bottom: 31px;
	}

	.slider-foot {
		justify-content: space-between;		
	}
}
.banner-contactpage{
	height: 400px;
	width: 100%;
	img{
		height:100%;
		width:100%;
		object-fit: cover;
		object-position: 50% 25%;
	}
}
#menu-sub-menu-trainingen{
	.contact-menu-item{
		margin-right: 30px;
		a{
			font-size:16px;
		}
	}
}
#contact{
	.contact-titel{
		font-size:32px;
		font-weight: 800;
		margin-bottom:26px!important;
	}
	.contact-beschrijving{
		max-width: 600px;
		margin-bottom:36px!important;
		font-size:16px;
	}
}
#form-contact{
	display: grid;
	grid-template-areas:
	"naam naam"
	"onderwerp onderwerp"
	"email  email"
	"telefoonnummer  telefoonnummer"
	"bericht  bericht"
	"verstuur  verstuur";
	column-gap: 30px;

	@include breakpoint(medium up) {
		grid-template-areas:
		"naam onderwerp"
		"email  telefoonnummer"
		"bericht  bericht"
		"verstuur  verstuur";
	}
	#naam{
		grid-area: naam;
	}
	#email{
		grid-area: email;
	}
	#telefoonnummer{
		grid-area: telefoonnummer;
	}
	#onderwerp{
		grid-area: onderwerp;
	}
	#bericht{
		grid-area: bericht;
	}
	#verstuur{
		grid-area: verstuur;
		button{
			background-color: #181B4B;
		}
		span{
			color:#ffffff;
			display: flex;
			align-items: center;
			svg{
				filter: invert(100%) sepia(0%) saturate(7469%) hue-rotate(87deg) brightness(97%) contrast(111%);
				margin-left:10px;
			}
		}
	}
	#verstuur:hover{
		button{
			background-color: #ffffff;
		}
		span{
			color:#181B4B;
			svg{
				filter: invert(15%) sepia(20%) saturate(2782%) hue-rotate(204deg) brightness(85%) contrast(104%);
			}
		}
	}
	.form-col{
		position: relative;
		margin-bottom:20px;
		label{
			position: absolute;
			left:18px;
			background-color: white;
			padding-left:5px;
			padding-right:5px;
		}
		input, textarea{
			margin-top:12px;
			padding: 16px 22px 16px;
		}
		textarea{
			@include breakpoint(large) {
				max-height:187px;
			}
		
		}
	}
}

.FAQ-block{
	margin: 50px 0px;
	background: #F8F8FA 0% 0% no-repeat padding-box;
	padding: 50px 0;

	@include breakpoint(large){
		margin: 100px 0px;
		padding: 100px 0px;
	}
	.FAQ-row{
		display: flex;

		&__titel{
			font-size:32px;
			font-weight:800;
			margin-bottom:30px!important;
		}
		&__vraagtitel{
			background-color:white !important;
		}
		&__vraagantwoord{
			background-color:white !important;
		}
		.accordion{
			background-color: #F8F8FA;
		}

		.bel-met-ons{
			display:grid;
			align-items: flex-start;

			.faq-callout{
				border:none;

				&__image{
					img{
						border-bottom: 2px solid $color-business-blue;
					}
				}
			}
		}
	}
}

.route-naar-cf{
	margin: 50px 0px;
	@include breakpoint(large){
		margin: 100px 0px;
	}
	.videotext-block-contact h2 {
		font-size:32px;
		margin-bottom:26px!important;
	}
	.videotext-block__text p {
		margin-bottom:26px;
	}
	.videotext-block__video__container{
		margin-bottom:0px!important;
	}
	.button-wanderoute{
		max-width:450px;
		width:100%;
		text-align: left;
		@include breakpoint(large){
			text-align: center;
		}
		a{
			margin:0 !important;
			color:white !important;
		}
		&-2{
			max-width:450px;
			width:100%;
			margin-top:10px;
			text-align: left;
			@include breakpoint(large){
				text-align: center;
			}
			a{
				margin:0 !important;
				color:$color-business-blue !important;
			}
		}
		svg{
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg) brightness(103%) contrast(103%);

		}
	}
	.button-wanderoute:hover{
		padding-right:20px;
		svg{
			filter: invert(13%) sepia(31%) saturate(3879%) hue-rotate(215deg) brightness(100%) contrast(97%);
		}
	}
	.button-wanderoute-2:hover{
		padding-right:20px;
		svg{
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(55deg) brightness(103%) contrast(103%);
		}
	}
}
.Daarom-competence{
	margin: 50px 0px;
	margin-bottom: 100px;
	@include breakpoint(large){
		margin: 100px 0px;
	}

	&__title{
		font-size:32px;
		font-weight:800;
		margin-bottom:26px!important;
	}
	
	.wij-trainers-container{
		padding:0 !important;
		margin:0 !important;
	}
	.Wij-trainers-wrapper{
		width: 100%;
		padding:0 !important;
		margin:0 !important;

		.slick-slideshow__slide{
			margin:0px !important;
			.slide-box{
				margin-bottom:15px;
			}
		}
	}
	.container.slidercontainer{
		padding-right:0px;
		@include breakpoint(large){
			padding-right:20px;
		
		}

	}
	.slick-track{
		margin: 0px !important;
	}
	.img-box { height: 180px; }
	.box-content{
		p { min-height:80px; }
		h3{
			font-size:20px;
			padding-bottom: 10px;
		}
	}
	.Why-cf-slider .slide-arrow {
		margin-right: 13px;
		@include breakpoint(medium){
			margin-right: 40px;
		}
		height: 45px !important;
		padding: 6px;
		bottom: -60px;
		width: 50px !important;
	}
	.Why-cf-slider .prev-arrow{
		right: 60px !important;
	}
}
.scroll-up .contactmenubalk {
    top: 73px;
    transition: top 400ms;
}
.contactmenubalk{
	position: sticky;
	top: 0px;
	z-index: 10;
}