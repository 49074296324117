.page.reviews{
	.testimonial-block__text{

		&__title{

			font-size:24px;
			text-align:left;
		}
		&__subtitle{
			text-align:left;

		}
		&.container {
			margin:0px;
			padding:0px;
		}
	}
} 
.testimonial-block {
	background-color:#F8F8FA;
	margin:100px 0px;
	padding:100px 0px;
	padding-bottom:0px;
	&__text{
		//margin-top:100px;
		&__title{
			font-family: 'Lexend', sans-serif;
			font-size:25px;
			margin-bottom:35px;
			font-weight:800;
			
  			@include breakpoint(medium) {
				font-size:42px;
				text-align:center;
  			
  			}
		}
		&__subtitle{
			font-size:16px;
  			@include breakpoint(medium) {
				text-align:center;
  			
  			}
		}
	}
	
	&__background{
		position:relative;
		margin-bottom: 50px;
		&__image{
			content: "";
			background-image: url(/images/dot-bgv4.png);
			height: 60%;
    		width: 65%;
			position: absolute;
			background-repeat: repeat;
			top: 10%;
			left: 0px;
			z-index: -1;
			background-position: center;
			background-size: unset; 
			@include breakpoint(medium){
				top: 10%;
	
			}
			&.incompany{
				background-image: url(/images/dot-bgv5.png);
			}
		}
		& > .row > .column {
			
				padding-right: 0rem;
				padding-left: 0rem;
			@include breakpoint(medium){
				padding-right: 2rem;
				padding-left: 2rem;
			}
		}
	}
	
	&__card{
  		background-color:white;
  		border-radius:15px;
  		border: 1px solid #1C2663;
		box-shadow: 0px 10px 30px 0px rgba(157,171,192,.2);
		&__top{
			padding:20px 35px;
			&__person{
				display:flex;
				align-items:center;
				justify-content: space-between;
				&__image{
					img {
						object-fit:contain;
						width:80px;
						max-width:80px;
						max-height:80px;
					}
					margin-right:20px;
				}
				&__name{
					color:$color-blue;
					font-size:18px;
					font-weight:700!important;
					@include breakpoint(large){
						font-size:22px;
					}
				}
			}
		}
		&__additional{
			padding:20px 35px;
			border-top:1px solid rgba(30,34,92,0.078);
			color:$color-blue;
			&__training{
				display:flex;
				justify-content:space-between;
				column-gap:10px;
				span:first-of-type{
					width:65%;
				}
				span:last-of-type{
					width:35%;
					text-align:right;
				}
			}
			&__grade{
				span{
					display:block;
				}
				svg path { fill:#F5C80B; }
				.fa-star{
					color:#F5C80B;
				}
			}
		}
		&__body{
			padding:35px 35px;
			padding-top:0px;
			//border-top:1px solid rgba(30,34,92,0.078);
			&__text{
				font-size:rem-calc(15px);
				font-weight:400;
				&__quoteicon{
					margin-bottom:10px;
					display:none;
					svg{
						path{
							fill:$color-blue;
						}
					}
				}
				&__recommend{
					display: flex;
					align-items: center;
					gap:5px;
					svg{
						path{
							fill:$color-blue;
						}
					}
				}
				p:last-of-type{
					margin-bottom:0;
				}
				
			}
		}
		&__bottom{
			display:none;
			padding:20px 35px;
			padding-top:20px;
			border-top:1px solid rgba(30,34,92,0.078);
			text-align:center;
			&__logo{
				height:60px;
				object-fit: contain;
			}
			@include breakpoint(large){
				display:block;
			}
		}
	
	}
	
	&__loadmore{
		text-align:center;
		padding-top:10px!important;
		padding-bottom:10px!important;
		@include breakpoint(medium){
			padding-top:0px;
		}
	}
	.deze-profile-name strong{
		display: block;
	}
	.deze-pro-img img{
		height: 100%;
		width: 100%;
	}
	.deze-pro-img {
		height: 70px;
		width: 70px;
		border-radius: 100%;
		overflow: hidden;
	}

	.deze-voor-box {
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 36px rgba(103, 103, 41, 0.4);
		border-radius: 14px;
		opacity: 1;   
		padding: 25px;
		margin: 40px 15px;
	}
	.deze-voor-box-body {
		margin: 0px -20px;
			margin-top: 30px;
	}
	.deze-voor-box-body .testimonial-block__card{
		margin: 40px 10px;
		margin-right:0px;
		border:none;
		@include breakpoint(medium){
			margin: 40px 15px;
		}
	}
	.deze-cat span {
		display: block;
		width: 100%;
	}
	.deze-profile-name {
		padding-left: 90px;
		padding-top: 5px;
	}
	.deze-voor-wrapper {
		margin-bottom: 60px;
	}
	.slick-next{
		display:block!important;
	}
	.slick-prev, .slick-prev:hover{
		padding: 14px;
		width: 60px !important;
		height: 60px !important;
		border-radius: 5px !important;
		background-color: #1b2a68;
		z-index: 1;
    	text-align: center;
    	display:flex;
    	justify-content: center;
    	line-height: 32px;
    	&:before{
			content: ' \276E';
			display: block;
			position: absolute;
			display: block;
			line-height:unset;
			opacity:1!important;
			font-family:unset;
			font-size: 16px;
			z-index: 555;
			color: #fff;
			border: 1px solid;
			border-radius: 100%;
			padding: 0px 12px;
		}
	}
	.slick-next, .slick-next:hover {
		right: 5px;
		width: unset;
		height: unset;
		top: 20%;
		padding: 14px;
		width: 60px !important;
		height: 60px !important;
		border-radius: 5px !important;
		background-color: #1b2a68;
		z-index: 1;
    	text-align: center;
    	display:flex;
    	justify-content: center;
    	line-height: 32px;
		@include breakpoint(medium){
    		right: -25px;
			top: 50%;
		}
		&:before{
			content: ' \276F';
			display: block;
			position: absolute;
			display: block;
			line-height:unset;
			opacity:1!important;
			font-family:unset;
			font-size: 16px;
			z-index: 555;
			color: #fff;
			border: 1px solid;
			border-radius: 100%;
			padding: 0px 12px;
		}
    }
    .slic-prev{
    left:-35px;
    }
	&__partners{
		display: none;
		row-gap:40px;
		@include breakpoint(large){
			display: flex;
			padding-bottom:100px;
		}

		img{

			width:100%;
			max-height:75px;
			@include breakpoint(large){
				max-height:75px;
				max-width:200px;
				width:100%;
				object-fit: contain;
			}
		}
	}
}
.testimonial_bottomtitle{
	font-size:35px;
	text-align: center;
	margin-top: 50px;
	display:none;
	margin-bottom:50px !important;
	@include breakpoint(large){
		display:block;
		//margin-bottom: 250px !important;
	}
}