.scroll-down .inspiratie__filterbalk{
	top:0px;
	@include breakpoint(large){
		top:0px;
		
	}
}
.scroll-up .inspiratie__filterbalk, .scroll-submenu .inspiratie__filterbalk{
	top:45px;
	@include breakpoint(large){
		top: 100px;
		
	}
}
.singlecol.inspiratie{
	padding-bottom:0px;
}

#pro-news-logo{
	top:2px;
}
.header__logo-mobile.pro-news-logo-mobile{
	height:unset!important;
}
#nieuws{
	max-width: 100rem;

	.nieuws_blogrij{
		display:flex;
		flex-direction: column;
		margin-top:0;
		width:100%;

		.trainingen-grid__item{
			display: flex;
			flex-direction: row-reverse;
			margin: 40px 20px 0 20px;
			padding-bottom: 20px;

			border-radius: 8px;
		}
	}
}

.inspiratie__filterbalk{
	position: sticky;
	top:0px;
	padding-bottom:10px;
	border-bottom:1px solid #DBE1E8;
	@include breakpoint(large){
    	top: 70px;
		border-bottom:0px;
		padding-bottom:0px;
		max-width: 1600px;
    	margin: 0 auto;
	}
	background-color: white;
	z-index: 10;
	transition: all 400ms;
}
.filterbalk{
	width: 100%;
	display:flex;
	//grid-template-columns: 6fr 1fr 1fr 1fr;
	margin-top:10px;
	padding:0px 20px;
	@include breakpoint(large){
		width:unset;
		padding:0px;
		padding-bottom:10px;
		border-bottom:1px solid #DBE1E8;
	}
	//grid-row-gap: 10px;
	column-gap: 5px;
	.categoriebalknieuws{
		//display: grid;
		//justify-items: flex-start;
		align-items: center;
		min-width: 170px;

		select{
			width:100%;
			border: 1px solid $color-business-blue;
			height:3rem;
			border-radius: 2px;
			line-height: 1.2rem;
			font-weight: 500;
			color: $color-business-blue;
			background-position: right -0.5rem center;
			background-size: 14px 10px;
			margin:0;
			font-size:14px;
			padding: 0.5rem 1rem 0.5rem 1rem; 
			background-image: url('/images/iconmonstr-caret-down-filled-32.png');
			background-size: contain;
			backdrop-filter:unset;
			&:focus{
				box-shadow: none;
			}
			//backdrop-filter: invert(17%) sepia(11%) saturate(5511%) hue-rotate(207deg) brightness(88%) contrast(104%);
		}
	}

	.typenieuws{
		display: flex;
		margin:auto;
		width: 100%;
		margin-bottom: -11px;
		overflow: scroll;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
    	scrollbar-width: none;  /* Firefox */
		position: relative;
		grid-column: span 3;
		&::-webkit-scrollbar { 
			width: 0;  /* Remove scrollbar space */
   			background: transparent;  /* Optional: just make scrollbar invisible */
		}

		.verschillendetype {
			padding: 9px 10px; /* Adjust padding to reduce button size */
			padding-bottom:19px;
			background-color: transparent; /* Remove background */
			color: #888AAA; /* Muted purple color */
			text-decoration: none;
			font-weight: 500; /* Default font weight */
			font-size: 14px;
			border: none; /* Remove borders */
			white-space: nowrap;
			transition: color 300ms, border-bottom 300ms;

			&:hover {
				color: #585a7f; /* Hover color: dark blue */
				font-weight: 600; /* Increase font weight on hover */
				padding-left:9px;
				padding-right:9.5px;
			}
		}

		.verschillendetype_selected {
			padding-left:9px;
			padding-right:9.5px;
			color: #585a7f; /* Active state color */
			font-weight: 600; /* Bold for active button */
			border-bottom: 1px solid #1E225C; /* Add bottom underline */
		}

	}
	.searchbarnieuws{
		display: grid;
		justify-items: center;
		@include breakpoint(large){
			justify-items: flex-end;
		}
		align-items: center;
		position: relative;
		&.search-selected{
			padding-right: 30px;
			#searchnieuwsoverzicht{
				width:90%;
			}
			#searchiconinspiratie{
				right:19%;
			}
		}
		#searchnieuwsoverzicht{
			margin:0;
			width: 80%;
			border:1px solid $color-business-blue;
			border-radius: 8px;
			padding-left: 1rem;

			&::placeholder{
				font-size:14px;
				color: $color-business-blue;
				opacity: 1;
			}
		}
	}

	.mobile-icon-box{
		border: 1px solid #1e225d;
		border-radius: 2px;
		display: grid;
		align-items: center;
		justify-items: center;
	}
}
#searchiconinspiratie{
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEuMTcyIDI0bC03LjM4Ny03LjM4N2MtMS4zODguODc0LTMuMDI0IDEuMzg3LTQuNzg1IDEuMzg3LTQuOTcxIDAtOS00LjAyOS05LTlzNC4wMjktOSA5LTkgOSA0LjAyOSA5IDljMCAxLjc2MS0uNTE0IDMuMzk4LTEuMzg3IDQuNzg1bDcuMzg3IDcuMzg3LTIuODI4IDIuODI4em0tMTIuMTcyLThjMy44NTkgMCA3LTMuMTQgNy03cy0zLjE0MS03LTctNy03IDMuMTQtNyA3IDMuMTQxIDcgNyA3eiIvPjwvc3ZnPg==");
	background-position: right -0.1rem center;
	background-repeat: no-repeat;
	background-size: 20px 15px;
	width:20px;
	border:0;
	background-color:transparent;
	position: absolute;
	right: 12%;
	@include breakpoint(large){
		right:7%;
	}
	cursor: pointer;
}
.geenpostsgevonden{
	font-size: 25px;
	font-weight: 600;
	text-align: center;
	margin: auto;
	margin-top: 100px;
}
.banner.row{
	max-width: 100rem;
}
.banner_nieuws {
	//border-top: 1px solid #DBE1E8;
	//border-bottom: 1px solid #d9d0d0;
	border-bottom:0px; 
	display: grid;
	grid-template-columns: 5.5fr 4.5fr; // Adjusting the width ratio between image and text
	@include breakpoint(xlarge){
		grid-template-columns: 7fr 4fr;
	}
	margin: 0 60px 0 60px;
	padding-top: 50px;
	padding-bottom: 20px;


	&:hover {
		cursor: pointer;
	}

	.banner_img_nieuws {
		width: 100%;
		min-height: 398px; // Slightly increased height for better proportion
		max-height: 398px;
		border-radius: 4px 20px 20px 20px;
		object-fit: cover;
	}

	.banner_tekst_nieuws {
		align-self: flex-start; // Align text to the top
		margin-left: 40px; // Reduced margin
		padding-top:15px;
		color: #333; // Default text color

		.nieuws_banner_type {
			margin-top: 10px;
			color: red; // Red for the type and extra info

			div {
				margin-right: 10px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.banner_title_nieuws {
			margin-top: 15px;
			font-size: 20px; // Slightly smaller font
			font-weight: 700;
			@include header-text;
			color: #0A246A;; // Blue for title
			margin-bottom:23px;
		}

		.banner_nieuws_tekst {
			margin-top: 23px;
			font-size: 15px; // Smaller description text
			line-height: 1.7;
			font-family:Gelasio;
			max-width: none; // Remove width restriction
			color: rgba(22,25,78,0.8); // Lighter blue
		}
	}
	.banner_new_marker {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		font-size: 13px;
		font-weight: 400;
		color: #4A9030;

		.relative_time {
			font-size: 13px;
			font-weight: 300;
			color: #4A9030;
		}
	}
}

.training-grid-block-blog-mobile{
	display: flex!important;
	flex-direction: column;
	gap:10px;
	margin-top: 20px !important;
	box-shadow: none !important;
	padding:0px!important;

	.inspiration-listing__author{
		display: flex;
		gap:10px;
		align-items: center;
		&__text{
			font-size:14px;
			@include header-text;
			span{
				font-size:14px;
				@include header-text;
			}
		}
	}
	.inspiration-listing__meta{
		
	}
	.training-grid-block__content{
		height:unset!important;
		min-height:unset!important;
		flex-direction: row;
	}
	.training-grid-block__content__text{
		margin-left:0px!important;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100% !important;
    	justify-content: space-between;
	}
	.training-grid-block__content__infotext{
		padding: 0px!important;
		flex-grow: 1;
	}
	.training-grid-block__image{
		//border-radius:8px;
		width:120px;
		height:80px;
		@include breakpoint(medium){
			width:160px;
			height:100px;

		}
		.training-grid-block__image__overlay{
			//border-radius:8px;
		}
	}

	.mobile-img-grid-block{
		height:80px !important;
		min-width:120px!important;
		@include breakpoint(medium){
			max-width:unset!important;
			width:160px!important;
			height:100px!important;
		}
		//margin-left: 10px;
		border-radius: 0px 8px 8px 8px!important;
	}
	.inspiratie-video-image-overlag .vid-icon img{
		width:40px;
	}
	p.training-grid-block__title{
		font-weight: 600 !important;
		@include header-text;
		font-size:16px;
		width: 90%;
		margin-bottom:0px;
		@include breakpoint(medium){
			font-weight:700;
			font-size:18px;
		}
	}
}
.nieuws-blogrijmobile{
	padding:0px 20px;
	width:100%;
}

#testing123{
	display: none;
}
@include breakpoint(large up){
	.nieuws_blogrij{
		padding-left: 20px;
    	padding-right: 20px;
		.training-grid-block__image, .training-grid-block__image__overlay, .training-grid-block__image img{
			//border-radius:8px 8px 0px 0px;
		}
		.inspiratie-video-image-overlag .vid-icon img{
			width:55px;
		}

		.training-grid-block:hover .training-grid-block__title {
			color: $color-business-blue;
		}
	}
	.nieuws_blogrij .label, .banner_nieuws .label{
		padding:6px 14px; 
		font-weight:500;
		text-transform: capitalize;

	}
	.filterbalk{
		//grid-template-columns: 1fr 3fr 0fr 1fr;
		//grid-row-gap: 0px;
		margin-top: 40px;
		//margin-bottom: 10px;
		//padding-left:60px;
		//padding-right:60px;
		margin-left:60px;
		margin-right:60px;
		column-gap: 0px;
		.categoriebalknieuws{
			justify-items: flex-start;
			margin-right:40px;
			select{
				//width:80%;
				height: initial;
			}
		}

		.typenieuws{
			overflow: scroll;
			position: relative;
			grid-column: inherit;
			
		}
	}

}
.mobile-searchbar{
	height: 20%;
	width: 100%;
}

.scroll-header .sidebar, .scroll-submenu .sidebar, .scroll-up .sidebar {
	top:145px;
}
.scroll-down .sidebar  {
	top:45px;
}

.doublecol-container {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;

	.main-content {
		width: 100%;
		flex-grow: 1;
	}

	#sidebar-content {
		// add gray line to separate sidebar from main content
		border-left: 1px solid #DBE1E8;
		flex-grow: 1;
		//margin-bottom: 127px;
		padding:20px;
		@include breakpoint(large){
			max-width:400px;
			padding:30px 40px;
		}
		box-shadow: none;
		border-radius: 0px;
		background-color: #FFF;

		.sidebar{
			background-color: #fff;
			padding: 0px;
			border-radius: 0px;
			box-shadow: none;
            // Make the sidebar sticky
            position: sticky;
            //top: 20px; // Adjust this value to control how far from the top it stays
            height: fit-content; // Ensure the sidebar doesn't overflow


        }
	}

	@include breakpoint(medium down) {
		flex-direction: column; // Stack elements vertically
		.main-content, .sidebar {
			width: 100%; // Make both take full width
			border-left: none; // Remove the border-left from the sidebar
		}
	}

	.post-type {
		font-size: 12px;
		font-weight: normal;
		color: #888AAA;
		display: flex;
		gap:15px;
	}

	.post-block {
		border-bottom: 0px;
		box-shadow: none;
		margin-top:0px!important;
		padding:20px!important;

		.training-grid-block__content__infotext{
			padding-top:0px;
			padding-left:0px;
			height:100%;
			display: flex;
			flex-direction: column;

		}
	}
	.post-row-block-divider{
		background-color: #DBE1E8;
		height: 1px;
		margin:30px 40px;

	}
	.post-row {

		&__title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 6px;
		}

		&__content {
			width: 100%;
			height:100%;
		}

		&__image {
			width: 25%;
			min-width: 290px;
		}

		&__image_overlay {
			width: 100%;
			position: absolute;
			height: 100%;
			opacity: 1;
			border-radius: 4px 12px 12px 12px;
		}

		&__text_container {
			height: auto;
			flex-grow: 1;
			max-width: 750px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap:20px;
		}

		&__text {
			font-size: 16px;
			text-overflow: ellipsis;
			font: normal normal normal 16px/26px Gelasio;
			height: 4em;
		}

		&__author {
			font-size: 14px;
			font-weight: 500;
			color: $color-business-blue;
			margin-bottom: 10px;
			display: flex;
			gap:8px;
			align-items: center;
		}

		&__author-icon {
			width: 2rem;
			height: 2rem;
			border-radius: 100%;
			object-fit: contain;
			border:1px solid #DBE1E8;
		}
		&__content{
		}
		&__meta {
			font-size: 14px;
			font-weight: 400;
			color: #888AAA;
			display: flex;
			@include header-text;
			gap:7px;
			align-items: center;

			@include breakpoint(medium down) {
				//padding-right: 5px;
				font-size: 10px;
			}
		}

		&__meta-container {
			display: flex;
			align-items: center;
			margin-top: 0px;
			gap:30px;

			@include breakpoint(medium down) {
				flex-direction: row;
				margin: 0 !important;
			}
		}

		&__meta-img {
			width: 20px;
			height: 20px;
		}
		&__svg {
			width: 20px;
			height: 20px;
			// filter to light gray
			filter: invert(65%) sepia(10%) saturate(684%) hue-rotate(198deg) brightness(87%) contrast(89%);
		}
	}

	.rotate-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: white; /* Button background */
		color: #1E225C; /* Arrow color */
		padding-top: 7px;
		border-radius: 50%; /* Makes the button circular */
		width: 35px; /* Fixed width for a circle */
		height: 35px; /* Fixed height for a circle */
		font-size: 18px; /* Size of the arrow */
		font-weight: bold; /* Make arrow bold */
		cursor: pointer; /* Indicate clickability */
	}

	.rotate-button:hover {
		//border-bottom: 2px solid #C0C0C0; /* Darker grey border on hover */
		color: #333333; /* Darker arrow color on hover */
	}
}

/* Header Styling */
header {
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	.container-inspiratie {
		margin: 0;
		padding: 0 1.5rem;
		display: flex;
		background: white;
		width: 100%;
		height: 90px;
		align-items: center;
		justify-content: space-between;
		gap:60px;
	}

	/* Left Section */
	.logo-section {
		display: flex;
		align-items: center;

		img {
			height: 40px;
		}

		span {
			font-size: 0.875rem;
			color: #6b7280; /* Gray */
			margin-left: 10px;
		}
	}

	/* Middle Section */
	.search-bar {
		flex-grow:1;
		.search-form {
			height:50px;
		}
		input {
			width: 100%;
			padding: 12px 33px 12px 66px;
			border: 1px solid #F7F7F7; /* Light gray */
			color:#1E225C;
			border-radius: 9999px;
			font-size: 16px;
			outline: none;
			background-color: #F7F7F7;
			box-shadow:none;
			margin-bottom:0px;
			@include breakpoint(large){
				height:50px;
				max-width:450px;
			}
			&::placeholder{
				color:$color-business-blue;
				font-size:16px;
			}
			&:focus {
				border-color:#1E225C;
				box-shadow: none;
			}
		}
		.search-form__button{
			left:20px;
			top: 8px;
		}
	}

	/* Right Section */
	.nav-section {
		display: flex;
		align-items: center;
		gap: 1rem;

		/* Creative Business School Link */
		a.business-school {
			font-size: 16px;
			font-weight: 600;
			color: #888AAA; /* Dark gray */
			text-decoration: none;
			display: flex;
			align-items: center;
			gap:5px;
			transition: color 400ms;
			.icon{
				transition: filter 400ms;
				transform: rotate(45deg);
				height:20px;
				filter: invert(58%) sepia(19%) saturate(440%) hue-rotate(198deg) brightness(92%) contrast(86%);
			}
			&:hover {
				text-decoration: none;
				color:#1E225C;
				.icon{
					filter: invert(6%) sepia(45%) saturate(6605%) hue-rotate(236deg) brightness(102%) contrast(87%);
				}
			}
		}

		/* Word Member Button */
		a.member-button {
			background-color: #1e3a8a; /* Dark blue */
			color: #ffffff;
			padding: 0.5rem 1rem;
			border-radius: 4px;
			font-size: 0.875rem;
			text-decoration: none;
			font-weight: bold;

			&:hover {
				background-color: #1d4ed8; /* Lighter blue */
			}
		}
	}

	/* Purple Gradient Bar */
	.promo-bar {
		background: linear-gradient(to right, #79b9d4, #3f6e9f);
		//background: linear-gradient(to right, #ec4899, #8b5cf6); /* Pink to purple */
		color: #ffffff;
		text-align: center;
		padding: 0.5rem 0;
		@include header-text;
		span {
			font-size: 14px;
			font-weight: 500;
		}

		a {
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			text-decoration: underline;
		}
	}

	.promo-bar-post {
		background: linear-gradient(to right, #79b9d4, #3f6e9f); /* Pink to purple */
		color: #ffffff;
		text-align: center;
		padding: 0.5rem 0;
		@include header-text;

		span {
			font-size: 14px;
			font-weight: 500;
		}

		a {
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			text-decoration: underline;
		}
	}
}

.header-mobile {
	background-color: white;
	position: sticky;
	top: 0;
	z-index: 1000;

	&__container {
		box-shadow: 0 3px 6px rgba(127, 139, 152, 0.16);
	}

	&__firstrow {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 1rem;
	}

	#search-mobile-input {
		width: 90%;
		padding: 0.5rem;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.header__menu-button img {
		width: 25px;
		height: 25px;
	}
}


.sidebar {
	background-color: #f9f9f9; // Light background for the sidebar
	padding: 20px; // Add padding for spacing
	border-radius: 8px; // Rounded corners
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
	.sidebar-spacer{
		width:100%;
		height:1px;
		background-color: #DBE1E8;
		margin:30px 0px;
	}
	.sidebar-section {
		margin-bottom: 30px; // Space between sections

		.sidebar-title {
			@include header-text;
			font-size: 16px; // Font size for section titles
			font-weight: 500; // Bold text
			margin-bottom: 34px; // Space below the title
			//color: #333; // Dark gray color
			//border-bottom: 2px solid #ddd; // Bottom border to underline
			//padding-bottom: 5px; // Add padding for spacing
		}

		.post-list {
			list-style: none; // Remove default list styling
			padding: 0; // Remove unwanted padding
			margin: 0; // Remove unwanted margins

			.post-item {
				margin-bottom: 34px; // Space between items
				display: flex; // Align thumbnail and title in a row
				align-items: center; // Vertically center items
				.post-link {
					display: flex; // Make the links row-based
					//align-items: center; // Vertically align items
					text-decoration: none; // Remove underline from links
					//color: #000; // Black text for links
					transition: all 0.3s ease; // Add hover transition
					flex-direction: column;
					width:100%;
					&:hover {
						color: #007bff; // Blue color on hover
					}
					.post-author{
						display: flex;
						gap: 9px;
						align-items: center;

						margin-bottom:10px;
						.author-text{
							font-size:12px;
							font-weight:500;
							color:#1E225C;
							@include header-text;
						}
					}
					.post-thumbnail {
						width: 50px; // Thumbnail width
						height: 50px; // Thumbnail height
						border-radius: 6px; // Rounded corners for thumbnails
						object-fit: cover; // Crop and fit image
						margin-right: 10px; // Space between thumbnail and title
						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for thumbnails
					}

					.post-title {
						font-family:Gelasio;
						font-size: 16px; // Font size for titles
						font-weight: 600; // Slightly bold text
						//white-space: nowrap; // Prevent text wrapping
						overflow: hidden; // Hide overflow for long text
						text-overflow: ellipsis; // Add ellipsis for long text
					}
				}
			}
		}

		.sidebar-readmore{
			a {
				color:$color-orange;
				text-decoration: none;
				font-size:13px;
				@include header-text;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}
.inspiration-detail{
	&__content{
		max-width: 740px;
		margin:0 auto;
		padding: 0 20px;
		&__hoofdstukken{
			margin-top:50px;
		}
		&__text{
			margin-top:60px;
			.page__content{
				h2{
					font: normal normal bold 24px/50px Lexend;
					line-height: 25px;
					color:#1E225C;
					strong, a, span{ font: normal normal bold 24px/50px Lexend;}
				}
				h3{
					font: normal normal bold 22px/48px Lexend;
					line-height: 1.3;
					color:#1E225C;
					strong, a, span{ font: normal normal bold 22px/48px Lexend;}
				}
				h4{
					font: normal normal bold 20px/48px Lexend;
					line-height: 1.3;
					color:#1E225C;
					strong, a, span{ font: normal normal bold 20px/48px Lexend;}
				}
				h5{
					font: normal normal bold 18px/48px Lexend;
					line-height: 1.3;
					color:#1E225C;
					strong, a, span{ font: normal normal bold 18px/48px Lexend;}
				}
				p{
					overflow: hidden;
					font-size: 16px;
					line-height: 26px;
					font: normal normal normal 18px/32px Gelasio;
					color:#1E225C;
					text-align: left;
					letter-spacing: 0;
					opacity: 1;
				} 
				ol li, ul li, p a, s, strong, del, span{
					font: normal normal normal 18px/32px Gelasio;
					line-height: 26px;
					color:#1E225C;
				}
				strong{
					font-weight:bold;
					a, span {font-weight: bold;}
				}
				.cf-button__text{
					font: normal normal normal 18px/32px Lexend;
					color:white;
				}
				@include breakpoint(medium){
					h2{
						font-size: 25px;
						font-weight: 700;
						line-height: 1.3;
						//margin-bottom: 26px !important;
						color:#181B4B;
						span{
							font-weight: bold !important;
						}
					}
					h3{
						font-size: 22px;
						font-weight: 700;
						line-height: 1.3;
						margin-bottom: 26px !important;
						color:#181B4B;
					}
					a {
						font: normal normal normal 18px/32px Gelasio;
					}
					p {
						font: normal normal normal 18px/32px Gelasio;
						line-height: 30px;
						color:#181B4B;
					}
					ul, ol {
						margin-top: 20px;
						margin-left: 20px;
						margin-bottom: 20px;
						li {
							font-size: 20px;
							line-height: 30px;
							color: #181B4B;
							margin-bottom: 1rem; /* Add spacing between bullet points */
							font: normal normal normal 18px/32px Gelasio;

							&::marker {
								font-weight: bold; /* Make bullet point numbers bold */
							}

							& > p {
								margin-bottom: 0.5rem;
							}
						}
					}
				}
			}
		}
	}
}
.banner-post {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 5rem auto 0 auto;
	max-width:920px;
	padding:0px 20px;

	.banner-post-title {
		font-size: 28px;
		font-weight: 600;
		color: #1e225b;
		max-width: 850px;
		margin-bottom: 15px;
		letter-spacing: 0px;
		@include breakpoint(medium){
			font-size: 30px;

		}
	}

	.banner-post-category {
		font-size: 13px;
		font-weight: 600;
		color: #1e225b;
		margin-bottom: 20px;
		@include breakpoint(medium){
			font-size:14px;
		}
	}

	.banner-post-intro {
		max-width: 850px;
		text-align: left;
		font: normal normal normal 20px/35px Gelasio; // cant have medium with Gelasio
		letter-spacing: 0;
		color: #888AAA;
		opacity: 1;
		@include breakpoint(medium){
			font-size:20px;
		}
	}

	.banner-post-image {
		width: 100%;
		height: 100%;
		margin-top: 45px;
		border-radius: 0px 15px 15px 15px;
	}

	.banner-post-author {
		display: flex;
        align-items: center;
        margin-bottom: 25px;
		margin-top: 25px;
		gap:14px;
		@include breakpoint(medium){
			margin-bottom: 30px;
			margin-top: 30px;
		}

		.author-icon {
			width: 45px;
			height: 45px;
			border-radius:100%;
			object-fit: contain;
			border:1px solid #DBE1E8;
		}
		.author-meta-row {
			display: flex;
			align-items: center;
			gap:14px;
		}
		.author-space-divider{
			color:#888AAA;
		}
        .author-text {
            font-size: 14px;
            font-weight: 600;
			color: #1e225b;
        }
		.author-follow{
            font-size: 14px;
			color:#FF9900;
            font-weight: 600;
			text-decoration: none;
		}
		.author-date, .author-readtime {
			font-size: 13px;
            font-weight: 400;
            color: #888AAA;
		}
	}

	.banner-divider {
		height: 1px;
		background-color: #e7ebf1;
		width: 100%;
	}
	.banner-meta {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid #e7ebf1;
		border-bottom: 1px solid #e7ebf1;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 1rem;

		.banner-meta-icon {
			width: 18px;
			height: 25px;
			// filter to light gray

			&__filter{
				width: 21px;
				height: 21px;
				filter: invert(56%) sepia(5%) saturate(1672%) hue-rotate(199deg) brightness(97%) contrast(88%);
			}
		}

		.banner-meta-container {
			display: flex;
			align-items: center;
			gap:35px;
		}

		.banner-meta-item {
			color: #888AAA;
			display: flex;
			gap:8px;
			align-items: center;
			&:first-of-type{
				margin-left:20px;
			}
			&:last-of-type{
				margin-right:20px;
			}
		}
		.banner-meta-text{
			font-size:14px;
			@include header-text;
			color:#888AAA;
		}

		.favorited {
			filter: brightness(0.5);
		}

		.favorite-link-post {

		}
	}

	.banner-post-quote {
		position: relative;
		display: flex;
		align-items: center;
		margin-top:35px;

		.quote-img-container {
			display: flex; /* Allows the image to shrink */
  			align-items: stretch; /* Stretches the image within */
		}
		.quote-img{
			width: auto;
			max-width: 100%;
			height: 100px; /* Ensures the image can shrink */
			object-fit: cover; /* Adjust this based on your needs */
		}

		.quote-container {
			position: relative;
			max-width: 100%;
			margin: 0 auto;
			text-align: left;
			flex: 1;
			display: flex;
			padding-top:20px;
		}

		.quote-text {
			font-size: 1.4rem;
			font-style: italic;
			color: #1e225b;
			line-height: 1.4;
			font-weight: 550;
			margin: 0;
			padding: 0 5px;
		}

		.quote-mark {
			font-size:24px;
		}

		
	}
}

.hoof_section{
	h3{
		font-size: 20px;
		margin-bottom: 10px;
		color:#1E225C;
	}
	.box_layout {
		display: flex;
		justify-content: start;
		overflow: scroll;
		gap:10px;
		max-width: 752px;
		margin-bottom: 0px;
		padding: 5px 0px;
		.box1 {
			width: 119px;
			min-height: 104px;
			padding: 5px;
			border: 1px solid #1E225C;
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-right:5px;
			margin-top:5px;
			box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
			p{
				font-size: 14px;
				line-height: 19px;
				&.custom_time{
					margin-bottom: 0;
				}
				margin-bottom:30px;
			}
			transition: box-shadow 400ms;
			&:hover{
				box-shadow: 0px 3px 6px rgba(0,0,0,0.32);
				cursor: pointer;
			}
		}
	}
}
