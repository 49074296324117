.cart {
  padding-top:15px;
  @include breakpoint(large){
	padding-right: 25px;
	padding-left:25px;

  }
  @include breakpoint(xxlarge){
	padding-right: 70px;
	padding-left:70px;
  }
  @include clearfix;

  &__title {

  }

  &__content {
    margin: 0 0 2rem;
  }

  &__next {
    margin: 1rem 0 0;
  }

  &__actions {
	//margin-top: 50px;
	//margin-bottom: 50px;
	@include breakpoint(large){
		padding-right: 30px;
		padding-left:30px;
		padding-top: 30px;
		&.couponsadjustment{
			padding-top:5px;
		} 

	}
	@include breakpoint(xxlarge){
		padding-right: 70px;
		padding-left:70px;
	}
  }
  &__coupons{

	padding-left:20px;
	padding-right:20px;
	@include breakpoint(large){
		
	    padding-right: 0.9375rem;
    	padding-left: 0.9375rem;
	}
	form{
		label{
			@include header-text;
			font-weight:600;
			color: $color-business-blue;
		}
		position: relative;
		input[type="text"]{
            margin-bottom: 10px;
            border: 1px solid #CBCBCB;
            border-radius: 8px;
            padding:7px 13px;
            padding:16px 30px;
            font-size:rem-calc(15px);
            height:unset;
            &::placeholder{
                color:#9798A2;
            }
            width:100%;
        }
		button{
			position:absolute;
			right:15px;
			top:42px;
			text-decoration: underline;
			color: $color-business-blue;
		}
	}
  }
  &__optie {
    @include raleway;
    font-weight: 600;
    margin: 10px 0;
    text-decoration: underline;

    @include breakpoint(medium) {
      margin: 25px 20px 0 0;
    }
  }
  
  &__steps{
	padding-top:20px;
  	//margin-bottom:20px!important;
	position: relative;
	margin:0px 0px 30px 0px!important;
	&__line{
		width: 70%;
		left: 0;
		right: 0;
		height: 1px;
		border:1px solid $color-business-blue;
		background-color:  $color-business-blue;
		position: absolute;
		z-index: -1;
		top: 20px;
		&.short{
			width:50%;
			left:-25%;
		}
		@include breakpoint(large){
			top: 30px;
		}
	}
	&__smallgrey{
		width: 100%;
		border:0.5px solid #E9E9E9;
		background-color:  #E9E9E9;
		margin: 0;
		margin-top: 20px;
	}
  	&__step{
		width:35px;
		height:35px;
		border:2px solid $color-business-blue;
		text-align:center;
		background:white;
		@include header-text;
		font-size:rem-calc(15px);
		margin:0 auto;
		font-weight:800;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
		position: relative;
		@include breakpoint(large){
			font-size:rem-calc(25px);
			width:50px;
			height:50px;
		}
		&.done, &.checked{
			background:$color-business-blue;
			color:white;

		}
		&.done::after{
			position:absolute;
			content:" ";
			border-radius:100%;
			background-color:#509b39;
			width:20px;
			height:20px;
			//margin-top:40px;
			//margin-left:10px;
			bottom: -10px;
			right: -10px;
			background:url("data:image/svg+xml,%3Csvg fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z'/%3E%3C/svg%3E") center center no-repeat;
			background-size:50%;
			background-color:#509b39;
			@include breakpoint(large){
				width:25px;
				height:25px;
			
			}
		}
  	}
	
  	&__text{
  		@include poppins;
  		color: $color-business-blue;
		text-align:center;
		font-weight:400;
		padding-top:10px;
		font-size:rem-calc(12px);
		word-wrap: break-word;
		@include breakpoint(large) {
			font-size:inherit;
			font-size:rem-calc(15px);
		}
		a{text-decoration: none;}
  	}
  	
  }
  &__buttons{
	padding-bottom:10px;
	padding-left:20px;
	padding-right:20px;
	display:flex;
	flex-wrap: wrap;
	flex-direction: column-reverse;
	
	@include breakpoint(large){
		padding-right: 0.9375rem;
		padding-left: 0.9375rem;
		display: block;
	}
	.cf-button, button.cf-button{
		@include header-text;
		height:unset;
		border-radius: 4px;
		padding: 8px 30px!important;
		margin-bottom:5px;
		border: 1px solid #ff9900;
		line-height:33.6px;
		@include breakpoint(large){
			display: inline-flex;
			margin-bottom:0px;
		}
		@include transition(all 400ms);
		&__text{
			font-size:16px;
			@include header-text;
			@include transition(all 400ms);
			margin:0px 30px 0px 0px;
			line-height:24px!important;
		}
		&__icon{
			margin-right:0px!important;
			@include transition(all 400ms);
			img{
				width:20px;
				filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(350deg) brightness(102%) contrast(100%);
			}
		}
		&:hover{
			padding-right:25px!important;
			.cf-button__text{
				margin-right:35px;
			}
			.cf-button__icon{
				margin-right:0px!important;
			}
		}
		&--alt{
			background:white;
			border:1px solid $color-business-blue;
			border-radius: 4px;
			padding: 8px 30px!important;
			margin-bottom:5px;
			@include breakpoint(large){
				margin-bottom:0px;
			}
			.cf-button__text{
				color:$color-business-blue;
				margin:0px;
				line-height: 33.6px!important;
				@include breakpoint(large){
				margin-right:0px!important;
				margin-left:30px!important;
				}
			}
			.cf-button__icon{
				display: none;
				@include breakpoint(large){
					display: block;
				}
				img{
					color:$color-business-blue;
					filter: invert(13%) sepia(21%) saturate(5404%) hue-rotate(220deg) brightness(89%) contrast(97%);
				}
			}
			&:hover{
				@include breakpoint(large){
					padding-left:25px!important;
					padding-right:30px!important;
					.cf-button__text.left{
						margin-left:35px!important;
						margin-right:0px!important;
					}
					.cf-button__icon{
						margin-left:0px!important;
					}
				}
			}
		}
	}
	button.cf-button--orange{
		padding-top:8px!important;
		padding-bottom:8px!important;
		width:100%;
		text-align: left;
		@include breakpoint(large){
			padding-top:8px!important;
			padding-bottom:8px!important;
			width:unset;
		}
		.cf-button__text{
			margin-left:0px!important;
			font-family: 'Lexend', sans-serif!important;
		}
	}
  }
}

#shopping-cart-body {
  margin-top: -15px;
  &.disabled {
    pointer-events: none;
  }
}
.carttitel{
	font-size:rem-calc(30px);
	font-weight:800;
	font-family: 'Lexend', sans-serif;
	margin-top:15px;
	margin-bottom: 25px;
	@include breakpoint(large){
		margin-top:40px;
		margin-bottom: 25px;
		font-size:rem-calc(35px);

	}
}
.cart-form-grid{
	display:grid;
	grid-template-columns: 7.5fr 2.5fr;
	grid-template-rows: auto 0.7fr;
	&.stap{
		grid-template-columns: none;
	}

}
.cart-form-grid-opleiding{
	display:grid;
	grid-template-columns: 7.5fr 2.5fr;
	margin-left: -30px;
	margin-right: -10px;
	border-bottom: 1px solid #e3e5e7;
	&.stap{
		grid-template-columns: none;
	}
	.cart-row__namebox{
		margin-left:0 !important;
	}
}
.rowlocation{
	grid-column: 1 / -1;
	margin-left: -15px;
    margin-bottom: -15px;
	margin-right: 5px;
	border-top: 1px solid #e3e5e7;
	.checkout__notice{
		margin:20px; 
	}
	p{
		color: #1E225C;
		font-size:16px;
		font-family: 'Lexend', sans-serif;
		font-weight: 500;
		margin-left:20px;
		margin-top: 15px;
	}
}
.rowaantalpersonen{
	padding: 15px 20px;
	margin-top:-15px;
	margin-right: 5px;
	label.middle{
		padding-top:0px;
		font-size:16px;
		font-family: 'Lexend', sans-serif;
		line-height: 20px;
	}
}
.locationselect{
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom:15px;
	p{
		color: #1E225C;
		font-size:16px;
		font-family: 'Lexend', sans-serif;
		font-weight: medium;
		margin-left:0px;
		margin-bottom:5px;
	}
	select{
		border: 1px solid #1E225C;
		border-radius: 4px;
	}
}
.leergangrow{
	margin-left:-30px;
}
.cart-row__location{
	border: 1px solid #1E225C;
	border-radius: 4px;
	height:2.5rem;
}
.rowselectopleiding{
	margin-left: -10px;
	margin-right: 10px;
}
.cart_coupons{
	flex:100%;
	max-width: 100%;
	.cart__next{
		float:left !important;
		height:auto;

		.cf-button--white{
			color:$color-business-blue;
		}

		span{
			padding:20px;
		}
	}
	.whiteborder{
		border: 2px solid $color-business-blue;
		margin-right: 20px;

		&:hover, &:active, &:hover, &:focus{
			background:white;
			color:$color-business-blue
		}
		.icon-arrow-right{
			margin:0;
			margin-left:20px;
			@include transform(rotate(-180deg));
		}
	}
	.cf-button__text{
		font-size:17px;
		font-weight: 600;
		font-family: 'Lexend', sans-serif;
	}
}