.opleidingsbudget {
  $padding: 20px;
  padding: $padding;

  &__titel {
    @include raleway;
    font-size: rem-calc(25);
    color: white;
    font-weight: 800;
    margin: 0;
    text-align:center;
  }

  &__text {
    color: white;
    text-align:center;
    margin-bottom:20px;
  }

  &__form {
  	 &__list{
  	 	list-style-type:none;
  	 }
	 input, select {
	 	margin-bottom:15px;
	 	border-radius:5px;
	 	padding:0.65em;
	 }
	 input.halfwidth{
	 	@include breakpoint(medium) {
	 		width:49.3%;
	 		display:inline-block;
	 	
	 	}
	 }
	 input::placeholder, textarea::placeholder, select option:first-child{
	 	color:$color-darker-gray;
	 }
	 button {
	 	width:100%;
	 	.cf-button__text{
	 		margin: -2px 15px 0 15px;
	 	}
	 }
	 &__voorwaarden{
    	font-size: rem-calc(14);
    	input{
    		margin-bottom:0px;
    	}
	 }
  }
  @media print {
  	display:none!important;
  }
}