.quote-block {
	background:#F7F7F7;
	margin:50px 0px;
	@include breakpoint(large){
		margin:125px 0px;
	}
	&__text {
		display:flex; 
		&__icon{
			padding:90px 0px;
			padding-right:10px;
			svg{
				path{
					fill: $color-business-blue;
				}
			}
		}
		&__container{
			padding:100px 0px;
		
			&__text{
				font-style:italic;
				font-weight:700;
				font-size:1.4rem;
				color:$color-blue;
				margin-bottom:20px;
				@include breakpoint(medium){
					font-size:30px;
					margin-right:100px;
				}
			}
			&__person{
				&::before{
					content:"-";
					color: $color-business-blue;
					margin-right:10px;
				}
				font-style:italic;
				font-weight:500;
				font-size: 1.4rem;
				color:$color-blue;
				margin-bottom:10px;
				padding-left:1rem;
    			@include breakpoint(medium) {
					text-align:right;
					padding-right:4.5rem;
					padding-left:0rem;
    			
    			}
				
			}
			&__function{
				
				font-style:italic;
				color:rgba(30, 34, 92, 0.5);
				font-size:1.1rem;
				padding-left:2rem;
				
    			@include breakpoint(medium) {
					text-align:right;
					padding-right:2rem;
					padding-left:0rem;
    			
    			}
			}
		}
	}
	
	&__image{
		
		position:relative;
		img {
			position:absolute;
			bottom:0px;
			right: 0px;
    		width: 27%;
    		@include breakpoint(medium) {
    			max-width:90%;
    			width:90%;
    		}
		}
	}
}