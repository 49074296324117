.zoekresultaat {
  border-top: 2px solid $color-line;
  padding: 1rem 0;
  margin: 0 0 1rem;

  &__title {
    @include raleway;
    color: $color-business-blue;
    font-size: rem-calc(16);
    font-weight: 600;
    margin: 0 0 0.5rem;
    &:first-letter {
      text-transform: uppercase;
    }
    a {
      color: $color-business-blue; 
      text-decoration: none;

      strong {
        color: $primary-color;
        font-weight: 8600;
      }
    }
  }

  &__intro {
    margin: 0;
    &:first-letter {
      text-transform: uppercase;
    }

    strong {
      color: $primary-color;
    }
  }
}