//-------------------------------------------Test XD om aan te passen-----------------------------------------------------------------------------------

.test_email div {
	max-width: 630px;

	@include breakpoint(medium) {
	min-width: 380px;
	}
	margin: 0 auto;
	background: #FFFFFF1D 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
	padding: 18px 18px;
	margin-bottom: 39px;
	p{ display:flex; align-items:center; gap:10px; text-align: left!important;}
	p:last-of-type{
		margin-bottom:0px;
	}
	img{ width:25px; filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); }
	span { color:white;}
	svg {
		margin-right: 10px;
		color: #fff;
		font-size: 50px;
	}
}

.test_cf_club div {
	max-width: 686px;
	min-width: 380px;
	//height: 67px !important;
	margin: 0 auto;
	background: #FFFFFF1D 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
	padding: 21px 33.32px;
	margin-bottom: 30px;
	p{ margin-bottom:0px;}
	svg {
		margin-right: 10px;
		color: #fff;
		font-size: 50px;
	}
}



.favoriete_text1 {
	/* Layout Properties */
	top: 375px;
	left: 476px;
	width: 597px;
	height: 67px;
	/* UI Properties */
	background: #FFFFFF1D 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
	margin: 0 auto;
	padding: 21px 33.32px;
	margin-bottom: 39px;


}



.persoonlijke_custom {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 2000px !important;



}

.fullscreenform__container__section__container .step-module__section__choices>div {
	min-width: 40%;
	max-width: 100%;
}



.fullscreenform__container__section__container .step-module__section__choices>div {
	font-size: 16px;

	p {
		text-align: left;
	}

	&.favoriete_text2 {
		min-height: 50px;
		padding: 10px 30px;

		input {
			top: 16px;
			accent-color: #1E225C;
		}

		@include breakpoint(xlarge) {
			min-width: 20%;
			max-width: 69%;
		}
	}
}

.step-module__section__choices>div input {

	accent-color: #1E225C;

}


.test_resultaat div {
	display: block;
	@include breakpoint(large){
		display: flex;

	}

	flex-direction: row;
	flex-wrap: wrap;

	max-width: 718px;
	height:unset;
	//height: 258px !important;
	@include breakpoint(medium){
	//height: 208px !important;
	min-width: 380px;
	}
	margin: 0 auto;
	background: #FFFFFF1D 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
	padding: 21px 20px;
	margin-bottom: 30px;
	h4{color:white;}

}

#test-text-resultaat {
	top: 378px;
	left: 453px;
	@include breakpoint(large){
	width: 624px;
	}
	//height: 48px;
	margin-bottom:0px;
	/* UI Properties */
	text-align: left;
	letter-spacing: -0.32px;
	color: #FFFFFF;
	opacity: 1;
}


.test-go-email {
	margin-top: 30px;
	justify-content: center;
	gap: 0px!important;
	margin-bottom: 20px;

	@include breakpoint(medium) {
		gap: 20px;
	}

	button,
	button:first-of-type,
	a,
	a:first-of-type {
		margin: 0px;
		flex-basis: 49%;

		@include breakpoint(large) {
			flex-basis: 25%;

		}
	}

	&.single {

		button,
		button:first-of-type,
		a,
		a:first-of-type {
			margin: 0px;
			flex-basis: 100%;

			@include breakpoint(large) {
				flex-basis: 50%;

			}
		}
	}
}

.test-account-buttons a{
	@include breakpoint(large){
		flex-basis:35%!important;
	}
}
.test-go-input-div{
	flex-basis: 75%;
	@include breakpoint(medium) {
		flex-basis: 51%;
	}
}
.test-go-input {
	border: 1px solid #707070 !important;
	margin-top: 10px !important;
	border-radius: 8px !important;
	padding: 25px 45px !important;
	height:unset!important;
	@include header-text;

	&::placeholder {
		color: $color-business-blue !important;
	}

	margin: 0 auto !important;
	width: 114% !important;
	@include breakpoint(large) {
		width: 114% !important;
	}
}




.test-go-button {
	
	width: 50px !important;
	
	background: #FF9900 0% 0% no-repeat padding-box !important;
	border-radius:0px 8px 8px 0px!important;

	flex-basis: 25% !important;
	@include breakpoint(large) {
	flex-basis: 15% !important;
	}

}


// -------------------------------------------------------Css---------------------------------------------------------------------------------------------------------------
.stap-icon {
	text-align: center;
	margin: 5px 0;
}

.let-op {
	background: #f7fb00;
	box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.2);
	border: 1px solid #1C2663;
	max-width: 500px;
	padding: 25px;
	margin-top: 25px;
	border-radius: 5px;
}

.kleiner {
	font-size: 0.9rem;
	color: #fff;
	font-style: italic;
}


.content-divider {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		left: 50%;
		margin-left: -50px;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;
		border-top: 50px solid $color-gray;
		border-bottom: 50px solid transparent;
	}

	&--blue {
		border-bottom: 2px solid $color-business-blue;

		&:after {
			border-top: 30px solid $color-business-blue;
		}

	}
	
	&--white {
		border-bottom: 2px solid white;

		&:after {
			border-top: 30px solid white;
		}

	}
	
	&--gray {
		border-bottom: 2px solid #F8F8FA;

		&:after {
			border-top: 30px solid #F8F8FA;
		}

	}
	
	&.cluster{
		margin-bottom:0px;
	}
}

.content-blocks {
	@include breakpoint(large) {
		padding-left: 5rem !important;
	}
}

.stap-content {
	margin-top: 60px;
	padding-bottom: 55px;
	border-bottom: 1px solid #ccc;

	&.seo {
		border: none;
	}

	&:first-of-type {
		margin-top: 0px;
	}

	&__heading {
		background-color: $color-business-blue;
		font-size: rem-calc(30px) !important;
		color: white !important;
		padding: 7px 15px;
		margin-bottom: 30px !important;

		&-v3{
			background-color: transparent;
			font-size: rem-calc(30px) !important;
			color: $color-business-blue !important;
			margin-bottom: 30px !important;
		}
	}

	h2 {
		font-size: 2rem;
		color: $color-blue;
		margin-bottom: 1rem;
		@include header-text;
	}

	h3 {
		color: $color-blue;
		@include header-text;
	}

	li {
		margin: 0rem 0;
	}

	.groen {
		color: #000;
	}


	ul.dichtbij li {
		margin: 7px 0;
	}

	.stap-newsitem {
		background: #02abac;
		border-radius: 5px;
		margin: 50px 0;
		padding: 25px;
	}

	.stap-newsdate {
		color: #000;
		font-weight: bold;
		font-size: 1.3 rem;
	}

	.stap-newstitle h3 {
		color: #fff;
	}

	.stap-newstext {
		color: #000;
	}

	.stap-newstext a {
		color: #000;
	}

	.groen {
		color: #000;
	}

	.biggerbold {
		font-size: 18px;
		color: #1e225d;
	}

	a:not(.cf-button):not(.cf-button--blue-flat-alt):not(.cf-button--orange-flat):not(.cf-button--blue) {
		color: $color-orange;
	}

	.bordergroen {
		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.2);
		border: 1px solid #1C2663;
		margin: 50px 0;
		border-radius: 12px;
		padding: 25px;
		color: #000;
	}

	.bordergroen h3 {
		color: $color-blue;
		font-size: 24px;

	}

	h2 {
		font-size: 2rem;
		color: #1e225d;
		margin-bottom: 1rem;
		@include header-text;
	}


	ul.dichtbij li {
		margin: 7px 0;
		color: #1E225C;
	}

	.stap-newsitem {

		box-shadow: 0px 3px 10px rgba(42, 62, 82, 0.2);
		border: 1px solid #1C2663;
		background: #00c4c2;
		border-radius: 12px;
		margin: 50px 0;
		padding: 25px;
	}


	section.enqform_section.contact__main {
		border-radius: 5px;
		margin-top: 50px;

		input::placeholder,
		textarea::placeholder {
			color: $color-blue;
		}
	}

	.cf-button--altblue,
	button.cf-button--altblue {
		border: 2px solid #1e225d;
		color: #fff;
		background-coloR: #1e225d;
	}


	&__nopaddingbottom {
		padding-bottom: 0px !important;

	}

	&__banner__image {
		padding: 60px 0px;
		background-position: center center;
		background-size: cover;
		background: rgba(32, 27, 102, 0.9);
	}

}

.event {

	&__overige {
		background-color: $color-blue;

		h2 {
			color: white;
		}
	}
}

.event {

	&__overige {
		background-color: $color-blue;
		h2 {
			color: white;
		}
		&.grey{
			background-color:#F8F8FA;
			h2{
				color:$color-business-blue;
			}
		}
	}
}

.sticky-sidebar {
	box-shadow: 0px 15px 30px rgba(42, 62, 82, 0.098);
	border-radius: 8px;
	position: sticky;
	top: 75px;
	margin-bottom: 50px;

	&__section {
		padding: 17px;
		border-bottom: 1px solid #F0F0F0;
		color: $color-blue;

		&__title {
			padding: 17px;
			font-weight: bold;
			@include header-text;
			color: $color-blue;
			border-bottom: 1px solid #F0F0F0;
		}

		a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.9rem;
			margin: 5px 0px;
		}

		&--bottom {
			padding: 17px;
			color: $color-blue;
			border-bottom: 0px;

			a {
				justify-content: initial;

				&.orange {
					color: $color-orange;
				}
			}
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

}

.white-callout {
	border: 1px solid #FFFFFF;
	border-radius: 12px;
	color: white;
	padding: 30px;

	ul.iconlist {
		list-style-type: none;
		margin: 0px;
		margin-top: 20px;
		margin-bottom: 20px;

		li {
			color: white;

			.icon {
				padding-right: 10px;
			}

			.icon img {
				width: 18px;
				filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(351deg) brightness(101%) contrast(101%);
			}

			a {
				color: white;
			}
		}
	}
}
.dark-callout {
	color: $color-business-blue;
	padding: 30px;
	text-align: center;
	&__title{
		font-size: 20px;
		font-weight: 800;
		@include header-text;
		margin-bottom: 10px;
		text-align: center;
	}
	ul.iconlist {
		list-style-type: none;
		margin: 0px;
		margin-top: 20px;
		margin-bottom: 20px;

		li {
			color:$color-business-blue;

			.icon {
				padding-right: 10px;
			}

			.icon img {
				width: 18px;
				filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(351deg) brightness(101%) contrast(101%);
			}

			a {
				color: $color-business-blue;
			}
		}
	}
	.cf-button--blue-alt{
		.cf-button__text{ @include header-text; font-size:16px; font-weight:800;}
	}
}
.image-container-yougotthis{
	margin-bottom:-60px; 
	margin-top:30px;
	position: relative;
	img{
	}
	&::after{
		background-image:url('/images/Logo_Sticker-YouGotThis-v2.png');
		position: absolute;
		display: block;
		content: " ";
		width:50px;
		height:50px;
		background-repeat: no-repeat;
		background-size: contain;
		top: 0px;
		right: 0px;
	}
}
.step-module {
	&.test {
		position: relative;

		.step-module__timeline {
			@include breakpoint(large) {
				position: absolute;
				right: 0;
			}
		}

		.step-module__section h3 {
			@include breakpoint(large) {
				font-size: 2rem;
				width: 50%;
				line-height: 1.2;
				letter-spacing: -1px;
				font-weight: 800;
			}
		}
	}

	&__result {
		margin-top: 25px;

		.trainingen-grid__item:hover {
			.training-grid-block__image__overlay {
				opacity: 0;
			}
		}
	}

	&__callout {
		margin-top: 60px;
		border-radius: 12px;
		border: 1px solid rgba(255, 255, 255, 0.494);
		padding: 10px;

		@include breakpoint(large) {
			margin: 0 auto;
			margin-top: 160px;
			padding: 20px;
			max-width: 700px;

		}

		h4 {
			color: white;
			font-size: 1.1rem;
			text-align: center;
			margin-bottom: 20px;

			@include breakpoint(large) {
				font-size: rem-calc(30px);
			}
		}

		p {
			font-size: 0.8rem;

			@include breakpoint(large) {
				font-size: rem-calc(18px);
			}
		}

		.label {
			background-color: #f7fb00;
			color: $color-business-blue;

			@include breakpoint(large) {
				font-size: rem-calc(18px);
			}
		}

		&__animation {
			margin-top: -65px;
		}

		&.has-tip {
			display: block;
			font-weight: unset;
		}
	}
	&__header{
		margin-bottom: 26px;
		border-bottom: 1px solid #DDDDDD;
		padding-bottom: 15px;
		@include breakpoint(large){
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 55px;
			padding-bottom: 20px;
		}
	}
	&__title{
		font-size:18px;
		font-weight: 900;
		@include header-text;
		color: $color-business-blue;
		margin-bottom:16px;
		@include breakpoint(large){
			margin-bottom:0px;
		}
	}
	&__timeline {
		display: flex;
		column-gap: 30px;
		margin-bottom: 50px;

		&.small {
			column-gap: 10px;

			.step-module__timeline__item {
				padding: 4px;
				height: 35px;
				width: 35px;

				&::after {
					width: 12px;
					margin-left: 11px;
				}
			}

			@include breakpoint(medium up) {
				column-gap: 30px;

				.step-module__timeline__item {
					padding: 3px;
					//padding: 10px;
					//height: 50px;
					//width: 50px;

					&::after {
						width: 30px;
						margin-left: 13px;
					}
				}
			}
		}

		&__item {
			border-radius: 100%;
			border: 1px solid $color-blue;
			@include header-text;
			font-size: 1.2rem;
			font-weight: 800;
			padding: 5px;
			width: 40px;
			height: 40px;
			text-align: center;
			color:$color-business-blue;
			cursor: pointer;

			&.filled {
				background: $color-blue;
				color: white;
			}

			&::after {
				position: absolute;
				border: 2px solid $color-blue;
				width: 30px;
				content: " ";
				margin-top: 11px;
				margin-left: 15px;

			}

			&:last-of-type {
				&::after {
					display: none;
				}
			}

			@include breakpoint(medium up) {
				column-gap: 80px;
				//width: 50px;
				//height: 50px;
				//padding: 10px;
				&::after {
					width: 80px;
					content: " ";
					margin-top: 12px;
					margin-left: 14px;

				}
			}

			&.small{
				@include breakpoint(medium up) {
					&::after {
						width:30px;
					}
				}
			}
		}

		
	}

	&__section {
		display: none;
		margin-bottom: 30px;
		&.current {
			display: block;
			&.hasimage{
				display:block;
				@include breakpoint(medium){
					display:flex;
					gap:20px;
				}

			}
		}
		&__image{
			margin-bottom:20px;
			@include breakpoint(medium){
				flex-basis: 25%;
				margin-bottom:0px;
			}
		}
		&__content{
			flex-basis: 75%;

			ul li{color:white;}
			&.dark{

				ul li{color:$color-business-blue;}
			}
		}
		h3 {
			font-size: 1.2rem;
			font-weight: bold;
			margin-bottom: 20px;
		}

		p {}

		a:not(.cf-button--white-alt) {
			color: $color-orange;
		}

		&__choices {
			display: flex;
			gap: 25px;
			flex-wrap: wrap;

			&>div {
				cursor: pointer;
				flex: 1 1 calc(25% - 10px);
				min-width: 90%;
				background-color: #fff;
				box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
				border: 1px solid white;
				border-radius: 8px;
				position: relative;
				padding: 20px;
				z-index: 2;
				text-decoration: none;
				display: block;
				height: 100%;
				font-weight: bold;
				font-size: 18px;
				justify-content: space-between;
				align-items: center;

				@include breakpoint(medium) {
					padding: 30px;
					display: block;
					min-width: 40%;
					max-width: 50%;
				}

				@include breakpoint(xlarge) {
					min-width: 20%;
					max-width: 32%;

					&.category {
						min-height: 170px;
					}
				}

				&:hover {

					border: 1px solid $color-blue;
					box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
					@include transition(all 400ms);

					.category-section__container__categories__card__title {
						color: $color-orange;
						@include transition(all 400ms);
					}

					.category-section__container__categories__card__link>.cf-button--orange-link {
						@include cf-button-orange-link-hover;
					}

				}

				&.checked {
					border: 1px solid $color-blue;
				}

				&.disabled {
					border: 1px solid gray;
					opacity: 0.5;
				}

				input {
					position: absolute;
					left: 10px;
					top: 10px;
				}

				p {
					margin-bottom: 0px;
				}

				p.subtitle {
					font-weight: normal;
					font-size: 16px;
				}

				img {
					width: 50px;
					filter: invert(19%) sepia(31%) saturate(1553%) hue-rotate(201deg) brightness(83%) contrast(106%);
					margin-bottom: 10px;
				}

			}
		}
	}

	&__buttons {
		display: flex;

		button:first-of-type() {
			margin-right: 20px;
		}
	}

	&.light {
		color: white;
		.step-module__timeline {
			&__item {
				border: 1px solid white;

				&.filled {
					background: white;
					color: $color-blue;
				}

				&::after {
					border: 2px solid white;

				}
			}
		}

		.step-module__section {
			color: white;

			h3 {
				color: white;
			}

			p {
				color: white;
			}

			ul li{color: white;}
		}
	}
}

.process-module{
	&__container{
		@include breakpoint(large){
			padding:0px 20px!important;
		}
		.step-module__timeline{ margin-bottom: 0px; }
		.step-module__section h3 { font-size:24px; font-weight: 800; font-family: Poppins; }
		.step-module__timeline { column-gap:20px;}
		.step-module__timeline__item::after{ width:20px; }
		@include breakpoint(medium up){
			.step-module__section h3 { font-size:32px; }
			.step-module__timeline { column-gap:50px;}
			.step-module__timeline__item::after{ width:50px; }
		}
	}
	&__divider{
		border-bottom:1px solid #DDDDDD;
		padding-top:40px;
		margin-bottom:20px;
	}
}
.stap-training-slider{
	margin:80px 0px;
	&__title{
		padding:0px 20px;
		@include breakpoint(large){
			padding:0px 20px;
		}
	}
	h2{
		font-size:25px;
		@include breakpoint(large){
			font-size:32px;
			@include header-text;
			font-weight:800;
			max-width: 530px;
			margin-bottom:0px!important;
		}
	}
	.training-slider-block__body{
		width:100%;
		max-width: 100%;
		.next-arrow, .prev-arrow{ margin-right:20px; }
		.opleiding-container .slider__counter {margin-right:20px; }
	}
	&__readmore{
		margin-top:20px;
		color:$color-business-blue;
		font-size:16px;
		padding:0px 20px;
		a{ 
			font-weight:bold; 
			&.externallink{
				display: inline-flex;
				align-items: center;
				gap:10px;
				.icon{
					img{
						width:16px;
						filter: invert(15%) sepia(9%) saturate(7193%) hue-rotate(209deg) brightness(97%) contrast(103%);
					}
				}
			}
		}
		@include breakpoint(large){
			margin-top:-80px;
			padding:0px 20px;
		}
	}
	.slider__counter{
		display: none;
		@include breakpoint(large){
			display: block;
		}
	}
}
.signup{
	&--stap{
		background-color: #F8F8FA;
		padding:80px 20px;
		@include breakpoint(large){
			padding:80px 0px;
		}
		.signup{
			&__container{
				@include breakpoint(large){
					padding:0px 20px;
				}
				&__text{
					@include breakpoint(large){
						align-self:center;
					}
					&__title{
						@include header-text;
						font-weight:800;
						font-size:25px;
						@include breakpoint(large){
							font-size: 32px;
						}
					}
					&__content { font-size:16px;}
				}
				&__image{
					text-align: center;
					img { 
						@include breakpoint(large){
							max-width: 500px;
						}
					}
					&__buttons{
						display: flex;
						justify-content: center;
						gap:15px;
						flex-wrap: wrap;
						margin-top:10px;
					}
					.naked-button{
						border: 1px solid #CFD0E5;
						border-radius: 8px;
						padding:8px 14px;
						display: inline-flex;
						align-items: center;
						gap:15px;
						text-decoration: none;
						.cf-button__text{
							@include header-text;
							font-size:15px;
							font-weight: medium;
						}
						.cf-button__icon{
							img{
								filter: invert(18%) sepia(11%) saturate(3629%) hue-rotate(198deg) brightness(94%) contrast(107%);
								width:18px;
							}
						}
					}
				}
				&__form{
					margin-bottom:20px;
					@include breakpoint(large){
						margin-bottom:0px;
					}
					.form-col {
						margin-bottom:10px;
					}
					.cluster-header__content-post__text__buttons{
						margin-top:25px;

					}
					label{
						font-size:12px;
						@include header-text;
						color:$color-business-blue;
						position: relative;
						top: 10px;
						left: 15px;
						background: #F8F8FA;
						display: inline-block;
						padding: 0px 5px;
					}
					input{
						border: 1px solid $color-business-blue;
						border-radius: 8px;
						background-color: transparent;
						padding:14px 20px;
						&::placeholder{
							color:#9798A2;
						}
						@include breakpoint(large){
							width:80%;
						}
						&.is-invalid-input:not(:focus){
							border: 1px solid #ec5840;
							background-color: #fdeeec;
							&::placeholder{
								color:#ec5840;
							}

						}
					}
				}
			}
		}
	}
}