.cart-row {
  border: 1px solid $color-line;
  padding: 15px 10px 15px 30px;
  margin-top:50px;
  border-radius: 8px;

  &:last-child {
    border-bottom: 1px solid $color-line;
  }

  label, select {
    margin-bottom: 0;
  }

  &__namebox{
    margin-left: -15px;
    margin-top: -15px;
    border-right: 1px solid #e3e5e7;
  }

  &__name {
    color: $color-business-blue;
    font-size: 1.25rem;
    font-weight: 800;
    margin: 0;
    padding:20px;

    &--leergang {
      font-weight: 800;
    }
  }
  &__aantal { 
    margin-top:5px;
    border: 1px solid #1E225C;
    border-radius: 4px;
    @include breakpoint(large) {
    	margin-top:0px;
    }
  }
  &__leergang-toelichting {
    margin: 5px 0 0;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }

  &__leergang-training {
    color: $color-business-blue;
    font-size: 1rem;
    font-weight: 600;
    margin: 10px 0 3px;
  }

  &__leergang-optioneel-toelichting {
    @include raleway;
    font-weight: 800;
    margin: 2rem 0 1rem;
    color: $color-darker-primary;
  }

  &__form {

  }

  &__delete {
    color: $color-black;
    text-decoration: none;
    display: inline-block;
    padding-top: 10px;
    @include breakpoint(large) {
    	padding-top:0px;
    }

    &:hover {
      color: $alert-color;
    }
  }

  &__aantal-label {
    color: $color-business-blue;
    font-family: "Lexend", sans-serif;
    @include raleway;
    font-weight: 500;
    font-size: 16px;
    margin-top:5px!important;
    text-align: start;
    padding-top:0;
    @include breakpoint(large) {
    	margin-top:0px!important;
    }
  }

  &__leergang-optioneel-delete {
    float: left;
    color: $color-black;
    text-decoration: none;

    &:hover {
      color: $alert-color;
    }
  }

  &__total {
    display: flex;
    height: 52px;
    @include breakpoint(medium) {
    	height: 82px;
    }
    @include breakpoint(large) {
    	height: 52px;
    }
    flex-direction: column;
    justify-content: center;
    text-align: right;
    align-items: flex-end;
    @include raleway;

    &-before {
      color: $color-dark-gray;
      font-weight: 600;
      text-decoration: line-through;
    }

    &-after {
      font-weight: 800;
    }

    &-active-discount {
      font-size: rem-calc(15);
      color: $color-blue;
    }
  }

  &__totals {
    @include raleway;

    &-top {

    }

    &-bottom {
      margin-top: 10px;
    }

    &-prijs {

    }

    &-korting {
    }

    &-subtotaal {
    }

    &-btw {
    }

    &-totaal {
      display: inline-block;
      font-weight: 800;
    }
  }
}