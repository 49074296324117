
/**
 * 4.0 - Alignment
 */

.alignleft {
  float: left;
  margin: 6px 28px 28px 0;
}

.alignright {
  float: right;
  margin: 6px 0 28px 28px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 28px;
}


/**
 * 5.0 - Caption
 */

.wp-caption {
  background: transparent;
  border: none;
  margin-bottom: 28px;
  max-width: 100%;
  padding: 0;
  text-align: inherit;
}

.wp-caption-text,
.wp-caption-dd {
  color: #686868;
  font-size: 13px;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 7px;
}


/**
 * 6.0 - Galleries
 */

.mce-content-body .wpview-wrap {
  margin-bottom: 28px;
}

.gallery {
  margin: 0 -1.1666667%;
  padding: 0;
}

.gallery .gallery-item {
  display: inline-block;
  max-width: 33.33%;
  padding: 0 1.1400652% 2.2801304%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery .gallery-caption {
  font-size: 13px;
  margin: 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}


/**
 * 7.0 - Audio / Video
 */

.wp-audio-shortcode a,
.wp-playlist a {
  box-shadow: none;
}

.mce-content-body .wp-audio-playlist {
  margin: 0;
  padding-bottom: 0;
}

.mce-content-body .wp-playlist-tracks {
  margin-top: 0;
}

.mce-content-body  .wp-playlist-item {
  padding: 10px 0;
}

.mce-content-body .wp-playlist-item-length {
  top: 10px;
}


/**
 * 8.0 - RTL
 */

.rtl blockquote {
  border: 0 solid #1a1a1a;
  border-right-width: 4px;
}

.rtl blockquote.alignleft,
.rtl blockquote.alignright {
  border: 0 solid #1a1a1a;
  border-top-width: 4px;
}

.rtl blockquote:not(.alignleft):not(.alignright) {
  margin-right: -28px;
  padding: 0 24px 0 0;
}

.rtl blockquote blockquote:not(.alignleft):not(.alignright) {
  margin-right: 0;
  margin-left: auto;
}

.rtl li > ul,
.rtl blockquote > ul {
  margin-right: 20px;
  margin-left: auto;
}

.rtl li > ol,
.rtl blockquote > ol {
  margin-right: 24px;
  margin-left: auto;
}

.rtl table th,
.rtl .mce-item-table th,
.rtl table caption {
  text-align: right;
}


/**
  * 9.0 Video
  */

.video-shortcode{
	max-width:100%;
	max-height:100%;
	height:unset!important;
}
