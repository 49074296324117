
.split-header{
	overflow:hidden;
	.mobile-container{ padding-bottom:0px; }
	.title-row{
		h1{
		margin-bottom: 15px;
			@include breakpoint(large){
				font-size:rem-calc(39px);
				margin: 50px 0px;
			}
		}
		.home-leader-new__subtext.rating{
			background:transparent;
			align-items:center;
			margin-bottom: 15px;
			.start__reviews__ratings, .start__reviews__ratings__text{
				font-size:rem-calc(16px);
				margin:0;
			}
		}
	}
	.background-row{
		position:relative;
	}
	.content-row{
		position:relative;
		z-index:10;
	}
	.background-letters{
		position:absolute;
		height:100%;
		width:100%;
		left:50%;
		transform: translateX(-50%);
		overflow:hidden;
		&__text{
			position:absolute;
			right:0px;
			bottom:5px;
			text-align:right;
			font-size:230px;
			font-weight:900;
			line-height:180px;
			letter-spacing: -27px;
			@include header-text;
			@include breakpoint(large){
			right:30px;
			bottom:15px;
			line-height:300px;
			font-size:475px;
			letter-spacing: -47px;
			
			}
		}
	}
	&__image{
		position:relative;
		& > img{
			position:absolute;
			bottom:0px;
			left:0px;
			max-width:210px;
			max-height:290px;
			@include breakpoint(medium){
				right:0px;
				left:unset;
			}
			@include breakpoint(large){
				max-width:100%;
				max-height:520px;
				right:unset;
				left:50%;
				transform: translateX(-50%);
			}
			
		}
		.vid-btn{
			height: 100%!important;
			.vid-icon{
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) !important;
				text-align: center;
				padding:0px;
				height:80px;
				width: 80px;
				@include breakpoint(large){
					padding:20px;
					height: 140px;
					width: 140px;
				}
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 100%;
				cursor: pointer; 
				-webkit-backdrop-filter: blur(10.7px);
				backdrop-filter: blur(10px);
				img{
					//width: 50%;
					height:60px;
					width: 60px;
					@include breakpoint(large){
						height:100px;
						width: 100px;
					}
				}
				@include transition(margin 400ms, box-shadow 400ms);
				&:hover{
					box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.5);
					cursor: pointer;
				}
			}
		}
	}
	&__container{
		background:white;
		box-shadow: 0px 2px 6px rgba(12,29,43,0.219);
		border-radius:8px;
		
		margin:19px 0px;
		@include breakpoint(large){
			margin:50px 0px 80px 0px;
		
		}
		&__upper{
			@include breakpoint(large){
				padding: 25px;
				padding-right:15px;
			}
			.training-header__uspblock__block{
				background:transparent;
				padding:15px 15px;
				row-gap:8px;
				@include breakpoint(large){
					padding:0px;
					row-gap:15px;
				
				}
				&__item{
					width: 100%;
					&__image{
						width:18px;	
						@include breakpoint(large){
							width:24px;
						}
					}
					&__text{
						font-size:rem-calc(13px);
						@include breakpoint(large){
							font-size:rem-calc(15px);
						}
					}
					@include breakpoint(large){
						width: 48%;
					}
				}
			}
		}
		&__lower{
			padding:5px;
			@include breakpoint(large){
				padding:12px;
			}
			&__button{
				.cluster-header__content__text__buttons__button{
				width:100%;
				}
				.cluster-header__content__text__buttons__button:last-of-type{margin-bottom:0px; }
				@include breakpoint(large){
					padding-left:0px!important;
				}
			}
			&__review{
				.home-leader-new__subtext.rating{
					display:flex;
					background-color:#F2F8FF;
					max-width:100%;
					padding: 13px 3px;
					@include breakpoint(xxlarge){
					padding: 13px 13px;
					}
					.start__reviews__ratings__text{
						font-size:rem-calc(14px);
						margin-left:0px;
					}
				}
				@include breakpoint(large){
					padding-right:0px!important;
					padding-left:0px!important;
				}
			}
			.cf-button--blue{
				margin-top:0px;
				margin-bottom:0px;
				font-size:rem-calc(14px);
				.cf-button__text{
					font-size:rem-calc(14px);
				}
			}
			@include breakpoint(large){
				border-top:1px solid #D8D8D8;
			}
		}
	}
	&__finalrow{
		border-bottom:1px solid #D8D8D8;
		.column{
			text-align:center;
			padding:20px 0px;
			img{}
			p{ 
				font-size:rem-calc(14px);
				@include header-text;
				font-weight:medium;
				width:80%;
				margin:0 auto;
			}
		}
	}

}
.training-grid-view{
	@include breakpoint(large){
		position:relative;
		z-index:30;
		margin-top:-30px;
		margin-bottom:40px;
	}
}