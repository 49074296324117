.callout-section{
    & > div.row > div.column{
        padding-left:2rem;
        padding-right:2rem;
    }
    &__container{
        border-radius: 12px;
        border:1px solid $color-business-blue;
        background-color: white;
        @include breakpoint(large){
            display: flex;
        }
        &__leftside{
            padding:30px;
            @include breakpoint(large){
                padding:45px 60px;
                padding-right:20px;
            }
        }
        &__rightside{
            padding:30px;
            padding-bottom:0px;
            @include breakpoint(large){
                padding:0px;
                min-width:230px;

            }

        }
        &__title{
            @include header-text;
            font-weight: 800;
            font-size:18px;
            @include breakpoint(large){
                font-size:25px;
            }
            margin-bottom:20px;

        }
        &__text{
            margin-bottom:25px;
        }
        &__image{
            position: relative;
            height:100%;
            width:100%;
            text-align: center;
            img{
                max-height:300px;
                margin: 0 auto;

                @include breakpoint(large){
                    position: absolute;
                    max-width:230px;
                    max-height: unset;
                    bottom:0px;
                    left:0px;
                }
            }
            &__label{
                padding:10px 16px;
                background-color: white;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                border-radius: 2px;
                position: absolute;
                bottom:25px;
                left:50%;
                transform: translateX(-50%);
                &__title{
                    font-weight:800;
                    font-size:14px;
                }
                &__subtitle{
                    font-size:14px;
                }
            }
        }
        &__buttons{
            a, button{ margin-bottom: 10px;}
            & > div{
                text-align: center;
            }
            @include breakpoint(medium){
                & > div{
                    text-align: left;
                }

            }
            @include breakpoint(large){
                display: flex;
                gap:20px;
                align-items: center;
                & > div{
                    display: inline;
                }
                a, button{ margin: 0;}
                div > a { font-weight:bold; }
            }
            
        }
    }
    &--blue{
        .callout-section__container{
            background-color: $color-business-blue;
            &__title{
                color:white;
            }
            &__text{
                color:white;
            }
            &__buttons{
                & > div{
                    color:white;
                    a { color: white;}
                }
                a, button{ margin-bottom: 10px;}
                .cf-button--blue-alt{
                    border-color:white;
                    .cf-button__text{
                        color:white;
                    }
                    &:hover{
                        background-color: white;
                        .cf-button__text{
                            color:$color-business-blue;
                        }
                    }
                }
                @include breakpoint(large){
                    a, button{ margin: 0;}
                }
            }
        }


    }
    &.greybackground{
        &.bgbegin{
            background: linear-gradient(180deg, white 50%, #f8f8fa 50%);
        }
        &.bgend{
            background: linear-gradient(180deg, #f8f8fa  50%, white 50%);
        }
    }
}

.section{
    margin:50px 0px;
    @include breakpoint(large){
        margin:100px 0px;
    }
    &__title{
        font-size:20px;
        font-weight:800;
        @include header-text;
        @include breakpoint(large){
            font-size:35px;
            max-width: 750px;
        }
        margin-bottom:26px;
        &.centered{
            text-align: center;
            margin-left:auto;
            margin-right:auto;
        }
    }
    &__subtitle{
        font-size:1rem;
        margin-bottom:25px;
        @include breakpoint(large){
            font-size:1rem;
            max-width:60%;
            margin-bottom:55px;
        }
        margin-top:26px;

    }
    & > div.row > div.column{
        padding-left:2rem;
        padding-right:2rem;
    }
    &--grey{
        background-color: #F8F8FA;
        margin:50px 0px;
        padding:50px 0px;
        @include breakpoint(large){
            margin:100px 0px;
            padding:100px 0px;
        }
    }
    &--blue{
        background-color: $color-business-blue;
        margin:50px 0px;
        padding:50px 0px;
        @include breakpoint(large){
            margin:100px 0px;
            padding:100px 0px;
        }
        .section__title{
            color:white;
        }
        .section__subtitle{
            color:white;
        }
        .evenementform p { color: white; text-align: center;}
        #voorwaarden { color:white;}
    }
    &.nomargintop{
        margin-top:0px;
    }
    &.nomarginbottom{
        margin-bottom:0px;
    }
    &.category{
        .category-section__container__categories__card__container{
            .category-section__container__categories__shape{
                @include breakpoint(large){
                    right:-80px;
                }
    
            }
        }
        
    }
    &.trainingcards, &.reviewcards{
        .rf-cards-scroller-crop {
            height: 38.5rem;
            @include breakpoint(medium){
                height: 31.5rem;
            }
            @include breakpoint(large){
                height:33.5rem;
    
            }
        }
    
        .rf-ccard-40 .rf-ccard-content {
            width: 23rem;
            @include breakpoint(large){
                width: 25rem;
            }
        }
    }
}

.temptitle{
    font-size:26px;
    @include breakpoint(large){
        font-size:40px;
    }
}
.extraimgbox{
    box-shadow:  3px 6px rgba(0,0,0,0.16);
}
.calendly{
    &__content{
    }
    &__leftside{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__top{
            @include breakpoint(large){
                margin-top:35px;
            }
        }
        &__bottom{

        }
    }
    &__usps{
        ul{
            list-style-type: none;
            margin: 0;
            li{ 
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 10px;
                span{ font-weight: 500; }

            }
        }
        &__icon{
            img{ width: 20px; }
        }
        margin-bottom: 45px;
    }
    &__contact{
        .cta-block__intake__text__callout__content__item .icon{
            margin-right:10px;
            img{ width:20px; }
        }
        margin-bottom: 45px;
    }
    &__image{
        position: relative;
        img{  }
        &__labels{
            position: absolute;
            bottom:15px;
            display: flex;
            gap:12px;
            p{
                background: #1E225D 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                border-radius: 2px 2px 12px 2px;
                color:white;
                font-size:14px;
                padding:7px 10px;
                strong{ color:white; }

            }
        }
    }
    &__border{
        border-bottom: 1px solid $color-business-blue;
    }
    &__embed{
        @include breakpoint(large){
            padding-left:0px!important;
        }
        .calendly-inline-widget > iframe > html > body > div#root > div > div, ._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv {
            margin-top:33px!important;
        }
    }
}