.training-list-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  //align-items: start;
  grid-template-areas: 
    "left right"
	"content content"
	"bottom bottom";
  justify-items: start;
  background-color:white;
  border-radius:8px;

  box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
  @include transition(background 400ms, box-shadow 400ms);
	
  @include breakpoint(medium) {
    //height: 460px;
  }
  @include breakpoint(large){
	grid-template-areas: 
    "left right"
	"left right"
	"left right";
  }

  &, &:active, &:hover, &:focus {
    text-decoration: none;
    color: inherit;

	
	.cf-button__icon{
		margin-left:10px!important;
		margin-right:0px!important;
	}
  }

  &__favorite {
  	border-radius:25px;
  	width:110px;
  	height:30px;
  	background-color:white;
  	//padding:11px 11px 10px 10px;
  	
  	margin-top:10px;
  	box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
 	border: 1px solid $color-business-blue;
	display:flex;
	justify-content: center;
	align-items: center;
	&::after{
		content:"Bewaar";
		color:$color-business-blue;
		font-size:14px;
		font-family: 'lexend', sans-serif;
		padding-left:2px;
	}
  	&.selected{
 		//border: 1px solid #FF4F57;
  		&::after{
  			content:"Bewaard";
  		}
  		&.collection{
  			padding:5px 5px 5px 5px;
			&::after{
				content:"Verwijder";
			}
  		}
  		img.selected{
  			filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
  			display:block;
  			margin:5px!important;
			height:75%;
			padding-left:2px;
  		}
  		img.notselected{
  			display:none;
  			margin:5px!important;
			height:75%;
			padding-left:2px;
  		}
  		&.collection{
  			img.selected{
  				filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
  			}
  		}
  	}
  	&.notselected{
  		img.selected{
  			display:none;
  			margin:5px!important;
  			margin-left:0px !important;
			height:75%;
			width:unset!important;
  		}
  		img.notselected{
  			filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
  			display:block;
  			margin:5px!important;
  			margin-left:0px !important;
			height:75%;
			width:unset!important;
  		}
  	}
  	&:hover{
  		cursor:pointer;
  		&.notselected{
			img.notselected{
				filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
				transition:(all 400ms);
			}
		}
  	}
  }
	&__image {
		grid-column-start: 1;
		grid-row-start: 1;
		@include breakpoint(large){
			grid-column-start: 1;
			grid-row-start: 1;
			grid-row-end: 4;
		}
		//height: 175px;
		overflow: hidden;
		padding:14px;
		padding-right:0px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 4px;
			object-fit: cover;
		}
	}
	&__title {
		
		grid-column-start: 2;
		grid-row-start: 1;
		align-self: center;
		padding: 15px 15px 10px 15px;
		h3{
			color: $color-business-blue;
			@include header-text;
			font-weight: 800;
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 0px;
		}
	}
  &__content {
    font-size:0.9rem;
    //padding: 20px 15px;

	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 2;
	width:100%;

    @include breakpoint(large){
		grid-column-start: 2;
		grid-row-start: 2;
	}
    &__information {
    	width:100%;
    	margin:0px;
    	padding: 10px 14px;
    	@include breakpoint(medium) {
    		padding: 14px;
    		width:25%;
    		border-bottom:0px;
    	}
    }
    &__intro {
    	width: 100%;
    	padding: 10px 15px;
    	line-height: 1.35;
    	flex-grow: 1;
    	max-height: 10em;
		font-size: 15px;
      	margin: 0;
    	@include breakpoint(medium) {
    		padding: 15px;
    		//width: 45%;
    		border-bottom:0px;
    	}
  	}
  	&__action {
    	width: 100%;
    	padding: 10px 15px;
      	margin: 0;
      	border:0px;
    	@include breakpoint(medium) {
    		padding: 15px;
    		border-left:1px solid $color-medium-light-gray;
    		width: 30%;
    	}
    	
    	&__prijs {
    		line-height: 1.1;
    		display:block;
  		}

  		&__normaal {
  			display:block;
   			@include raleway;
    		font-size: rem-calc(16);
    		font-weight: 700;
    		color: $color-header;
  		}

  
  		&__kortingprijs {
  			display:block;
    		@include open-sans;
    		font-size: rem-calc(20);
    		color: $color-black;
    		font-weight: 700;
    		width: 100%;
  		}

  		&__uwkorting {
  			display:block;
    		font-size: rem-calc(14);
    		color: $color-black;
    		padding-bottom: 10px;
  		}
  
  		&__vanaf {
    		@include raleway;
    		font-size: rem-calc(20);
    		font-weight: 700;
    		color: $color-black;
    		display:inline-block;
    		margin-bottom:10px;
  		}

  		&__vanafprijs {
    		@include open-sans;
    		font-size: rem-calc(20);
    		color: $color-black;
    		font-weight: 700;
    		display:inline-block;
    		margin-bottom:10px;
  		}

  		&__korting {
    		font-weight: 700;
    		font-size: rem-calc(18);
    		color: $color-black;
    		padding-bottom: 3px;
    		margin-left: 1rem;
  		}
  
  		&__btw {
  			font-size: rem-calc(12);
  			color: slategray;
  			font-style:italic;
  		}
  	}
  }

	&__bottom{
		//align-self:flex-end;
		//border-top:1px solid #E3E3E3;
		display:flex;
		padding:14px;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 3;
		width:100%;
		
		@include breakpoint(medium) {
			padding:14px;
		}
		@include breakpoint(large){
		
			padding:14px;

			grid-column-start: 2;
			grid-column-end: 2;
			grid-row-start: 3;
		}
		@include breakpoint(xlarge){
		
			padding:14px;
		}
		justify-content:space-between;
		align-items:center;
		&__info {
			display:flex;
			align-items:center;
			flex-wrap: wrap;
			column-gap:10px;
			flex-basis: 80%;
			//margin-bottom:15px;
			& > div{
				display:flex;
				flex-basis: 48%;
				align-items:center;
				@include header-text;
				font-size:14px;
				@include breakpoint(xlarge){
				
				font-size:14px;
				}
				font-weight:400;
				column-gap:5px;
				color: $color-business-blue;
				& svg path{
				fill:#bababa;
				}
				img { width:14px; height:14px; filter: invert(70%) sepia(10%) saturate(367%) hue-rotate(199deg) brightness(94%) contrast(90%);}
				&.label {
					background-color:#FFF500;
					border-radius:100px;
					color:$color-business-blue;
				}
				&.nolabel{
					padding: 0.33333rem 0rem;
					line-height:1;
					
					/*&::after{
						content:"\2022";
						margin-left:5px;
					}*/
				}
			}
		}
		&__action{
			display:flex;
			align-items:center;
			align-self:flex-end;
		}
		&__view{
			font-size: 16px;
			color:$color-orange;
			font-weight:500;
			margin-right:10px;
			opacity:0;
			display:none;
			@include breakpoint(medium) {
				display:none;
			}
			@include breakpoint(large){
				display:none;
			}
			@include breakpoint(xlarge){
				//display:block;
				display:none;
			}
		}
		&__clusterview{
			color:$color-orange;
			margin-right: 10px;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight:500;
		}
	
	}
	&__action {
		margin-bottom:0px;
		border-radius:4px;
		@include transition(all 400ms);
		&.cf-button--orange-flat{
			height:23px;
			width:23px;
			padding:5px 0px;
			&:hover{
				.cf-button__icon{
					margin-left:10px!important;
					margin-right:0px!important;
				}
			}
		}
		svg{
			transform: scale(0.7);
		}
		.cf-button__icon{
			top:0px;
			line-height: 0px;
		  margin-left:5px!important;
		  margin-right:5px!important;
		}
		.cf-button__icon img{
			height:12px;
		}
  
		&:hover{
		  .cf-button__icon{
			  margin-left:10px!important;
			  margin-right:0px!important;
		  }
	  }
	}

	&--home {
		height: auto;
		@include breakpoint(large) {
			height: 460px;
		}
	}

  

  

  &--home {
    height: auto;
    @include breakpoint(large) {
      height: 460px;
    }
    .training-list-block {
		&__content {
		height: auto;
		@include breakpoint(large) {
			height: 460px - 175px;
		}
		}

      &__intro {
        margin: 0 0 1rem;
        @include breakpoint(large) {
          margin: 0;
        }
      }

    }
  }

  &:hover, &:focus, &:active {
    //background: $block-hover;
  	box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
    border-color: $block-hover-border;
	.clusterprijs{
		display:none;
	}
	
	.clusterprijsbottom{
		display:block;
	}

	.keuzehulp_cluster{
		top:0;
		background-color:transparent;
		border: 1px solid white;
		display:flex !important;
		&::after{
			color:white;
		}
		img{
			filter: invert(100%) sepia(4%) saturate(3%) hue-rotate(39deg) brightness(103%) contrast(101%);
		}
	}
    .training-grid-block {
		&__title {
			color: $color-orange;
			@include transition(all 400ms);
		}
		&__action {
			@include cf-button-orange-hover;
		}
		&__bottom{
			//&__view{
			//	opacity:1;
			//	@include transition(all 400ms);
			//}
		}
		//&__pricelabel{
		//	@include transition(all 400ms);
		//	opacity:1;
		//}
		&__image{
			&__overlay{
				width:100%;
				position:absolute;
				height:100%;
				opacity:1;
				//background-color:rgba(32, 42, 54, 0.5);
				//background: linear-gradient(155deg, rgba(0,0,0,0.2007177871148459) 0%, rgba(255,255,255,0) 100%);
				background: linear-gradient(to top left, transparent 50%, rgba(0, 0, 0, 0.8));
    			border-radius:4px 4px 0px 0px;
				@include transition(all 400ms);
			}
		}
    }
  }

}
.account-fav-filters{
	z-index:9999 !important;
	@include breakpoint(medium) {
		//height: 460px;
		z-index:7 !important;
	  }
}
.account__profile__content__saved-content-block{
	.training-list-block {
		@include breakpoint(medium) {
			//height: 460px;
			grid-template-columns: 1fr 5fr;
		  }
	}
}