.op-deze-pagina {

  &--mobile {
    .sidebar-block__title {
      margin-bottom: 0.3rem;
    }
  }

  &__menu {
    li {
      a {
        text-decoration: none;
        color: $color-business-blue;

        &.active, &:active {
          background: $color-darker-primary;
          font-weight: bold;
          color: $white;
        }

        &:hover {
          background: $color-darker-primary;
          color: $white;
        }
      }
    }
  }

  &__menu--mobile {
    li {
      a {
        display: block;
        padding: 0.4rem 0;
        border-bottom: 1px solid $color-darker-primary;
        &:hover {
          background: none;
        }
      }
    }
  }
}