.start {
	margin-top:-25px;    
	position: relative;
    z-index: 20;
	
	padding-bottom: 0px;
	/*padding-bottom: 20px;*/
	@include breakpoint(medium) {
		margin-top:-90px;
		/*padding-bottom: 1rem;*/
	}

	&__search {
		position: relative;
		z-index: 1;
	}

	&__search {
		margin: 0;

		@include breakpoint(medium) {
			margin: 0px 0 0;
		}
		
		&__text {
			color:white;
			padding:0.5rem 1rem;
			background: $color-business-blue;
		}
		
		&__bar {
			padding:1rem;
			background: $color-business-blue;
			
			@include breakpoint(large) {
				form {
					position: relative;
				}
			}
		}
	}
	
	&__reviews{
		margin-top:1rem;
		
		&__ratings{
			display:inline-block;
			padding:5px 14px;
			border-radius:10px;
			background:$color-blue-ultralight;
			font-weight:600;
			&__star-full{
				display:inline-block;
				background:url(/images/starfull.png);
				background-repeat:no-repeat;
				background-position:top-left;
				height:15px;
				width:15px;
				background-size:cover;
				margin-right:5px;
			}
			&__text{
				display:inline-block;
				margin-left:5px;
				span{
					color:white;
					strong { color: white;}
				}
    			@include breakpoint(small only) {
    				display:block;
    				margin-top:5px;
    			}
			}
		}
	}
	&__reviews > .column {
		text-align:center;
		background-color:#fff;
		padding:20px;
		padding-bottom:0px;
		@include breakpoint(large) {
			/*text-align:left;*/
			padding-left:50px;
			padding-right:50px;
		}
		& > .review-container {
			padding-bottom: 20px;
			border-bottom:1px dashed #000;
		}
	}
	&__faq {
		font-size:0.5rem;
		color:$color-light-gray;
		margin:2rem 0 0;
		text-align:center;
	}
}

.start-alumni{
	background:white;
	padding:2rem 0; 
	&__text{
		color: $color-blue;
		text-align: center;
		font-weight: 700;
		line-height: 1.25;
		font-size:1.2rem;
		padding-bottom:2rem; 
	}
	&__logo {
		text-align:center;
	}
	.slick-dots li button:before {
		font-size:1.7rem;
		color:black;
	}
}

.usps {
	border-top:1px solid $color-medium-gray;
	border-bottom:1px solid $color-light-gray;
	&__row{
		&__column{
			border-bottom:1px dashed $color-medium-gray;
			@include breakpoint(medium) {
				border-right:1px dashed $color-medium-gray;
				border-bottom:0px;
			}
			&__last{
				border-bottom:0px;
				@include breakpoint(medium) {
					border-right:0px;
				}
			}
		}
		&__benefit{
			padding:15px 0px;
			margin-bottom:0px;
			&__greencheckmark{
				
			}
			&__text{
				display:inline-block;
				background:url(/images/greencheckmark.jpg);
				background-repeat:no-repeat;
				background-position:0px 5px;
				background-size:25px 25px;
				padding-left:40px;
				min-height: 35px;
    			padding-top: 5px;
			}
		}
	}
}

.lastminutes {
	background:$color-business-blue;
	padding-bottom:2.5rem;
	&__title{
		width: 100%;
    	color: white;
    	font-weight: 900;
    	font-size: 1.6rem;
   		line-height: 1.6;
    	margin: 25px 0px;
    	text-align:center;
	}
	&__row {
		display:flex;
		flex-wrap:wrap;
      	
	}
}

.ervaring{
	background: 
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
      rgba(29,33,94,0.9), 
      rgba(29,33,94,0.9)
    ),
    /* bottom, image */
    url('/images/ervaringbackground-smaller.jpg');
    padding:3rem 0;
	&__title{
		text-transform:uppercase;
		text-align: center;
		color: white;
		font-weight: 800;
		line-height: 1.25;
		font-size:1.6rem; 
		margin-bottom:50px;
	}
	&__testimonial{
		&:nth-child(n+2){
			display:none;
			@include breakpoint(medium) {
				display:block;
			}
		}
		&__tekstbubbel{
			&__tekst{
    			@include raleway;
				background:white;
				border-radius: 8px;
				padding:30px;
				padding-left:50px;
				&::before{
					content:'“';
    				@include raleway;
    				font-size:4rem;
    				font-weight:800;
    				color:#4B4B4B;
    				position:absolute;
    				margin-left:-40px;
    				margin-top:-30px;
    				
				}
			}
			&__hoekje{
				width:0px;
				height:0px;
				border-top:solid 20px white;
				border-right:solid 20px transparent;
				border-bottom:solid 20px transparent;
				border-left:solid 20px white;
				margin-left:20%;
				//-ms-transform: rotate(40deg); /* IE 9 */
  				//-webkit-transform: rotate(40deg); /* Safari */
  				//transform: rotate(40deg);
			}
		}
		&__foto{
			padding:15px 0px;
		}
		&__naam{
    		@include raleway;
    		color:white;
    		font-size:1.3rem;
    		font-weight:800;
    		line-height:1.1;
    		margin:0px;
		}
		&__titel{
			@include raleway;
    		color:white;
    		font-size:1.1rem;
    		font-weight:700;
    		line-height:1.1;
		}
		&__cursus{
    		@include raleway;
    		color:white;
    		font-size:0.9rem;
    		font-weight:regular;
    		line-height:1.1;
    		margin:0;
		}
		&__cijfer{
    		@include raleway;
    		color:white;
    		font-size:0.9rem;
    		font-weight:700;
    		line-height:1.1;
    		&__ster{
				display:inline-block;
				background:url(/images/starfull.png);
				background-repeat:no-repeat;
				background-position:top-left;
				height:10px;
				width:10px;
				background-size:cover;
				margin-right:10px;
				margin-left:8px;
    			
    		}
		}
	}
	&__button{
		margin-top:40px;
		text-align:center;
		margin-bottom:-60px;
		@include breakpoint(medium) {
			margin-bottom:-70px;
		}
	}
}

.about-cf{
	background:$color-ultralight-gray;
	&__title{
		font-size:1.6rem;
		font-weight:bold;
		margin-bottom:5px;
	}
	&__subtitle{
		font-size:1.3rem;
	}
	
	&__paragraphtitle{
		font-size:1.2rem;
		font-weight:bold;
	}
}