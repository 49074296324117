.facebook-follow {
  background: $facebook-background;
  color: $facebook-color;
  padding: 25px;

  @include breakpoint(large) {
    width: 375px;
    float: right;
  }

  &__title {
    color: $facebook-color;

    strong {
      text-transform: lowercase;
      font-size: 110%;
    }
  }

  &__text {
    font-size: rem-calc(17);
  }

  &__widget {
    width: 100%;
  }
}