.admin-boekingtable {
  font-size: rem-calc(13px);
  line-height: 1.2;
  border: 1px solid $color-line;

  &__id {
    text-align: left;
  }

  &__row {
    cursor: pointer;
  }
  &__row--afgerond {
    background: lighten($success-color, 34%) !important;
    &-striped {
      background: lighten($success-color, 38%) !important;
    }
  }
  &__row--in_checkout {
    background: lighten($warning-color, 37%) !important;
    &-striped {
      background: lighten($warning-color, 41%) !important;
    }
  }
  &__row--wacht_op_betaling {
    background: lighten($alert-color, 28%) !important;
    &-striped {
      background: lighten($alert-color, 32%) !important;
    }
  }
  &__row--optie {
    background: #e8e8e8;
    &-striped {
      background: #f3f3f3;
    }
  }

  &__details {
    display: none;
    &.visible {
      display: table-row;
    }
  }

  &__details--afgerond {
    background: lighten($success-color, 41%) !important;
  }
  &__details--in_checkout {
    background: lighten($warning-color, 45%) !important;
  }
  &__details--wacht_op_betaling {
    background: lighten($alert-color, 38%) !important;
  }
  &__details--optie {
    background: #fafafa !important;
  }
}