.updates{
	&__latest{
		margin:35px 0px;
		margin-top:85px;
		@include breakpoint(medium){
			margin:35px 0px;
		}
	}
	&__item{
		margin-bottom:30px;
		&:last-of-type{
			margin-bottom:0px;
		}
		&__date{
			&__tab{
				background-color:$color-blue;
				color:white;
				border:1px solid $color-blue;
				border-bottom:0px;
				border-radius: 8px 8px 0px 0px;
				padding-top:5px;
				padding-bottom:5px;
				text-align:center;
				&.latest{
					border-radius: 8px 0px 0px 0px;
					text-align:center;
				}
				@include breakpoint(large){
					max-width:17%;
					flex: 0 0 17%;
				
				}
			}
			&__showall{
				border:1px solid $color-blue;
				border-bottom:0px;
				border-radius: 0px 8px 0px 0px;
				padding-top:5px;
				padding-bottom:5px;
				text-align:center;
				@include breakpoint(large){
				
					max-width:17%;
					flex: 0 0 17%;
				}
			}
		}
		&__text{
			border:1px solid $color-blue;
			border-radius: 0px 8px 8px 8px;
			box-shadow: 0px 3px 10px rgba(42,62,82,0.129);
			padding:15px;
			&__container{
				border-top:1px solid #E3E3E3;
				padding-top:15px;
				@include breakpoint(medium){
					padding-top:0px;
					border:none;
				}
			}
			&__timeslot{
				@include breakpoint(medium){
					border-right:1px solid #E3E3E3;
				}
				&__title{
					display:flex;
					align-items:center;
					margin-bottom:12px;
					&__icon{
						height:39px;
						margin-right:12px;
						padding: 5px 0px;
					}
					&__text{
						@include header-text;
						font-size:rem-calc(18);
					}
				}
				&__text{
				
				}
				&__button{
				}
			}
			&__title{
				display:flex;
				align-items:center;
				margin-bottom:12px;
				&__icon{
					width:39px;
					height:39px;
					border-radius: 100px;
					background: #FFF500 0% 0% no-repeat padding-box;
					margin-right:12px;
					padding: 5px 7px;
				}
				&__text{
					@include header-text;
					font-size:rem-calc(18);
				}
			}
			&__text{
			
			}
		}
	
	}

}