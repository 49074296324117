@mixin cf-button-hover {
  background: darken($color-orange, 6%);
  color: #fff;
  .cf-button__icon {
    margin: -2px 15px 0 0;
    @include transition(margin 400ms, box-shadow 400ms);
  }
}

@mixin meerweergeven-button-hover{
	span{
		margin-top: 20px;
    	@include transition(margin 400ms);
	}
}
@mixin cf-button-altblue-hover {
	background: darken($color-business-blue, 6%)!important;
	border-color: darken($color-business-blue, 6%);
	color: #fff;
	//028384
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}
@mixin cf-button-altwhite-hover {
	background: white!important;
	color: $color-business-blue;
	//028384
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}

@mixin cf-button-yellow-hover {
	background: transparent;
	border:1px solid #f7fb00;
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}
@mixin cf-button-yellow-alt-hover {
	background: transparent;
	border:1px solid #f7fb00;
	color:black;
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}


@mixin cf-button-orange-hover {
  	background: #ffac13;
	//background: transparent;
	color:white;
    padding: 13px 20px;
    padding-right:0px; 
	border:1px solid #ffac13;
	.cf-button__icon {
    	margin: 0px 10px 0 20px;
		@include transition(all 400ms);
	  }
	&.iconleft{
		padding-right:20px;
		.cf-button__icon {
			margin:0px 20px 0px 0px;
		}
	}
	&.noicon{
		padding-right:20px;
	}
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }
	&.small{
  		padding:5px 0px;
  		.cf-button__icon {
			margin-left:10px;
			margin-right:0px;
		}
	}
	&.smaller{
  		padding:10px 15px;
	}
	&.textcentered{
		padding-right:25px; 
		.cf-button__icon {
			margin-left:30px;
			margin-right:0px;
		}

	}
	
	@include transition(all 400ms);
}
@mixin cf-button-orange-alt-hover {
	background: $color-orange;
	border:1px solid $color-orange;
	color:white;
    padding-right:0px; 
	.cf-button__icon {
		margin: 0px 10px 0 20px;
		path{
			fill:white;
		}
		@include transition(margin 400ms, box-shadow 400ms);
	  }
	&.iconleft{
		padding-right:20px;
		.cf-button__icon {
			margin:0px 20px 0px 0px;
		}
	}
	&.noicon{
		padding-right:20px;
	}
	  
	@include transition(background 400ms);
}
@mixin cf-button-orange-link-hover {
	background: transparent;
	color: $color-orange;
	.cf-button__text{
		color: $color-orange;
		text-decoration:underline;
		@include transition(text-decoration 400ms);
	}
	.cf-button__icon:not(.left) {
		margin: 0px 0px 0px 15px;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}

@mixin cf-button-green-hover {
	background: darken(#02ABAC, 6%)!important;
	border-color: darken(#035B5B, 6%);
	color: #fff;
	//028384
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}
@mixin cf-button-red-hover {
	background: darken(#ff5055, 6%)!important;
	border-color: darken(#d64446, 6%);
	color: #fff;
	//028384
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}

@mixin cf-button-transparent-hover {
	background: #fff!important;
	color: #000!important;
	//028384
	.cf-button__icon {
		margin: -2px 15px 0 0;
		@include transition(margin 400ms, box-shadow 400ms);
	  }
}

@mixin cf-button-alt-hover {
  background: $color-orange; 
  color: #fff;
  .cf-button__icon {
    margin: -2px 15px 0 0;
    @include transition(margin 400ms, box-shadow 400ms);
  }
}

@mixin cf-button-blue-hover {
	background: white;
	color: $color-business-blue-dark;
    padding: 13px 20px;
    padding-right:0px; 
    .cf-button__text{
  		color: $color-business-blue-dark;
    }
  	@include transition(all 400ms, background 400ms);
	.cf-button__icon {
    	margin: 0px 10px 0 30px;
		@include transition(all 400ms);
  		color: $color-business-blue-dark;
		img{
			filter: invert(12%) sepia(43%) saturate(3297%) hue-rotate(224deg) brightness(85%) contrast(95%);
		}

		.fas { color:$color-business-blue-dark; }
		svg path{ fill:$color-business-blue-dark;}
	  }
	&.iconleft{
		padding-right:20px;
		.cf-button__icon {
			margin:0px 20px 0px 0px;
		}
	}
	&.noicon{
		padding-right:20px;
	}
	&.onlyicon{
  		.cf-button__icon {
  			margin-left:9px;
  			margin-right:3px;
  		}
    }
	&.small{
  		padding:5px 0px;
	}
}

@mixin cf-button-blue-alt-hover {
	background: $color-business-blue-dark;
	border-color: darken($color-business-blue-dark, 6%);
	color: #fff;
	//028384
  	@include transition(all 400ms, background 400ms);
    padding-right:0px; 
	.cf-button__text {
		color:white;
	}
	.cf-button__icon {
    	margin: 0px 10px 0 20px;
		@include transition(all 400ms);
		color:white;
		path{
			fill:white;
		}
		img{
			filter: invert(95%) sepia(95%) saturate(19%) hue-rotate(337deg) brightness(106%) contrast(106%);
		}
	  }
	&.iconleft{
		padding-right:20px;
		.cf-button__icon {
			margin:0px 20px 0px 0px;
		}
	}
	&.noicon{
		padding-right:20px;
	}
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }&.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }
  	&.outline{
  		background:transparent;
  		border:none;
		.cf-button__text {
  			color:$color-business-blue;
  			text-decoration:underline;
  		}
  		.cf-button__icon {
		color:$color-business-blue;;
		path{
			fill:$color-business-blue;;
		}
		img{
			filter: unset;
		}
	  }
  	}
  	
	&.small{
  		padding:5px 0px;
	}

	&.textcentered{
		padding-right:20px; 
		.cf-button__icon {
			margin-left:30px;
			margin-right:0px;
		}

	}
}

@mixin cf-button-noborder-hover {
	//background: $color-business-blue-dark;
	//border-color: darken($color-business-blue-dark, 6%);
	//color: #fff;
	//028384
  	@include transition(all 400ms, background 400ms);
    padding-right:0px; 
	.cf-button__text {
		//color:white;
		text-decoration: underline;
	}
	.cf-button__icon {
    	margin: 0px 10px 0 30px;
		@include transition(all 400ms);
		//color:white;
		path{
			//fill:white;
		}
		img{
			//filter: invert(95%) sepia(95%) saturate(19%) hue-rotate(337deg) brightness(106%) contrast(106%);
		}
	  }
	&.iconleft{
		padding-right:20px;
		.cf-button__icon {
			margin:0px 20px 0px 0px;
		}
	}
	&.noicon{
		padding-right:20px;
	}
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }&.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }
  	&.outline{
  		background:transparent;
  		border:none;
		.cf-button__text {
  			color:$color-business-blue;
  			text-decoration:underline;
  		}
  		.cf-button__icon {
		color:$color-business-blue;;
		path{
			fill:$color-business-blue;;
		}
		img{
			filter: unset;
		}
	  }
  	}
  	
	&.small{
  		padding:5px 0px;
	}
}

@mixin cf-button-clean-hover{
  	@include transition(all 400ms, background 400ms);
    padding-right:0px;
	.cf-button__text {
		text-decoration:underline;
	}
	.cf-button__icon {
    	margin: 0px 10px 0 30px;
		@include transition(all 400ms);
    	
	  }
	&.iconleft{
	
	}
	&.noicon{
		padding-right:20px;
	}
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:17px;
  			margin-right:3px;
  		}
    }
	&.small{
	}
}

@mixin cf-button-white-alt-hover {
	background: white;
	color: $color-business-blue!important;
	//028384
	padding-right:10px;
	@include transition(color 400ms, background 400ms, padding 400ms);
	.cf-button__icon {
		margin: 0px 0px 0px 30px;
		img{
			filter:none;
		}
		@include transition(margin 400ms, box-shadow 400ms, filter 400ms);
	}
	.cf_button__text{
		color: $color-business-blue!important;
	}
	&.lefticon{
		padding-right:20px;
		padding-left:10px;		
		.cf_button__text{
			color: $color-business-blue!important;
		}

		.cf-button__icon {
			margin: 0px 30px 0 0;
			@include transition(margin 400ms, box-shadow 400ms, filter 400ms, padding 400ms);
		}
		
	}
	&.noicon{
		padding-right:20px;
		.cf_button__text{
			color: $color-business-blue!important;
		}
	
	}
}

@mixin cf-button-simple-hover {
  background: none;
  text-decoration: none;
  color:$color-black;
  .cf-button__icon {
    margin: -2px 15px 0 0;
    @include transition(margin 400ms, box-shadow 400ms);
  }
}

@mixin cf-button-padding-hover {
  background: darken($color-orange, 6%);
  color: #fff;
  .cf-button__icon {
    margin: -2px 15px 0 5px;
    @include transition(margin 400ms, box-shadow 400ms);
  }
}

@mixin cf-button-top10-hover {
	.cf-button__icon, .homebutton-icon {
	  margin: 0px 5px 0px 15px;
	  @include transition(margin 400ms, box-shadow 400ms);
	}
  }

.cf-button, button.cf-button {
  @include custom-button-base;
  border-radius:5px;
  display: flex;
  background: white;
  border:2px solid $color-orange;
  color: $color-black;
  text-decoration: none;
  height: 42px;
  cursor:pointer;
  &.extralabel::after{content: "VOL!";
    color: black;
    background-color: yellow;
    padding: 5px 50px;
    transform: rotate(22deg);
    font-weight: 800;
    position: absolute;
    font-size: 1.4rem;
    margin-left: -130px;
    margin-top: -20px;
  	
  }
  @include breakpoint(medium) {
    //margin: 0 0 0 4px;
  }

  .cf-button__text {
    @include raleway;
    font-weight: bold;
    flex-grow: 1;
    align-self: center;
    margin: -2px 30px 0 20px;
    @include breakpoint(medium) {
      margin: -2px 30px 0 15px;
    }
	@include header-text;
	letter-spacing: -0.1px;
  }

  .cf-button__icon {
    font-size: 140%;
    align-self: center;
    margin: -2px 20px 0 0;

    @include breakpoint(medium) {
      margin: -2px 20px 0 0;
    }
  }
  
  &.fullwidth{
      width: 90%;
  }
  &.shadow {
  	box-shadow: 0 4px 4px 0 #022E2E; 
  	border-bottom:0px;
  }

  &:hover, &:active, &:focus {
    @include cf-button-hover;
  }
  &.selected{
  	background-color:$color-orange;
  	color:white; 
  }
  &--yellow-flat {
  	background: #f7fb00;
  	border:1px solid #f7fb00;
  	border-radius:0px;
    color:black;
    padding: 10px 10px;
  	display:inline-block;
  	height:inherit!important;
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-yellow-hover;
    }
    @include breakpoint(medium) {
    	padding:15px 20px;
    }
  }
  
  &--yellow-flat-alt {
  	background: #f7fb00;
  	border:1px solid #f7fb00;
  	border-radius:0px;
    color:black;
    padding: 10px 10px;
  	display:inline-block;
  	height:inherit!important;
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-yellow-alt-hover;
    }
    @include breakpoint(medium) {
    	padding:15px 20px;
    }
  }
  &--savelater{
  	border:1px solid $color-business-blue-dark;
  	border-radius:100px;
  	padding:13px 20px;
    font-weight:normal;
  	display:inline-block;
  	.cf_button__text{
  		color:$color-business-blue-dark;
  	}
  	.cf-button__icon{
  		margin-right:10px;
  	}
  }
  &--orange-flat {
	background: $color-orange;
  	border:1px solid $color-orange;
  	border-radius: 8px;
    color:white;
    padding: 13px 20px;
    font-weight:bold;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	margin-bottom:10px;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
  	.cf-button__text{
  		margin:0px;
  		text-decoration:none;
		position: relative;
		line-height: normal;
		color:white;
		@include breakpoint(medium){
			top:unset;
		}
		@include header-text;
		letter-spacing: -0.1px;
  	}
  	.cf-button__icon { 
		position: relative;
		margin-left:10px;
		path {
			fill:white;
		}
		img{
			filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(351deg) brightness(101%) contrast(101%);
			height:19.5px;
		}
  	}
	.cf-button__icon.image{
		top:0;
	}
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:10px;
  		}
    }
    &.noicon{
    	padding-right:20px;
    }
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:10px;
  			margin-right:10px;
  		}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.fullwidth{
    	display:flex;
    	justify-content: space-between;
    	width:100%;
    }
    &.iconleft.fullwidth{
    	justify-content:center;
    	align-items:center;
    }
    &.small{
  		padding:5px 0px; 
  		//display:inline;
  		width:inherit;
  		.cf-button__icon {
  			margin-left:5px;
  			margin-right:5px;
  		}
    }
    &.smaller{
    	padding:10px 15px; 
  		//display:inline;
  		width:inherit;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
	&.textcentered{
		.cf-button__text{
			display: inline-block;
			width:100%;
			text-align: center;
		}
		.cf-button__icon {
			margin-left:20px;
			margin-right:0px;
		}
	}
    &:hover, &:active, &:focus {
    	@include cf-button-orange-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--orange-flat-alt {
  	background: transparent;
  	border:1px solid $color-orange;
  	border-radius: 8px;
    color:white;
    padding: 13px 20px;
    font-weight:bold;
  	display:inline-block;
  	display: inline-flex;
  	text-decoration:none;
  	margin-bottom:10px;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
  	.cf-button__text{
		position: relative;
		@include breakpoint(medium){
			top:unset;
		}
		@include header-text;
		letter-spacing: -0.1px;
  	}
  	.cf-button__icon{
  		margin-left:10px;
  		margin-right:20px;
		path{
			fill:$color-orange;
		}
		img{
			filter: invert(52%) sepia(93%) saturate(1215%) hue-rotate(2deg) brightness(106%) contrast(104%);
			height:19.5px;
		}
  	}
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.noicon{
    	padding-right:20px;
    	
		.cf-button__text{
			top:unset;
		}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.orangetext{
    	color:$color-orange;
    }
    &.fullwidth{
    	justify-content: space-between;
    	width:100%;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-orange-alt-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--orange-link-button {
  	background: transparent;
  	border:1px solid transparent;
  	border-radius: 8px;
    color:$color-orange;
    padding: 15px 20px;
  	display:inline-block;
  	height:inherit!important;
  	margin-bottom:10px;
  	.cf-button__text{
		position: relative;
		top: -0.125rem;
    	color:$color-orange;
		@include header-text;
		letter-spacing: -0.1px;
		@include breakpoint(medium){
			top:unset;
		}
  	}
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.fullwidth{
    	justify-content: space-between;
    	width:100%;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-orange-link-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--orange-link {
  	border-radius:0px;
    color:$color-orange;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
    .cf-button__text{ 
    	margin-right:10px;
    	margin-left:0px;
    	color:$color-orange;
		@include header-text;
		letter-spacing: -0.1px;
    }
    .cf-button__icon{
    	color:$color-orange;
    	path{
    		fill:$color-orange; 
    	}
    } 
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-orange-link-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--orange-tertiary {
  	border-radius:0px;
    color:$color-orange;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	cursor:pointer;
    .cf-button__text{ 
    	margin-right:10px;
    	margin-left:0px;
    	font-weight:600;
    	color:$color-orange;
		@include header-text;
		letter-spacing: -0.1px;
    	//text-decoration:underline;
    }
    .cf-button__icon{
    	color:$color-orange;
    	svg{
    		width:16px;
    		height:16px;
    	}
    	path{
    		fill:$color-orange; 
    	}
		img{
			filter: invert(76%) sepia(73%) saturate(2862%) hue-rotate(342deg) brightness(103%) contrast(102%);
			height: 19.5px;
		}
		&.left{
			margin-right:10px;
		}
    } 
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-orange-link-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--blue {
	background: $color-business-blue-dark;
  	border:1px solid $color-business-blue-dark;
  	border-radius: 8px;
    color:white;
    //box-shadow: 0px 7.5px 15px 0px rgba(27, 42, 104, 0.25);
    padding: 13px 20px;
  	text-decoration:none;
  	font-weight:bold;
  	margin-bottom:10px;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width: initial;
    }
  	.cf-button__text{
  		margin:0px;
		position: relative;
		color:white;
		@include header-text;
		letter-spacing: -0.1px;
		@include breakpoint(medium){
			top:unset;
		}
  	}
  	.cf-button__icon { 
		position: relative;
		margin-right:0px;
		margin-left:20px;
		color:white;
		.fas { color:white; }
		svg path{ fill:white;}
		img{
			filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(351deg) brightness(101%) contrast(101%);
			height: 19.5px;
		}
  	}
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.onlyicon{
  		.cf-button__icon {
  			margin-left:6px;
  			margin-right:6px;
  		}
    }
    &.noicon{
    	padding-right:20px;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.fullwidth{
    	display:flex;
    	justify-content: space-between;
    	width:100%;
    }
    &.iconleft.fullwidth{
    	display:flex;
    	justify-content:center;
    	align-items:center;
    }
    &.alignleft{
    	justify-content:flex-start!important;
    	float:none;
		display:flex;
    }
    
    &.small{
  		padding:5px 0px; 
  		//display:inline;
  		width:inherit;
    }
    &.shadow{
    	box-shadow: 0px 7.5px 15px 0px rgba(27,42,104,0.25);
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-blue-hover;
    }
    @include breakpoint(medium) {
  		margin-bottom:0px;
    }
  }
  
  &--blue-alt {
	background: transparent;
  	border:1px solid $color-business-blue-dark;
  	border-radius: 8px;
    color:$color-business-blue-dark;
    //box-shadow: 0px 7.5px 15px 0px rgb(27, 42, 104, 0.25);
    padding: 13px 20px;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	font-weight:bold;
  	margin-bottom:10px;
  	cursor:pointer;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
	&.greybrdr{
		border:1px solid #CCCCCC;
	}
  	.cf-button__text{
  		margin:0px;
		position: relative;
		@include header-text;
		letter-spacing: -0.1px;
		@include breakpoint(medium){
			top:unset;
		}
  	}
  	.cf-button__icon { 
		position: relative;
		margin-right:0px;
		margin-left:20px;
		path{
			fill:$color-blue;
		}
		img{
			filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
			height:19.5px;
		}
  	}
    &.noicon{
    	padding-right:20px;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.fullwidth{
    	display:flex;
    	justify-content: space-between;
    	width:100%;
    	align-items: center;
    }
    &.iconleft.fullwidth{
    	justify-content:center;
    	align-items:center;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
	&.textcentered{
		.cf-button__text{
			display: inline-block;
			width:100%;
			text-align: center;
		}
		.cf-button__icon {
			margin-left:20px;
			margin-right:0px;
		}
	}
    &:hover, &:active, &:focus {
    	@include cf-button-blue-alt-hover;
    }
    @include breakpoint(medium) {
			margin-bottom:0px;
	}
  }

  &--noborder {
	background: transparent;
  	border:1px solid white;
  	border-radius: 8px;
    color:$color-business-blue-dark;
    //box-shadow: 0px 7.5px 15px 0px rgb(27, 42, 104, 0.25);
    padding: 13px 20px;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	font-weight:bold;
  	margin-bottom:10px;
  	cursor:pointer;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
  	.cf-button__text{
  		margin:0px;
		position: relative;
		@include breakpoint(medium){
			top:unset;
		}
  	}
  	.cf-button__icon { 
		position: relative;
		margin-right:0px;
		margin-left:20px;
		path{
			fill:$color-blue;
		}
		img{
			filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
			height:19.5px;
		}
  	}
    &.noicon{
    	padding-right:20px;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.fullwidth{
    	display:flex;
    	justify-content: space-between;
    	width:100%;
    	align-items: center;
    }
    &.iconleft.fullwidth{
    	justify-content:center;
    	align-items:center;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-noborder-hover;
    }
    @include breakpoint(medium) {
			margin-bottom:0px;
	}
  }
  
  
  &--blue-flat-alt {
  	background: transparent;
  	border:1px solid $color-blue;
  	border-radius: 8px;
    color:$color-blue;
    padding: 15px 20px;
    //padding-right:0px;
  	display:inline-block;
  	display: inline-flex;
  	text-decoration:none;
  	font-weight:600;
  	display: flex;
    width: 100%;
    cursor:pointer;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
	&.greybrdr{
		border:1px solid #CCCCCC;
	}
	.cf-button__text{
		@include header-text;
		letter-spacing: -0.1px;
	}
  	.cf-button__icon{
  		margin-left:10px;
  		margin-right:20px;
		path{
			fill:$color-blue;
		}
		img{
			filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(74deg) brightness(106%) contrast(101%);
			height:19.5px;
		}
  	}
    &.noicon{
    	padding-right:20px;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
  	&.lefticon{
    	.cf-button__icon{
			top: .125em;
			position: relative;
			margin-right:20px;
			margin-left:0px;
    	
    	}
    	.cf-button__icon.image{
    		top:0;
    	}
  	
  	}
  	&.outline{
  		border:none;
  		padding-left:0px;
  		@include breakpoint(medium){
  			padding-left:20px;
  		}
  	}
    &.whitebg{
    	background-color:white;
    }
    &.shadow{
    	box-shadow: 0px 3px 10px rgba(42,62,82,0.129);
    }
	&.nopaddingleftright{
		padding-left:0px;
		padding-right:0px;
	}
    &.fullwidth{
    	justify-content: space-between;
    	width:100%;
    	display:flex;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-blue-alt-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--clean {
	background: transparent;
  	border:none;
    color:$color-business-blue-dark;
    padding: 16px 0px;
    padding-right:11px;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	font-weight:bold;
  	cursor:pointer;
  	display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
    &.border{
    	border-bottom:1px solid $color-business-blue-dark;
    }
  	.cf-button__text{
  		margin:0px;
		position: relative;
		@include breakpoint(medium){
			top:unset;
		}
		@include header-text;
		letter-spacing: -0.1px;
  	}
  	.cf-button__icon { 
		position: relative;
		margin-right:0px;
		margin-left:20px;
		path{
			fill:$color-blue;
		}
		img{
			filter: invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
			height:19.5px;
		}
  	}
    &.noicon{
    	padding-right:0px;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.iconleft{
  		.cf-button__icon { 
  			margin-left:0px;
  			margin-right:20px;
  		}
    }
    &.fullwidth{
    	display:flex;
    	justify-content: space-between;
    	width:100%;
    	align-items: center;
    }
    &.iconleft.fullwidth{
    	justify-content:center;
    	align-items:center;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-clean-hover;
    }
    @include breakpoint(medium) {
    }
  }
  
  &--white-alt {
	background: transparent;
  	border:2px solid white;
  	border-radius: 8px;
    color:white;
    //box-shadow: 0px 7.5px 15px 0px rgb(27, 42, 104, 0.25);
    padding: 15px 20px;
  	display:inline-block;
  	height:inherit!important;
  	text-decoration:none;
  	font-weight:bold;
  	margin-bottom:10px;
  	cursor:pointer;
  	display: inline-flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium){
  		display:inline-block;
  		width:inherit;
    }
  	.cf-button__text{
  		margin:0px;
		position: relative;
		top: -0.125rem;
		color:white;
		@include breakpoint(medium){
			top:unset;
		}
		@include header-text;
		letter-spacing: -0.1px;
  	}
  	.cf-button__icon { 
		top: .125em;
		position: relative;
		margin-right:0px;
		margin-left:20px;
		img{
			filter: invert(100%) sepia(2%) saturate(9%) hue-rotate(351deg) brightness(101%) contrast(101%);
		}
  	}
	.cf-button__icon.image{
		top:0;
	}
  	&.lefticon{
    	.cf-button__icon{
			top: .125em;
			position: relative;
			margin-right:20px;
			margin-left:0px;
    	
    	}
    	.cf-button__icon.image{
    		top:0;
    	}
  	
  	}
    &.noicon{
    	//padding-right:0px!important;
    	display:inline-block;
    	//padding-top:7px;
    	.cf-button__text{
    		margin:0px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.fullwidth{
    	justify-content: space-between;
    	width:100%;
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-white-alt-hover;
    }
    @include breakpoint(medium) {
    }
  }  
  &--green {
  	background: #02ABAC;
  	border:0px;
  	border-bottom:3px solid #035B5B;
    color:white;
    .cf-button__text{
    	color:white;	
    }
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 20px;
    		text-align: center;
    		display:inline-block;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat; 
    }
    &:hover, &:active, &:focus {
    	@include cf-button-green-hover;
    }
  }
  
  &--red {
  	background: #ff5055;
  	border:0px;
  	border-bottom:3px solid #d64446;
    color:white;
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    &.loading{
    	background:#aad3d3 url('/images/ajax-loader.gif');
    	background-position:center center;
    	background-repeat:no-repeat;
    }
    &:hover, &:active, &:focus {
    	@include cf-button-red-hover;
    }
  }
  
  &--transparent {
  	background: transparent;
  	color: white;
  	border:2px solid white;
  	&:hover, &:active, &:focus {
    	@include cf-button-transparent-hover;
    }
  }
  
  &--orange {
    background: $color-orange;
    color:white;
    
	.cf-button__text{
    	color:white;
	}
    &.noicon{
    	padding-right:0px!important;
    	display:inline-block;
    	padding-top:7px;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    		color:white;
    	}
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    @include breakpoint(medium) {
    }

    &:hover, &:active, &:focus {
      @include cf-button-alt-hover;
    }
  }

  &--alt {
    background: $color-orange;
    color:white;
    @include breakpoint(medium) {
    }
	span { color:white; }
	svg path { fill: white; }

    &:hover, &:active, &:focus {
      @include cf-button-alt-hover;
    }
  }
  &--dark-background {
  	background:none;
  	color:$color-white;
  	&:hover, &:active, &:focus {
      @include cf-button-alt-hover;
    }
  }
  &--simple {
    border:none;
    background:none;
    border-radius:none;
    text-decoration:underline;
    //color:white;
    .cf-button__text{
    	font-weight:500;
    }
    &.noiconfullwidth{
    	padding-right:0px!important;
    	width: 100%;
    	.cf-button__text{
    		margin:0 30px;
    		text-align: center;
    	}
    }
    @include breakpoint(medium) {
    }

    &:hover, &:active, &:focus {
      @include cf-button-simple-hover;
    }
  }
  &--padding{
  	display:inline-block;
  	padding:7px 10px;
  	height:0px;
  	min-height:42px;
  	.cf-button__text{
  		padding-top:2px;
  		display:inline-block;
  		vertical-align: top;
    	margin: -2px 10px 0 5px;
   	    @include breakpoint(medium) {
      		margin: -2px 30px 0 15px;
    	}
  	}
  	.cf-button__icon{
  		padding-top:2px;
  		display:inline-block;
    	margin: -2px 5px 0 0;
   	    @include breakpoint(medium) {
      		margin: -2px 20px 0 0;
    	}
  	}
  	&:hover, &:active, &:focus {
      @include cf-button-padding-hover;
    }
  }
  &--lastminute {
  	@include custom-button-base;
    display: flex;
    border:none;
    background:none;
    border-radius:none;
    text-decoration:none;
    margin-bottom:45%;
    margin-top:45%;
    @include breakpoint(medium) {
   		margin-bottom:0;
    }
    //color:white;
    .cf-button__text{
    	font-size:1.3rem;
    	@include raleway;
    	font-weight: bold;
    	flex-grow: 1;
    	align-self: center;
    	margin: -2px 30px 0 20px;
   	    @include breakpoint(medium) {
      		margin: -2px 30px 0 15px;
    	}
    }
    .cf-button__icon{
    	font-size: 140%;
    	align-self: center;
    	margin: -2px 20px 0 0;

    	@include breakpoint(medium) {
      		margin: -2px 20px 0 0;
    	}
    }
    @include breakpoint(medium) {
    }

    &:hover, &:active, &:focus {
      @include cf-button-simple-hover;
    }
  }
  
  &--light {
    
  	border:1px solid $color-medium-gray;
  	.cf-button__text{
    	font-weight:500;
    }
    
    @include breakpoint(medium) {
    }

    &:hover, &:active, &:focus {
      @include cf-button-alt-hover;
    }
  }
  
  &--large {
  	height:50px;
  	padding-top:12px!important;
    @include breakpoint(medium) {
  		padding-top:8px!important;
    }
  	.cf-button__text{
    	font-size:1.2rem;
    	padding:10px;
    }
  }
  &--altwhite{
  	border:2px solid white;
  	background-coloR:transparent;
    &:hover, &:active, &:focus {
      @include cf-button-altwhite-hover;
    }
  }
  &--altblue{
  	border:2px solid $color-business-blue;
  	color: $color-business-blue;
  	background-coloR:transparent;
    &:hover, &:active, &:focus {
      @include cf-button-altblue-hover;
    }
  	.cf-button__text{
  		margin: 0px 30px 0 30px; 
  	}
  }
  &--top10training {
    
    height: 53px;
  	border:1px solid $color-blue;
  	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  	.cf-button__trainingicon > img {
  		margin:7px 15px;
  		margin-right:0px;
  	}
  	.cf-button__text{
    	font-weight:700;
    	font-size:1.1rem;
    	margin-top:0px;
    	color:$color-blue;
    	@include header-text;
    }
    .cf-button__icon{
    	color:$color-orange;
    }
    
    @include breakpoint(medium) {
		.cf-button__text{
			font-size:1rem;
		}
    }
     @include breakpoint(large) {
		.cf-button__text{
			font-size:1.1rem;
		}
    }

    &:hover, &:active, &:focus {
      @include cf-button-alt-hover;
      color:white;
      
		.cf-button__text{
			color:white;
		
		}
	  .cf-button__icon {
		color:white;
	  	}
    }
	&__homebutton{
		border:none;
		margin:0 !important;
		height: 100%;
		@include breakpoint(medium up) {
			margin: 15px 0px !important;
		}
		.cf-button__text{
			font-size:15px;
			color:$color-business-blue;
			font-weight: 500;
			margin:0;
			span{
				font-weight:800;
			}
			@include breakpoint(medium up) {
				font-size:16px;
				span{
					font-weight:500;
				}
			}
		}
		.homebutton-icon{
			display: grid;
			align-content: center;
			margin-right: 20px;
			font-size: 1.2rem;
			color:$color-business-blue;
		}
		@include breakpoint(medium) {
			.cf-button__text{
				font-size:1rem;
			}
		}
		 @include breakpoint(large) {
			.cf-button__text{
				font-size:1.1rem;
			}
		}

		&:hover, &:active, &:focus {
			@include cf-button-top10-hover;
			color:$color-business-blue;
			background-color: transparent;
			.cf-button__text{
				color:$color-business-blue;
				
			}
			.cf-button__icon {
				color:$color-business-blue;
			}
		}

		
	  }
  }
}