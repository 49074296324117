.opleiding-container .slick-track{
	width: max-content !important;
}
.training-slider-block{
	margin-top: 120px;
	& > div.row > .column.small-12{
		padding:0 2rem;
		@include breakpoint(medium){
			padding:0 0.9375rem;
		}
	}
	&__title{
		font-size:25px;
		font-weight:800;
		@include breakpoint(medium){
			font-size:39px;
		}
	}
	&__body{
		position: relative;
		padding: 30px 0px 0px;
		margin-bottom: 80px;
		&:before{
			content: "";
			height: 65%!important;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			top: 30%!important;
			left: 0px;
			z-index: -1;
			background-position: center;
			background-size: unset; 
			@include breakpoint(medium){
				//background-image: url(/images/dot-bgv4.png); 
				background-image: none!important;
			
			}
		}

		&.keuzehulp_sliders{
			padding: 20px 0px 0px;
		}
		
		.tab-title{
			margin-top:0px;
		}
		.tab-head {
			display: flex;
			justify-content: space-between;
			border-radius: 4px;
			box-shadow: none!important;
			border:none; //border: solid 0.3px rgba(2,37,67,0.419);
			background-color: transparent!important;
			padding-bottom:0px;
		}
		.tab-head h6 {
			font-size: rem-calc(16);
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			padding-bottom: 10px;
		   /* border-bottom: 2px solid #747AA557;*/
			margin-right: 40px;
			margin-bottom: 10px;
		}
		.tab-title{
			padding:0px!important;
			@include breakpoint(medium up){
				//padding: 20px 20px;
				//padding-bottom:15px;
			}
			&.categories{
				overflow:scroll;
				flex-basis: 75%!important;
				@include breakpoint(medium){
					flex-basis: 50%!important;
				}
			}
			&.usps{
				border:none!important;
				.listing{
					padding-top:0px!important;
					column-gap:15px;
					& > div > div{
						margin-bottom:15px;
						//flex-basis:50%!important;
						&:last-of-type{
							margin-bottom:10px;
						}
					}
					img.checkmark-svgs {
						width: 20px;
						position: absolute;
					}
					span {
						padding-left: 25px;
						display: block;
					}
				}
			}
		}
		.tab-title:nth-child(2) {
			flex-basis: 50%!important;
		}
		.tab-title:nth-child(3) {
			flex-basis: 25%!important;
		}
		ul.tabbing li{
			margin-bottom:0px;
			margin-right:0px;
			@include breakpoint(medium up){
				margin-bottom:8px !important;
				margin-right:4px!important;
				font-size:14px!important;
			}
		}
		.tab-title.filter{
			border-left: 1px solid #D6D6D6;
			select{
				margin-bottom:0px;
				font-size: 12px;
				font-weight:600;
				padding-left:2rem;
				height: initial;
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 34, 92%29'></polygon></svg>"), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style="fill: rgb%2830, 34, 92%29" d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>');
				background-origin: content-box, content-box;
				background-position: right -1rem center, left -1.5rem center;
				background-repeat: no-repeat, no-repeat;
				background-size: 9px 6px, 18px 15px;
				border: 1px solid $color-blue;
				color:$color-blue;
			}
		}
		.loadmore{
			border-top:1px solid #D6D6D6;
			margin-top:25px;
			padding-top:25px;
			text-align:center;
		}		
		.slider .slick-slide{
			margin-bottom:30px;
			margin-right: 20px;
			&[data-slick-index="0"]{
			    //margin-left: 12vw;
			    //margin-left: 160px;
			}
		}
		.slider .slick-track{
			@include transition(padding 400ms);
		}
		.slider .slick-track.startingposition{
			padding-left:8vw;
			.slick-slide:last-of-type{
				margin-right:100px;
			}
		}
		
		
		
		
	}
	&--fullwidth{
		background-color:$color-gray-background;
		padding:75px 0px;
		.training-slider-block{
			&__title{
				text-align:center;
				&__row{
					justify-content:center;
				}
			}
			&__body{
				h6{display:none;}
				.tab-title.categories{
					flex-basis:100%!important;
					@include breakpoint(medium){
						text-align:center;
					}
					.tabbing{ 
						@include breakpoint(medium){
						display:inline-flex; 
						border: 1px solid #1C2663;
						border-radius: 12px;
						background:white;
						padding:5px;
						column-gap:5px;
						margin-bottom:35px;
						}
						li{
							@include breakpoint(medium){
								margin-bottom:0px!important;
								margin-right:0px!important;
								border-radius: 8px;
							}
						}
					}
				}
				.slick-list { 
					
				}
			}
		}
		.tab-bottom__keuzehulp{
			display:none;
		}
	}
}

.tab-bottom__keuzehulp{
	a{
		color:$color-orange;
	}
}