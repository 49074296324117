.incompany{
	&__intro{
		&__container {
			display:flex;
			justify-content:flex-end;
			row-gap:40px;
			&__text{
				//padding-left:2.6rem!important;
				font-size:16px;
				p {
					@include breakpoint(large){
						font-size:16px;
					}
				}
				a:not(.cf-button,.cf-button--orange-flat,.cf-button--blue-alt,.cf-button--blue-flat-alt) {
					color:$color-orange;
				}
				padding-left:2rem;
				padding-right:2rem;
				@include breakpoint(medium){
					padding-left:2rem!important;
					padding-right:3rem!important;
					&.contact{
						padding-right:2rem!important;
					}
				}
				@include breakpoint(large){
					max-width:690px;
					padding-right:4rem!important;
				}
				h1{
					font-size: 2.4rem;
					margin-bottom:20px;
				}
				p:not(.incompany__intro__container__text__subclusters) {
					line-height: 1.8;
					margin-bottom:20px;
				}
				&__subclusters{
					font-weight:700;
					border-bottom:1px solid #D6D6D6;
					margin-top:20px;
					margin-bottom:10px;
					&__row{
						row-gap:15px;
						@include breakpoint(medium){
							& > .column:nth-of-type(odd){
								padding-right: 0.9375rem;
							}
						}
						a.cf-button--blue-flat-alt{
							padding: 20px 20px;
							border:0px;
						}
					}
				}
				&__more{
					display:none;
					font-size:14px!important;
				}
				&__readmore{
					font-weight:500;
					color:$color-orange;
					text-decoration:none;
					font-size:16px!important;
					&:hover{
						text-decoration:none;
					}
				}
				.checkmark li {
					font-size:14px;
				}
			}
			.incompany__intro__usp-bar{
				padding:0px;
				margin:0px;
				margin-bottom:30px;
				&__usp{
					margin-bottom:5px;
				}
				& > .container.row{ padding:0px; display:block;}
			}
			&__image{
				position:relative;
				&__overlay{
					position:absolute;
					
					left:10px;
					bottom:-20px;
					@include breakpoint(large up){
					left:-90px;
					top:20px;
					bottom:initial;
					}
					img{
						width:125px;
					}
				}
				&__container{
					img{
						object-fit: cover;
						border-radius: 0px 0px 0px 70px;
						width:100%;
						@include breakpoint(large){
							height:78vh;
						}
						
					}
				}
			}
			&__background{
				position:relative;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				border-radius: 0px 0px 0px 70px;
				min-height:300px;
				@include breakpoint(medium){
					height:78vh;
				}
				&__overlay{
					position:absolute;
					display:flex;
					left:5px;
					bottom:-80px;
					z-index:30;
					@include breakpoint(medium up){
						left: -85px;
						top:20px;
						bottom:initial;
					}
					img{
						width:115px;
						margin-right: -1px;
						//object-fit:cover;
					}
					&__text {
						padding:20px;
						padding-right:10px;
						background-color:$color-blue;
						display:inline-block;
						color:white;
						display:flex;
						width: 65%;
						@include breakpoint(medium){
							width: 70%;
							padding: 23px;
						}
						@include breakpoint(large up){
						padding:20px;
							width: 60%;
						}
						.start__reviews__ratings__text {
							font-size:15px;
						}
						align-content:center;
						a{ color:white; font-weight:bold; text-decoration:underline; font-size: 14px;
							&:hover{
								text-decoration:none;
							}
						}
						&__icon{
						margin-top:5px;
						}
					}
				}
				&__image{
				    width: 50%;
				    @include breakpoint (medium){
				    	height: 75vh;
				    	position:relative;
				    }
    				overflow: hidden;
    				
    				img{
				    	@include breakpoint (medium){
    						position: absolute;
    						bottom: 0;
    						//height:100%;
    						max-width:90%;
    						max-height: 70vh;
    						&.subcluster{
    							max-height: 357px;
    						}
    					}
    				}
				}
				&__container{
					position:relative;
					img{
						object-fit: cover;
						width:100%;
						@include breakpoint(large){
						z-index:10;
						}
					}
				}
				&__designation{
					position:absolute;
					color:white;
					z-index:11;
					p{
						color:white;
					}
					left:50px;
					top:30px;
					@include breakpoint(medium up){
						top:initial;
						left:70px;
						bottom:40px;
					}
				}
			}
		}
		&__usp-bar{
			margin:40px 0px;
			padding-right: 0.625rem;
			padding-left: 0.625rem;
			@include breakpoint(medium){
				padding-left:1.3rem;
				padding-right:1.3rem;
			}
			& > .row{ justify-content:space-between; }
			&__usp{
				display:flex;
				span{
					color:$color-blue;
				}
				&__icon{
					width:20px;
					height:20px;
					path{
						fill:$color-blue;
					}
					margin-right:10px;
				}
			}
		}
		&--subcategory{
			.incompany__intro__container{
				&__text{
					h1{
						font-size:2.5rem;
					}
					@include breakpoint(medium){
					    padding-right: 6rem!important;
					}
				}
				&__image{
					position:relative;
					&__container{
						img{
							@include breakpoint(medium){
								height:400px;
							}
						}
					}
					
				}
				&__background{
					@include breakpoint(medium){
						height:400px;
					}
					&__image{
						@include breakpoint (medium){
							height:400px;
							img {
								right: 0;
								max-width: 70%;
							}
						}
						
					}
				}
			}
		}
		&--stap{
			background-color:#B579DB;
			.incompany__intro__container{
				&__text{
					color:white;
					h1{
						font-size:2.5rem;
						color:white;
					}
					p { color:white; font-weight:medium;}
					@include breakpoint(medium){
					    padding-right: 6rem!important;
					}
				}
				&__image{
					position:relative;
					&__container{
						img{
							@include breakpoint(medium){
								height:400px;
							}
						}
					}
					
				}
				&__background{
					@include breakpoint(medium){
						height:400px;
					}
					&__image{
						@include breakpoint (medium){
							height:400px;
							img {
								right: 0;
								max-width: 70%;
							}
						}
						
					}
				}
			}
		}
	}
	
	&__slider{
		margin:150px 0px;
		h3{
			margin-bottom:50px;
		}
		
	}
	&__section{
		margin:80px 0px;
		&.nopaddinglarge{
			@include breakpoint(large){
				.row{	
					padding:0px;
				}
			}
		}
	
	}
	&__possibilities{
		background:$color-gray-background; 
		margin:70px 0px;
		padding:65px 0px;
		margin-bottom:0px;
		position:relative;
		& > .row{
			row-gap:20px;
		}
		&__title{
			margin-bottom:55px;
			h2 {  }
			p { max-width:75%; margin-bottom:0px; }
		}
		&__background{
			z-index: -1;
			&::before{
				content: "";
				background-image: url(/images/dot-bgv2.png);
				height: 50%;
				width: 50%;
				position: absolute;
				background-repeat: repeat;
				top: 140px;
				right: 0px;
				z-index: -1;
				background-position: center;
				background-size: unset;
				@include breakpoint(medium){
					top: 120px;
				}
			}
		}
		&__container{
			background-color:white;
			border-radius:8px;
			box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
			border: 2px solid #022543;
			padding:0px;
			height:100%;
			color:$color-blue;
			display:flex;
			flex-direction:column;
			justify-content:space-between;
			&__top{
				&__header{
					padding:20px;
					border-bottom:1px solid #E3E3E3;
					p{ margin-bottom:0px; }
				}
				&__body{
					padding:20px;
					ul{
						margin-left:0px;
						li{
							list-style:none;
							background-image: url('/images/check-mark.png');
							background-repeat: no-repeat;
							background-position: left 2px;
							background-size: 20px;
							padding-left:25px;
						}
					}
				}
			}
			&__bottom{
				padding:20px;
				padding-top:0px;
			}
			li {
				color:$color-blue;
			}
			&--blue{
				border-color:#3BC3BE;
			}
			
			&--yellow{
				border-color:#F5A001;
			}
			
			&--red{
				border-color:#EF5155;
			}
		}
	}
	&__process{
		background:$color-gray-background;
		margin-bottom:-75px;
		padding:65px 0px;
		.column.large-3.small-6{
			margin-bottom:20px;
		}
		&__image{
			margin-bottom:-65px;
			width:50%;
			&.stappagina{
				margin-bottom:-25px;

			}
			@include breakpoint(medium up){
				&.stappagina{
					margin-bottom:-65px;
	
				}

			}
			@include breakpoint(large){
				width:100%;
			}
		}
	}
	&__why{
		margin:150px 0px;
		position:relative;
		&::before{
			content: "";
			background-image: url(/images/dot-bgv2.png);
			height: 100%;
			width: 40%;
			position: absolute;
			background-repeat: repeat;
			top: 100px;
			right: 0px;
			z-index: -1;
			background-position: center;
			background-size: unset;
		}
		&__testimonial{
			
		}
	}
	&__companies{
		padding:0px;
		margin:50px 0px;
		&__logoslider{ 
			//background:$color-gray-background;
			padding-top:40px!important;
			padding-bottom:15px!important;
			img{
			filter: grayscale(1);
			}
		}
	}
	&__intake{
		@include breakpoint(large){
			margin-top:0px;
	
		}
	}
}
.logo-grid{
	display:flex;
	gap:5px;
	flex-wrap:wrap;
	& > img{
		flex: 1 1 calc(25% - 10px);
		/*min-width: 90%;
		@include breakpoint(medium){
			min-width: 40%;
			max-width: 50%;
		}
		@include breakpoint(xlarge){*/
			min-width: 25%;
			max-width: 32%;
		//}
	}
}