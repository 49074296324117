.nieuws-list-row {
  margin: 1rem 0 2rem;
  padding: 0 0.5rem 1rem 0;
  border-bottom: 2px solid $color-line;

  &__title {
    a {
      color: $color-header;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__action {

  }
  &__pagination{
  	margin-bottom:15px;
    margin-top:50px;
    padding:0px 10px;
    display: block;
      @include breakpoint(large){
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin: 20px;
        max-width:100rem;
        padding:0px 60px;
      }
    &__left{
      .cf-button{
        width:unset;
        height:unset;
        padding:2px 10px;
        min-height:unset;
        //min-width:35px;
        //text-align: center;
        span{
          margin:0px;
          padding: 0px;
        }

      }
    }
    &__right{
      display: flex;
      gap:10px;
      margin-top: 20px;
      & > a{
        flex-basis: 49%;
      }
      @include breakpoint(large){
        display: flex;
        margin-top: 0px;
      }
      .cf-button{
        gap:5px;
        padding:4px 10px;
        height:unset;
        min-height: unset;
        span{padding: 0px; margin: 0px; font-size:14px;}
      }
    }
  	.cf-button__text{
  		margin: -2px 5px 0 5px;
      padding-top:4px;
  	}
    .cf-button--padding{
      border:1px solid $color-business-blue;
      color:$color-business-blue;
      border-radius:0px;
      transition: color 400ms, background-color 400ms;
      font-family: 'Lexend', sans-serif!important;
      &:hover,&:focus,&:active,&.selected{
        background-color: $color-business-blue;
        color:white;
        span { color:white; }
      }
    }
    .cf-button--alt{
      border:1px solid $color-business-blue;
      background-color: $color-business-blue;
      color:white;
      border-radius:4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cf-button__text{
        flex-grow: unset;
        color:white;
      }
    }
  }
}