.trainingen-sidenav {
  &__title {
    @include raleway;
    font-weight: 800;
    font-size: rem-calc(16);
    color: $color-dark-gray;
  }

  &__menu {
    margin: 0;
    li {
      margin: 0;
      a {
        text-decoration: none;
        margin: 0;
        padding: 0.3rem 0 !important;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.training__sidebar__eerstvolgende p{
  font: normal normal normal 14px/25px Poppins;
  background: #EFF8FC 0% 0% no-repeat padding-box;
  display:inline-block;
  padding: 2px 25px;
}