.training-nav {
  position: relative;
  z-index: 999;
  height: 0;
  display: none;

  &__subsite-link {
    font-size: 13px;
    font-weight: bold;
    padding: 10px 12px 10px 10px !important;
    &:hover {
      padding: 10px 12px 10px 10px !important;
    }
  }
  &__subsite-link--business-school {
    color: $color-business-blue-dark;
    &:hover {
      color: $color-business-blue-darker;
    }
  }
  &__subsite-link--factory {
    color: $color-purple;
    &:hover {
      color: $color-purple-dark;
    }
  }

  &__menu-container {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    height: $trainingen-menu-height;
    width: 100%;
    overflow: auto;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
  }

  &__categories-container {
    display: flex;
    flex-wrap: wrap; //Was no-wrap
  }

  &__categories {
    width: $trainingen-menu-left-width + 1px;
    min-width: $trainingen-menu-left-width + 1px;

    ul {

      & > li {

        & > .training-nav__category-topmargin {
          display: block;
          border-left: 1px solid $color-line;
          border-right: 1px solid $color-line;
          background: white;
          height: 12px;
        }

        & > a {
          text-decoration: none;
          font-size: rem-calc(16px);
          border-left: 1px solid $color-line;
          border-right: 1px solid $color-line;
          background: white;
          padding: 10px 12px 10px 10px;
          &:hover {
            padding: 10px 2px 10px 8px;
            font-weight: bold;
          }
          &.active {
            border-top: 1px solid $color-line;
            border-bottom: 1px solid $color-line;
            border-right: none;
            padding: 9px 3px 9px 8px;
            font-weight: bold;
          }
        }
      }
    }
  }

  &__subnav {
    padding: 1.2rem 1.5rem;
    width: 100%;
    height: $trainingen-menu-height;
  }

  &__cat-name {
    width: 100%;
    color: $color-header;
    font-weight: 500;
    font-size: rem-calc(26);
    margin: 0 0 1rem;
  }

  &__subcat-name {
    color: $color-header;
    font-size: rem-calc(16);
    font-weight: 800;
    margin: 0 0 0.5rem;

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__trainings {
    margin: 0 0 2rem;
    font-size: rem-calc(14);

    & > li {
      & > a {
        padding: 0;
        margin: 0;
        text-decoration: none;
        line-height: $paragraph-lineheight;

        &:hover {
          text-decoration: underline;
        }
      }

      & > a.training-nav__subcat-page {
        margin-top: 4px;
        font-weight: 800;
      }
    }
  }

  &__subcat-more {

  }
}