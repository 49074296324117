.seo-page-header{
    &__image{
        &__video__container{
            img{
                width:100%;
                height:200px;
                object-fit: cover;
                @include breakpoint(large){
                    height:300px;
                }
            }
        }
    }
}