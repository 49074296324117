.overons-dropdown, .incompany-dropdown, .opleidingen-dropdown, .events-dropdown{
  @include raleway;
  z-index:99999;
  padding:0px;
	&__menu{
  	padding-top:5px;
  	padding-bottom:40px;
  	& ul{
  		list-style-type:none;
  		margin:0;
  		& li a{
  			text-decoration:none;
  			font-size:0.95rem;
  			&:hover{
  				text-decoration:underline;
  			}
  		}
  	}
  }
}
.overons-dropdown, .incompany-dropdown, .events-dropdown{
  &__menu{
  	padding-top:5px;
  	padding-bottom:10px;
  	padding-left:10px;
  }
}
.opleidingen-dropdown {
  width:100vw!important;
  z-index:300;
  padding-top:40px;
  padding-bottom:0px;
  border-radius:8px;
  box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
  border:none;
  & > .row:first-of-type{
  	//padding-right:65px;
  	column-gap:40px;
  	max-width:95%;
  	justify-content: space-between;
  }
  &__section{
  	padding:0px;
  	&.trainingen{
  		max-width:70%;
  		& > .row{
  			gap:25px;
  			justify-content: space-between;
  		}
  		.opleidingen-dropdown__section__header {
  			margin-bottom:30px;
  		}
  		.opleidingen-dropdown__section__header > div {
  			min-width: 160px;
  		}
  	}
  	&__header{
  		margin-bottom:20px;
  		display:flex;
  		column-gap:17px;
  		align-items:center;
  		justify-content:space-between;
  		div{
			font-size:rem-calc(16px);
			@include header-text;
			font-weight:600;
			color:$color-blue;
  			flex-grow:2;
  		}
  		&:after {
			content: '';
			width: 100%;
			height: 1px; // suit your need
			background: $color-blue; // suit your need
		}
  	}
  }
  &__callout{
  	display:inline-block;
	border-radius: 12px;
	min-height:225px;
	padding:20px;
	text-decoration:none;
	margin-bottom:15px;
	display: flex;
    flex-direction: column;
    justify-content:space-between;
  	&__top{
  		display:flex;
  		justify-content:space-between;
  		column-gap:5px;
  	}
  	&__bottom{
		text-align: center;
  		img {
  			margin-bottom:-20px;
  			max-height:123px;
  		}
  	}
  	&__text{
  		&__title{
  			display:block;
  			@include header-text;
  			font-size:rem-calc(18px);
  			font-weight:800;
  			margin-bottom:2px;
  		}
		&__subtitle{
  			display:block;
  			font-size:rem-calc(15px);
  			font-weight:400;
		}
  	}
  	&__button{
  		min-width:32px;
		.training-grid-block__action{
			transition: none;
		}
  	}
  	&.advies{
  		background-color:#9ED18C;
  	}
  	&.trainingen{
  		background-color:#FCB84C;
		min-height:unset;
  	}
  	&.aiproof{
  		background-color:#C087E3;
		min-height:unset;
  	}
  	&.test{
  		background-color:#00C4C2;
		min-height:unset;
  	}
  }
  &__column {
  	max-width: 30%;
  	padding:0px;
  }
  &__container{
  	//padding-left:65px;
  }
  &__title{
  	@include header-text;
  	font-size:1rem;
  	font-weight:700;
  	color:$color-blue;
  	a {
  		text-decoration:none;
  		transition:color 400ms;
  		img{
  			height:12px;
  			margin-left:5px;
  			transition:filter 400ms;
  		}
  		&:hover{
  			color:$color-orange;
  			img{
  				filter: invert(53%) sepia(77%) saturate(647%) hue-rotate(358deg) brightness(102%) contrast(108%);
  			}
  		}
  	}
  	padding-bottom:5px;
  	border-bottom:1px solid $color-gray;
  }
  &__menu {
  	color:$color-blue
  }
}
