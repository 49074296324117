
.training-header {
	width: 100%;
	position:relative;
	background-color:#00c4c2;
	&__container {
		display:flex;
		align-items: center;
		flex-wrap: wrap;
		height:100%;
		padding-top: 40px;
		@include breakpoint(large up) {
			padding-top: 0px;
			height:100%;
		}
		&__text{
			flex-basis:100%;
			@include breakpoint(large up){
				flex-basis:50%;
				padding-right:25px;
				padding:75px 0px;
			}
			@include breakpoint(xlarge up){
				padding-right:50px;
			}
			&__area{
				position: relative;
				background: #FFFFFF 0% 0% no-repeat padding-box;
				box-shadow: 0px 3px 36px rgba(0,0,0,0.16);
				border-radius: 2px 16px 16px 16px;
				opacity: 1;
				padding: 30px;
				min-height: 0px;
				@include breakpoint(medium up){
					padding: 40px;
				}
				@include breakpoint(large up){
					padding: 60px; 
				}
				&.haslabel{
					.training-header__title{
						padding-right:50px;
						@include breakpoint(large){
							padding-right:30px;
						}
					}
				}
				&__label{
					position: absolute;
					right:5px;
					top:10px;
					@include breakpoint(large){
						right:10px;
					}
					img{
						width:80px;
					}
				}
				&__status-label{
					position: absolute;
					left:5px;
					top:5px;
					background-color: #f2f8ff;
					padding: 5px 13px;
					border-radius: 6px;
					text-transform: capitalize;
					color: #1e225d;
					font-size: 12px;
					font-weight: bold;
					@include breakpoint(large){
						left:10px;
						top:10px;
					}
				}
			}
		}
		&__image{
			position: static;
			right: 0;
			width: 100%;
			flex-basis:100%;

			
			//max-height: 400px;
			@include breakpoint(medium) {
				max-height: initial;
			}


			@include breakpoint(large up){
				flex-basis:50%;
				position: absolute;
				right: 0px;
				width: 50%;
				/*overflow: hidden;*/
				top: 0px;
				height:100%;
			}
			&__background{
				height: 320px;
				.cluster-header__background__overlay{
					bottom:-25px;
				}
				.cluster-header__background__image{
					
				}
				@include breakpoint(large up){
					height: 100%;
					position: relative;
					.cluster-header__background__overlay{
						bottom:25px;
					}
					
					.cluster-header__background__image{
						overflow:visible;
						width: 70%;
						bottom:0px;
						img{
							bottom:-25px;
							max-height: 500px;
							max-width:100%;
						}
					}				
				}
			}
		}
	}
  
	&__title{
			
		@include header-text;
		letter-spacing: -0.2px;
		margin-bottom:20px;
		line-height: 30px;
		font-size:22px;
		font-weight:700;
		@include breakpoint(medium up){
			font-size:28px;
			line-height: 44px;
			padding-right:50px;
		}
	}

	&__intro{
		margin-bottom:20px;
	}
	&__uspblock{
		margin-bottom:20px;
		&__block{
			background-color:#F2F8FF;
			border-radius:4px;
			padding:12px 21px;
			display:flex;
			flex-wrap:wrap;
			gap:10px;
			row-gap:18px;
			&:not(:first-of-type){
				margin-top:8px;
			}
			&__item{
				display:flex;
				width:100%;
				gap:10px;
				@include breakpoint(large){
					width:48%;
				
				}
				align-items:center;
				&.fullwidth{
					width:100%;
				}
				&__image{
					filter: invert(12%) sepia(43%) saturate(3297%) hue-rotate(224deg) brightness(85%) contrast(95%);
				}
				&__text{
					@include header-text;
					font-size:rem-calc(15px);
					font-weight:500;
					color:$color-business-blue;
				}
			}
		}
	}
	&__priceblock{
		margin-bottom:20px;
	}
	&__price{
		//padding: 7px 18px;
		//background-color: #f2f8ff;
		margin-bottom:10px;
		font-size:rem-calc(16px);
		display:flex;
		gap:35px;
		&__oneoff{
			&__description{
				font-size:14px;
				display: block;

			}
			&__amount{
				display: block;
				font-size:18px;
				font-weight:600;
				@include header-text;
			}
		}
		&__oneoff, &__installments{
			//flex-basis:49%;
		}
		&__divider{
			width:1px;
			background-color:$color-business-blue;
		}
		.training__sidebar__main__vanaf,.training__sidebar__main__vanafprijs, .training__sidebar__main__nuprijs{
			font-size:rem-calc(16px);
			font: normal normal 600 16px Lexend;
			span{
				font: normal normal 600 16px Lexend;
			}
		}
		.training__sidebar__main__nuprijs {
			margin-left:15px;
		}
		@include breakpoint(medium up){
			font-size:rem-calc(16px);
			.training__sidebar__main__vanaf,.training__sidebar__main__vanafprijs{
				font-size:rem-calc(16px);
			}
		}
	}

	&__discount{
		padding: 7px 18px;
		font-size: 0.875rem;
		color: #1E225C;
		margin-left: 0rem;
		background-color: #f2f8ff;
		display: inline-block;
		border-radius:5px;
	}

	&__bubble{

		margin-bottom:20px;
	}

	&__buttons{
		//margin-bottom:15px;
		&__primary{
			.cf-button--orange-flat, .cf-button--blue-alt{
				padding: 13px 14px;
				line-height: 1.4;
				justify-content: center!important;
				@include breakpoint(medium up){
					padding: 13px 15px;
					margin-bottom: 5px;
					.cf-button__icon{
						margin-right: 10px;
					}
					.cf-button__text{
						flex-grow:1;
						text-align:center;
					}
					&:hover{
						padding-left:15px;
					}
				}
				@include breakpoint(xlarge up){
					.cf-button__icon{
						margin-right: 15px;
					}
				}
			}
		}
		&__secondary{
			//margin-bottom:10px;
			.cf-button--blue-alt, .cf-button--blue, .cf-button--orange-flat{
				
				padding: 13px 14px;
				.cf-button__text{
					flex-grow:1;
					text-align:center;
				}
				@include breakpoint(medium up){
					padding: 13px 15px;
					&:hover{
						padding-left:11px;
					}
				}
			}
			@include breakpoint(medium up){
				display:flex;
				column-gap:6px;
				& > div:first-of-type{
					//flex-basis:38%;
					flex-basis:45%;
				}
				& > div:last-of-type{
					//flex-basis:62%;
					flex-basis:55%;
				}
				.cf-button--blue-alt, .cf-button--blue, .cf-button--orange-flat {
					width:100%;	
					text-align: center;
					margin-bottom:0px;
					.cf-button__icon{
						margin-right: 10px;
					}
					.cf-button__text{
						flex-grow:1;
						text-align:center;
					}			
				}
				.cf-button--blue-alt:hover, .cf-button--blue:hover, .cf-button--orange-flat:hover{
					.cf-button__icon{
						margin-right: 10px;
					}
				}
			}
			@include breakpoint(xlarge up){
				.cf-button--blue-alt, .cf-button--blue, .cf-button--orange-flat {
					.cf-button__icon{
						margin-right: 15px;
					}
				}
				.cf-button--blue-alt:hover, .cf-button--blue:hover, .cf-button--orange-flat:hover{
					.cf-button__icon{
						margin-right: 15px;
					}
				}
			}
		}
	}

	&__secondary{
		border-top:1px solid #D6D6D6;
		padding-top:15px;
	}

}
.tooltip-trainingen-certificaat{
	border-bottom: none;
	display: inline-block;
	width: fit-content;
	align-items: center;
	img{
		width: 16px !important;
		height: 21px !important;
		margin-right: 0 !important;
	}
}
.tooltip-trainingen-certificaatsidebar{
	border-bottom: none;
	display: flex;
	width: fit-content;
	align-items: center;
	margin-left:5px;
	img{
		width: 16px !important;
		height: 16px !important;
		margin-right: 0 !important;
	}
}

