.training-small-block {
  border: 1px solid $color-line;
  background: $color-lighter-gray;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  @include breakpoint(medium) {
    height: 320px;
  }
  
  @media print {
  	margin-bottom:0px;	
  }
  &--opleiding {
  	background: $color-white;
  	border: 1px solid $color-dark-gray;
  	//-webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	//-moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
	//box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
  	margin-bottom:0px;
  	&--row{
  		padding-left:0.93rem;
  		padding-right:0.93rem;
  		row-gap:25px;
  		@include breakpoint(small only){
  			&--container{
				padding:0px;
				&:first-of-type{
					.training-small-block--opleiding{
						border-radius:15px 15px 0px 0px;
					}
				}
				&:not(:last-of-type):not(:first-of-type){
					.training-small-block--opleiding{
						border-top:0px;
					}
				}
				&:last-of-type{
					.training-small-block--opleiding{
						border-radius:0px 0px 15px 15px;
						border-top:0px;
					}
				}
			}
  		}
  		@include breakpoint(large down){
  			&--container{
				padding:0px;
  				&.row-medium-4{
					&:first-of-type{
						.training-small-block--opleiding{
							border-radius:15px 0px 0px 0px;
						}
					}
					&:not(:last-of-type):not(:first-of-type){
						.training-small-block--opleiding{
							border-top:0px;
						}
					}
					&:last-of-type{
						.training-small-block--opleiding{
							border-radius:0px 0px 15px 15px;
							border-top:0px;
						}
					}
				}
				&.row-medium-3{
			
				}
				&.row-medium-2{
					&:first-of-type{
						.training-small-block--opleiding{
							border-radius:15px 0px 0px 0px;
						}
					}
					&:not(:last-of-type):not(:first-of-type){
						.training-small-block--opleiding{
						}
					}
					&:last-of-type(2){
						.training-small-block--opleiding{
							border-radius:0px 0px 15px 15px;
							border-top:0px;
						}
					}
					&:last-of-type{
						.training-small-block--opleiding{
							border-radius:0px 0px 15px 15px;
							border-top:0px;
						}
					}
				}
				&.row-medium-1{
					&:first-of-type{
						.training-small-block--opleiding{
							border-radius:15px 0px 0px 15px;
						}
					}
					&:not(:last-of-type):not(:first-of-type){
						.training-small-block--opleiding{
						}
					}
					&:last-of-type{
						.training-small-block--opleiding{
							border-radius:0px 15px 15px 0px;
						}
					}
				}
  			}
  			
  		}
  		@include breakpoint(xlarge){
  			&--container{
				padding:0px;
				
				&.row-xlarge-4{
			
				}
				&.row-xlarge-3{
			
				}
				&.row-xlarge-2{
			
				}
				&.row-xlarge-1{
					&:first-of-type{
						.training-small-block--opleiding{
							border-radius:15px 0px 0px 15px;
						}
					}
					&:not(:last-of-type):not(:first-of-type){
						.training-small-block--opleiding{
							border-left:0px;
							border-top:1px;
						}
					}
					&:last-of-type{
						.training-small-block--opleiding{
							border-radius:0px 15px 15px 0px;
							border-left:0px;
							border-top:1px;
						}
					}
			
				}
			}
  		}
  		/*&--container{
			padding:0px;
			&:first-of-type{
				.training-small-block--opleiding{
					border-radius:15px 15px 0px 0px;
					@include breakpoint(medium) {
						//border-radius:15px 0px 0px 15px;
					}
				}
			}
			&:not(:last-of-type):not(:first-of-type){
				.training-small-block--opleiding{
				
					@include breakpoint(small only) {
						border-top:0px;
					}
					@include breakpoint(medium) {
						border-left:0px;
					}
				}
			}
		
			&:last-of-type{
				.training-small-block--opleiding{
					border-radius:0px 0px 15px 15px;
					@include breakpoint(small only) {
						border-top:0px;
					}
					@include breakpoint(medium) {
						//border-radius:0px 0px 15px 0px;
						border-left:0px;
					}
				}
			}
			/*&:nth-child(3){
				@include breakpoint(large) {
			
				}
			}
			&:nth-last-child(4):first-child,
			&:nth-last-child(4):first-child ~ * {
				// unique CSS for elements when there are 6 of these
			}  	
		}*/
  	}
  	

  }
  &__title {
    width: 100%;
    color: $color-business-blue;
    font-weight: 900;
    font-size: 1.1rem;
    line-height: 1.1;
    margin-bottom: 15px;
  }

  &__intro {
    width: 100%;
    line-height: 1.35;
    flex-grow: 1;
    margin: 0;
    overflow: hidden;
  }

  &__priceinfo {
    @include raleway;
    font-weight: 500;
    margin: 15px 0;
    line-height: rem-calc(32);
    font-size: rem-calc(20);
    color: $color-business-blue;
   strong  {
      @include open-sans;
      font-weight: 700;
      font-size: rem-calc(26);
      color: $color-business-blue;
      text-decoration: none;
    }
    @media print {
    	margin-top:0px;
    	margin-bottom:0px;
    }
  }

  &__action {
    width: 100%;
    min-height: 42px;
    @media print{
    	display:none;
    }
  }
}