.iframe-container{
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
	& > iframe{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%!important;
	}
}

.videotext-block {
	//padding:75px 0px;
  	@include breakpoint(medium) {
		margin:100px 0px;
  	}
	&.graybg{
		background-color:#F8F8FA;
		padding:50px 0px;
		@include breakpoint(large) {
			padding:100px 0px;

		}

	}
	&.homepage{
		margin:50px 0px;
		@include breakpoint(medium) {
			margin:125px 0px;

		}

	}
	.row-video{
		@include breakpoint(large) {
		//max-width:100rem;

		}
	}
  	&.header{
  		margin:0;
  		margin-top:20px;
		background-color:#FFF;
  	}
	.imagetoright{
		@include breakpoint(medium up) {
			border-radius: 2px 16px 16px 16px !important;
		}
	}
	&__video{
		padding:0 2rem;
		&.centre{
			padding-right: 2rem;
			padding-left: 2rem;
			@include breakpoint(large){
				padding-right: 2rem;
				padding-left: 2rem;

			}
		}
		&.left{
			position:relative;
			&:before{
				/*
				content: "";
				height: 93%!important;
				width: 75%;
				position: absolute;
				background-repeat: repeat;
				top: 9%!important;
				left: 2%;
				z-index: -1;
				background-position: left;
				background-size: unset; 
				background-image: url(/images/dot-bgv4.png);
				@include breakpoint(medium){
					top: 7%!important;
				
				}
				@include breakpoint(large){
					top: 4%!important;
				
				}
				*/
			}
			&.incompany:before{
				background-image: url(/images/dot-bgv5.png);
			}
			#videoplayer-container3{
				height: 200px;
				width: 100%;
				object-fit: cover;
				border-radius: 2px 16px 16px 16px !important;
				@include breakpoint(medium up) {
					height: 500px;
					width: 100%;
					object-fit: cover;
				}
  				@include breakpoint(large) {
					margin-bottom:0px;
					width: 100%;
					//margin-bottom: 60px;
  				}
			}
			.videotext-block__video__container3{
				margin-bottom:20px;

				#background-img{
					height: 200px;
					width: 100%;
					object-fit: cover;
					border-radius: 2px 16px 16px 16px !important;
				}
				.background-img-class{
					height: 200px;
					width: 100%;
					object-fit: cover;
					border-radius: 2px 16px 16px 16px !important;
				}
				@include breakpoint(medium up) {
					#background-img{
						height: 500px;
						width: 100%;
						object-fit: cover;
					}

					#background-img-home{
						height: 600px;
						width: 100%;
						object-fit: cover;
					}
				}
  				@include breakpoint(large) {
					margin-bottom:0px;
					width: 100%;
					//margin-bottom: 60px;
  				}
				.corner-img{
					position: absolute;
					z-index: 1;
					height: 10vh;
					width:10vh;
					bottom: 0;
					right: 0;
					padding: 10px;
					backdrop-filter: blur(21px);
					border: 1px solid white;
					border-radius: 8px !important;
					margin: 10px;

					@include breakpoint(large) {
						margin: 20px;
						padding: 20px;
						height: 12vh;
						width: 12vh;
					}
				}
				.right-cornerimg{
					position: absolute;
					z-index: 1;
					height: 10vh;
					width: 10vh;
					bottom: 0;
					right: 0;
					padding: 10px;
					backdrop-filter: blur(21px);
					border: 1px solid white;
					border-radius: 8px !important;
					margin: 10px;
					object-fit: contain;

					@include breakpoint(medium up) {
						left: 0;
					}
					@include breakpoint(large) {
						margin: 20px;
						padding: 10px;
						height: 12vh;
						width: 12vh;
						left: 0;
					}
				}
			}
  			@include breakpoint(medium) {
			padding-left:2rem;
			padding-right:2.7rem;
			}
		}
		&.right{
			position:relative;
			&:before{
				content: "";
				height: 93%!important;
				width: 75%;
				position: absolute;
				background-repeat: repeat;
				top: 9%!important;
				right:0;
				z-index: -1;
				background-position: right;
				background-size: unset; 
				background-image: url(/images/dot-bgv4.png);
				@include breakpoint(medium){
					right: 2%;
					top: 7%!important;
				
				}
				@include breakpoint(large){
					top:5%!important;
				
				}
			}
			&.incompany:before{
				background-image: url(/images/dot-bgv5.png);
			}
			.videotext-block__video__container{
				margin-bottom:20px;
  				@include breakpoint(large) {
					margin-bottom:0px;
					width: 92%;
					margin-bottom: 60px;
  				}
			}
  			@include breakpoint(medium) {
				padding-right:0.9375rem;
				padding-left:2.7rem;
			}
			& > div > img{
				@include breakpoint(medium) {
					border-radius: 2px 16px 16px 16px;
				}
			}
		}
		&.nobackground{
			&:before{
				background-image: none;
			}
		}
		&__container{
			position:relative;
			
			& > img{
				//@include breakpoint(medium) {
					border-radius: 2px 16px 16px 16px !important;
				//}

			}
			& > #homepage-background-image{
				height: 200px;
				width: 100%;
				object-fit: cover;
				border-radius: 2px 16px 16px 16px !important;
				@include breakpoint(medium) {
					height: 700px;
				}
			}
			.vid-btn {
				font-size: 50px;
				color: #fff;
				position: absolute;
				top: 0px;
				left: 0px;
				 transform: none; 
				text-align: center;
				 border: none; 
				border-radius: 100%;
				opacity: .95;
				height: 100%;
				width: 100%;
				border-radius: 0px 0px 0px 90px;
			}
			.vid-btn iframe, .vid-btn figure, .video_play {
				height: 100% !important;
				width: 100% !important;
			}

			#vid-icon {
				font-size: 60px;
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				text-align: center;
				padding:20px;
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 100%;
				cursor: pointer; 
				-webkit-backdrop-filter: blur(10.7px);
				backdrop-filter: blur(10px);
				width: 25%;
				@include breakpoint(medium up){
					height:auto;
					width:auto;
				}

				img{
					width: 100%;
					display: block;
					@include breakpoint(medium up){
						height:auto;
						width:auto;
						display: inline-block;
					}
				}
				
				.fa-play-circle {
					vertical-align: 1em;
				} 
				@include transition(margin 400ms, box-shadow 400ms, transform 400ms);
				&:hover{
					transform: translate(-50%,-50%) scale(1.2);
					box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.5);
				} 
			}
			div#vid-icons {
				font-size: 50px;
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) !important;
				text-align: center;
				border: 20px solid #ffffffa1;
				border-radius: 100%;
				opacity: .95;
				-webkit-backdrop-filter: blur(10.7px);
				backdrop-filter: none;
				cursor: pointer;
				/* height: 100%; */
				/* width: 100%; */
			}
			
			&:hover{
				cursor: pointer;
				#vid-icon {
					transform: translate(-50%,-50%) scale(1.2);
					box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.5);
				}
			}

		}
	}
	&__text{
		padding:0 2rem;
		&.right{
  			@include breakpoint(medium) {
			margin-bottom:40px;
			padding-right:2rem;
			padding-left:2.7rem;
			margin-bottom:0px;
			}
		}
		&.right-home{
  			@include breakpoint(medium) {
			padding-right:2rem;
			padding-left:2.7rem;
			}
		}
		&.left{
			margin-bottom:40px;
  			@include breakpoint(medium) {
			padding-left:2rem!important;
			padding-right:2.7rem;
			margin-bottom:0px;
			}
		}
		&.left-home{
			margin-bottom:40px;
  			@include breakpoint(medium) {
			padding-left:2rem!important;
			padding-right:2.7rem;
			}
		}
		&__homepagetitle{
			font-size:25px !important;
  			@include breakpoint(medium) {
  				font-size:39px !important;
				margin-bottom: 2rem;
				
  			}
		}
		h2 {
			@include header-text;
			margin-top:25px;
			margin-bottom:25px!important;
			font-size:20px;
			letter-spacing: 0px;
  			@include breakpoint(medium) {
  				font-size:30px;
				margin-bottom: 2rem;
				
  			}
  			@include breakpoint(large) {
				margin-top:0px;

			}
			
		}
		p {
			margin-bottom:40px;
			line-height: 1.8;
			margin-right: 5vw;
			font-size:16px;
		}
		a{
			font-size:16px;
			justify-content: space-between;

			@include breakpoint(medium up) {
				justify-content: center;
			}
			img{
				margin-left: 10px;
				filter: invert(100%) sepia(0%) saturate(7480%) hue-rotate(140deg) brightness(105%) contrast(105%);
			}
		}
		a:hover{
			img{
				filter: invert(10%) sepia(36%) saturate(4183%) hue-rotate(227deg) brightness(99%) contrast(96%);
			}
		}
		&__button{
			padding: 20px 50px !important;
			padding-right:40px !important;
		}
		&__trainerknop{
			display: flex;
			width: fit-content;
			padding:20px 20px;

			&:hover, &:active, &:focus{
				padding:20px 20px;

				svg{
					filter: invert(11%) sepia(56%) saturate(2101%) hue-rotate(221deg) brightness(102%) contrast(100%);
				}
			}
			svg{
				margin-right:10px;
				margin-left:20px;
				filter: invert(98%) sepia(79%) saturate(0%) hue-rotate(338deg) brightness(102%) contrast(106%);
				
			}
		}
	
	}
	&-home{
		background-color:#ffffff;
		padding:0px;
		@include breakpoint(medium){
			padding:50px 0px;
		}
		&__text{
			&__incompanytitle{
				font-size:25px !important;
				@include breakpoint(medium){
					font-size:39px !important;
				}
			}
		}
		&__reverseblocks{
			display:flex;
			flex-direction: column-reverse;

			@include breakpoint(medium){
				flex-direction: row;
			}
		}
	}
  
}
.reveal iframe, #videohead iframe, #video1 iframe, #video2 iframe {
	width:100%!important;
	height: 70vh;
}
.reveal:not(#signup-modal2):not(#loginModal):not(#evenement-done):not(#signup-modal3):not(#gids-done):not(#nieuwsbrief-footer-done):not(#signup-done), #videohead, #video1, #video2{
background: #222222 0% 0% no-repeat padding-box;
border-radius: 12px;
border:none;
	@include breakpoint(large){
		width:900px;
	}
}
.reveal-overlay{
background-color: rgba(10, 10, 10, 0.75);
}