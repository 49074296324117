.wordpress-admin-bar {
  width: 100%;
  height: 25px;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    float: left;
    height: 100%;

    a {
      float: right;
      background: #ffffff;
      color: $color-black;
      display: block;
      line-height: 25px;
      padding: 0 20px;
      font-weight: 300;
      font-size: rem-calc(13);
      text-decoration: none;
      text-transform: uppercase;

      &:hover, &:focus {
        color: $color-dark-primary;
      }

      &__dashboard {
      }

      &__edit {
      }
    }
  }
}