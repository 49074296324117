
.cm--box{
  background-color:$color-business-blue !important;
  max-width:unset!important;
  border-radius: 8px;
  padding:1.2em!important;
  margin-left: 2%;
  margin-right: 2%;
  width: 96% !important;
  margin: 0 auto;
  margin-bottom: 20px!important;
  display:grid !important;
  @include breakpoint(large) {
    max-width: 1350px!important;
    padding:2.5rem!important;
    width: 90% !important;
    margin-bottom:4%!important;
  }
  @include breakpoint(medium) {
    display:flex !important;
  }
  &.cc-invisible{
  	display:none!important;
  }
}


#cc-main .cm__texts{
  margin: 0 auto;
  padding-top:0px!important;
  @include breakpoint(large){
    max-width: 39em!important;
  }
}

#cc-main .cm__desc{
  font: normal normal normal 16px/20px Lexend !important;
  letter-spacing: 0px;
  --cc-secondary-color:white;
  padding-top: 1em;
  padding-bottom: 1em;
  padding:0!important;
  text-align: center;
  &::before{
    content:"";
    width:50px;
    height:50px;
    display:block;
    //float:left;
    background-position:center;
    background-repeat:no-repeat;
    background-size:contain;
    //background-color: #393d79;
    background-image: url("/images/iconmonstr-candy-5-48.png"),
    linear-gradient(to right, #393D79, #393D79);
    border-radius: 8px;
    margin-right:auto;
    background-size: 25px 25px, auto;
    margin-left: auto;
    margin-bottom: 25px;
  }
}
#cc-main .cc__link, #cc-main a{
  --cc-btn-primary-bg:white;
  @include header-text;
  font-weight:400!important;
  text-decoration: underline;
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  &:hover{
    color:white!important;
  }
}
#cc-main .cm__btns{
  --cc-separator-border-color:$color-business-blue;
}
#cc-main .cm__btn{
  padding: 1em 1.7em;
}
#cc-main .cm__btn--secondary{
  font: normal normal 400 14px/16px Lexend !important;
  text-decoration: underline !important;
  --cc-btn-secondary-bg:$color-business-blue;
  --cc-btn-secondary-border-color:$color-business-blue;
  --cc-btn-secondary-color:white;
  font-weight:400!important;
  &:hover{
    --cc-btn-secondary-hover-bg:$color-business-blue;
    --cc-btn-secondary-hover-border-color:$color-business-blue;
    --cc-btn-secondary-hover-color:white;
    text-decoration: none !important;
  }

}
#cc-main .cm__btn[data-role="all"]{
  font: normal normal 600 16px/20px Lexend !important;
  margin:0 auto!important;
  font-size:17px!important;
  font-weight:700;
  padding:17px 30px;
  min-width:250px;
  @include header-text;
  //display: flex !important;
  //align-items: center;
  //justify-content: center;
  --cc-btn-primary-bg:rgb(241, 214, 0);
  --cc-btn-primary-color:black;
  --cc-btn-border-radius:8px;
  &:hover{
    --cc-btn-primary-hover-bg:rgb(241, 229, 0);
    --cc-btn-primary-hover-color:black;
  }
}

#cc-main .cm__footer{
  --cc-footer-bg:$color-business-blue;
  --cc-footer-border-color:$color-business-blue;
  --cc-footer-color:white;
}