.admin-evaluatie-gemiddelden {
  font-size: rem-calc(13px);
  width: auto;
  line-height: 1.2;
  border: 1px solid $color-line;

  th {
    text-align: left;
  }

  td, th {
    padding: 0.25rem 0.625rem 0.35rem;
  }
}