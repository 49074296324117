.trainingen {

  @include breakpoint(medium) {

    &__content {
      border-left: 1px solid $color-line;
      padding-left: 1.5rem;
    }

    &__title {
      margin-bottom: 2rem;
      border-bottom: 2px solid $color-line;
    }

    &__topcat {
      border-bottom: 2px solid $color-line;
      margin-bottom: 2rem;
    }
  }

  &__topcat-title {
    color: $color-header;
    font-weight: 500;
    font-size: rem-calc(26);
    margin: 1rem 0 1rem;
    padding: 0.8rem 0 0;
    border-top: 2px solid $color-line;

    @include breakpoint(medium) {
      margin: 0 0 1rem;
      padding: 0;
      border-top: none;
    }

  }

  &__subcat {
  }

  &__subcat-title {
    color: $color-darker-primary;
    font-size: rem-calc(16);
    font-weight: 800;
    margin: 0 0 0.5rem;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__subcat-page {
    font-weight: 800;
  }

  &__traininglist {
    margin: 0 0 2rem;
    font-size: rem-calc(14);

    & > li {
      & > a {
        padding: 0;
        margin: 0;
        text-decoration: none;
        line-height: $paragraph-lineheight;

        &:hover {
          text-decoration: underline;
        }
      }

      & > a.training-nav__subcat-page {
        margin-top: 4px;
        font-weight: 800;
      }
    }
  }
}
