
.submenu-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid $color-line;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  padding: 0 0 15px;
  z-index: 9999999;
}

.no-js .mainmenu-mobile {
  display: none;
}

.submenu-desktop {
  z-index: 1;
  display: flex;
  padding:12px 0px;
  justify-content:space-between;
  align-items:center;
  &__back{
  	color: $color-darker-gray;
  	text-decoration:none;
  	svg{
  		margin-right:10px;
  		width:12px!important;
  		height:12px!important;
  		path{
  			fill:$color-darker-gray;
  		}
  	}
  	&:hover{
  		text-decoration:underline;
  	}
  } 
  .menu {
    width: auto;
    & > .menu-item { 
		@include custom-button-base;
		float: left;
		font-weight:500;
		margin-bottom:0px;
		margin-right:10px;
		&:last-of-type{
			margin-right:0px;
			a::after{
				display:none;
			}
		}
		@include breakpoint(1050px){
			font-size:0.9rem;
		}
		@include breakpoint(large){
			font-size: 1rem;
		}
		svg {
			position:relative;
			top:-0.125rem;
			transform:scale(0.65);
			margin-left:5px;
		}
		&:hover {
		  //text-decoration:underline;
		}

		a {
		  text-decoration: none;
		  padding: 8px 18px;
		  color: $color-blue;
		  font-size:15px;
		  //border-radius:100px;
		  position:relative;
		  padding-bottom:20px;
		  font-family: 'Lexend', sans-serif;
		}
		a.active,a:hover{
			//color:white;
			//background-color:$color-blue;
			border-bottom: 2px solid $color-blue;
			font-weight:500;
			font-size:15px;
			
    		@include transition(all 400ms);
		}
		/*a::after{
			content:" ";
			border-top:1px solid rgba(30,34,92,0.513);
			position:absolute;
			width:10px;
			height:1px;
			right:-11px;
			top:50%;
		}*/
		a.cf-button--orange-flat {
		  text-decoration: none;
		  color: white;
		  font-weight:500;
		  
		  &:hover{
		  text-decoration: none;
		  }
		}

		&--trainingen {
		  font-weight: 500;
		}

		&--aanbiedingen {
		  font-weight: 500;

		}
		
		/*11-10-2021*/
		
		&.data-inschrijven {
			//margin-right:5px;
		}
		&.data-inschrijven a {    
			//background-color: white;
			//border-radius: 100px;
			opacity: 1;
			//padding: 8px 16px !important;
			color: $color-blue;
			//font-weight: 400;
			//border: 1px solid rgba(30,34,92,0.513);
			-webkit-transition: all .4s ease;
			-o-transition: all .4s ease;
			-moz-transition: all .4s ease;
			transition: all .4s ease;
			//font-weight: 700;
			display: flex;
			font-family: 'Lexend', sans-serif;
			font-size:15px;
			&::before{
				display: inline-block;
				width: 22px;
				height: 18px;
				margin-right: 5px;
				content: "";
				background-color:$color-blue;
				mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTAuMTQ5IDcuMzgxYy0xLjQ4Mi0uMDA4LTMuMzQzLjc5NS00Ljc2MyAyLjIxNC0uNTQxLjU0MS0xLjAxOSAxLjE3My0xLjM4NiAxLjg4MiAxLjI4MS0uOTY5IDIuNjQ3LTEuMjczIDQuMjQ3LS4zNzUuNDcxLTEuMjQ1IDEuMDk5LTIuNTEyIDEuOTAyLTMuNzIxem04LjQ3NyA4LjQ2MWMtMS4zMTMuODU4LTIuNjA0IDEuNDc4LTMuNzIzIDEuOTE0Ljg5NyAxLjYuNTk0IDIuOTY0LS4zNzYgNC4yNDQuNzEyLS4zNjcgMS4zNDMtLjg0NCAxLjg4NC0xLjM4NiAxLjQyNS0xLjQyMyAyLjIyOS0zLjI4OCAyLjIxNS00Ljc3MnptNS4zMjktMTMuODA1Yy0uMzU3LS4wMjUtLjcwNy0uMDM3LTEuMDUxLS4wMzctNy40NTEgMC0xMS42OTIgNS42NzgtMTMuMjM1IDEwLjI0NWw0LjA5NSA0LjA5NGM0LjcwMi0xLjY4NSAxMC4yMzYtNS43NjcgMTAuMjM2LTEzLjEzNiAwLS4zOC0uMDE1LS43NjgtLjA0NS0xLjE2NnptLTguNTY1IDguNTc5Yy0uMzM5LS4zMzgtLjMzOS0uODg2IDAtMS4yMjQuMzM4LS4zMzguODg2LS4zMzggMS4yMjQgMCAuMzM5LjMzOC4zMzkuODg2IDAgMS4yMjQtLjMzOS4zMzktLjg4Ni4zMzktMS4yMjQgMHptMi40NDktMi40NDhjLS42NzctLjY3Ni0uNjc3LTEuNzcyIDAtMi40NDlzMS43NzMtLjY3NiAyLjQ0OSAwYy42NzcuNjc2LjY3NyAxLjc3MiAwIDIuNDQ5LS42NzYuNjc2LTEuNzcyLjY3Ni0yLjQ0OSAwem0tNi4wODkgOC43MjNjLTEuNDY5IDEuNzk3LTMuODEyIDMuMDg0LTYuMTI2IDMuMDg0LTMuMiAwLTUuNjI0LTIuMzU0LTUuNjI0LTUuMzExIDAtMS40ODUuNjExLTMuMTIyIDIuMDQzLTQuNjg5LTMuMjAxIDUuMTM3IDEuNTMyIDkuODMxIDcuMTAxIDQuMzFsLjc2NS43NjVjLS45MSAxLjA2MS0xLjg0IDEuOC0zLjE0OSAyLjQ2MSAxLjM0NC4wNTcgMy4wNDktLjU2OCA0LjE5NS0xLjQxNmwuNzk1Ljc5NnoiLz48L3N2Zz4=") no-repeat 0 0;
				mask-size: cover;
				position:relative;
				
			}
			/*&::after{
				display:none;
			}*/
		}
		/*&.data-inschrijven a:hover,&.data-inschrijven a.active{
			border: 1px solid $color-blue;
			color: #fff !important;
			background: $color-blue;
			&::before{
				background-color:white;
			}

		}*/
		/*11-10-2021*/
		&.download-brochure a {    
			background-color: #FF9900;
			border-radius: 8px;
			opacity: 1;
			padding: 13px 16px !important;
			color: #fff!important;
			font-weight: 400;
			-webkit-transition: all .4s ease;
			-o-transition: all .4s ease;
			-moz-transition: all .4s ease;
			transition: all .4s ease;
			font-weight: 700;
			display: flex;
			 border: 1px solid $color-orange;
			display: flex;
			margin-bottom: 10px;
			&::before{
				display: inline-block;
				width: 15px;
				height: 15px;
				margin-right: 5px;
				content: "";
				background-color:#fff;
				mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjIgMGgtMTdjLTEuNjU3IDAtMyAxLjM0My0zIDN2MThjMCAxLjY1NyAxLjM0MyAzIDMgM2gxN3YtMjBoLTR2OGwtMi0yLTIgMnYtOGgtOC41MDVjLTEuMzc1IDAtMS4zNzUtMiAwLTJoMTYuNTA1di0yeiIvPjwvc3ZnPg==") no-repeat 0 0;
				mask-size: cover;
				position:relative;
			}
			 &::after{
				display:none;
			}
		}
		&.download-brochure a:hover{
			border: 1px solid $color-orange;
			color: #FF9900 !important;
			background: transparent;
			&::before{
				background-color:$color-orange;
			}

		}
		
	  }
  }
  @media print {
	display:none!important;
  }
  

  .menu > li {
    & > a {
      padding: 0.9rem 0.9rem;
    }
  }
}