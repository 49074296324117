.singlecol-nieuwsbrief {
	padding-bottom:0px;
}
.nieuwsbrief {
	background-color: #1C2663;
	&__container{
		height:calc(100vh - 74px); 
		& > .row {
			height:100%;
		}

	}
	//height:80vh;
	&__image-column{
		padding:0;
		height:100%;
		img{
			height:100%;
			object-fit: cover; 
		}
	}
	&__content-column{
		padding:20px;
		padding-top:70px;
		@include breakpoint(large){
			padding:100px;
			width:80%;
			height: 100%;
			overflow:scroll;
		}
		&.welcome{
			@include breakpoint(large){
				padding:80px;
			}
		}
		& > .page {
			@include breakpoint(large){
				width:80%;
				max-width:600px;
			}
			&.welcome{
				@include breakpoint(large){
					width:100%;
					max-width:600px;
				}
			}
		}
		h1 {
			margin-bottom:25px;
			font-size:26px;
			position: relative;
			display: inline-block;
			@include breakpoint(large){
				font-size:35px;
			}
			&::after{
				content:" ";
				width:75px;
				height:75px;
				background-image: url('/images/you-got-mail.png');
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				display: inline-block;
				right:-125px;
				top:-50px;
				@include breakpoint(large){
					width:110px;
					height:110px;
					right:-160px;
					top:-85px;
				}
			}
			&.yougotthis::after{
				background-image: url('/images/Logo_Sticker-YouGotThis-v2.png');

			}
		}
		&.welcome{
			h1::after{
				background-image: url('/images/Logo_Sticker-YouGotThis-v2.png');
				@include breakpoint(large){
					width:60px;
					height:60px;
					right:-60px;
					top:-65px;
				}
			}
			input{ width:100%!important;}
			input[type="checkbox"] { width: unset !important;}
		}
		p {
			font-size:16px;
		}
		.gray-text{
			color:#919191;
			font-size:14px;
			@include breakpoint(large){
				font-size:15px;
			}
		}
		.signup--stap{
			padding:0px;
			margin-top:30px;
			margin-bottom:50px;
			background-color: transparent;
			@include breakpoint(large){
				margin-top:40px;
				margin-bottom:60px;
			}
			.signup__container {
				padding:0px!important;
				.login-off-canvas__form{
					margin-bottom: 0px;
				}
				label{
					background-color: white!important;

				}
				input{
					width:95%;
				}
				.login-off-canvas__form__input-withbutton input{
					width:100%;
				}
			}
		}

	}
	//background:$color-business-blue;
	&__yougotthis{
  		text-align:left;
		&__hoekje{
			width: 0px;
			height: 0px;
			border-top: solid 20px transparent;
			border-right: solid 5px transparent;
			border-bottom: solid 10px $color-business-blue;
			border-left: solid 10px $color-business-blue;
			margin-top: 20%;
			margin-left:-5px;
			display:inline-block;
			vertical-align: bottom;
		}
		&__tekst{
			text-align:left;
			width:80%;
			@include breakpoint(medium) {
				width:60%;
			}
			@include breakpoint(large) {
				width:80%; 
			}
			background:$color-business-blue;
			padding:20px;
			display:inline-block;
			h1{
				color:white;
				font-size:3rem;
				text-transform:uppercase;
			}
			&__titel{
				font-size: rem-calc(17);
				@include raleway;
				font-weight: bold;
				line-height: 1.4;
				margin-top: 0;
				margin-bottom: .5rem;
			}
			&__text{
				color:white;
			}
		
		}
	}
}
.nieuwsbrief-aanmelden {
  $padding: 80px 20px;
  padding: $padding;
  &__callout{
  	border: 2px solid #1C2663;
	border-radius: 8px;
	padding:15px;
	@include breakpoint(large){
		padding:35px;
	}
	margin: 0px 15px;
	.event__cta__title{
		
  		@include breakpoint(large){
  			width: 70%;
    		margin: 0 auto;
    		margin-bottom:30px!important;
  		}
	}
  }
  &__titel {
    font-family:'Lexend', sans-serif;
	font-size: rem-calc(25);
    color: white;
    font-weight: 800;
    margin: 0;
    text-align:start;

	@include breakpoint(medium){
		font-size: rem-calc(35);
		text-align:center;
	}
  }

  &__text {
    color: white;
    text-align:start;
    margin-bottom:50px;
	font-size: 16px;
	margin-top: 10px;

	@include breakpoint(medium){
		font-size: 25px;
		text-align:center;
	}
  }
  &__flex{
  	@include breakpoint(large){
  		display:flex;
  	}
  	&__fields{
  		input{ width:unset!important; }
  		input[type="text"], .input-group-field{
  			width:100%!important;
  			border: 1px solid #1C2663;
			border-radius: 8px;
			box-shadow:none;
			padding:1em;
  		}
  		@include breakpoint(large){
			flex-grow:1;
			display:flex;
			flex-wrap:wrap;
			gap:10px;
			& > div{
				flex-basis:49%;
			}
  		}
  	}
  	&__cta{
  		@include breakpoint(large){
  			padding-top:24px;
  		}
  		button{
  			width:100%!important;
  			@include breakpoint(large){
				padding: 16px 20px;
			}
  		}
  	}
  }
  &__form {
  	 label{
  	 	font-size:rem-calc(14px);
  	 	letter-spacing: -0.28px;
  	 	color:$color-blue;
  	 }
	 input {
	 	margin-bottom:15px;
	 	border-radius:5px;
	 	padding:20px 20px;
	 	@include breakpoint(medium) {
	 		width:38%;
	 		display:inline-block;
	 		margin-bottom:0px;
	 	}
	 }
	 input::placeholder{
		opacity: 1; /* Firefox */
	 	color:$color-business-blue;
		font-size:18px;
	 }
	 button {
	 	width:100%;
	 	@include breakpoint(medium) {
	 		width:22%;
	 		display:inline-block;
	 		margin-bottom: 3px;
	 	}
	 	.cf-button__text{
	 		margin: -2px 15px 0 15px;
			font-size:18px;
			font-weight:800;
	 	}
	 }
  }
  @media print {
  	display:none!important;
  }
}

.brochure {
	background:white;
	&__yougotthis{
  		text-align:left;
		&__hoekje{
			width: 0px;
			height: 0px;
			border-top: solid 20px transparent;
			border-right: solid 5px transparent;
			border-bottom: solid 10px $color-business-blue;
			border-left: solid 10px $color-business-blue;
			margin-top: 20%;
			margin-left:-5px;
			display:inline-block;
			vertical-align: bottom;
		}
		&__tekst{
			text-align:left;
			width:80%;
			@include breakpoint(medium) {
				width:60%;
			}
			@include breakpoint(large) {
				width:80%; 
			}
			background:$color-business-blue;
			padding:20px;
			display:inline-block;
			h1{
				color:$color-business-blue;
				font-size:3rem;
				text-transform:uppercase;
			}
			&__titel{
				font-size: rem-calc(17);
				@include raleway;
				font-weight: bold;
				line-height: 1.4;
				margin-top: 0;
				margin-bottom: .5rem;
			}
			&__text{
				color:$color-business-blue;
			}
		
		}
	}
}
.brochure-aanmelden { 
  $padding: 20px;
  padding: $padding;

  &__titel {
    @include raleway;
    font-size: rem-calc(25);
    color: $color-business-blue;
    text-transform:uppercase;
    font-weight: 800;
    margin: 0;
    text-align:center;
  }

  &__text {
    color: rgb(10, 10, 10);
    text-align:left;
    margin-bottom:20px;
  }
  
  &__afbeelding{
  	text-align:center;
  }
  
  &__button{
  		text-align:center;
	 	@include breakpoint(medium) {
  			text-align:left;
	 	
	 	}
  	.cf-button{
  		text-align:center;
  		display:inline-block;
  		margin-top:10px;
	 	@include breakpoint(medium) {
  			text-align:left;
	 	
	 	}
  	}
  }

  &__form {
	 input {
	 	margin-bottom:15px;
	 	border-radius:5px;
	 	padding:0.65em;
	 	@include breakpoint(medium) {
	 		width:39%;
	 		display:inline-block;
	 		margin-bottom:0px;
	 	}
	 }
	 input::placeholder{
	 	color:$color-darker-gray;
	 }
	 button {
	 	width:100%;
	 	@include breakpoint(medium) {
	 		width:20%;
	 		display:inline-block;
	 		margin-bottom: 3px;
	 	}
	 	.cf-button__text{
	 		margin: -2px 15px 0 15px;
	 	}
	 }
  }
  @media print {
  	display:none!important;
  }
}
.nieuwsbrief-aanmelden__knop{
	height:65px !important;
}