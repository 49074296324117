.callout{
	border-radius: 12px;
	box-shadow: 0px 3px 10px rgba(42,62,82,0.2);
	color:$color-business-blue;
	font-weight:600;
	&.icon{
		display:flex;
		column-gap:20px;
		align-items:center;
		flex-wrap: wrap;
	}
	&__icon{
		img{ width:50px; height:50px; }
	}
	&--blue{
		border:1px solid $color-business-blue;
	}
	&--lightblue{
		border:3px solid rgba(59, 195, 190, 0.5);
	}
	&--yellow{
		border:3px solid rgba(245, 160, 1, 0.5);
	}
	&--red{
		border:3px solid rgba(239, 81, 85, 0.5);
	}
}