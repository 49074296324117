/* Class for clearing floats */
.clear {clear:both; display:block; overflow:hidden; visibility:hidden; width:0; height:0;}
.cf:after,.cf:before{display: table;content: ''}
.cf:after{clear: both;}
.categorie__container *, .categorie__container *:before, .categorie__container *:after {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}


.categories-body{
    color: #1e225c;
    //border-top: solid 0.3px #efefef;
    position: relative;
}
.mr-10{
	margin-right: 10px;
}
.categories-body ul,.categorie__container ul {
    margin: 0px;
    padding:0px;
}
.categories-body ul:not(.training__programma__accordion) li, .categorie__container ul:not(.training__programma__accordion) li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 16px;
    position: relative;
 
}
.hbo-body-left ul:not(.training__programma__accordion) {
	padding:initial;
	margin:0px 0px 20px 20px;
}
.hbo-body-left ul:not(.training__programma__accordion) li {
	margin-bottom:5px;
	list-style: disc;
}
.hbo-body-left ul:not(.training__programma__accordion) li a{
	text-decoration:underline;
}

.container{
    width: 100%;
    max-width: 1380px;
    margin: 0px auto;
    padding: 0px 20px
}
.categories-body h1:not(.categorie__overview__title):not(.training-intro__titel):not(.training-header__title), .categorie__container h1:not(.categorie__overview__title):not(.training-intro__titel):not(.training-header__title){
    font-size: 45px;
    color: #1e225c;
    line-height: 40px!important;
    text-align: left;
    font-family: Lexend, Open sans;
    letter-spacing: -1;
    margin-bottom: 2rem!important;
	
}
.categorie__container h1:not(.categorie__overview__title):not(.training-intro__titel):not(.training-header__title){
	font-size:45px;
	line-height:50px;
	@include breakpoint(medium up){
    	font-size: 45px;	
		padding-right:70px;
	}
}
.categories-body h2.h2, .categorie__container h2.h2 {
}
.h2-2rem{
    font-size: 2.5rem;
}
.small-text{
    font-size:  12px;
    font-weight: 600;
}

 .block{
    display: block !important;
 }
.white{color: #fff !important;}
.light-blue{ color: #8b8dab;}
.orange{color: #ffa120; svg path{fill:#ffa120;}}
.yellow{color: #FFC107 svg path{fill:#FFC107;}}
.l-yellow{color: #ffc069;}
.pink{color: #ff6969;}
.green{color: #8dff46;}


.flot-left{float: left;}
.flot-right{float: right;}

.width-50{width: 50%;}

.bg-color{background-color: #ffbc24;}
.bg-offwhite{background-color: #fafafc;}
.bg-blue{background-color: #1e225d;}
.bg-transparent{background-color: transparent !important;}


.mt-20{margin-top: 20px;}
.mt-50{margin-top: 50px;}
.mt-60{margin-top: 60px;}
.mr-20{margin-right: 20px;}
.mt-120{margin-top: 120px;}
.mb-20{margin-bottom: 20px;}
.mb-50{margin-bottom: 50px;}
.pb-50{padding-bottom: 50px;}

.flex {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;   
}
.flex-start{
    justify-content: flex-start;
}
.wrap{
    flex-wrap: wrap;
}
.justi-space-btw{
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}
.left-text .justi-space-btw{
    justify-content: flex-start;
    column-gap:30px;
}
.aline-center{
    align-items: center;
}
.aline-end {
    align-items: flex-end;
}
.left-text {
    padding-right: 100px;
}
.link{
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left; 
    display: inline-block;  
    font-stretch: 14px;      
}
.link-a {
    background: #f48a25;
    padding: 7px 14px;
    color: #fff;
    position: absolute;
    bottom: 26px;
    right: 15px;
}
.bg-color{
	border-radius: 0px 0px 0px 70px;
	opacity: 1;
	height: 550px;
	position: relative;
}
.flex-basis-50{
	flex-basis: 50%;
}

.flex-basis-60{
    flex-basis: 60%;
}
.flex-basis-30{
    flex-basis: 30%;
}
.btn-yellow {
	font-family:$header-font-family;
	text-decoration:none;
    background-color: #FFA120;
    border-radius: 8px;
    opacity: 1;
    padding: 19px 30px;
    color: #fff !important;
    font-weight: 400;
    border:  2px solid #FFA120;
    -webkit-transition: all .4s ease; 
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    font-weight: bold;
    display:inline-block;
    svg path{ fill:white; }
    &.disabled{
        background-color: transparent;
        border-color:#E2E2E2;
        color:#E2E2E2!important;
        svg path{ fill:#E2E2E2; }
    }
}
.btn-yellow a {
    color: #fff;
}
.btn-yellow:hover{
    border-color: #FFA120;    
    color:  #FFA120 !important;
    background-color: #FFF;
    cursor:pointer;
    svg path{ fill:#FFA120; }
    
}
.btn-yellow.disabled:hover{
    background-color: transparent;
    border-color:#E2E2E2;
    color:#E2E2E2!important;
    svg path{ fill:#E2E2E2; }
    cursor: default;

}

.banner-body ul li{
    font-size: 18px;
}
.black-border-btn{
	/*background: #FFFFFF 0% 0% no-repeat padding-box;*/
	border: 2px solid #1E225C;
	font-family:$header-font-family;
	text-decoration:none;
	border-radius: 8px;
	opacity: 1;
	padding: 15px 30px;
	-webkit-transition: all .4s ease; 
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    color: #1E225C;
    text-align: center;
    display: inline-block;
    font-weight: bolder;
}
.black-border-btn:hover{
	background: #1E225C 0% 0% no-repeat padding-box;	
	color: #fff;
	cursor:pointer;

}
//a{
//	text-decoration: none;
//}
.right-img {
    position: relative;
}
p.designation strong {
    font-size: 14px;
    color: white;
    
}
p.designation {
    position: absolute;
    bottom: 30px;
    left: 50px;
    font-size: 15px !important;
    color: white;
    z-index: 50;
}
img#logo {
    //top: -13px;
}
.img-caption {
    background: rgba(0,0,0,0.1);
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    border-radius: 15px;
    padding: 15px;
    position: absolute;
    top: 30px;
    width: fit-content;
    color: #fff;
    left: 30px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  /*  border: solid 0.5px #dddddd76;*/
   
}
.img-caption p {
    width: 354px;
    font-size: 13px;
    padding-left: 15px;
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: top;
}

.img-caption strong {
    padding-left: 8px;
    font-size: 14px;
    color: #ffffff;
    display: block;
}
.profile-img-img {
    width: 60%;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.listing span{
    font-size: 14px;
}
.listing div {
    position: relative;
}
.listing svg {
    position: absolute;
    left: 0px;
    top: 5px;
}

.listing span {
    padding-left: 25px;
    display: block;
}
.left-text p {
    font-size: 16px !important;
    font-weight: 400 !important;
}
/*.categorie__container p {
    font-size: 16px !important;
    font-weight: 400;
}*/
.post-hbo h3{
    font-size: 18px;
}
#keuzehulpWhitepaperTitle{
	font-size:16px;
    display:flex;
    justify-content: center;
    div{
        position: inherit;
        height: 19px;
        width: 16px;
        margin-top: 2px;
        padding-right:30px;
        filter: invert(98%) sepia(8%) saturate(83%) hue-rotate(320deg) brightness(120%) contrast(100%);
    }
}
.cluster-header__content__text__buttons__button{
    .cf-button__text{
        font-size:16px;
    }
}
#keuzehulp_backgroundimgbanner{
    img{
        @include breakpoint(large){
            //height: 70vh;
            max-width: 100%;
        }
    }
}
.wij-trainers-container{
    margin:0;
    max-width: 100%;
    padding:0;
}
.opleiding-container{
    margin:0;
    max-width: 100%;
    padding:0;
    .keuzehulp-tabbingdiv{
        margin-left:1rem;
        @include breakpoint(medium){
            margin-left:auto;
        }
    }
    .slick-track{
        width:max-content !important;
    }
    .slider__counter{
        @include breakpoint(large){
            margin-right:200px;
        }
    }
}
.icon-home2{
    width:16px;
    height:15px;
    filter: invert(11%) sepia(46%) saturate(2857%) hue-rotate(223deg) brightness(100%) contrast(97%);
  }
#trainingen{
    margin-top: 0;
    padding-top: 120px;
    background-color:#F8F8FA;
}
.titleTopCatogorytrainingen{
 

    div{
        text-align: start;

        h2{
            font-family: 'Lexend', sans-serif;
            font-size:30px;
            font-weight: 600;

            margin-left:0.5rem;
            @include breakpoint(medium){
                margin-left:initial;
            }
        }
    }
}
#keuzehulpTabbing{
    flex-basis: 100% !important;
    overflow: auto;

    .tabbing{
        display: inline-flex;
        border-radius: 8px;
        background: #fff;
        padding: 5px;
        column-gap: 5px;
        margin-bottom: 15px;
        margin-left:10px;
        box-shadow: 0px 3px 10px hsla(210, 32%, 24%, 0.129);
        @include breakpoint(medium){
            margin-bottom: 35px;
            margin-left:0px;
            margin-right:20px;
        }
        .tab-link{
            border-radius: 8px;
            padding: 10px 20px;
            border:0px;
            margin-bottom: 0px !important;
            font-size:15px;
            font-weight: 600;
            font-family: 'Lexend', sans-serif;
        }
    }
}
.categorie__container span{
    font-size:  14px;
}
.deze-box-body {
    padding-top: 15px;
}
.deze-box-body p{
	font-stretch: 16px;
}
.listing {
    display: flex;
    justify-content: space-between;
    padding: 35px 0px 0px 0px;
}
.title h2 {
	color: #1e225c;
	text-align: center;
	letter-spacing: 0px;	
	opacity: 1;
	font-size: 2rem;
	line-height: 48px;
	text-align: center;
    font-weight: 900;
}

.categories-body .trainingen {
    margin-top: 120px;
}
.left-text ul {
    margin-bottom: 45px !important;
    margin-top: 40px !important;
}
.listing-body {
    padding: 20px 0px 0px;
}
.title p {
    max-width: 800px;
    margin: 0px auto;
    padding: 20px 0px;
}
.title {
    text-align: center;
}
/*tabbing */
ul.tabbing{
    margin: 0px;
    padding: 0px;
    list-style: none;
    
}
.tab-title{
    flex-basis: 60%;
}
.tab-title:nth-child(2) {
    flex-basis: 40%;
}

ul.tabbing li.current {
    background: #106bc6;
    color: #fff;
}
.verticle-tabbing ul.tabbing li.current {
    color: #fff;
    border: solid 0.5px #707070;
    background-color: #1a2c6c;   
    color: #fff;
}
.verticle-tabbing .tab-content{
    margin-top: 0px;
}
.verticle-tabbing .tab-content strong {
    padding: 20px 0px;
    display: block;
}

ul.tabbing li {
    background: none;    
    display: inline-block;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 12px;
    font-weight:600;
    line-height: 20px;
    border: solid 0.5px rgba(30,34,92,0.462);
    background-color: #fff;
    margin-right: 10px;
}
.verticle-tabbing ul.tabbing li {
    display: block;
    border-radius: 2px;
    border: transparent;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1e225c;
    font-size: 20px;
    position: relative;
    width: 350px;
    padding: 15px;
    margin-left: 50px;
    -webkit-transition: all .4s ease; 
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;

}
.verticle-tabbing li.tab-link:hover {
    background-color: #1a2c6c;
    color: #fff !important;
}
.verticle-tabbing ul.tabbing li span{
     font-size: 16px;
}
.tab-content .left{
    padding-right: 60px;
}
 .tab-content .right{
    padding-left: 60px;
 }
 #tab-filter{
 display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    row-gap:1.8rem;
 }
.verticle-tabbing  ul.tabbing {
    position: relative;
}
.verticle-tabbing  ul.tabbing:before {
    border-right: 2px solid;
    content: "";
    width: 2px;
    height: 100%;
    background: #1a2c6c;
    display: block;
    position: absolute;
    right: 35px;
    opacity: .3;
}
.verticle-tabbing .tab-content-body {
    width: 100%;
}
.count-no {
    position: absolute;
    left: -50px;
    height: 35px;
    width: 35px;
    border: 3px solid;
    text-align: center;
    border-radius: 100%;
    line-height: 30px;
    color: #1a2c6c;
    top: 12px;
    /* padding: 10px; */
}
.count-no:after {
    content: "";
    width: 2px;
    height: 55%;
    display: block;
    background-color: #000;
    position: absolute;
    left: 48%;
    /* top: 10px; */
    margin-top: 7px;
}
.tab-link:last-child .count-no:after{
        background-color: transparent;
}
.programma-body-box {
    padding: 50px 60px;
    border-radius: 15.5px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-color: #fff;
}

.programma-body-box .tab-content-body li, .programma-wrapper.mobile .programma-body-box li{
	list-style: disc outside none;
	display: list-item;
	margin-left: 1.25rem;
}

.tab-content{
    display: none;
    background: transparent;
  /*  padding: 15px;*/
    margin-top: 20px;
        height: max-content;
}

.tab-content.current{
    display: inherit;
}

.tab-grid{
    margin-top: 20px;
    
    .grid-item{
    	margin-bottom:20px;
    	padding:0px;
    }
}
.design-training-slider-body,
.online-training-slider-body{
    position: relative;
    padding: 50px 0px 0px;
    margin-bottom: 80px;
}
.design-training-slider-body:before,
.Wij-trainers-wrapper:before,
.online-training-slider-body:before{
    content: "";
    background-image: url(/images/dot-bgv4.png);
    height: 100%;
    width: 50%;
    position: absolute;
    background-repeat: repeat;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-position: center;
    background-size: unset;   
}
.Wij-trainers-wrapper{
    position: relative;
}
.Wij-trainers-wrapper:before{
    right: 0px !important;
    left: auto;
}
.categorie__container .tab-title{
	margin-top:0px;
}
.categorie__container .tab-head {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
    border:none; //border: solid 0.3px rgba(2,37,67,0.419);
    background-color: #fff;
    padding-bottom:0px;
}
.categorie__container .tab-head h6 {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
   /* border-bottom: 2px solid #747AA557;*/
    margin-right: 40px;
    margin-bottom: 10px;
}
.categorie__container .tab-title{
	padding:0px;
	@include breakpoint(medium up){
    	padding: 20px 20px;
    	padding-bottom:15px;
    }
    &.categories{
    	overflow:scroll;
    	flex-basis: 75%;
    }
}
.categorie__container .tab-title:nth-child(2) {
    flex-basis: 25%;
}
.categorie__container ul.tabbing li{
	margin-bottom:0px;
	margin-right:0px;
	@include breakpoint(medium up){
		margin-bottom:10px;
		margin-right:10px;
	}
}
.categorie__container .tab-title.filter{
    border-left: 1px solid #D6D6D6;
    select{
    	margin-bottom:0px;
    	font-size: 12px;
    	font-weight:600;
    	padding-left:2rem;
    	height: initial;
    	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2830, 34, 92%29'></polygon></svg>"), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style="fill: rgb%2830, 34, 92%29" d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>');
		background-origin: content-box, content-box;
		background-position: right -1rem center, left -1.5rem center;
		background-repeat: no-repeat, no-repeat;
		background-size: 9px 6px, 18px 15px;
		border: 1px solid $color-blue;
		color:$color-blue;
    }
}
.categorie__container .loadmore{
	border-top:1px solid #D6D6D6;
	margin-top:25px;
	padding-top:25px;
	text-align:center;
}
.Waarom-box p {
    font-weight: 500;
}
/*slider*/


.design-training-slider .slick-list, .Wij-trainers-slider .slick-list, .online-training-slider .slick-list {
    padding-left: 0px!important;
}

.slick-slide{
    //height: auto !important;
}
.design-training-slider, .design-training-slider-fullwidth, .online-training-slider{
    height: 100% !important;
    background: transparent !important;
}
.design-training-slider .slick-slide,  .design-training-slider-fullwidth .slick-slide, .online-training-slider .slick-slide{ margin-right:20px; }
.design-training-slider .training-grid-block__intro, .design-training-slider-fullwidth .training-grid-block__intro{
height:100px;
}
.slick-slider {
    margin: 0px !important;
}
.slick-slide img {
    display: block;
    width: 100%;

}
.slide-box {
    margin-right: 20px;
    background-color: #fff;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 7px 7px 0 rgba(42,62,82,0.129);
    background-color: #fff;
    margin-bottom: 40px;
    border-radius: 8px;
    overflow: hidden;
    @include transition(all 400ms);
}
.Wij-trainers-slider .slide-box{
	width:400px;
}
.img-box {
    height: 230px;
    overflow: hidden;
}
.box-content {
    padding: 20px;
}
.box-content p {
   font-size: 14px!important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-height: 60px;
    margin-bottom: 20px;
}
.Wij-trainers-slider .box-content p {
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-height: 70px;
}
.box-content h3 {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 50px;
}

.box-foot-nav ul {
    display: flex;
    justify-content: flex-start;
}
.box-foot-nav ul li {
    padding-right: 30px;
    color: #000;
    opacity: 0.39;
    font-size: 13px;
}
.heart {
    position: absolute;
    right: 13px;
    z-index: 222;
    font-size: 26px;
    top: 12px;
    color: #fff;
}
.slider__counter {
    position: absolute;
    bottom: -25px;
    right: 162px;
    font-weight: 600;
    font-size: 18px;
}
.box-foot-nav ul {
    transform: none;
}

button.slide-arrow {
    cursor: pointer;
    background-color: #1b2a68;
    padding: 14px;
    display: block;
    position: absolute;
    bottom: -40px;
    right: 0px;
    z-index: 1;
    width: 60px!important;
    height: 60px!important;
    border-radius: 5px!important;
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 32px;
}
button.slide-arrow.prev-arrow.slick-arrow:after {
    content: ' \276E';
    display: block;
    position: absolute;
    display: block;
    z-index: 555;
    color: #fff;
    border: 1px solid;
    border-radius: 100%;
    padding: 0px 12px;
}
button.slide-arrow.prev-arrow.slick-arrow {
    right: 75px;
}
button.slide-arrow.next-arrow.slick-arrow:after{
    content: ' \276F';
   
    display: block;
    position: absolute;
    display: block;
    z-index: 555;
    color: #fff;
    border: 1px solid;
    border-radius: 100%;
    padding: 0px 12px;
}
button.slide-arrow{
    cursor: pointer;
}
.Waarom-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px rgba(47,50,65,0.403);
    
	box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
    border-radius: 8px;
    opacity: 1;
    margin: 0px 15px;
    padding: 50px;
    text-align: center;
    flex-basis: 25%;
}
.Waarom-box img {
    padding-bottom: 30px;
    max-width:100px;
    max-height:100px;
    object-fit:contain;
}

.Waarom-body{
    position: relative;
    top: -50px;
    margin-top: 150px;
}
.Waarom-bg {
    background-color: #f5f5f5;
    padding: 0px 0px 30px;
    margin-top: 40px;
}
.video-body video {
    width: 100%;
    min-height: 500px;
}
.video-body img {
    width: 100%;
}
.video-body {
    border-radius: 0px 0px 0px 45px;
    overflow: hidden;   
    position: relative;        
}
.Waarom-video-text {
    padding-left: 50px;
}
 .Waarom-video-wrapper {
    margin-top: 50px;
    margin-bottom: 120px;
}
.yellow-border-btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f48a25;
    border-radius: 4px;
    border: solid 1px #f48a25;
    background-color: #fff;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 20px;
        padding: 18px 36px;

}
.yellow-border-btn:hover, 
.yellow-border-btn:focus{
        background-color: #f48a25;
        color: #fff;
}
.blue-fill-btn {
    padding: 13px 30px;
    border-radius: 6px;
    border: 2px solid #1b2a68;
    box-shadow: 0 7.5px 15px 0 rgba(27,42,104,0.25);
    background-color: #1b2a68;
    color: #fff;
    display: inline-block;
    -webkit-transition: all .4s ease; 
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 20px;
    font-weight: 600;
}
.blue-fill-btn:hover,
.blue-fill-btn:focus{
    color: #1b2a68;
    border: 2px solid #1b2a68;
    background-color: #fff;
}
.vid-btn {
    font-size: 50px;
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
     transform: none; 
    text-align: center;
     border: none; 
    border-radius: 100%;
    opacity: .95;
    -webkit-backdrop-filter: none;
    backdrop-filter:none;
    height: 100%;
    width: 100%;
}
.vid-btn iframe {
    height: 100% !important;
    width: 100% !important;
}

/*#vid-icon {
    font-size: 50px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) !important;
    text-align: center;
    border: 20px solid rgba(255,255,255,0.631);
    border-radius: 100%;
    opacity: .95;
    -webkit-backdrop-filter: blur(10.7px);
    backdrop-filter:none;
    cursor: pointer;   
}*/

.deze-profile-name strong{
    display: block;
}
.deze-pro-img img{
    height: 100%;
    width: 100%;
}
.deze-pro-img {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    overflow: hidden;
}

.deze-voor-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 36px rgba(103,103,103,0.16);
    border-radius: 14px;
    opacity: 1;   
    padding: 25px;
    margin: 40px 15px;
}
.deze-voor-box-body {
    margin: 0px -20px;
        margin-top: 30px;
}
.deze-cat span {
    display: block;
    width: 100%;
}
.deze-profile-name {
    padding-left: 90px;
    padding-top: 5px;
}
.deze-voor-wrapper {
    margin-bottom: 100px;
}
/*.Wil-wrapper start */
.Wil-wrapper {
   /* border-radius: 5px;
    box-shadow: 0 7.5px 20px 0 #2a3e5221;*/
/*    background-color: #4faead;*/
    padding: 100px;
    margin-bottom: 120px;
    
}

.categories-body .will-img img, .categorie__container .will-img img {
    width: 100%;
}
.categories-body .will-img,.categorie__container .will-img {
    width: 18%;
    height: auto;
}
.categories-body .will-text, .categorie__container .will-text {
    width: 60%;
}
.Wij-slider-body {		  
    width: 100%;
}
.Wij-trainers-text {
    width: 100%;
    display: grid;
    justify-items: center;

    h2{
        text-align: center !important;

        @include breakpoint(large){
        font-size: 39px !important;
        max-width: 900px;
        font-weight: 800 !important;
        }
    }
}
.intrainerbody{
	list-style-type:none;
	margin-left:0px;
    display: grid;
    justify-content: center;
    margin-top:50px !important;

    li{
        margin-left:50px;
        @include breakpoint(medium){
            font-size:17px !important;
            margin-left:0;
        }
        margin-top:10px;

        &::before{
            height:29px !important;
            width:23px !important;
            left:-10px !important;
        }
    }
}
#subclusterCATE{
    .incompany__intro__container__text__subclusters__row{
        .cf-button__text{
            font-size:16px;
            font-family: 'Lexend', sans-serif;
        }
    }
}
.testimonial-block__background__image2{
    content: "";
    height: 110%;
    width: 100%;
    position: absolute;
    background-repeat: repeat;
    top: 10%;
    left: 0px;
    z-index: -1;
    background-position: center;
    background-size: unset;
    background-color: #f8f8fa;
    }
.Wij-trainers-wrapper {
    padding: 80px 0px 70px 0px;
}
.Wij-trainers-slider .slick-track{
    display: flex !important;
}

.Wij-trainers-slider .slick-slide{
    height: inherit !important;
}

.Wij-trainers-slider  .slick-slide{
    display: flex !important;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
}
.Wij-slider-body h3 {
    padding-bottom: 20px;
}
/*.praktijkcases-wrapper section start */
.praktijkcases-wrapper {
    margin-top: 120px;
    margin-bottom: 120px;
}
.praktijkcases-body {
    padding-top: 30px;
    position: relative;
}

.left-imgbox {
    background-size: cover;
    height: 580px;
    width: 80%;
    background-repeat: no-repeat;
    border-radius: 5px;
    background-position: center;
}

.contact-box {
    width: 550px;
    right: 0;
    /* top: 80px; */
    padding: 50px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
    margin-top: 80px;
    position: inherit;
    margin-left: -200px;
}
.contact-box button {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
}
.contact-box  a{
    display: block;
    width: 100%;
    text-align: center;
}
img.contactbox-img {
    width: 100px;
}

.kom-img img {
    width: 100%;
}
.kom-img {
    width: 35%;
    height: auto;
}
.Meer-body .slide-box {
	display:block;
    flex-basis: 31%;
}
.Meer-body .slide-box:hover {
  box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.35);
  cursor:pointer;
}
.Meer-body .slide-box:hover h3{
	color:#ffa120;
}
.box-foot-nav .link{
    -webkit-transition: all .4s ease; 
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 10px;
    text-decoration:none;
}
.box-foot-nav .link:hover svg {
    margin-left: 20px;
}
.img-box img {
    width: 100%;
    object-fit:cover;
}
    .img-box img {
    width: 100%;
    height: 100%;
}
.Meer-body {
    padding: 50px 0px 0px;
}
.sec-btn {
    display: block;
    text-align: center;
}
.Meer-wrapper {
    margin-bottom: 120px;
}
.date {
    display: block;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight:  300;
}
.direct-ux-wrapper p.white {
    font-size: 24px !important;
}
.overleggen-img {
    padding-left: 100px;
}
.overleggen-img p{
    margin: 0px;
}
.overleggen-img img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 290px;
}
.overleggen a {
    margin-right: 15px;
}
.direct-ux-wrapper {
       padding: 100px 0px 100px 0px;
    margin: 120px 0px;
    margin-top:-75px;
    background:#FAFAFC;
}
.direct-ux-body {
    margin-right: -20px;
    margin-top: 50px;
    flex-wrap:wrap;
}
/*alles-body*/
.alles-wrapper {
    margin-bottom: 120px;
}
.alles-body {
    position: relative;
}
.alles-left {
    padding-right: 80px;
}
.alles-img-block {
    border-radius: 11.5px;
    box-shadow: 0 7.5px 20px 0 rgba(42,62,82,0.129);
    overflow: hidden;
    height: 400px;
}
.alles-img-block img {
    width: 100%;
    height: 100%;
   
}
.alles-left .icon-ul li {
    padding-right: 20px;
    font-size: 14px;
    padding-bottom: 15px;
    padding-top: 10px;
}
.alles-rigth-card {
    width: 370px;
    padding: 25px 24px 21.5px 22px;
    box-shadow: 0 5px 19px 0 rgba(103,103,103,0.16);
    background-color: #fff;
    position: absolute;
    right: 0px;
    /* border-bottom: 99px; */
    bottom: unset;
    top: 280px; 
}
.card-title img {
    background-color: #1b2a68;
    padding: 10px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin-right: 20px;
}
.blue-checkmark li:before {
    content: "";
    background-image: url(/images/greencheckmark-blue.png);
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 5px;
}
.card-title {
    margin-bottom: 20px;
}
.alles-rigth-card .quote {
    margin-top: 25px;
    margin-bottom: 20px;
}
.alles-body a {
    margin-right: 30px;
}
.quote-sign {
    position: absolute;
    font-size: 88px;
    line-height: 37px;
    font-family: 'Poppins';
    z-index: 1;
    color: #1e225c;
    opacity: .1;
    font-weight: bold;
}
ul.li-dots li:before {
    content: "";
    background: #1b2a68;
    height: 6px;
    width: 6px;
    display: block;
    position: absolute;
    left: 0px;
    border-radius: 100%;
    top: 7px;
}

ul.blue-checkmark li,
ul.li-dots li{
    padding-left: 25px;
}

.Eerstvolgende-wrapper .direct-ux-body {
    margin-top: 30px;
}
.Eerstvolgende-wrapper .slide-box{
    width: 24%;
}
.Eerstvolgende-wrapper {
    padding: 50px 0px;
}
.van-wireframes {
    margin-top: 0px;
    padding-top: 80px;
}
.overleggen-body {
    background-color: #4faead;
    border-radius: 20px 0px 0px 45px;
    /*box-shadow: 0 7.5px 0px 0 #2a3e5221;*/
    border: solid 2.5px #fff;
    padding: 55px;
    border-right: none;
}
.overleggen-wrapper{
    margin: 20px 0px 120px 0px;
    position: relative;

}
.overleggen {
	position: relative;
}
.overleggen-wrapper:after, .overleggen:after  {
    content: "";
    background-color: #4faead;
    display: block;
    position: absolute;
    right: 0px;
    width: 45%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    z-index: -1;
    border-top: solid 2.5px #fff;
    border-bottom: solid 2.5px #fff;
}

/*accordian css*/

.faq-body ul .inner {
  /*padding-left: 1em;
  overflow: hidden;*/
  display: none;
}
ul.accordion li a:after {   
    content: ' \276F';
    transform: rotate(90deg);
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
}

.faq-body ul li {
    margin: 0.5em 0;
    border-bottom: 1px solid;
}

/*.faq-body ul li a.toggle {
    width: 100%;
    display: block;
    background: #fff;
    padding:  15px 0px;
    border-radius: 0.15em;
    transition: background 0.3s ease;
    color: #1E225C;
    font-weight: 600;
    font-size: 16px;
}*/

.left-fqa-text {
    padding-right: 90px;
}
.faq-wrapper {
    margin-bottom: 120px;
}
.banner-wrapper {
    position: relative;
    min-height: 550px;
}

/*06-10-2021*/

.deze-box-header-border {
    margin: 0px -25px; 
    border-bottom: 1px solid rgba(30,34,92,0.078);
}

.deze-box-header {
    padding: 0px 25px 20px;
}
.box-foot-nav ul li svg {
    margin-right: 6px;
}
.box-content strong {
    font-size: 22px;
}
.contact-box h3 {
    font-weight: 900;
}

.right-img {
    position: relative;    
    position: absolute;
    right: 0px;
    width: 50%;
    border-radius: 0px 0 0 70px;
    overflow: hidden;
    top: 0px;
}
ul.checkmark li {
    position: relative;
    padding-left: 25px;
}
ul.checkmark li:before {
    content: "";
    background-image: url(/images/check-mark.png);
    height: 16px;
    width: 20px;
    position: absolute;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 5px;
}
img.checkmark-svgs {
    width: 20px;
    position: absolute;   
}
li.calender-svg:after {
    background-image: url(/images/calendar.svg);
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 5px;
    content: "";
}
li.calender-svg:before {
    display: none;
}

a.black-border-btn:hover img#phone_icon {
    display: none;
}
img.call-white {
    display: none;
}
a.black-border-btn:hover img.call-white{
    display: block;
}
a.black-border-btn:hover img.call-white {
        display: inline-block;
    }
.profile-img-img img {
    width: 550px;
}
.tab-btn a {
margin-bottom:10px; 
}



/*product detail page start*/
.product-detail-banner:before {
    content: "";
    background: #ffbc24;
    right: 0px;
    position: absolute;
    top: 0px;
    height: 100%;
    width: 50%;
}
.product-detail-banner {
    position: relative;
    /*overflow: hidden;*/
    background-color: #ffbc24;
}

.post-hbo {
    margin: 60px 0px;
}
.h2-2rem{
    font-size: 1.8rem;
}
.rectangle-box {  
    padding: 25px;
    border-radius: 15.5px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-color: #fff;
    padding: 40px;
    position: relative;
}

.rectangle-box .faq-body ul li a.toggle {
    width: 90%;
}

.profile-designation p {
    //margin-left: 50px;
}
.profile-designation img{
    width: 80px;
}
.card-log {
    display: block;
    text-align: center;
}

.blue-reg-box{
    padding: 35px;
    background-color: #1e225d;
    border-radius: 10px 0px 0px 10px; 
}
.blue-reg-box ul li {
    margin-bottom: 5px;
    line-height: 28px;
    font-size: 16px;
}
.blue-reg-box .link {
    font-weight: bold;
    display: inline-block;
}
.blue-reg-box .link:hover, 
.blue-reg-box .link:focus {
    color: #fff;
}
.reg-box-foot strong {
    display: block;
    font-size: 15px;    
    font-weight: 900;
}
.reg-box-foot p {
}

.reg-box-foot {
    margin-top: 30px;
    border-top: 2px solid rgba(255,255,255,0.5);
    margin-left: -40px;
    margin-right: -40px;
    padding: 30px 40px 0px;
}
table{
    color: #1e225c;
    border-collapse: collapse;
}
thead {
    background: $color-blue !important;
     color: white!important;
    border: solid 0.5px #707070;
}
table tr{
    box-shadow: 0 1.5px 3px 0 rgba(0,0,0,0.16);
}
thead tr td {
    padding: 20px 10px;
}
table .btn-yellow {
    padding: 14px 30px;
    width: 100%;
    display: block;
    text-align: center;
}
table .black-border-btn{
    width: 100%;
    display: block;
    text-align: center;
}
tbody tr:nth-child(even) {
     border-bottom: none !important; 
     background-color: transparent !important; 
}
.banner-tab {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 36px rgba(0,0,0,0.16);
    border-radius: 18px;
    opacity: 1;
    padding: 20px 40px 20px;
  /*  margin-left: 60px;*/
        min-height: 0px;

}
.width-30{
     width: 30%;   
}

.banner-tab ul.tabbing li.current {
    border: none;
    background: transparent;
    border-bottom: 2px solid;
    border-radius: 0px;
    padding: 10px 0px;
    color: #FEA120;
}
.banner-tab ul.tabbing li{
    border: none;
    padding: 10px 0px;
    color: #1C2663;
    font-weight: 600;    
    font-size: 14px;

    
}
.tab-content ul li {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    font-size: 14px;
    margin: 0px;
}
.banner-body .left-text ul{
    margin: 0px !important
}
.card-log img {
    width: 200px;
    margin-bottom: 15px;
}
.phone {
    position: absolute;
    right: 30px;
}
.product-detail-banner .bg-color {
    border-radius: 0px 0px 0px 90px;
    opacity: 1;
    height: 650px;
    position: relative;
}
.product-detail-banner .left-text p {
    font-size: 16px !important;
}
.tab-btn a { 
    padding: 10px 20px;
    line-height: 20px;
}
.tab-btn{
    margin-top: 35px;
}
.product-detail-banner .link {
    font-size: 13px;
}
.banner-tab strong {
    font-size: 28px;
}

.product-detail-banner .profile-img-img {
    width: 80% !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index:20;
    @include breakpoint(medium up){
    	z-index:40;
    }
}
.FAQ-box  a {
    text-decoration: underline !important;
}

.hbo-body-left {
    padding-right: 100px;
    width: 70%;
}
.mobile{
    display: none;
}
/*.post-hbo iframe {
    margin-bottom: 40px;
}*/

.right-img.flex-basis-50 .incompany__intro__container__background__overlay{
	left:5px;
}
/* CSS Document */

/* media-query
------------------------------------------------*/
@media screen and (min-width: 75em){

}



@media handheld, only screen and (max-width: 1250px) {}

@media handheld, only screen and (max-width: 1140px) {
	.contact-box{
		padding: 20px 15px 30px 15px;
	}
	#trainingsdata-table {
		overflow-x: auto;
	}
}

@media handheld, only screen and (max-width: 1024px) {
	.title h2, h2.h2{
		font-size: 30px;
    	line-height: 35px;
	}
	.btn-yellow{
		padding: 19px 25px;
	}
	.black-border-btn{
    	padding: 13px 20px;
	}
	.Waarom-box{
	    padding: 30px 20px;
	}
	.Waarom-body{
	    margin-top: 70px;
	}
	.Waarom-video-wrapper {
	    margin-top: 0px;
	    margin-bottom: 60px;
	}
	.design-training-slider-body{
	    padding: 20px 0px 0px;
	    .container{
	    position:relative;
	    }
	}
	.categories-body  .trainingen {
	    margin-top: 60px;
	}
	 /*h3{
	    font-size: 1.3rem !important;
	}*/
	.box-content h3 {
	    font-size: 16px !important;
	}
	.link{
		font-size: 13px;
	}
	.will-text {
	    width: 60%;
	}
	.box-foot-nav ul li {
	    padding-right: 15px;
	}
	.box-content {
	    padding: 15px;
	}
	.box-content p {
	    font-size: 13px !important;
	}
	/*.alles-rigth-card{
	    bottom: 20%;
	}*/
	.alles-wrapper {
	    margin-bottom: 60px;
	}
	.alles-left {
	    padding-right: 50px;
	}
	.direct-ux-wrapper{
	    margin: 60px 0px;
	}
	.van-wireframes {	    
	    padding-top: 60px;
	}
	.faq-body ul li a.toggle{
		//padding-right: 25px;
	}
	
}

@media(max-width: 800px) {
		table {
			overflow-x:scroll;
		}
       /* table {border: 0;}
        thead{display: none;}
        tr{display: block;margin: 0 0 20px 0;}
        td{display: block;text-align: right; }
        td:before{content: attr(aria-label);float: left;font-weight: bold;}
        td:last-child{border-bottom: 0;}        
        tr:nth-child(odd){background-color: #F2F2F2;}
        tr:nth-child(even){background-color: #FFFFFF;border-width: 3px 0 3px 0;border-style: solid; border-color: #E5E5E5;}
        thead, tbody, tfoot{
        	border: none !important;
        }
        tbody tr:nth-child(even) {
		     border-bottom: 1px solid rgba(30,34,92,0.46); 
		    background-color: #ededed;
		}
		tbody tr:nth-child(even) {
		     border-bottom: 1px solid rgba(30,34,92,0.46) !important; 
		    background-color: transparent !important;
		    border: 1px solid rgba(30,34,92,0.46) !important;
		}
		tbody th, tbody td {
		    padding: 0.5rem 0.625rem 0.625rem;
		    border-bottom: 1px solid rgba(0,0,0,0.039);
		}
		tbody th, tbody td {
   			padding: 15px !important;
   		}
   		table tr {
		    box-shadow: 0 1.5px 3px 0 rgba(0,0,0,0.16);
		    border-radius: 10px;
		}*/
}

@media handheld, only screen and (max-width: 991px) {
	.flex{
		flex-wrap: wrap;
	}
	.left-text {  
	    margin-bottom: 50px;
	}
}

@media handheld, only screen and (max-width: 767px) {
	
	.flex-basis-50, .flex-basis-30 {
	    flex-basis: 100%;
	}
	.banner-wrapper:before {
	    background-color: transparent;
	}
	.left-text {
	    padding-right: 0px;
	}
	.categories-body{
		margin-top: 15px;
	}
	.bg-color {	    
	    height: 280px;
	    border-radius: 20px 0px 0px 50px;	  
	}
	.profile-img-img {
	    width: 75%;
	}
	p.designation {
	    bottom: unset;
	    left: 20px;
	    top: 20px;
	    z-index:50;
	}
	button.btn-yellow.mr-10, .black-border-btn {
	    display: block;
	    width: 100%;
	    margin-bottom: 15px;
	    margin-right: 0px;
	}
	p.designation strong {
	    font-size: 18px;
	    color: white;
	}
	.img-caption strong{
	    color: #1e225c;
	    padding-left: 27px;
	}
	    
	.img-caption {
	    top: auto;
	    bottom: -89px;
	    background: #fff;
	    z-index: 1;
	    color: #1e225c;
	    left: 0;
	    width: 95%;
	    border-radius: 20px;	    
	    backdrop-filter: initial;
	}
	.img-caption p {
	    width: auto;	 
	    font-size: 13px;
	    padding-left: 25px;
	    margin-bottom: 5px;
	    display: inline-block;
	    vertical-align: top;	
	    font-weight: 500;
	    font-size: 14px !important;
	}
	.img-caption svg {
	    position: absolute;
	    top: 20px;
	}
	.listing {	    
	    padding: 110px 0px 0px 20px;
	    display: block;
	    width: 100%;
	}
	.listing div {
	    position: relative;
	    margin-bottom: 20px;
	}
	.title h2, h2.h2 {
	    font-size: 24px;
	}
	.design-training-slider:before, .Wij-trainers-wrapper:before, .online-training-slider:before{
		display: none;
	}
	.tab-head {
	    display: flex;
	    padding-bottom: 25px;
	    overflow: auto;
	}
	ul.tabbing {
	    display: flex;
	    white-space: nowrap;
	    /*overflow: scroll;*/
	}
	.tab-head h6{
		padding: 0px;
	}
	.tab-title {
	    flex-basis: 100%;
	    margin-top: 10px;
	}
	.slider__counter {
	    top: -50px; 
	    font-size: 16px;
	}
	.slide-box {
    	margin-right: 0px;
    }
    .slick-slideshow__slide {
	    padding: 0px 10px;
	}	
	.slick-next,.slick-prev {    
		display: none !important;
	}
	.design-training-slider .slick-dots, .online-training-slider .slick-dots {
	    position: absolute;
	    bottom: -70px !important;
	}
	.slick-dots {
	    position: absolute;
	    top:0px;
	}
	.slick-dots li.slick-active button:before {
	    opacity: 1 !important;
	   background-color: #1E225C !important;
	}
	.slick-dots li button:before {	   
	    font-size: 10px !important;
	    line-height: 10px !important;
	    opacity: .25 !important;
	    color: transparent !important;
	   	background-color: #E5E5E5 !important;	  
	    border: 2px solid #707070; 	   	
    	border-radius: 100%;
    	height: 10px !important;
    	width: 10px !important;

	}
	.slick-dots li{
		margin: 0px !important;
	}
	.Waarom-box{
		flex-basis: 48%;
		margin: 0px;
    	margin-bottom: 20px;
	}
	.Waarom-box {
	    padding: 15px;
	}
	.Waarom-body {
    	margin-top: 0px;
	}

	.Waarom-body {
	    position: static;	   
	    margin-top: 0px;
	    padding: 50px 0px 0px;
	}
	.Waarom-bg {	   
	    margin-top: 15px;
	    margin-bottom: 60px;
	}
	.design-training-slider-body{
		margin-bottom: 0px;
	}
	.Waarom-video-text {
	    padding-top: 30px;
	    padding-left: 0px;
	}
	.Wil-wrapper{
		padding: 50px 0px;
		    margin-bottom: 0px;
	}
	.will-text {
	    width: 100% !important;
	}
	.will-img {
	    width: 100%;
	    height: auto;
	    margin-top: 30px;
	    margin-bottom: 30px;
	}
	.Wij-trainers-wrapper {
	    padding: 50px 0px 50px 0px;
	}
	.Wij-trainers-text {
	     width: 100%; 
	}
	.Wij-slider-body{
		width: 100%;
		padding-top: 30px;
	}		
	.praktijkcases-wrapper {
	    margin-top: 0px;
	    margin-bottom: 25px;
	}
	.left-imgbox{
		width: 100%;		
    	height: 300px;
	}
	.contact-box{
		position: static;
		width: 100%;
		box-shadow: none;
		padding-top: 30px;
		margin-top: 0px;
		margin-left: 0px ;
	}
	.kom-img {
	    width: 100%;
	    height: auto;
	    margin-top: 30px;
	}
	.Waarom-video-wrapper {
	    margin-top: 50px;
	    margin-bottom: 60px;
	}
	.Meer-body .slide-box {
	    flex-basis: 100%;
	    margin-bottom: 25px;
	}
	.blue-fill-btn {
	    display: block;
	    width: 100%;
	    margin-bottom: 15px;
	    text-align: center;
	}
	.overleggen-img {
	    text-align: left;
	     padding-left: 0px; 
	    display: block;
	    width: 100%;
	    margin-top: 20px;
	    padding: 40px 0px;
	}
	.overleggen-img img {
	    position: absolute;
	    right: 0px !important;
	    bottom: 0px;
	    height: 200px !important;
	}
	.Meer-wrapper {
	    margin-bottom: 50px;
	}
	.direct-ux-body {
    	margin-right: 0px;
    }
    .direct-ux-slider .slick-dots li.slick-active button:before {
	    opacity: 1 !important;
	    background-color: #ffffff !important;
	}
	.direct-ux-slider .slick-dots li button:before{
		border: 2px solid #ffffff;
	}
	.alles-left {
	    padding-right: 0px;
	}
	.alles-left .icon-ul li {
	    padding-right: 10px;
	    font-size: 12px;
	}	
	.alles-rigth-card{
		position: static;
		width: 100%;
		box-shadow: none;
		padding: 27px 20px 20px;
	}
	.link {
	    font-size: 14px;
	    text-align: center;	   
	    margin-bottom: 15px;
	    text-align: left;
	    display: block;
	    width: 100%;
	}
	.box-foot-nav .link {
		 text-align: left;
	}
	.direct-ux-wrapper p.white {
	    font-size: 18px !important;
	}
	.alles-img-block{
		height: fit-content;		
	}

	/*.Eerstvolgende-wrapper {
	    padding: 20px 0px;
	}*/
	.Eerstvolgende-wrapper .slide-box {
	    width: 100%;
	    margin-bottom: 20px;
	}
	.overleggen-wrapper {
	    margin: 20px 0px 50px 0px;
	}
	.overleggen-body{
		padding:25px;
		overflow: hidden;
	}
	.left-fqa-text {
	    padding-right: 0px;
	}
	ul.accordion li a:after{
		right: 5px;
	}
	.design-training-slider, .design-training-slider-fullwidth, .online-training-slider {
	    margin-bottom: 50px !important;
	}
	.right-img {	    
	    position: static;
	    right: 0;
	    width: 100%;
	    border-radius: 20px 0 0 90px;
	    overflow: unset;
	}
	.yellow-border-btn{
		    padding: 18px 26px;
	}
	.blue-fill-btn {
	    padding: 13px 20px;
	}
	.categories-body h1:not(.categorie__overview__title):not(.training-intro__titel):not(.training-header__title), .categorie__container h1:not(.categorie__overview__title):not(.training-intro__titel):not(.training-header__title):not(.training-header__title){
	    //font-size: 48px!important;
	    color: #1e225c;
	    line-height: 40px!important;
	}
	.deze-voor-box{
		margin: 15px 5px;
	}
	.deze-voor-slider .slick-dots {
	    position: absolute;
	    bottom: -40px !important;
	}
    .box {
      display: flex;
      flex-direction: row;
    }
    .Wil-wrapper .will-text { order: 1; }
    .Wil-wrapper .flot-right .will-img { order: 1; }
    .van-wireframes a.link {
	    border: 2px solid #1E225C;
	    border-radius: 8px;
	    opacity: 1;
	    padding: 13px 20px;
	    -webkit-transition: all .4s ease;
	    -o-transition: all .4s ease;
	    -moz-transition: all .4s ease;
	    transition: all .4s ease;
	    color: #1E225C;
	    text-align: center;
	    display: inline-block;
	    font-weight: bolder;
	}
	.banner-tab {
	    width: 100%;
	    margin: 10px 0px;
	    padding: 20px;
	}
	.product-detail-banner .right-img .bg-color {
	    height: 300px!important;
	}
	.product-detail-banner .bg-color {	   
	    height: fit-content;
	}
	
	.product-detail-banner .right-img .bg-color img {
		//display:none;
	}
	.product-detail-banner .left-text {
	    margin-bottom: 10px;
	}
	.faq-wrapper {
	    margin-bottom: 60px;
	}
	/*27-10-2021*/
	.programma-body-box ul.tabbing {
	    display: flex;
	    white-space: nowrap;
	  /*  overflow: scroll;*/
	    flex-wrap: wrap;
	}
	.programma-body-box {
	    padding: 20px;
	}
	.verticle-tabbing ul.tabbing li {
	    font-size: 13px;
	    width: 80%;
	}
	.verticle-tabbing ul.tabbing:before {
	    border-right: none;	  
	     width: unset; 
	    height: 100%;
	    background: transparent;	    
	}
	.verticle-tabbing .tab-title {	   
	    height: 100%;
	}
	.tab-content .left  {
	    padding-right: 0px;
	}
	.tab-content .right {
	    padding-left: 0px;
	}
	.Wil-wrapper.mt-120, .programma-wrapper.mt-120 {
	    margin-top: 0px;
	}
	.post-hbo {
	    margin: 60px 0px;
	}
	iframe {
	    width: 100%;
	}
	tbody th, tbody td {
	    padding: 0.5rem 0.625rem 0.625rem;
	    white-space: nowrap;
	}
	.banner-tab ul.tabbing li{
		font-size: 11px;
	}
	.tab-btn a {
	    display: block;
	    margin-bottom: 10px;
	    text-align: center;
	}
	.product-detail-banner, .product-detail-banner:before{
		background-color: transparent;
	}
	.hbo-body-left {
	    padding-right: 0px;
	    width: 100%;
	    margin-top: 100px;
	}
	.product-detail-banner .link {
	     border-bottom: none;
	}
	.bg-sm-transparent{
		background-color: transparent !important;
	}
	.programma-wrapper{
		margin-bottom:150px;
	}
	.programma-wrapper ul.accordion li a:after {
	    right: 20px;
	}
	.programma-wrapper a.toggle.active{
	    padding-right: 25px;
	    background: #1E225C;
	    color: #fff;
	    padding: 15px;
	    margin-bottom: 20px;
	    padding-right: 25px !important;
	}
	.programma-wrapper ul li {
		border-bottom:  none;
	}
	.desktop{
		display: none;
	}
	.mobile{
	    display: block;
	}
	.deze-voor-wrapper.mt-120 {
	    margin-top: 30px;
	}
	#cursusdata {
	    margin-top: 60px;
	    background-color:#F5F5F5;
	}
	
	.right-img.flex-basis-50 .incompany__intro__container__background__overlay{
		bottom: -80px;
		top:unset;
	}
	.blue-reg-box .link {
		width:initial;
	}

}

	.profile-img-img img{			
		height:300px;
		object-fit:contain;

		@include breakpoint(medium){
		
			height:550px;
			object-fit:contain;
		}
	}
@media handheld, only screen and (max-width: 639px) {}

@media handheld, only screen and (max-width: 550px) {}

@media handheld, only screen and (max-width: 479px) {}